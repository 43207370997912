import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, TreeSelect, Input } from "antd";

import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";

import { ResultCodeEnum, permissionsApi, roleApi } from "@/api/index";

const FormItem = Form.Item;
const { TextArea } = Input;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading
    treeData: [],
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
    this.getUpmsPermissions();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await roleApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      const permissionCodeList = [];
      detail.permissionList.map((el) => {
        permissionCodeList.push(el.code);
      });
      detail.permissionCodeList = permissionCodeList;
      this.setState({
        detail: detail,
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        name: detail.name,
        permissionCodeList: detail.permissionCodeList,
        remark: detail.remark,
      });
    }
  };

  // 获取权限列表
  getUpmsPermissions = async () => {
    const res = await permissionsApi.list();
    if (res.code === ResultCodeEnum.success.code) {
      const treeData = Utils.transformTreeData(res.data, { id: null });
      this.setState({
        treeData,
      });
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { history } = this.props;
    const { id, isEdit } = this.state;
    values.permissionCodeList = values.permissionCodeList.join(",");
    this.setState({ submitting: true });
    if (isEdit) {
      const res = await roleApi.update(id, values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("编辑成功", () => {
          history.push(`/user/role/index`);
        });
      }
    } else {
      const res = await roleApi.create(values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("提交成功", () => {
          history.push(`/user/role/index`);
        });
      }
    }
  };

  render() {
    const { isEdit, detail, submitting } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/user/role/index`}>
                  <span>角色管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{isEdit ? "编辑角色" : "新增角色"}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="角色名称"
                name="name"
                rules={[{ required: true, message: "请输入角色名称" }]}
              >
                <Input placeholder="请输入角色名称" />
              </FormItem>

              {!isEdit && (
                <FormItem
                  {...formItemLayout}
                  label="编码"
                  name="code"
                  rules={[{ required: true, message: "请输入编码" }]}
                >
                  <Input placeholder="请输入编码" />
                </FormItem>
              )}

              {isEdit && (
                <FormItem {...formItemLayout} label="编码">
                  <span>{detail.code}</span>
                </FormItem>
              )}

              <FormItem
                {...formItemLayout}
                label="权限分配"
                name="permissionCodeList"
                rules={[{ required: true, message: "请选择权限" }]}
              >
                <TreeSelect
                  allowClear
                  multiple
                  placeholder="请选择权限"
                  showCheckedStrategy={TreeSelect.SHOW_ALL}
                  treeCheckable
                  treeData={this.state.treeData}
                />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="备注"
                name="remark"
                rules={[{ required: false, message: "请填写备注(选填)" }]}
              >
                <TextArea placeholder={"请填写备注(选填)"} rows={4} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const RoleDetail = List;
export default RoleDetail;
