import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Card, Descriptions, Button, Row, Col } from "antd";

import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, enterprisesApi, enterpriseFinancesApi } from "@/api/index";
class Detail extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    enterpriseId: "",
    detail: {}, // 详情
    enterpriseInfo: "",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const id = match.params.id;
    const enterpriseId = match.params.enterpriseId;
    this.setState({
      id,
      enterpriseId,
    });
    this.getDetail(id);
    this.getEnterpriseInfo();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();
  /** 获取企业信息 */
  getEnterpriseInfo = async (id) => {
    const res = await enterprisesApi.detail(this.props.match.params.enterpriseId);
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        enterpriseInfo: res.data,
      });
    }
  };
  // 获取详情
  getDetail = async (id) => {
    const res = await enterpriseFinancesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({
        detail,
      });
    }
  };

  ColAttachment = (label, value) => {
    const ColSpan = { xs: 24, sm: 12, md: 12, lg: 12 };
    return (
      <Col className="details_col" lg={ColSpan.md} md={ColSpan.md} sm={ColSpan.md} xs={ColSpan.xs}>
        <Row className="details_col_row" gutter={20}>
          <Col span={24}>
            {value.length > 0 ? (
              value.map((el, index) => {
                return (
                  <div key={index}>
                    <div className="details_col_value attachment">
                      <span className="attachment_name">{el.name}</span>
                      <Button
                        className="download"
                        loading={el.isPull}
                        onClick={this.onDownload.bind(this, el.url)}
                        type="link"
                      >
                        下载
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>无</div>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  onDownload = (url) => {
    window.open(url);
  };

  /** 打开 */
  handleModalOpen = () => {
    this.setState({
      visible: true,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 取消 */
  handleModalCancel = () => {
    this.setState({
      visible: false,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 确定 */
  handleModalOk = async (values) => {
    this.setState({
      modalLoading: true,
    });
    const id = this.state.id;
    const res = await enterprisesApi.audit(id, values);
    this.setState({
      modalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.handleModalCancel();
      this.getDetail(id);
      dialog.success("操作成功");
      this.setState({
        auditStatus: "",
      });
    }
  };

  render() {
    const { detail } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index`}>
                  <span>企业管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index/finance/${this.state.enterpriseId}`}>
                  <span>财务情况</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>查看详情</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Descriptions bordered title="财务情况详情">
              <Descriptions.Item label="企业名称" span={3}>
                {this.state.enterpriseInfo.name}
              </Descriptions.Item>
              <Descriptions.Item label="年份" span={3}>
                {detail.year || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="季度" span={3}>
                {detail.quarter === 0
                  ? "全年"
                  : detail.quarter === 1
                  ? "第一季度"
                  : detail.quarter === 2
                  ? "第二季度"
                  : detail.quarter === 3
                  ? "第三季度"
                  : detail.quarter === 4
                  ? "第四季度"
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="营收(万元)">{detail.revenue || "-"}</Descriptions.Item>
              <Descriptions.Item label="净利润(万元)">{detail.netProfit || "-"}</Descriptions.Item>
              <Descriptions.Item label="总资产(万元)">{detail.totalAssets || "-"}</Descriptions.Item>
              <Descriptions.Item label="总负债(万元)" span={3}>
                {detail.totalLiabilities || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="创建时间" span={3}>
                {detail.gmtCreate ? Utils.formatDate(detail.gmtCreate, "yyyy-mm-dd hh:ii") : "-"}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseManagementDetail = Detail;
export default EnterpriseManagementDetail;
