import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, Input, DatePicker, Select } from "antd";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";
import { checkDigit } from "@/util/formValidation";

import { ResultCodeEnum, enterpriseFinancesApi } from "@/api/index";

import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await enterpriseFinancesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({
        detail: detail,
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        year: detail.year ? moment(detail.year, "YYYY") : undefined,
        quarter: detail.quarter,
        revenue: detail.revenue,
        netProfit: detail.netProfit,
        totalAssets: detail.totalAssets,
        totalLiabilities: detail.totalLiabilities,
        creditDetail: detail.creditDetail,
      });
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const enterpriseId = this.props.match.params.enterpriseId;
    const { history } = this.props;
    const { id, isEdit } = this.state;
    values.enterpriseId = enterpriseId;
    values.year = values.year.format("YYYY");
    this.setState({ submitting: true });
    if (isEdit) {
      const res = await enterpriseFinancesApi.update(id, values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("编辑成功", () => {
          history.push(`/enterprise/management/index/finance/${enterpriseId}`);
        });
      }
    } else {
      const res = await enterpriseFinancesApi.create(values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("提交成功", () => {
          history.push(`/enterprise/management/index/finance/${enterpriseId}`);
        });
      }
    }
  };

  render() {
    const { isEdit, submitting } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index`}>
                  <span>企业管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index/finance/${this.props.match.params.enterpriseId}`}>
                  <span>财务情况</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{isEdit ? "编辑" : "新增"}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="年份"
                name="year"
                rules={[{ required: true, message: "请选择年份" }]}
              >
                <DatePicker picker="year" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="季度"
                name="quarter"
                rules={[{ required: true, message: "请选择季度" }]}
              >
                <Select placeholder="请选择季度">
                  <Option value={0}>全年</Option>
                  <Option value={1}>第一季度</Option>
                  <Option value={2}>第二季度</Option>
                  <Option value={3}>第三季度</Option>
                  <Option value={4}>第四季度</Option>
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="营收"
                name="revenue"
                rules={[{ required: true, message: "请输入营收" }, { validator: checkDigit }]}
              >
                <Input addonAfter="万元" placeholder="请输入营收" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="净利润"
                name="netProfit"
                rules={[{ required: true, message: "请输入净利润" }, { validator: checkDigit }]}
              >
                <Input addonAfter="万元" placeholder="请输入净利润" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="总资产"
                name="totalAssets"
                rules={[{ required: true, message: "请输入总资产" }, { validator: checkDigit }]}
              >
                <Input addonAfter="万元" placeholder="请输入总资产" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="总负债"
                name="totalLiabilities"
                rules={[{ required: true, message: "请输入总负债" }, { validator: checkDigit }]}
              >
                <Input addonAfter="万元" placeholder="请输入总负债" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="银行授信明细"
                name="creditDetail"
                rules={[{ required: false, message: "请输入总负债" }]}
              >
                <TextArea placeholder="选填" rows={4} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseFinanceForm = List;
export default EnterpriseFinanceForm;
