import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Table,
  Breadcrumb,
  Card,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Modal,
  Checkbox,
  Space,
  Cascader,
  Timeline,
} from "antd";

import "@/component/carousel/styles.less";

import { TableSize, loanStatusdictItemId, FormSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { checkMoney } from "@/util/formValidation";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import { ResultCodeEnum, enterpriseLoansApi, dictApi, mycenterApi, baseApi } from "@/api/index";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

import "./styles.less";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    /** 银行信息 */
    bankList: [],
    userList: [],
    userInfo: {},

    /** 弹窗 */
    visible: false,
    visibleKey: 1,
    modalLoading: false,

    /** 弹窗 */
    setupVisible: false,
    loanVisible: false,
    loanVisibleKey: 100,
    loanModalLoading: false,

    loanStatusList: [],

    selectedIndex: "",

    provincesList: [],

    progressList: [],
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getUserInfo();
    this.getLoanStatusList();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  loadsFormRef = React.createRef();
  setupFormRef = React.createRef();

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          userInfo: res.data,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    if (this.state.userInfo.loginName !== "admin" && this.state.userInfo.roleCodes.indexOf("bank_manager") > -1) {
      params.userId = this.state.userInfo.id;
    }
    this.setState({ loading: true });
    const res = await enterpriseLoansApi.listForEnterprise({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取贷款状态列表 */
  getLoanStatusList = async () => {
    const res = await dictApi.list({
      pageNum: 0,
      pageSize: 100,
      dictItemId: loanStatusdictItemId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        loanStatusList: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    if (values.year) {
      values.year = values.year.format("YYYY");
    }
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /** 打开 */
  handleModalOpenLoan = async (enterpriseLoanId) => {
    const res = await enterpriseLoansApi.progressList({
      enterpriseLoanId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        progressList: res.data,
      });
    }
    this.setState(
      {
        loanVisible: true,
        loanVisibleKey: this.state.visible + 1,
      },
      () => {}
    );
  };
  handleModalOpenSetup = () => {
    this.getAllProvinces();
    this.setState({
      setupVisible: true,
    });
  };

  /** 取消 */
  handleModalCancelLoan = () => {
    this.setState({
      loanVisible: false,
      loanVisibleKey: this.state.visible + 1,
    });
  };
  handleModalCancelSetup = () => {
    this.setState({
      setupVisible: false,
    });
  };

  /** 确定 */
  handleModalOkLoan = async (values) => {
    this.setState({
      loanModalLoading: true,
    });
    const { selectedIndex, list } = this.state;
    const id = list[selectedIndex].id;
    const res = await enterpriseLoansApi.update(id, values);
    this.setState({
      loanModalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.handleModalCancelLoan();
      dialog.success("更新成功");
      list[selectedIndex].loansStatus = values.loansStatus;
      list[selectedIndex].actualLoans = Number(values.actualLoans);
      list[selectedIndex].remark = values.remark;
      this.setState({
        list,
      });
    }
  };
  handleModalOkSetup = async (values) => {
    console.log(values);
  };

  /** 行政区域 */
  // 拉取全部省级区域
  getAllProvinces = async () => {
    const res = await baseApi.listProvinces();
    if (res.code === ResultCodeEnum.success.code) {
      const provincesList = res.data.map((item) => {
        return {
          value: item.areaCode,
          label: item.name,
          isLeaf: false,
        };
      });
      this.setState({ provincesList });
    }
  };

  // 拉取所有相关 areaCode 的全部下级区域
  getListChildren = async (areaCode) => {
    const res = await baseApi.listChildren({ areaCode });
    if (res.code === ResultCodeEnum.success.code) {
      const result = res.data.map((item) => {
        return {
          value: item.areaCode,
          label: item.name,
        };
      });
      return result;
    }
    return [];
  };

  // 拉取相关 areaCode 的整条路径(两级)
  getParentPaths = async (areaCode) => {
    const res = await baseApi.getParentPaths({ areaCode });
    if (res.code === ResultCodeEnum.success.code) {
      const result = res.data.map((item) => {
        return {
          value: item.areaCode,
          label: item.name,
        };
      });
      return result;
    }
    return {};
  };

  /**
   * 懒加载相关联 areaCode 的全部下级区域
   * @param {*} selectedOptions
   */
  loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const res = await this.getListChildren(targetOption.value);
    targetOption.loading = false;
    targetOption.children = res;
    this.setState({});
  };

  render() {
    const { list, loading, pagination, provincesList } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "企业名称",
        dataIndex: "enterpriseName",
        key: "enterpriseName",
      },
      {
        title: "贷款金额(万元)",
        dataIndex: "intentionLoans",
        key: "intentionLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "实际贷款金额(万元)",
        dataIndex: "actualLoans",
        key: "actualLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "银行名称",
        dataIndex: "houseName",
        key: "houseName",
      },
      {
        title: "支行",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "客户经理",
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: "状态",
        dataIndex: "loansStatus",
        key: "loansStatus",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              <Button onClick={this.handleModalOpenLoan.bind(this, record.id)} type="link">
                查看进度
              </Button>
            </div>
          );
        },
      },
    ];

    /**
     * 高级设置选项
     */
    const advanceSettings = [
      {
        key: "intentionLoans",
        title: "贷款金额",
        options: [
          {
            label: "100万以内",
            value: "100万以内",
          },
          {
            label: "100万-200万",
            value: "100万-200万",
          },
          {
            label: "200万-500万",
            value: "200万-500万",
          },
          {
            label: "500万-1000万",
            value: "500万-1000万",
          },
          {
            label: "1000万以上",
            value: "1000万以上",
          },
        ],
      },
      {
        key: "regCapitals",
        title: "注册资本",
        options: [
          {
            label: "100万以内",
            value: "100万以内",
          },
          {
            label: "100万-200万",
            value: "100万-200万",
          },
          {
            label: "200万-500万",
            value: "200万-500万",
          },
          {
            label: "500万-1000万",
            value: "500万-1000万",
          },
          {
            label: "1000万以上",
            value: "1000万以上",
          },
        ],
      },
      {
        key: "estiblishTime",
        title: "成立时间",
        options: [
          {
            label: "1年内",
            value: "1年内",
          },
          {
            label: "1-5年",
            value: "1-5年",
          },
          {
            label: "5-10年",
            value: "5-10年",
          },
          {
            label: "10-15年",
            value: "10-15年",
          },
          {
            label: "15年以上",
            value: "15年以上",
          },
        ],
      },
      {
        key: "regStatus",
        title: "企业状态",
        options: [
          {
            label: "吊销",
            value: "吊销",
          },
          {
            label: "注销",
            value: "注销",
          },
          {
            label: "停业",
            value: "停业",
          },
          {
            label: "撤销",
            value: "撤销",
          },
          {
            label: "迁入",
            value: "迁入",
          },
          {
            label: "迁出",
            value: "迁出",
          },
          {
            label: "存续（在营、开业、在业）",
            value: "存续（在营、开业、在业）",
          },
        ],
      },
      {
        key: "companyOrgType",
        title: "企业类型",
        options: [
          {
            label: "有限责任公司",
            value: "有限责任公司",
          },
          {
            label: "股份有限公司",
            value: "股份有限公司",
          },
          {
            label: "集体所有制",
            value: "集体所有制",
          },
          {
            label: "股份合作制",
            value: "股份合作制",
          },
          {
            label: "国有企业",
            value: "国有企业",
          },
          {
            label: "个体工商户",
            value: "个体工商户",
          },
          {
            label: "个人独资企业",
            value: "个人独资企业",
          },
          {
            label: "有限合伙",
            value: "有限合伙",
          },
          {
            label: "普通合伙",
            value: "普通合伙",
          },
          {
            label: "外商投资企业",
            value: "外商投资企业",
          },
          {
            label: "港、澳、台",
            value: "港、澳、台",
          },
          {
            label: "联营企业",
            value: "联营企业",
          },
          {
            label: "私营企业",
            value: "私营企业",
          },
        ],
      },
      {
        key: "beListedStatus",
        title: "上市状态",
        options: [
          {
            label: "未上市",
            value: "未上市",
          },
          {
            label: "A股",
            value: "A股",
          },
          {
            label: "中概股",
            value: "中概股",
          },
          {
            label: "港股",
            value: "港股",
          },
          {
            label: "科创板",
            value: "科创板",
          },
          {
            label: "新三板",
            value: "新三板",
          },
          {
            label: "新四板",
            value: "新四板",
          },
        ],
      },
      {
        key: "scienceTechnologyType",
        title: "科技型企业",
        options: [
          {
            label: "科技型中小企业",
            value: "科技型中小企业",
          },
          {
            label: "民营科技企业",
            value: "民营科技企业",
          },
          {
            label: "瞪羚企业",
            value: "瞪羚企业",
          },
          {
            label: "专精特新企业",
            value: "专精特新企业",
          },
          {
            label: "企业技术中心",
            value: "企业技术中心",
          },
          {
            label: "科技小巨人企业",
            value: "科技小巨人企业",
          },
          {
            label: "雏鹰企业",
            value: "雏鹰企业",
          },
          {
            label: "众创空间",
            value: "众创空间",
          },
          {
            label: "专精特新小巨人企业",
            value: "专精特新小巨人企业",
          },
          {
            label: "科技企业孵化器",
            value: "科技企业孵化器",
          },
          {
            label: "技术创新示范企业",
            value: "技术创新示范企业",
          },
          {
            label: "隐形冠军企业",
            value: "隐形冠军企业",
          },
          {
            label: "技术先进型服务企业",
            value: "技术先进型服务企业",
          },
          {
            label: "独角兽企业",
            value: "独角兽企业",
          },
        ],
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>贷款进度</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          {/* <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label={`状态`} name={`loansStatus`}>
                    <Select placeholder="请选择">
                      <Option value={""}>全部</Option>
                      {this.state.loanStatusList.map((item, index) => {
                        return (
                          <Option key={index + 1000} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={`核心企业`} name={`enterpriseName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card> */}
          {/** 表格列表 */}
          <Card bordered={false}>
            {/* <div className="tableListOperator flex">
              <Button onClick={this.handleModalOpenSetup.bind(this)} type="primary">
                高级设置
              </Button>
            </div> */}
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.loanVisibleKey}
          loading={this.state.loanModalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancelLoan}
          onOk={this.handleModalOkLoan}
          title={`贷款进度`}
          visible={this.state.loanVisible}
          width={600}
        >
          <Timeline mode={"left"}>
            {this.state.progressList.map((item, index) => {
              return (
                <Timeline.Item key={item.id} label={Utils.formatDate(item.gmtCreate, "yyyy-mm-dd hh:ii:ss")}>
                  <p>状态: {item.loansStatus}</p>
                  <p>实际贷款金额(万元): {item.actualLoans}</p>
                  <p>备注: {item.remark ? item.remark : "无"}</p>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Modal>
      </div>
    );
  }
}

const LoansAuditList = List;
export default LoansAuditList;
