import React from "react";
import { Route, Redirect } from "react-router-dom";

import UpdatePwd from "./updatePwd";
import ResetPwd from "./resetPwd.component";

import EnterpriseManagementForm from "@/component/enterprise/management/form.component";

class Mycenter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />

        <Route component={(props) => <UpdatePwd {...props} />} exact path={`/myCenter/index/updatePwd`} />

        <Route
          component={(props) => <EnterpriseManagementForm form={`enterprise`} {...props} />}
          exact
          path={`/myCenter/index/updateEnterprise`}
        />

        <Route component={(props) => <ResetPwd {...props} />} exact path={`/resetPwd/index/:id`} />
      </div>
    );
  }
}

export default Mycenter;
