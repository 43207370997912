import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, Select, Input } from "antd";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";
// import { radio_image } from "@/util/imageUpload";

import { ResultCodeEnum, userApi, roleApi, districtApi, banksApi, housesApi, upmsOrgsApi } from "@/api/index";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading
    imgUploadLoading: false,

    roleList: [],

    provinces: [],
    city: [],
    provinceCode: "",

    bankList: [],
    BankBranchList: [],
    orgList: [],

    roleCode: "",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    const subBankId = match.params.subBankId;
    const bankId = match.params.bankId;
    if (subBankId) {
      this.setState(
        {
          roleCode: "bank_manager",
          subBankId,
          bankId,
        },
        () => {
          this.getBankBranchList(bankId);
        }
      );
    }

    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
    this.getRoleList();
    this.getBankList();
    this.getOrgList();
    // 获取地区数据
    // this.getListProvinces();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await userApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      const roleCodeList = [];
      detail.roles.map((el) => {
        roleCodeList.push(el.code);
      });
      detail.roleCodeList = roleCodeList;
      this.setState({
        detail: detail,
        roleCode: detail.roleCodeList[0],
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        headImg: detail.headImg,
        loginName: detail.loginName,
        name: detail.name,
        phone: detail.phone,
        email: detail.email,
        gender: detail.gender,
        roleCodeList: detail.roleCodeList,
        houseId: detail.houseId,
        bankId: detail.bankId,
        orgId: detail.orgId,
        position: detail.position,
        remark: detail.remark,
      });
      if (detail.cityCode) {
        this.getParentPaths(detail.cityCode);
      }
      if (detail.houseId) {
        this.getBankBranchList(detail.houseId);
      }
    }
  };

  // 获取角色列表
  getRoleList = async () => {
    const res = await roleApi.list({ pageNum: 0, pageSize: 100 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        roleList: res.data.rows,
      });
    }
  };

  // 获取分部列表
  getOrgList = async () => {
    const res = await upmsOrgsApi.list({ pageNum: 0, pageSize: 100 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        orgList: res.data.rows,
      });
    }
  };

  // 获取银行列表
  getBankList = async () => {
    const res = await housesApi.list({ pageNum: 0, pageSize: 500 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          bankList: res.data.rows,
        },
        () => {
          if (this.state.bankId) {
            this.formRef.current.setFieldsValue({
              houseId: parseInt(this.state.bankId),
            });
          }
        }
      );
    }
  };

  onChangeBank = async (value) => {
    this.getBankBranchList(value);
    this.formRef.current.setFieldsValue({
      bankId: undefined,
    });
  };

  /** 获取支行列表 */
  getBankBranchList = async (houseId) => {
    const res = await banksApi.list({ houseId, pageNum: 0, pageSize: 500 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          BankBranchList: res.data.rows,
        },
        () => {
          if (this.state.bankId) {
            this.formRef.current.setFieldsValue({
              roleCodeList: "bank_manager",
              bankId: parseInt(this.state.subBankId),
            });
          }
        }
      );
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { history } = this.props;
    const { id, isEdit, detail } = this.state;
    values.headImg = detail.headImg;
    this.setState({ submitting: true });
    if (isEdit) {
      const res = await userApi.update(id, values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("编辑成功", () => {
          history.go(-1);
        });
      }
    } else {
      const res = await userApi.create(values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("提交成功", () => {
          history.go(-1);
        });
      }
    }
  };

  /** 获取省份 */
  getListProvinces = async () => {
    const res = await districtApi.getListProvinces();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        provinces: res.data,
      });
    }
  };

  /** 根据areaCode获取下级区域 */
  getListChildren = async (code, type) => {
    const res = await districtApi.getListChildren({
      areaCode: code,
    });
    if (res.code === ResultCodeEnum.success.code) {
      if (type === "provinces") {
        this.setState({
          city: res.data,
        });
      }
    }
  };

  /** 地区选择回调 */
  onChangeDistrict = (value, type) => {
    if (type === "provinces") {
      this.formRef.current.setFieldsValue({
        cityCode: undefined,
      });
      this.setState(
        {
          provinceCode: value,
          city: [],
        },
        () => {
          this.getListChildren(value, type);
        }
      );
    }
  };

  getParentPaths = async (cityCode) => {
    const res = await districtApi.getParentPaths({ areaCode: cityCode });
    if (res.code === ResultCodeEnum.success.code) {
      this.getListChildren(res.data[1].areaCode, "provinces");
      this.setState({
        provinceCode: res.data[1].areaCode,
      });
      this.formRef.current.setFieldsValue({
        provinceCode: res.data[1].areaCode,
        cityCode: res.data[0].areaCode,
      });
    }
  };

  render() {
    const {
      isEdit,
      // detail,
      submitting,
    } = this.state;

    /**
     * 第一个函数为上传成功后的回调
     * 第二个函数为上传中的回调
     */
    // const UploadProps = radio_image(
    //   (data) => {
    //     const detail = this.state.detail;
    //     detail["headImg"] = data;
    //     this.setState({ detail, imgUploadLoading: false });
    //   },
    //   () => {
    //     this.setState({ imgUploadLoading: true });
    //   }
    // );

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            {!this.state.bankId && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/user/management/index`}>
                    <span>用户管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{isEdit ? "编辑用户" : "新增用户"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
            {this.state.bankId && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/bank/management/index`}>
                    <span>银行管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/bank/management/index/branch/${this.state.bankId}`}>
                    <span>支行管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/bank/management/index/branch/${this.state.bankId}/user/${this.state.subBankId}`}>
                    <span>客户经理管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{isEdit ? "编辑用户" : "新增用户"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              {/**
                <FormItem
                  {...formItemLayout}
                  label="头像"
                  name="headImg"
                  rules={[{ required: true, message: "请上传图片" }]}
                >
                  <Upload {...UploadProps}>
                    {detail.headImg ? (
                      <img alt="" className="img-upload" src={detail.headImg} />
                    ) : this.state.imgUploadLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                  </Upload>
                </FormItem>
              */}

              <FormItem
                {...formItemLayout}
                label="登录账号"
                name="loginName"
                rules={[{ required: true, message: "请输入登录账号" }]}
              >
                <Input placeholder="请输入登录账号" />
              </FormItem>

              {!isEdit && (
                <FormItem
                  {...formItemLayout}
                  extra="请输入8-20位，包含大小写字母和数字且不含其它特殊字符的密码"
                  label="登录密码"
                  name="loginPassword"
                  rules={[{ required: true, message: "请输入登录密码" }]}
                >
                  <Input.Password placeholder="请输入登录密码" />
                </FormItem>
              )}

              <FormItem
                {...formItemLayout}
                label="姓名"
                name="name"
                rules={[{ required: true, message: "请输入姓名" }]}
              >
                <Input placeholder="请输入姓名" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="手机号"
                name="phone"
                rules={[{ required: false, message: "请输入手机号" }]}
              >
                <Input placeholder="请输入手机号" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="职务"
                name="position"
                rules={[{ required: false, message: "请输入职务" }]}
              >
                <Input placeholder="请输入职务" />
              </FormItem>

              {/**    
                <FormItem
                  {...formItemLayout}
                  label="性别"
                  name="gender"
                  rules={[{ required: true, message: "请选择性别" }]}
                >
                  <Radio.Group>
                    <Radio value={`man`}>男</Radio>
                    <Radio value={`woman`}>女</Radio>
                  </Radio.Group>
                </FormItem>
              */}

              <FormItem
                {...formItemLayout}
                label="关联角色"
                name="roleCodeList"
                rules={[{ required: true, message: "请选择关联角色" }]}
              >
                <Select
                  disabled={this.state.isEdit || this.state.bankId}
                  onChange={(value) => {
                    this.setState({
                      roleCode: value,
                    });
                  }}
                  placeholder="请选择关联角色"
                >
                  {this.state.roleList.map((el) => {
                    return (
                      <Option key={el.id} value={el.code}>
                        {el.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>

              {this.state.roleCode === "bank_manager" && (
                <FormItem
                  {...formItemLayout}
                  label="所属银行"
                  name="houseId"
                  rules={[{ required: true, message: "请选择所属银行" }]}
                >
                  <Select
                    disabled={this.state.isEdit || this.state.bankId}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.onChangeBank.bind(this)}
                    optionFilterProp="children"
                    placeholder="请选择所属银行"
                    showSearch
                  >
                    {this.state.bankList.map((el) => {
                      return (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              )}

              {this.state.roleCode === "bank_manager" && (
                <FormItem
                  {...formItemLayout}
                  label="支行"
                  name="bankId"
                  rules={[{ required: true, message: "请选择支行" }]}
                >
                  <Select disabled={this.state.isEdit || this.state.bankId} placeholder="请选择支行">
                    {this.state.BankBranchList.map((el) => {
                      return (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              )}

              {this.state.roleCode !== "bank_manager" && (
                <FormItem
                  {...formItemLayout}
                  label="所属分部"
                  name="orgId"
                  rules={[{ required: true, message: "请选择所属分部" }]}
                >
                  <Select placeholder="请选择所属分部">
                    {this.state.orgList.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              )}

              {/** 
                <FormItem
                  {...formItemLayout}
                  label="所属省份"
                  name="provinceCode"
                  rules={[{ required: false, message: "请选择省份" }]}
                >
                  <Select onChange={(value) => this.onChangeDistrict(value, "provinces")} placeholder="请选择所属省份">
                    {this.state.provinces.map((item) => {
                      return (
                        <Option key={item.id} value={item.areaCode}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="所属城市"
                  name="cityCode"
                  rules={[{ required: this.state.provinceCode ? true : false, message: "请选择所属城市" }]}
                >
                  <Select onChange={(value) => this.onChangeDistrict(value, "city")} placeholder="请选择所属城市">
                    {this.state.city.map((item) => {
                      return (
                        <Option key={item.id} value={item.areaCode}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              */}

              <FormItem
                {...formItemLayout}
                label="备注"
                name="remark"
                rules={[{ required: false, message: "请填写备注(选填)" }]}
              >
                <TextArea placeholder={"请填写备注(选填)"} rows={4} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const UserDetail = List;
export default UserDetail;
