import React from "react";
import { Form, Input, Button, Card, Breadcrumb } from "antd";
import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";
import { mycenterApi } from "@/api/index";

const FormItem = Form.Item;

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      submitting: false,
    };
  }

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  handleSubmit = (values) => {
    const _this = this;
    _this.setState({
      submitting: true,
    });
    mycenterApi.updatePwd({ ...values }).then((res) => {
      _this.setState({
        submitting: false,
      });
      if (res.code === "OK") {
        this.formRef.current.setFieldsValue({
          oldPwd: "",
          newPwd: "",
          confirmPwd: "",
        });
        dialog.success("修改成功", () => {});
      }
    });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次密码输入不一致!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>修改密码</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.handleSubmit} ref={this.formRef}>
              <div>
                <FormItem
                  {...formItemLayout}
                  label="旧密码"
                  name="oldPwd"
                  rules={[{ required: true, message: "请填写旧密码!" }]}
                >
                  <Input placeholder="请输入" type="password" />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  extra="请输入8-20位，包含大小写字母和数字且不含其它特殊字符的密码"
                  label="新密码"
                  name="newPwd"
                  rules={[{ required: true, message: "请填写新密码!" }]}
                >
                  <Input placeholder="请输入" type="password" />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="确认密码"
                  name="confirmPwd"
                  rules={[
                    {
                      required: true,
                      message: "请确认密码",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("newPwd") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次密码输入不一致!");
                      },
                    }),
                  ]}
                >
                  <Input onBlur={this.handleConfirmBlur} placeholder="请输入" type="password" />
                </FormItem>
              </div>
              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={this.state.submitting} type="primary">
                  {this.state.submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const UpdatePwd = Detail;
export default UpdatePwd;
