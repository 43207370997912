import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Card, Row, Col, Input, Alert, Select, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "@/component/carousel/styles.less";

import { TableSize, loanStatusdictItemId, FormSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { checkMoney } from "@/util/formValidation";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import {
  ResultCodeEnum,
  enterpriseLoansApi,
  enterprisesApi,
  banksApi,
  userApi,
  dictApi,
  mycenterApi,
  housesApi,
} from "@/api/index";

const FormItem = Form.Item;
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},
    enterpriseId: "",
    enterpriseInfo: {},

    /** 银行信息 */
    bankList: [],
    BankBranchList: [],
    userList: [],
    userInfo: {},

    /** 弹窗 */
    recommendVisible: false,
    visible: false,
    visibleKey: 1,
    modalLoading: false,

    /** 弹窗 */
    loanVisible: false,
    loanVisibleKey: 100,
    loanModalLoading: false,

    loanStatusList: [],
    selectedIndex: "",

    recommendList: [],
    recommendPagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    recommendLoading: false,
    recommendConfig: {
      pageNum: 0,
      pageSize: 10,
    },
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    const enterpriseId = match.params.enterpriseId;
    this.setState({
      enterpriseId,
    });
    this.getUserInfo();
    this.getEnterpriseInfo(enterpriseId);
    this.getBankList();
    this.getLoanStatusList();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          userInfo: res.data,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      ...{
        enterpriseId: this.props.match.params.enterpriseId,
      },
    };
    if (this.state.userInfo.loginName !== "admin" && this.state.userInfo.roleCodes.indexOf("bank_manager") > -1) {
      params.userId = this.state.userInfo.id;
    }
    this.setState({ loading: true });
    const res = await enterpriseLoansApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取企业信息 */
  getEnterpriseInfo = async (id) => {
    const res = await enterprisesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        enterpriseInfo: res.data,
      });
    }
  };

  /** 获取银行列表 */
  getBankList = async () => {
    const res = await housesApi.list({ pageNum: 0, pageSize: 500 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        bankList: res.data.rows,
      });
    }
  };

  onChangeBank = async (value) => {
    this.getBankBranchList(value);
    this.formRef.current.setFieldsValue({
      branchId: undefined,
      userId: undefined,
    });
  };

  /** 获取支行列表 */
  getBankBranchList = async (houseId) => {
    const res = await banksApi.list({ houseId, pageNum: 0, pageSize: 500 });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        BankBranchList: res.data.rows,
      });
    }
  };

  onChangeBankBranch = async (value) => {
    this.getUserList(value);
    this.formRef.current.setFieldsValue({
      userId: undefined,
    });
  };

  /** 获取银行客户经理人员 */
  getUserList = async (bankId) => {
    const res = await userApi.list({ pageNum: 0, pageSize: 500, bankId });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        userList: res.data.rows,
      });
    }
  };

  /** 获取贷款状态列表 */
  getLoanStatusList = async () => {
    const res = await dictApi.list({
      pageNum: 0,
      pageSize: 100,
      dictItemId: loanStatusdictItemId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        loanStatusList: res.data.rows,
      });
    }
  };

  /** 获取智能推荐列表 */
  getRecommendList = async (params) => {
    params = {
      ...this.state.recommendConfig,
      ...params,
      ...{
        enterpriseId: this.props.match.params.enterpriseId,
      },
    };
    this.setState({ recommendLoading: true });
    const res = await enterpriseLoansApi.bankList({ ...params });
    this.setState({ recommendLoading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.recommendPagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      res.data.rows = res.data.rows.map((item) => {
        return {
          id: item.id,
          houseName: item.house.name,
          bankName: item.bank.name,
          bankAddressDistrictName: item.bank.addressDistrictName,
          managerName: item.manager.name,
          managerPhone: item.manager.phone,
        };
      });
      this.setState({
        recommendPagination: pagination,
        recommendList: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    if (values.year) {
      values.year = values.year.format("YYYY");
    }
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };
  onChangeRecommendTable = (pagination) => {
    const pager = { ...this.state.recommendPagination };
    const config = { ...this.state.recommendConfig };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      recommendPagination: pager,
      recommendConfig: config,
    });
    this.getRecommendList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
    });
  };

  /** 打开 */
  handleModalOpen = () => {
    this.setState({
      visible: true,
      visibleKey: this.state.visible + 1,
    });
  };
  handleModalOpenRecommend = () => {
    this.setState(
      {
        recommendList: [],
        recommendVisible: true,
      },
      () => this.getRecommendList()
    );
  };

  /** 取消 */
  handleModalCancel = () => {
    this.setState({
      visible: false,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 确定 */
  handleModalOk = async (values) => {
    const _this = this;
    confirm({
      title: "重要提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认提示该贷款申请吗?",
      cancelText: "取消",
      okText: "确定",
      async onOk() {
        _this.setState({
          modalLoading: true,
        });
        const res = await enterpriseLoansApi.create({
          enterpriseId: _this.state.enterpriseId,
          intentionLoans: values.intentionLoans,
          userId: values.userId,
        });
        _this.setState({
          modalLoading: false,
        });
        if (res.code === ResultCodeEnum.success.code) {
          _this.handleModalCancel();
          dialog.success("申请成功");
          _this.getList();
        }
      },
    });
  };

  /** 打开 */
  handleModalOpenLoan = (detail, index) => {
    this.setState(
      {
        loanVisible: true,
        loanVisibleKey: this.state.visible + 1,
        selectedIndex: index,
      },
      () => {
        this.formRef.current.setFieldsValue({
          loansStatus: detail.loansStatus,
          actualLoans: detail.actualLoans,
          remark: detail.remark,
        });
      }
    );
  };

  /** 取消 */
  handleModalCancelLoan = () => {
    this.setState({
      loanVisible: false,
      loanVisibleKey: this.state.visible + 1,
    });
  };
  handleModalCancelRecommend = () => {
    this.setState({
      recommendVisible: false,
    });
  };

  /** 确定 */
  handleModalOkLoan = async (values) => {
    this.setState({
      loanModalLoading: true,
    });
    const { selectedIndex, list } = this.state;
    const id = list[selectedIndex].id;
    const res = await enterpriseLoansApi.update(id, values);
    this.setState({
      loanModalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.handleModalCancelLoan();
      dialog.success("更新成功");
      list[selectedIndex].loansStatus = values.loansStatus;
      list[selectedIndex].actualLoans = Number(values.actualLoans);
      list[selectedIndex].remark = values.remark;
      this.setState({
        list,
      });
    }
  };

  render() {
    const { list, loading, pagination, recommendLoading, recommendPagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "贷款金额",
        dataIndex: "intentionLoans",
        key: "intentionLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "实际贷款金额",
        dataIndex: "actualLoans",
        key: "actualLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "银行名称",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "客户经理",
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: "状态",
        dataIndex: "loansStatus",
        key: "loansStatus",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              {this.props.permission.indexOf("audit_loan") > -1 ? (
                <Button onClick={this.handleModalOpenLoan.bind(this, record, index)} type="link">
                  审核状态
                </Button>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
    ];
    const recommendColumns = [
      {
        title: "银行名称",
        dataIndex: "houseName",
        key: "houseName",
      },
      {
        title: "支行",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "所属城市",
        dataIndex: "bankAddressDistrictName",
        key: "bankAddressDistrictName",
      },
      {
        title: "客户经理",
        dataIndex: "managerName",
        key: "managerName",
      },
      {
        title: "联系电话",
        dataIndex: "managerPhone",
        key: "managerPhone",
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index`}>
                  <span>企业管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>融资申请</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}

          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={1} span={6}>
                  <Form.Item label={`状态`} name={`loansStatus`}>
                    <Select placeholder="请选择">
                      <Option value={""}>全部</Option>
                      {this.state.loanStatusList.map((item, index) => {
                        return (
                          <Option key={index + 1000} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            {this.props.permission.indexOf("apply_loan") > -1 && (
              <div className="tableListOperator">
                <Button onClick={this.handleModalOpen.bind(this)} type="primary">
                  融资申请
                </Button>
                <Button onClick={this.handleModalOpenRecommend.bind(this)} type="primary">
                  智能推荐
                </Button>
              </div>
            )}
            <div style={{ marginBottom: 16 }}>
              <Alert message={`企业名称：${this.state.enterpriseInfo.name || "-"}`} type="info" />
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.visibleKey}
          loading={this.state.modalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          onOk={this.handleModalOk}
          title={`融资申请`}
          visible={this.state.visible}
          width={600}
        >
          <Form onFinish={this.handleModalOk.bind(this)} ref={this.formRef}>
            <FormItem
              label="贷款金额"
              labelCol={modalLabelCol}
              name="intentionLoans"
              rules={[{ required: true, message: "请输入贷款金额" }, { validator: checkMoney }]}
              wrapperCol={modalWrapperCol}
            >
              <Input addonAfter="万元" placeholder="请输入贷款金额"></Input>
            </FormItem>

            <FormItem
              label="指派银行"
              labelCol={modalLabelCol}
              name="bankId"
              rules={[{ required: true, message: "请选择银行" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.onChangeBank.bind(this)}
                optionFilterProp="children"
                placeholder="请选择银行"
                showSearch
              >
                {this.state.bankList.map((el) => {
                  return (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="支行"
              labelCol={modalLabelCol}
              name="branchId"
              rules={[{ required: true, message: "请选择支行" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select onChange={this.onChangeBankBranch.bind(this)} placeholder="请选择支行">
                {this.state.BankBranchList.map((el) => {
                  return (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="客户经理"
              labelCol={modalLabelCol}
              name="userId"
              rules={[{ required: true, message: "请选择客户经理" }, { validator: checkMoney }]}
              wrapperCol={modalWrapperCol}
            >
              <Select placeholder="请选择客户经理">
                {this.state.userList.map((el) => {
                  return (
                    <Option key={el.id} value={el.id}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.modalLoading} type="primary">
                    {this.state.modalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancel.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
        <Modal
          footer={null}
          key={this.state.loanVisibleKey}
          loading={this.state.loanModalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancelLoan}
          onOk={this.handleModalOkLoan}
          title={`审核状态`}
          visible={this.state.loanVisible}
          width={600}
        >
          <Form onFinish={this.handleModalOkLoan.bind(this)} ref={this.formRef}>
            <FormItem
              label="贷款状态"
              labelCol={modalLabelCol}
              name="loansStatus"
              rules={[{ required: true, message: "请选择贷款状态" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select>
                {this.state.loanStatusList.map((item, index) => {
                  return (
                    <Option key={index + 100} value={item.name}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="实际贷款金额"
              labelCol={modalLabelCol}
              name="actualLoans"
              rules={[{ required: false, message: "请输入实际贷款金额" }, { validator: checkMoney }]}
              wrapperCol={modalWrapperCol}
            >
              <Input addonAfter="万元" placeholder="请输入实际贷款金额"></Input>
            </FormItem>

            <FormItem
              label="进度说明"
              labelCol={modalLabelCol}
              name="remark"
              rules={[{ required: false, message: "请输入进度说明" }]}
              wrapperCol={modalWrapperCol}
            >
              <TextArea placeholder="选填" rows={4}></TextArea>
            </FormItem>

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.loanModalLoading} type="primary">
                    {this.state.loanModalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancelLoan.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
        <Modal
          centered
          closable
          footer={null}
          onCancel={this.handleModalCancelRecommend.bind(this)}
          title="智能推荐"
          visible={this.state.recommendVisible}
          width={1000}
        >
          <Table
            columns={recommendColumns}
            dataSource={this.state.recommendList}
            loading={recommendLoading}
            onChange={this.onChangeRecommendTable}
            pagination={recommendPagination}
            rowKey="id"
            size={TableSize}
          />
        </Modal>
      </div>
    );
  }
}

const EnterpriseLoansList = List;
export default EnterpriseLoansList;
