import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Layout } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import LogoComponent from "@/component/common/Logo";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

/**
 * 对于使用 iconfont.cn 的用户
 * 通过设置 createFromIconfontCN 方法参数对象中的 scriptUrl 字段
 * 即可轻松地使用已有项目中的图标
 */
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1721572_iy1ebn1zawa.js",
});

import logoImg from "@/assets/images/logo.png";

class SiderNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false, // 左侧栏收缩开关
      defaultSelectedKeys: "", // 初始选中的菜单项 key 数组
      defaultOpenKeys: "", // 初始展开的 SubMenu 菜单项 key 数组
      menu: [], // 菜单栏数据
      permissionCodes: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      collapsed: this.props.collapsed,
      menu: this.props.menu,
      permissionCodes: this.props.permissionCodes,
    });

    // 刷新回显菜单栏
    if (window.location.hash === "#/" || window.location.hash === "") {
      return;
    }
    const path = window.location.hash.substring(1).split("/"),
      defaultSelectedKeys = path[1] + "_" + path[2];
    this.setState({
      defaultSelectedKeys: defaultSelectedKeys,
      defaultOpenKeys: path[1],
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      collapsed: nextProps.collapsed,
      menu: nextProps.menu,
      permissionCodes: nextProps.permissionCodes,
    });
    if (nextProps.collapsed) {
      this.setState({
        defaultOpenKeys: undefined,
      });
    }
  }

  // 渲染菜单栏
  getMenuItem = () => {
    const _this = this;
    return _this.state.menu.map((record) => {
      if (record.children.length === 0 && this.state.permissionCodes.indexOf(record.key) > -1) {
        return (
          <Menu.Item key={record.key}>
            <NavLink to={record.path}>
              {" "}
              {/* <IconFont style={{ color: "#FFF", fontSize: 15 }} type={record.icon} /> */}
              <i className={`icon ${record.icon}`} style={{ color: "#FFF", fontSize: 15, marginRight: 15 }}></i>
              <span className="nav-text">{record.name}</span>
            </NavLink>
          </Menu.Item>
        );
      } else if (record.children.length !== 0) {
        const hasPermission = (item) => {
          return this.state.permissionCodes.indexOf(item.key) > -1;
        };
        if (record.children.some(hasPermission)) {
          return (
            <SubMenu
              key={record.key}
              title={
                <span className="nav-text">
                  {/* <IconFont style={{ color: "#FFF", fontSize: 15 }} type={record.icon} /> */}
                  <i className={`icon ${record.icon}`} style={{ color: "#FFF", fontSize: 15, marginRight: 15 }}></i>
                  <span className="nav-text">{record.name}</span>
                </span>
              }
            >
              {record.children.map((item) => {
                if (this.state.permissionCodes.indexOf(item.key) > -1) {
                  return (
                    <Menu.Item key={item.key}>
                      <NavLink to={item.path}>
                        {" "}
                        <span className="nav-text">{item.name}</span>
                      </NavLink>
                    </Menu.Item>
                  );
                }
              })}
            </SubMenu>
          );
        }
      }
    });
  };

  // SubMenu 展开/关闭的回调
  handleOpenChange = (openKeys) => {
    this.setState({
      defaultOpenKeys: openKeys[openKeys.length - 1],
    });
  };

  render() {
    return (
      <Sider collapsed={this.state.collapsed} collapsible trigger={null} width="220px">
        <div className="index_logo">
          <a className="flex flex-middle flex-center flex-wrap" href="javescript:;">
            {/* <img alt="logo" src={logoImg} /> */}
            <LogoComponent />
            <h1>金网络CRM</h1>
          </a>
        </div>
        <Menu
          defaultOpenKeys={[this.state.defaultOpenKeys]}
          defaultSelectedKeys={[this.state.defaultSelectedKeys]}
          mode="inline"
          onOpenChange={this.handleOpenChange}
          openKeys={[this.state.defaultOpenKeys]}
          theme="dark"
        >
          {this.getMenuItem()}
        </Menu>
      </Sider>
    );
  }
}
export default SiderNav;
