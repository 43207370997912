import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Breadcrumb,
  Card,
  Input,
  Upload,
  Tooltip,
  Progress,
  Modal,
  Space,
  Checkbox,
  DatePicker,
  Row,
  Col,
  Select,
  Badge,
  Table,
  Divider,
  Popconfirm,
} from "antd";
import {
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import locale from "antd/es/date-picker/locale/zh_CN";

import request from "@/util/request";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";
import { checkMoney, checkDigit } from "@/util/formValidation";
import { beforeUpload } from "@/util/fileUpload";
import { radio_image } from "@/util/imageUpload";

import { TableSize } from "@/constants";
import { ResultCodeEnum, enterprisesApi, mycenterApi, urls } from "@/api/index";

const { TextArea } = Input;
const FormItem = Form.Item;
const FormList = Form.List;
const { confirm } = Modal;
const { Option } = Select;

import "../style.less";

import moment from "moment";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    curFullYear: new Date().getFullYear(),

    isEnterpriseEdit: false, // 是否为企业用户编辑
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading

    attachmentList: [], // 附件列表
    fileUploadLoading: false,
    imgUploadLoading: false,

    businessCardList: [],

    // 日期选择框
    isOpenDatePicker: false,

    // 联动字段
    hasPawn: false,

    // 财务情况
    isEditFinancial: false,
    editFinancialIdx: 0,
    financialVisible: false,
    insertFinancialKey: 100,
    insertFinancialVisible: false,
    financialList: [],
  };

  /**
   * 在组件render之后调用
   */
  async UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
    // 判断是否为企业用户编辑模式
    if (url.indexOf("updateEnterprise") > -1) {
      this.setState({
        isEdit: true,
        isEnterpriseEdit: true,
      });
      this.getUserInfo();
    }
  }

  // 获取详情
  getDetail = async (id) => {
    const res = await enterprisesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;

      // 已取得授信的银行名单
      const creditGrantingBanks = detail.creditGrantingBanks ? JSON.parse(detail.creditGrantingBanks) : [];
      let creditGrantingBanksOther = creditGrantingBanks[creditGrantingBanks.length - 1];
      if (creditGrantingBanksOther instanceof Object) {
        creditGrantingBanks.pop();
      } else {
        creditGrantingBanksOther = "";
      }

      // 授信品种
      let creditVarieties = [];
      if (detail.creditVarietyCreditVarietyList) {
        creditVarieties = detail.creditVarietyCreditVarietyList.map((item) => {
          return item.creditVariety;
        });
      }

      // // 近三年营业收入
      // let incomeInThreeYears = detail.incomeInThreeYears;
      // if (detail.incomeInThreeYears) {
      //   incomeInThreeYears = JSON.parse(detail.incomeInThreeYears);
      // }

      // 近一个月营业收入
      const incomeInRecentMonth = detail.incomeInRecentMonth
        ? JSON.parse(detail.incomeInRecentMonth)
        : detail.incomeInRecentMonth;
      if (incomeInRecentMonth && incomeInRecentMonth.month) {
        incomeInRecentMonth.month = moment(incomeInRecentMonth.month, "YYYY年MM月");
      }

      // 附件
      const attachmentList = [];
      detail.attachmentList.map((item, index) => {
        attachmentList.push({
          name: item.name,
          url: item.url,
          type: item.type,
          status: "done",
          uid: `${item.id}_${index}`,
        });
      });

      // 联系人
      const contactList = [];
      const businessCardList = [];
      detail.contactList.map((item, index) => {
        contactList.push({
          name: item.name,
          phone: item.phone,
          title: item.title,
          businessCard: {
            file: {
              response: {
                code: "OK",
                data: item.businessCard,
              },
            },
          },
        });
        businessCardList.push(item.businessCard);
      });

      this.setState({
        detail: detail,
        attachmentList,
        businessCardList,
        financialList: detail.enterpriseFinanceList || [],
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        name: detail.name,
        intentionLoans: detail.intentionLoans,
        contactList: contactList,
        // incomeInThreeYears: incomeInThreeYears,
        incomeInRecentMonth: [incomeInRecentMonth],
        debtRatio: detail.debtRatio,
        creditGrantingBanks: creditGrantingBanks,
        creditVarieties: creditVarieties,
        // legalPersonIdCardFont: detail.legalPersonIdCardFont,
        // legalPersonIdCardBack: detail.legalPersonIdCardBack,
        remark: detail.remark,
        hasPawn: detail.hasPawn,
        pawnContent: detail.pawnContent,
        financialList: detail.enterpriseFinanceList,
      });
    }
  };

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          id: res.data.enterpriseId,
        },
        () => this.getDetail(res.data.enterpriseId)
      );
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { id, isEdit, isEnterpriseEdit } = this.state;

    const params = {
      hasPawn: values.hasPawn,
      transactionStatus: values.transactionStatus,
      pawnContent: values.pawnContent,
      intentionLoans: values.intentionLoans,
      remark: values.remark,
    };

    if (isEnterpriseEdit) {
      // 已取得授信的银行名单
      params.creditGrantingBanks = [];
      if (values.creditGrantingBanks) {
        params.creditGrantingBanks = values.creditGrantingBanks;
      }
      if (values.creditGrantingBanksOther) {
        values.creditGrantingBanks.push({ creditGrantingBanksOther: values.creditGrantingBanksOther });
      }
      params.creditGrantingBanks = JSON.stringify(params.creditGrantingBanks);

      // 授信品种
      params.creditVarieties = values.creditVarieties;

      // 近三年营业收入
      // params.incomeInThreeYears = values.incomeInThreeYears;
      // if (values.incomeInThreeYears) {
      //   params.incomeInThreeYears = JSON.stringify(values.incomeInThreeYears);
      // }

      // 近一个月营业收入
      params.incomeInRecentMonth = values.incomeInRecentMonth[0];
      if (params.incomeInRecentMonth && params.incomeInRecentMonth.month) {
        params.incomeInRecentMonth.month = moment(params.incomeInRecentMonth.month).format("YYYY年MM月");
      }
      params.incomeInRecentMonth = params.incomeInRecentMonth
        ? JSON.stringify(params.incomeInRecentMonth)
        : params.incomeInRecentMonth;

      // 正反面证件
      // params.legalPersonIdCardBack = values.legalPersonIdCardBack;
      // params.legalPersonIdCardFont = values.legalPersonIdCardFont;

      // 负债率
      params.debtRatio = values.debtRatio;

      // 财务情况
      params.enterpriseFinanceList = this.state.financialList;
    }

    // 附件
    const attachmentList = [];
    this.state.attachmentList.forEach((el) => {
      if (el.status === "done") {
        attachmentList.push({
          name: el.name,
          url: el.url,
          type: el.type,
        });
      }
    });
    params.attachmentList = attachmentList;

    // 联系人
    const contactList = [];
    if (values.contactList) {
      values.contactList.forEach((el, index) => {
        contactList.push({
          businessCard: this.state.businessCardList[index],
          name: el.name,
          phone: el.phone,
          title: el.title,
        });
      });
    }
    params.contactList = contactList;

    this.setState({ submitting: true });
    if (isEdit) {
      this.updateEnterprise(id, params);
    } else {
      const res = await enterprisesApi.create({
        name: values.name,
      });
      if (res.code === ResultCodeEnum.success.code) {
        this.updateEnterprise(res.data.id, params);
      } else {
        this.setState({ submitting: false });
      }
    }
  };

  /** 更新信息 */
  updateEnterprise = async (id, params) => {
    const { history } = this.props;
    const res = await enterprisesApi.update(id, params);
    this.setState({ submitting: false });
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("操作成功", () => {
        if (this.state.isEnterpriseEdit) {
          history.push(`/enterprise/loansProgress/index`);
        } else {
          history.push(`/enterprise/management/index`);
        }
      });
    }
  };

  /* 删除单个附件 */
  onDeleteAttachment = async (index) => {
    const _this = this;
    confirm({
      title: "确定此次操作",
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        let { attachmentList } = _this.state;
        attachmentList = attachmentList.filter((el, ind) => {
          return index !== ind;
        });
        _this.setState({
          attachmentList,
        });
        _this.formRef.current.setFieldsValue({
          attachmentList,
        });
      },
    });
  };

  // 修改是否有抵押物
  changeHasMortgageHandel = (selValue) => {
    this.setState({
      hasPawn: selValue,
    });
  };

  // 显示财务情况回调
  openFinancialHandel = () => {
    this.setState({ financialVisible: true });
  };
  openInsertFinancialHandel = (e) => {
    e.stopPropagation();
    this.setState({
      isEditFinancial: false,
      insertFinancialVisible: true,
    });
  };

  // 关闭财务情况回调
  closeFinancialHandel = () => {
    this.setState({ financialVisible: false });
  };
  closeInsertFinancialHandel = () => {
    this.setState({
      insertFinancialVisible: false,
      insertFinancialKey: this.state.insertFinancialKey + 1,
    });
  };

  // 新建/编辑 财务情况
  submitFinancialHandel = (values) => {
    const { isEditFinancial, financialList, editFinancialIdx: index } = this.state;
    if (isEditFinancial) {
      financialList[index] = {
        ...values,
        id: financialList[index].id,
        year: moment(values.year).format("YYYY"),
      };
    } else {
      financialList.push({
        ...values,
        id: Date.now(),
        year: moment(values.year).format("YYYY"),
      });
    }
    this.setState({
      financialList: [...financialList],
      insertFinancialVisible: false,
      insertFinancialKey: this.state.insertFinancialKey + 1,
    });
  };
  changeFinancialHandel = (index) => {
    const data = this.state.financialList[index];
    this.setState(
      {
        isEditFinancial: true,
        editFinancialIdx: index,
        insertFinancialVisible: true,
      },
      () => {
        this.financialFormRef.current.setFieldsValue({
          ...data,
          year: moment().set("year", data.year),
        });
      }
    );
  };
  deteleFinancialHandel = (index) => {
    this.state.financialList.splice(index, 1);
    this.setState({ financialList: this.state.financialList });
  };

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();
  financialFormRef = React.createRef();

  render() {
    const _this = this;
    const {
      curFullYear,
      detail,
      isEdit,
      isEnterpriseEdit,
      submitting,
      fileUploadLoading,
      attachmentList,
      isOpenDatePicker,
      financialList,
      financialVisible,
      insertFinancialKey,
      insertFinancialVisible,
    } = this.state;

    /**
     * 第一个函数为上传成功后的回调
     * 第二个函数为上传中的回调
     */
    // const UploadProps = radio_image(
    //   (data) => {
    //     const cardList = [];
    //     const contactList = this.formRef.current.getFieldsValue().contactList;
    //     if (contactList.length !== 0) {
    //       contactList.map((el) => {
    //         if (el) {
    //           const file = el.businessCard.file;
    //           if (file.response.code === ResultCodeEnum.success.code) {
    //             cardList.push(file.response.data);
    //           }
    //         } else {
    //           cardList.push(undefined);
    //         }
    //       });
    //     }
    //     this.setState({
    //       businessCardList: cardList,
    //     });
    //     const detail = this.state.detail;
    //     detail["license"] = data;
    //     this.setState({ detail, imgUploadLoading: false });
    //   },
    //   () => {
    //     this.setState({ imgUploadLoading: true });
    //   }
    // );
    const UploadProps = (key, loading) =>
      radio_image(
        (data) => {
          if (key === "businessCardList") {
            const cardList = [];
            const contactList = this.formRef.current.getFieldsValue().contactList;
            if (contactList.length !== 0) {
              contactList.map((el) => {
                if (el) {
                  const file = el.businessCard.file;
                  if (file.response.code === ResultCodeEnum.success.code) {
                    cardList.push(file.response.data);
                  }
                } else {
                  // cardList.push(undefined);
                }
              });
            }

            this.setState({
              businessCardList: cardList,
            });
            const detail = this.state.detail;
            detail["license"] = data;
            this.setState({ detail, imgUploadLoading: false });
          } else {
            const detail = this.state.detail;
            detail[key] = data;
            this.setState({ detail, [loading]: false });

            const fromData = this.formRef.current.getFieldValue();
            fromData[key] = data;
            this.formRef.current.setFieldsValue(fromData);
          }
        },
        () => {
          this.setState({ [loading]: true });
        }
      );

    const document_props = (type) => {
      return {
        accept: ".",
        name: "file",
        action: urls.uploadImage,
        multiple: true,
        beforeUpload: beforeUpload,
        onChange(info) {
          // 避免原来的文件丢失，这样子写就可以了
          _this.setState({ attachmentList: info.file.status ? [...info.fileList] : _this.state.attachmentList });
        },
        customRequest(info) {
          const file = info.file;
          const formData = new FormData();
          formData.append("file", file);
          request
            .upload(formData, {
              onUploadProgress(progress) {
                let { attachmentList } = _this.state;
                attachmentList = attachmentList.map((item) => {
                  if (item.uid === file.uid) {
                    item.percent = Math.round((progress.loaded / progress.total) * 100);
                    item.type = type;
                  }
                  return item;
                });
                _this.setState({
                  attachmentList,
                });
                _this.formRef.current.setFieldsValue({
                  attachmentList,
                });
              },
            })
            .then((res) => {
              let { attachmentList } = _this.state;
              attachmentList = attachmentList.map((item) => {
                if (item.uid === file.uid) {
                  item.status = res.data.code === ResultCodeEnum.success.code ? "done" : "error";
                  item.url = res.data.code === ResultCodeEnum.success.code ? res.data.data : "";
                  item.type = type;
                }
                return item;
              });
              _this.setState({
                attachmentList,
              });
              _this.formRef.current.setFieldsValue({
                attachmentList,
              });
              if (res.data.code !== ResultCodeEnum.success.code) {
                dialog.error(res.data.errorMsg);
              }
            });
        },
        fileList: _this.state.attachmentList,
        defaultFileList: _this.state.attachmentList,
        showUploadList: { showPreviewIcon: false, showRemoveIcon: true, showDownloadIcon: false },
      };
    };

    // 附件信息
    const AttachmentInfo = (el) => {
      return (
        <div
          className="attachment_item flex flex-item flex-middle"
          style={el.status === "done" ? { color: "#3B71DE" } : el.status === "error" ? { color: "#D11C1F" } : {}}
        >
          <div className="flex-item attachment_name">{el.name}</div>
        </div>
      );
    };

    // 财务情况表格配置
    const financialColumns = [
      {
        title: "年份",
        dataIndex: "year",
        key: "year",
      },
      {
        title: "季度",
        dataIndex: "quarter",
        key: "quarter",
        render: (quarter) => {
          let text = "";
          switch (quarter) {
            case 0:
              text = "全年";
              break;
            case 1:
              text = "第一季度";
              break;
            case 2:
              text = "第二季度";
              break;
            case 3:
              text = "第三季度";
              break;
            case 4:
              text = "第四季度";
              break;
          }
          return <span>{text}</span>;
        },
      },
      {
        title: "营收(万元)",
        dataIndex: "revenue",
        key: "revenue",
      },
      {
        title: "净利润(万元)",
        dataIndex: "netProfit",
        key: "netProfit",
      },
      {
        title: "总资产(万元)",
        dataIndex: "totalAssets",
        key: "totalAssets",
      },
      {
        title: "总负债(万元)",
        dataIndex: "totalLiabilities",
        key: "totalLiabilities",
      },
      {
        title: "银行授信明细",
        dataIndex: "creditDetail",
        key: "creditDetail",
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              <Button onClick={this.changeFinancialHandel.bind(this, index)} type="link">
                编辑
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.deteleFinancialHandel.bind(this, index)}
                placement="left"
                title="确定删除该财务情况吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            {isEnterpriseEdit && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <span>企业信息</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
            {!isEnterpriseEdit && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/enterprise/management/index`}>
                    <span>企业管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{isEdit ? "编辑企业" : "新增企业"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="企业名称"
                name="name"
                rules={[{ required: true, message: "请输入企业名称" }]}
              >
                <Input disabled={this.state.isEdit} placeholder="请输入企业名称" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="意向授信金额"
                name="intentionLoans"
                rules={[{ required: isEnterpriseEdit, message: "请输入意向授信金额" }, { validator: checkMoney }]}
              >
                <Input addonAfter="万元" placeholder="请输入意向授信金额" />
              </FormItem>

              {/** 
                <FormItem
                  {...formItemLayout}
                  label="联系人"
                  name="contactName"
                  rules={[{ required: false, message: "请输入联系人" }]}
                >
                  <Input placeholder="请输入联系人" />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="联系电话"
                  name="contactPhone"
                  rules={[{ required: false, message: "请输入联系电话" }]}
                >
                  <Input placeholder="请输入联系电话" />
                </FormItem>
              */}

              <FormItem {...formItemLayout} label="联系人" required={isEnterpriseEdit}>
                <Form.List label="联系人" name="contactList" {...formItemLayout}>
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                        <Space align="baseline" key={key} style={{ display: "flex", marginBottom: 8 }}>
                          <Form.Item
                            {...restField}
                            fieldKey={[fieldKey, "name"]}
                            name={[name, "name"]}
                            rules={[{ required: true, message: "请输入" }]}
                            style={{ width: 100 }}
                          >
                            <Input disabled={isEnterpriseEdit && index === 0} placeholder="姓名" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            fieldKey={[fieldKey, "phone"]}
                            name={[name, "phone"]}
                            rules={[{ required: true, message: "请输入" }]}
                            style={{ width: 120 }}
                          >
                            <Input disabled={isEnterpriseEdit && index === 0} placeholder="联系电话" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            fieldKey={[fieldKey, "title"]}
                            name={[name, "title"]}
                            rules={[{ required: false, message: "请输入" }]}
                            style={{ width: 100 }}
                          >
                            <Input disabled={isEnterpriseEdit && index === 0} placeholder="职务" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            fieldKey={[fieldKey, "businessCard"]}
                            name={[name, "businessCard"]}
                            rules={[{ required: false, message: "请上传" }]}
                          >
                            <Upload
                              {...UploadProps("businessCardList", "imgUploadLoading")}
                              disabled={isEnterpriseEdit && index === 1}
                            >
                              {this.state.businessCardList[index] ? (
                                <div>
                                  <img alt="" className="img-upload" src={this.state.businessCardList[index]} />
                                </div>
                              ) : this.state.imgUploadLoading ? (
                                <LoadingOutlined />
                              ) : (
                                <PlusOutlined />
                              )}
                            </Upload>
                          </Form.Item>
                          {((isEnterpriseEdit && index !== 0) || !isEnterpriseEdit) && (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(name);
                                let cardList = this.state.businessCardList;
                                cardList = cardList.filter((el, cardIndex) => {
                                  return cardIndex !== index;
                                });
                                this.setState({
                                  businessCardList: cardList,
                                });
                              }}
                            />
                          )}
                        </Space>
                      ))}
                      <Form.Item noStyle>
                        <Button block icon={<PlusOutlined />} onClick={() => add()} type="dashed">
                          新建联系人
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </FormItem>

              {isEnterpriseEdit && (
                <>
                  <FormItem
                    {...formItemLayout}
                    label="财务情况"
                    name="financialStatus"
                    rules={[{ required: false, message: "请填写财务情况" }]}
                  >
                    <Button
                      className="showFinancialBtn flex flex-middle flex-center"
                      onClick={this.openFinancialHandel.bind(this)}
                      type="dashed"
                    >
                      {!financialList.length && <FormOutlined />}
                      {!!financialList.length && <Badge count={financialList.length} size="small" />}
                      <span>新增/编辑 财务情况</span>
                    </Button>
                  </FormItem>

                  {/* <FormItem label="近三年营业收入" {...formItemLayout}>
                    <FormList
                      {...formItemLayout}
                      name="incomeInThreeYears"
                      rules={[{ required: false, message: "请输入近三年营业收入" }]}
                    >
                      {(fields, { add, remove }, { errors }) => {
                        if (fields.length === 0) {
                          add();
                          add();
                          add();
                        }
                        return (
                          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                              <Space className="flex" key={key}>
                                <FormItem
                                  {...restField}
                                  fieldKey={[fieldKey, "year"]}
                                  initialValue={`${curFullYear - index}`}
                                  name={[name, "year"]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Input disabled placeholder="请输入" />
                                </FormItem>
                                <FormItem
                                  {...restField}
                                  fieldKey={[fieldKey, "amount"]}
                                  name={[name, "amount"]}
                                  rules={[{ validator: checkMoney }]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Input addonAfter="万元" placeholder="请输入" />
                                </FormItem>
                              </Space>
                            ))}
                          </Space>
                        );
                      }}
                    </FormList>
                  </FormItem> */}

                  <FormItem {...formItemLayout} label="近一个月营业收入">
                    <FormList
                      {...formItemLayout}
                      name="incomeInRecentMonth"
                      rules={[{ required: false, message: "近一个月营业收入" }]}
                    >
                      {(fields, { add, remove }, { errors }) => {
                        if (fields.length === 0) {
                          add();
                          this.setState({});
                        }
                        return (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                              <Space className="flex" key={key}>
                                <FormItem
                                  {...restField}
                                  fieldKey={[fieldKey, "month"]}
                                  name={[name, "month"]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <DatePicker
                                    format="YYYY-MM"
                                    locale={locale}
                                    mode="month"
                                    onChange={() => {
                                      const fromData = this.formRef.current.getFieldValue();
                                      fromData.incomeInRecentMonth[index]["month"] = null;
                                      this.formRef.current.setFieldsValue(fromData);
                                    }}
                                    onOpenChange={(status) => {
                                      if (status) {
                                        this.setState({ isOpenDatePicker: true });
                                      } else {
                                        this.setState({ isOpenDatePicker: false });
                                      }
                                    }}
                                    onPanelChange={(v) => {
                                      const fromData = this.formRef.current.getFieldValue();
                                      if (!fromData.incomeInRecentMonth[index]) {
                                        fromData.incomeInRecentMonth.splice(index, 1, { month: v, income: null });
                                      } else {
                                        fromData.incomeInRecentMonth[index]["month"] = v;
                                      }
                                      this.formRef.current.setFieldsValue(fromData);
                                      this.setState({ isOpenDatePicker: false });
                                    }}
                                    open={isOpenDatePicker}
                                    placeholder="请选择"
                                    style={{ width: 182 }}
                                  />
                                </FormItem>
                                <FormItem
                                  rules={[{ validator: checkMoney }]}
                                  {...restField}
                                  fieldKey={[fieldKey, "income"]}
                                  name={[name, "income"]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Input addonAfter="万元" placeholder="请输入" />
                                </FormItem>
                              </Space>
                            ))}
                          </>
                        );
                      }}
                    </FormList>
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="负债率"
                    name="debtRatio"
                    rules={[{ required: false, message: "请输入负债率" }]}
                  >
                    <Input addonAfter="%" placeholder="请输入负债率" />
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="已取得授信的银行名单："
                    name="creditGrantingBanks"
                    rules={[{ required: false, message: "" }]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row gutter={[16, 16]} style={{ margin: 0 }}>
                        <Col span={6}>
                          <Checkbox value="中国银行">中国银行</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="农业银行">农业银行</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="工商银行">工商银行</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="建设银行">建设银行</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="交通银行">交通银行</Checkbox>
                        </Col>
                        <Col span={9}>
                          <FormItem label="其他" name="creditGrantingBanksOther" style={{ marginBottom: 0 }}>
                            <Input placeholder="请输入" size="small" />
                          </FormItem>
                          {/* <span>其他：</span> */}
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </FormItem>

                  <Modal
                    className="financialWarpper"
                    closeIcon={
                      <Button
                        className="insertFinancialBtn"
                        onClick={this.openInsertFinancialHandel.bind(this)}
                        type="primary"
                      >
                        新增
                      </Button>
                    }
                    footer={null}
                    onCancel={this.closeFinancialHandel.bind(this)}
                    title="财务情况"
                    visible={financialVisible}
                    width={1200}
                  >
                    {/* <div className="financialHeader">
                    <Button type="primary">新增</Button>
                  </div> */}
                    <Table
                      columns={financialColumns}
                      dataSource={financialList}
                      key={financialList}
                      rowKey="id"
                      size={TableSize}
                    />
                  </Modal>

                  <Modal
                    footer={null}
                    key={insertFinancialKey}
                    onCancel={this.closeInsertFinancialHandel.bind(this)}
                    title="新增/编辑财务情况"
                    visible={insertFinancialVisible}
                    width={1200}
                  >
                    <Form onFinish={this.submitFinancialHandel.bind(this)} ref={this.financialFormRef}>
                      <FormItem
                        {...formItemLayout}
                        label="年份"
                        name="year"
                        rules={[{ required: true, message: "请选择年份" }]}
                      >
                        <DatePicker locale={locale} picker="year" style={{ width: "100%" }} />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="季度"
                        name="quarter"
                        rules={[{ required: true, message: "请选择季度" }]}
                      >
                        <Select placeholder="请选择季度">
                          <Option value={0}>全年</Option>
                          <Option value={1}>第一季度</Option>
                          <Option value={2}>第二季度</Option>
                          <Option value={3}>第三季度</Option>
                          <Option value={4}>第四季度</Option>
                        </Select>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="营收"
                        name="revenue"
                        rules={[{ required: true, message: "请输入营收" }, { validator: checkDigit }]}
                      >
                        <Input addonAfter="万元" placeholder="请输入营收" />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="净利润"
                        name="netProfit"
                        rules={[{ required: true, message: "请输入净利润" }, { validator: checkDigit }]}
                      >
                        <Input addonAfter="万元" placeholder="请输入净利润" />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="总资产"
                        name="totalAssets"
                        rules={[{ required: true, message: "请输入总资产" }, { validator: checkDigit }]}
                      >
                        <Input addonAfter="万元" placeholder="请输入总资产" />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="总负债"
                        name="totalLiabilities"
                        rules={[{ required: true, message: "请输入总负债" }, { validator: checkDigit }]}
                      >
                        <Input addonAfter="万元" placeholder="请输入总负债" />
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="银行授信明细"
                        name="creditDetail"
                        rules={[{ required: false, message: "请输入银行授信明细" }]}
                      >
                        <TextArea placeholder="选填" rows={4} />
                      </FormItem>

                      <FormItem {...submitFormLayout}>
                        <Button htmlType="submit" loading={submitting} type="primary">
                          提交
                        </Button>
                      </FormItem>
                    </Form>
                  </Modal>
                </>
              )}

              <FormItem
                {...formItemLayout}
                label="客户状态："
                name="transactionStatus"
                rules={[{ required: false, message: "请选择客户状态" }]}
              >
                <Select allowClear placeholder="请选择客户状态">
                  <Option value="follow_up">跟进中</Option>
                  <Option value="done_deal">已交易</Option>
                  <Option value="give_up">储备客户</Option>
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="是否有抵押物："
                name="hasPawn"
                rules={[{ required: true, message: "请选择是否有抵押物" }]}
              >
                <Select onChange={this.changeHasMortgageHandel.bind(this)} placeholder="请选择是否有抵押物">
                  <Option value={Boolean(true)}>是</Option>
                  <Option value={Boolean(false)}>否</Option>
                </Select>
              </FormItem>

              {this.state.hasPawn && (
                <>
                  <FormItem
                    {...formItemLayout}
                    label="抵押物内容"
                    name="pawnContent"
                    rules={[{ required: true, message: "请输入抵押物内容" }]}
                  >
                    <Input.TextArea placeholder="请输入抵押物内容" />
                  </FormItem>
                </>
              )}

              {isEnterpriseEdit && (
                <>
                  {/* Todo 修改字段名称 */}
                  <FormItem
                    {...formItemLayout}
                    label="授信品种："
                    name="creditVarieties"
                    rules={[{ required: false, message: "" }]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row gutter={[16, 16]} style={{ margin: 0 }}>
                        <Col span={6}>
                          <Checkbox value="fiduciary_loan">信用授信</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="mortgage_loan">抵押授信</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="supply_chain_finance">供应链金融</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="comprehensive_loan">综合授信</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </FormItem>
                  {/* <FormItem {...formItemLayout} label="法人身份证正反面">
                    <Space>
                      <FormItem name="legalPersonIdCardFont" style={{ marginBottom: 0 }}>
                        <Upload
                          {...UploadProps("legalPersonIdCardFont", "legalPersonIdCardFontLoading")}
                          className="bgImg-uploader"
                        >
                          {detail.legalPersonIdCardFont ? (
                            <img alt="" className="img-upload" src={detail.legalPersonIdCardFont} />
                          ) : this.state.legalPersonIdCardFontLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                        </Upload>
                      </FormItem>
                      <FormItem name="legalPersonIdCardBack" style={{ marginBottom: 0 }}>
                        <Upload
                          {...UploadProps("legalPersonIdCardBack", "legalPersonIdCardBackLoading")}
                          className="bgImg-uploader"
                        >
                          {detail.legalPersonIdCardBack ? (
                            <img alt="" className="img-upload" src={detail.legalPersonIdCardBack} />
                          ) : this.state.legalPersonIdCardBackLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                        </Upload>
                      </FormItem>
                    </Space>
                  </FormItem> */}
                </>
              )}

              {/** 
                <FormItem
                  {...formItemLayout}
                  label="营业执照"
                  name="license"
                  rules={[{ required: false, message: "请上传图片" }]}
                >
                  <Upload {...UploadProps}>
                    {detail.license ? (
                      <img alt="" className="img-upload" src={detail.license} />
                    ) : this.state.imgUploadLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                  </Upload>
                </FormItem>
              */}

              <FormItem
                {...formItemLayout}
                className="attachmentList"
                label="基础资料"
                name="attachmentList"
                rules={[{ required: false, message: "请上传附件" }]}
              >
                <Upload {...document_props("basic")} className="file_upload">
                  {fileUploadLoading ? (
                    <Button>
                      <LoadingOutlined /> 上传中
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> 上传附件
                    </Button>
                  )}
                </Upload>
                <div className="attachment_list">
                  {attachmentList.map((el, index) => {
                    if (el.type === "basic") {
                      return (
                        <div className="attachment" key={index}>
                          <div className="attachment_info flex">
                            {el.status !== "error" ? (
                              AttachmentInfo(el)
                            ) : (
                              <Tooltip placement="top" title={`上传失败`}>
                                {AttachmentInfo(el)}
                              </Tooltip>
                            )}
                            <Button onClick={this.onDeleteAttachment.bind(this, index)} type="link">
                              删除
                            </Button>
                          </div>
                          {el.status !== "done" && el.status !== "error" && (
                            <div className="attachment_progress">
                              <Progress percent={el.percent} status={el.status === "error" ? "exception" : "-"} />
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </FormItem>

              <FormItem
                {...formItemLayout}
                className="attachmentList"
                label="股东资料"
                name="attachmentList"
                rules={[{ required: false, message: "请上传附件" }]}
              >
                <Upload {...document_props("shareholders")} className="file_upload">
                  {fileUploadLoading ? (
                    <Button>
                      <LoadingOutlined /> 上传中
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> 上传附件
                    </Button>
                  )}
                </Upload>
                <div className="attachment_list">
                  {attachmentList.map((el, index) => {
                    if (el.type === "shareholders") {
                      return (
                        <div className="attachment" key={index}>
                          <div className="attachment_info flex">
                            {el.status !== "error" ? (
                              AttachmentInfo(el)
                            ) : (
                              <Tooltip placement="top" title={`上传失败`}>
                                {AttachmentInfo(el)}
                              </Tooltip>
                            )}
                            <Button onClick={this.onDeleteAttachment.bind(this, index)} type="link">
                              删除
                            </Button>
                          </div>
                          {el.status !== "done" && el.status !== "error" && (
                            <div className="attachment_progress">
                              <Progress percent={el.percent} status={el.status === "error" ? "exception" : "-"} />
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </FormItem>

              <FormItem
                {...formItemLayout}
                className="attachmentList"
                label="财务资料"
                name="attachmentList"
                rules={[{ required: false, message: "请上传附件" }]}
              >
                <Upload {...document_props("finance")} className="file_upload">
                  {fileUploadLoading ? (
                    <Button>
                      <LoadingOutlined /> 上传中
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> 上传附件
                    </Button>
                  )}
                </Upload>
                <div className="attachment_list">
                  {attachmentList.map((el, index) => {
                    if (el.type === "finance") {
                      return (
                        <div className="attachment" key={index}>
                          <div className="attachment_info flex">
                            {el.status !== "error" ? (
                              AttachmentInfo(el)
                            ) : (
                              <Tooltip placement="top" title={`上传失败`}>
                                {AttachmentInfo(el)}
                              </Tooltip>
                            )}
                            <Button onClick={this.onDeleteAttachment.bind(this, index)} type="link">
                              删除
                            </Button>
                          </div>
                          {el.status !== "done" && el.status !== "error" && (
                            <div className="attachment_progress">
                              <Progress percent={el.percent} status={el.status === "error" ? "exception" : "-"} />
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </FormItem>

              <FormItem
                {...formItemLayout}
                className="attachmentList"
                label="其他资料"
                name="attachmentList"
                rules={[{ required: false, message: "请上传附件" }]}
              >
                <Upload {...document_props("others")} className="file_upload">
                  {fileUploadLoading ? (
                    <Button>
                      <LoadingOutlined /> 上传中
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> 上传附件
                    </Button>
                  )}
                </Upload>
                <div className="attachment_list">
                  {attachmentList.map((el, index) => {
                    if (el.type === "others") {
                      return (
                        <div className="attachment" key={index}>
                          <div className="attachment_info flex">
                            {el.status !== "error" ? (
                              AttachmentInfo(el)
                            ) : (
                              <Tooltip placement="top" title={`上传失败`}>
                                {AttachmentInfo(el)}
                              </Tooltip>
                            )}
                            <Button onClick={this.onDeleteAttachment.bind(this, index)} type="link">
                              删除
                            </Button>
                          </div>
                          {el.status !== "done" && el.status !== "error" && (
                            <div className="attachment_progress">
                              <Progress percent={el.percent} status={el.status === "error" ? "exception" : "-"} />
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="备注"
                name="remark"
                rules={[{ required: false, message: "请输入备注" }]}
              >
                <TextArea placeholder="请输入备注" rows={3} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const FileManagementForm = List;
export default FileManagementForm;
