import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Divider, Card, Input, Row, Col, Select, Modal } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize, AuditStatus } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";

import { ResultCodeEnum, enterprisesApi } from "@/api/index";

const { Option } = Select;
const TextArea = Input.TextArea;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    importUploadLoading: false,

    /** 弹窗 */
    currentModalId: "",
    visible: false,
    visibleKey: 1,
    modalLoading: false,
    requiredRefuseReason: false,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await enterprisesApi.auditList({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  handleModalOk = async (values) => {
    const { currentModalId, list } = this.state;

    this.setState({ modalLoading: true });
    const res = await enterprisesApi.audit(currentModalId, values);
    if (res.code === ResultCodeEnum.success.code) {
      const idx = list.findIndex((el) => el.id === currentModalId);
      list[idx].status = values["status"];

      dialog.success("提交成功", () => {
        this.setState({ list, visible: false, modalLoading: false });
      });
    } else {
      this.setState({ modalLoading: false });
    }
  };

  handleModalOpen = (id) => {
    this.setState({ visible: true, currentModalId: id });
  };

  handleModalClose = () => {
    if (!this.state.modalLoading) {
      this.formRef.current.resetFields();
      this.setState({ visible: false, requiredRefuseReason: false });
    }
  };

  changeApprovalStatus = (status) => {
    if (status === AuditStatus.audit_refuse.code) {
      this.setState({ requiredRefuseReason: true });
    } else {
      this.setState({ requiredRefuseReason: false });
    }
  };

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  render() {
    const { match } = this.props;
    const { list, loading, pagination, visible, visibleKey, modalLoading, requiredRefuseReason } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "企业名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "法定代表人",
        dataIndex: "legalPersonName",
        key: "legalPersonName",
      },
      {
        title: "注册资本",
        dataIndex: "regCapital",
        key: "regCapital",
        render: (text) => {
          return <span>{text || "-"}</span>;
        },
      },
      {
        title: "登记状态",
        dataIndex: "regStatus",
        key: "regStatus",
      },
      {
        title: "来源",
        dataIndex: "isInternalAddition",
        key: "isInternalAddition",
        render: (isInternalAddition) => {
          return <span>{isInternalAddition ? "内部" : "外部"}</span>;
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return <span>{AuditStatus[text].desc}</span>;
        },
      },
      {
        title: "创建人",
        dataIndex: "createUserName",
        key: "createUserName",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id) => {
          return (
            <div className="operation">
              <Button onClick={this.handleModalOpen.bind(this, id)} type="link">
                审核
              </Button>
              <Divider type="vertical" />
              <Link to={`${match.url}/detail/${id}`}>企业详情</Link>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>企业审核</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={2} span={8}>
                  <Form.Item label={`企业名称`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={6} span={8}>
                  <Form.Item label={`状态`} name={`status`}>
                    <Select placeholder="请选择">
                      <Option value="">全部</Option>
                      <Option value="to_audit">待审核</Option>
                      <Option value="audit_pass">通过</Option>
                      <Option value="audit_refuse">不通过</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
          <Modal
            footer={null}
            key={visibleKey}
            loading={modalLoading}
            maskClosable={false}
            onCancel={this.handleModalClose.bind(this)}
            title={`企业审核`}
            visible={visible}
            width={600}
          >
            <Form onFinish={this.handleModalOk.bind(this)} ref={this.formRef}>
              <Form.Item
                label="审核"
                labelCol={modalLabelCol}
                name="status"
                rules={[{ required: true, message: "请选择审核状态" }]}
                wrapperCol={modalWrapperCol}
              >
                <Select onSelect={this.changeApprovalStatus.bind(this)} placeholder={`请选择审核状态`}>
                  <Option value={`audit_pass`}>通过</Option>
                  <Option value={`audit_refuse`}>不通过</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="审核意见"
                labelCol={modalLabelCol}
                name="refuseReason"
                rules={[{ required: requiredRefuseReason, message: "请输入审核意见" }]}
                wrapperCol={modalWrapperCol}
              >
                <TextArea
                  placeholder={requiredRefuseReason ? "请输入审核意见" : "请输入审核意见（选填）"}
                  rows={3}
                ></TextArea>
              </Form.Item>

              <Form.Item wrapperCol={modalSubmitFormLayout}>
                <Button htmlType="submit" loading={modalLoading} type="primary">
                  {modalLoading ? "提交中" : "确定"}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

const EnterpriseAuditList = List;
export default EnterpriseAuditList;
