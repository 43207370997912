import React from "react";
import { Modal, Form, Input, Button, Descriptions } from "antd";
import PropTypes from "prop-types";

class ModalDetail extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    showList: PropTypes.array.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    onCancel: () => {},
    onConfirm: () => {},
    params: {},
  };

  state = {};

  _onCancel = () => {
    this.props.onCancel();
  };

  _renderItem = (item) => {
    if (item.hidden) {
      return null;
    } else if (item.render) {
      return (
        <Descriptions.Item key={item.dataIndex} label={item.title} span={3}>
          {item.render(this.props.data[item.dataIndex], this.props.data)}
        </Descriptions.Item>
      );
    } else {
      return (
        <Descriptions.Item key={item.dataIndex} label={item.title} span={3}>
          {this.props.data[item.dataIndex]}
        </Descriptions.Item>
      );
    }
  };

  render() {
    const props = this.props;

    return (
      <Modal
        footer={null}
        maskClosable
        onCancel={this._onCancel}
        title={"详情"}
        visible={props.visible}
        width={props.width || 750}
      >
        <Descriptions bordered>
          {props.showList.map((item) => {
            return this._renderItem(item);
          })}
        </Descriptions>

        {/* <div style={{ textAlign: "right" }}>
          <Button onClick={this._onCancel} style={{ marginLeft: 10 }} type="default">
            取消
          </Button>
          <Button htmlType="submit" style={{ marginLeft: 10 }} type="primary">
            确定
          </Button>
        </div> */}
      </Modal>
    );
  }
}
export default ModalDetail;
