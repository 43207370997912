const districtList = [
  {
    areaCode: 110000000000,
    children: [
      {
        areaCode: 110100000000,
        children: [
          {
            areaCode: 110101000000,
            label: "东城区",
            value: "东城区",
          },
          {
            areaCode: 110102000000,
            label: "西城区",
            value: "西城区",
          },
          {
            areaCode: 110105000000,
            label: "朝阳区",
            value: "朝阳区",
          },
          {
            areaCode: 110106000000,
            label: "丰台区",
            value: "丰台区",
          },
          {
            areaCode: 110107000000,
            label: "石景山区",
            value: "石景山区",
          },
          {
            areaCode: 110108000000,
            label: "海淀区",
            value: "海淀区",
          },
          {
            areaCode: 110109000000,
            label: "门头沟区",
            value: "门头沟区",
          },
          {
            areaCode: 110111000000,
            label: "房山区",
            value: "房山区",
          },
          {
            areaCode: 110112000000,
            label: "通州区",
            value: "通州区",
          },
          {
            areaCode: 110113000000,
            label: "顺义区",
            value: "顺义区",
          },
          {
            areaCode: 110114000000,
            label: "昌平区",
            value: "昌平区",
          },
          {
            areaCode: 110115000000,
            label: "大兴区",
            value: "大兴区",
          },
          {
            areaCode: 110116000000,
            label: "怀柔区",
            value: "怀柔区",
          },
          {
            areaCode: 110117000000,
            label: "平谷区",
            value: "平谷区",
          },
          {
            areaCode: 110118000000,
            label: "密云区",
            value: "密云区",
          },
          {
            areaCode: 110119000000,
            label: "延庆区",
            value: "延庆区",
          },
        ],
        label: "直辖区",
        value: "直辖区",
      },
    ],
    label: "北京市",
    value: "北京市",
  },
  {
    areaCode: 120000000000,
    children: [
      {
        areaCode: 120100000000,
        children: [
          {
            areaCode: 120101000000,
            label: "和平区",
            value: "和平区",
          },
          {
            areaCode: 120102000000,
            label: "河东区",
            value: "河东区",
          },
          {
            areaCode: 120103000000,
            label: "河西区",
            value: "河西区",
          },
          {
            areaCode: 120104000000,
            label: "南开区",
            value: "南开区",
          },
          {
            areaCode: 120105000000,
            label: "河北区",
            value: "河北区",
          },
          {
            areaCode: 120106000000,
            label: "红桥区",
            value: "红桥区",
          },
          {
            areaCode: 120110000000,
            label: "东丽区",
            value: "东丽区",
          },
          {
            areaCode: 120111000000,
            label: "西青区",
            value: "西青区",
          },
          {
            areaCode: 120112000000,
            label: "津南区",
            value: "津南区",
          },
          {
            areaCode: 120113000000,
            label: "北辰区",
            value: "北辰区",
          },
          {
            areaCode: 120114000000,
            label: "武清区",
            value: "武清区",
          },
          {
            areaCode: 120115000000,
            label: "宝坻区",
            value: "宝坻区",
          },
          {
            areaCode: 120116000000,
            label: "滨海新区",
            value: "滨海新区",
          },
          {
            areaCode: 120117000000,
            label: "宁河区",
            value: "宁河区",
          },
          {
            areaCode: 120118000000,
            label: "静海区",
            value: "静海区",
          },
          {
            areaCode: 120119000000,
            label: "蓟州区",
            value: "蓟州区",
          },
        ],
        label: "直辖区",
        value: "直辖区",
      },
    ],
    label: "天津市",
    value: "天津市",
  },
  {
    areaCode: 130000000000,
    children: [
      {
        areaCode: 130100000000,
        children: [
          {
            areaCode: 130102000000,
            label: "长安区",
            value: "长安区",
          },
          {
            areaCode: 130104000000,
            label: "桥西区",
            value: "桥西区",
          },
          {
            areaCode: 130105000000,
            label: "新华区",
            value: "新华区",
          },
          {
            areaCode: 130107000000,
            label: "井陉矿区",
            value: "井陉矿区",
          },
          {
            areaCode: 130108000000,
            label: "裕华区",
            value: "裕华区",
          },
          {
            areaCode: 130109000000,
            label: "藁城区",
            value: "藁城区",
          },
          {
            areaCode: 130110000000,
            label: "鹿泉区",
            value: "鹿泉区",
          },
          {
            areaCode: 130111000000,
            label: "栾城区",
            value: "栾城区",
          },
          {
            areaCode: 130121000000,
            label: "井陉县",
            value: "井陉县",
          },
          {
            areaCode: 130123000000,
            label: "正定县",
            value: "正定县",
          },
          {
            areaCode: 130125000000,
            label: "行唐县",
            value: "行唐县",
          },
          {
            areaCode: 130126000000,
            label: "灵寿县",
            value: "灵寿县",
          },
          {
            areaCode: 130127000000,
            label: "高邑县",
            value: "高邑县",
          },
          {
            areaCode: 130128000000,
            label: "深泽县",
            value: "深泽县",
          },
          {
            areaCode: 130129000000,
            label: "赞皇县",
            value: "赞皇县",
          },
          {
            areaCode: 130130000000,
            label: "无极县",
            value: "无极县",
          },
          {
            areaCode: 130131000000,
            label: "平山县",
            value: "平山县",
          },
          {
            areaCode: 130132000000,
            label: "元氏县",
            value: "元氏县",
          },
          {
            areaCode: 130133000000,
            label: "赵县",
            value: "赵县",
          },
          {
            areaCode: 130183000000,
            label: "晋州市",
            value: "晋州市",
          },
          {
            areaCode: 130184000000,
            label: "新乐市",
            value: "新乐市",
          },
        ],
        label: "石家庄市",
        value: "石家庄市",
      },
      {
        areaCode: 130200000000,
        children: [
          {
            areaCode: 130202000000,
            label: "路南区",
            value: "路南区",
          },
          {
            areaCode: 130203000000,
            label: "路北区",
            value: "路北区",
          },
          {
            areaCode: 130204000000,
            label: "古冶区",
            value: "古冶区",
          },
          {
            areaCode: 130205000000,
            label: "开平区",
            value: "开平区",
          },
          {
            areaCode: 130207000000,
            label: "丰南区",
            value: "丰南区",
          },
          {
            areaCode: 130208000000,
            label: "丰润区",
            value: "丰润区",
          },
          {
            areaCode: 130209000000,
            label: "曹妃甸区",
            value: "曹妃甸区",
          },
          {
            areaCode: 130223000000,
            label: "滦县",
            value: "滦县",
          },
          {
            areaCode: 130224000000,
            label: "滦南县",
            value: "滦南县",
          },
          {
            areaCode: 130225000000,
            label: "乐亭县",
            value: "乐亭县",
          },
          {
            areaCode: 130227000000,
            label: "迁西县",
            value: "迁西县",
          },
          {
            areaCode: 130229000000,
            label: "玉田县",
            value: "玉田县",
          },
          {
            areaCode: 130281000000,
            label: "遵化市",
            value: "遵化市",
          },
          {
            areaCode: 130283000000,
            label: "迁安市",
            value: "迁安市",
          },
        ],
        label: "唐山市",
        value: "唐山市",
      },
      {
        areaCode: 130300000000,
        children: [
          {
            areaCode: 130302000000,
            label: "海港区",
            value: "海港区",
          },
          {
            areaCode: 130303000000,
            label: "山海关区",
            value: "山海关区",
          },
          {
            areaCode: 130304000000,
            label: "北戴河区",
            value: "北戴河区",
          },
          {
            areaCode: 130306000000,
            label: "抚宁区",
            value: "抚宁区",
          },
          {
            areaCode: 130321000000,
            label: "青龙满族自治县",
            value: "青龙满族自治县",
          },
          {
            areaCode: 130322000000,
            label: "昌黎县",
            value: "昌黎县",
          },
          {
            areaCode: 130324000000,
            label: "卢龙县",
            value: "卢龙县",
          },
        ],
        label: "秦皇岛市",
        value: "秦皇岛市",
      },
      {
        areaCode: 130400000000,
        children: [
          {
            areaCode: 130402000000,
            label: "邯山区",
            value: "邯山区",
          },
          {
            areaCode: 130403000000,
            label: "丛台区",
            value: "丛台区",
          },
          {
            areaCode: 130404000000,
            label: "复兴区",
            value: "复兴区",
          },
          {
            areaCode: 130406000000,
            label: "峰峰矿区",
            value: "峰峰矿区",
          },
          {
            areaCode: 130421000000,
            label: "邯郸县",
            value: "邯郸县",
          },
          {
            areaCode: 130423000000,
            label: "临漳县",
            value: "临漳县",
          },
          {
            areaCode: 130424000000,
            label: "成安县",
            value: "成安县",
          },
          {
            areaCode: 130425000000,
            label: "大名县",
            value: "大名县",
          },
          {
            areaCode: 130426000000,
            label: "涉县",
            value: "涉县",
          },
          {
            areaCode: 130427000000,
            label: "磁县",
            value: "磁县",
          },
          {
            areaCode: 130428000000,
            label: "肥乡县",
            value: "肥乡县",
          },
          {
            areaCode: 130429000000,
            label: "永年县",
            value: "永年县",
          },
          {
            areaCode: 130430000000,
            label: "邱县",
            value: "邱县",
          },
          {
            areaCode: 130431000000,
            label: "鸡泽县",
            value: "鸡泽县",
          },
          {
            areaCode: 130432000000,
            label: "广平县",
            value: "广平县",
          },
          {
            areaCode: 130433000000,
            label: "馆陶县",
            value: "馆陶县",
          },
          {
            areaCode: 130434000000,
            label: "魏县",
            value: "魏县",
          },
          {
            areaCode: 130435000000,
            label: "曲周县",
            value: "曲周县",
          },
          {
            areaCode: 130481000000,
            label: "武安市",
            value: "武安市",
          },
        ],
        label: "邯郸市",
        value: "邯郸市",
      },
      {
        areaCode: 130500000000,
        children: [
          {
            areaCode: 130502000000,
            label: "桥东区",
            value: "桥东区",
          },
          {
            areaCode: 130503000000,
            label: "桥西区",
            value: "桥西区",
          },
          {
            areaCode: 130521000000,
            label: "邢台县",
            value: "邢台县",
          },
          {
            areaCode: 130522000000,
            label: "临城县",
            value: "临城县",
          },
          {
            areaCode: 130523000000,
            label: "内丘县",
            value: "内丘县",
          },
          {
            areaCode: 130524000000,
            label: "柏乡县",
            value: "柏乡县",
          },
          {
            areaCode: 130525000000,
            label: "隆尧县",
            value: "隆尧县",
          },
          {
            areaCode: 130526000000,
            label: "任县",
            value: "任县",
          },
          {
            areaCode: 130527000000,
            label: "南和县",
            value: "南和县",
          },
          {
            areaCode: 130528000000,
            label: "宁晋县",
            value: "宁晋县",
          },
          {
            areaCode: 130529000000,
            label: "巨鹿县",
            value: "巨鹿县",
          },
          {
            areaCode: 130530000000,
            label: "新河县",
            value: "新河县",
          },
          {
            areaCode: 130531000000,
            label: "广宗县",
            value: "广宗县",
          },
          {
            areaCode: 130532000000,
            label: "平乡县",
            value: "平乡县",
          },
          {
            areaCode: 130533000000,
            label: "威县",
            value: "威县",
          },
          {
            areaCode: 130534000000,
            label: "清河县",
            value: "清河县",
          },
          {
            areaCode: 130535000000,
            label: "临西县",
            value: "临西县",
          },
          {
            areaCode: 130581000000,
            label: "南宫市",
            value: "南宫市",
          },
          {
            areaCode: 130582000000,
            label: "沙河市",
            value: "沙河市",
          },
        ],
        label: "邢台市",
        value: "邢台市",
      },
      {
        areaCode: 130600000000,
        children: [
          {
            areaCode: 130602000000,
            label: "竞秀区",
            value: "竞秀区",
          },
          {
            areaCode: 130606000000,
            label: "莲池区",
            value: "莲池区",
          },
          {
            areaCode: 130607000000,
            label: "满城区",
            value: "满城区",
          },
          {
            areaCode: 130608000000,
            label: "清苑区",
            value: "清苑区",
          },
          {
            areaCode: 130609000000,
            label: "徐水区",
            value: "徐水区",
          },
          {
            areaCode: 130623000000,
            label: "涞水县",
            value: "涞水县",
          },
          {
            areaCode: 130624000000,
            label: "阜平县",
            value: "阜平县",
          },
          {
            areaCode: 130626000000,
            label: "定兴县",
            value: "定兴县",
          },
          {
            areaCode: 130627000000,
            label: "唐县",
            value: "唐县",
          },
          {
            areaCode: 130628000000,
            label: "高阳县",
            value: "高阳县",
          },
          {
            areaCode: 130629000000,
            label: "容城县",
            value: "容城县",
          },
          {
            areaCode: 130630000000,
            label: "涞源县",
            value: "涞源县",
          },
          {
            areaCode: 130631000000,
            label: "望都县",
            value: "望都县",
          },
          {
            areaCode: 130632000000,
            label: "安新县",
            value: "安新县",
          },
          {
            areaCode: 130633000000,
            label: "易县",
            value: "易县",
          },
          {
            areaCode: 130634000000,
            label: "曲阳县",
            value: "曲阳县",
          },
          {
            areaCode: 130635000000,
            label: "蠡县",
            value: "蠡县",
          },
          {
            areaCode: 130636000000,
            label: "顺平县",
            value: "顺平县",
          },
          {
            areaCode: 130637000000,
            label: "博野县",
            value: "博野县",
          },
          {
            areaCode: 130638000000,
            label: "雄县",
            value: "雄县",
          },
          {
            areaCode: 130681000000,
            label: "涿州市",
            value: "涿州市",
          },
          {
            areaCode: 130683000000,
            label: "安国市",
            value: "安国市",
          },
          {
            areaCode: 130684000000,
            label: "高碑店市",
            value: "高碑店市",
          },
        ],
        label: "保定市",
        value: "保定市",
      },
      {
        areaCode: 130700000000,
        children: [
          {
            areaCode: 130702000000,
            label: "桥东区",
            value: "桥东区",
          },
          {
            areaCode: 130703000000,
            label: "桥西区",
            value: "桥西区",
          },
          {
            areaCode: 130705000000,
            label: "宣化区",
            value: "宣化区",
          },
          {
            areaCode: 130706000000,
            label: "下花园区",
            value: "下花园区",
          },
          {
            areaCode: 130708000000,
            label: "万全区",
            value: "万全区",
          },
          {
            areaCode: 130709000000,
            label: "崇礼区",
            value: "崇礼区",
          },
          {
            areaCode: 130722000000,
            label: "张北县",
            value: "张北县",
          },
          {
            areaCode: 130723000000,
            label: "康保县",
            value: "康保县",
          },
          {
            areaCode: 130724000000,
            label: "沽源县",
            value: "沽源县",
          },
          {
            areaCode: 130725000000,
            label: "尚义县",
            value: "尚义县",
          },
          {
            areaCode: 130726000000,
            label: "蔚县",
            value: "蔚县",
          },
          {
            areaCode: 130727000000,
            label: "阳原县",
            value: "阳原县",
          },
          {
            areaCode: 130728000000,
            label: "怀安县",
            value: "怀安县",
          },
          {
            areaCode: 130730000000,
            label: "怀来县",
            value: "怀来县",
          },
          {
            areaCode: 130731000000,
            label: "涿鹿县",
            value: "涿鹿县",
          },
          {
            areaCode: 130732000000,
            label: "赤城县",
            value: "赤城县",
          },
        ],
        label: "张家口市",
        value: "张家口市",
      },
      {
        areaCode: 130800000000,
        children: [
          {
            areaCode: 130802000000,
            label: "双桥区",
            value: "双桥区",
          },
          {
            areaCode: 130803000000,
            label: "双滦区",
            value: "双滦区",
          },
          {
            areaCode: 130804000000,
            label: "鹰手营子矿区",
            value: "鹰手营子矿区",
          },
          {
            areaCode: 130821000000,
            label: "承德县",
            value: "承德县",
          },
          {
            areaCode: 130822000000,
            label: "兴隆县",
            value: "兴隆县",
          },
          {
            areaCode: 130823000000,
            label: "平泉县",
            value: "平泉县",
          },
          {
            areaCode: 130824000000,
            label: "滦平县",
            value: "滦平县",
          },
          {
            areaCode: 130825000000,
            label: "隆化县",
            value: "隆化县",
          },
          {
            areaCode: 130826000000,
            label: "丰宁满族自治县",
            value: "丰宁满族自治县",
          },
          {
            areaCode: 130827000000,
            label: "宽城满族自治县",
            value: "宽城满族自治县",
          },
          {
            areaCode: 130828000000,
            label: "围场满族蒙古族自治县",
            value: "围场满族蒙古族自治县",
          },
        ],
        label: "承德市",
        value: "承德市",
      },
      {
        areaCode: 130900000000,
        children: [
          {
            areaCode: 130902000000,
            label: "新华区",
            value: "新华区",
          },
          {
            areaCode: 130903000000,
            label: "运河区",
            value: "运河区",
          },
          {
            areaCode: 130921000000,
            label: "沧县",
            value: "沧县",
          },
          {
            areaCode: 130922000000,
            label: "青县",
            value: "青县",
          },
          {
            areaCode: 130923000000,
            label: "东光县",
            value: "东光县",
          },
          {
            areaCode: 130924000000,
            label: "海兴县",
            value: "海兴县",
          },
          {
            areaCode: 130925000000,
            label: "盐山县",
            value: "盐山县",
          },
          {
            areaCode: 130926000000,
            label: "肃宁县",
            value: "肃宁县",
          },
          {
            areaCode: 130927000000,
            label: "南皮县",
            value: "南皮县",
          },
          {
            areaCode: 130928000000,
            label: "吴桥县",
            value: "吴桥县",
          },
          {
            areaCode: 130929000000,
            label: "献县",
            value: "献县",
          },
          {
            areaCode: 130930000000,
            label: "孟村回族自治县",
            value: "孟村回族自治县",
          },
          {
            areaCode: 130981000000,
            label: "泊头市",
            value: "泊头市",
          },
          {
            areaCode: 130982000000,
            label: "任丘市",
            value: "任丘市",
          },
          {
            areaCode: 130983000000,
            label: "黄骅市",
            value: "黄骅市",
          },
          {
            areaCode: 130984000000,
            label: "河间市",
            value: "河间市",
          },
        ],
        label: "沧州市",
        value: "沧州市",
      },
      {
        areaCode: 131000000000,
        children: [
          {
            areaCode: 131002000000,
            label: "安次区",
            value: "安次区",
          },
          {
            areaCode: 131003000000,
            label: "广阳区",
            value: "广阳区",
          },
          {
            areaCode: 131022000000,
            label: "固安县",
            value: "固安县",
          },
          {
            areaCode: 131023000000,
            label: "永清县",
            value: "永清县",
          },
          {
            areaCode: 131024000000,
            label: "香河县",
            value: "香河县",
          },
          {
            areaCode: 131025000000,
            label: "大城县",
            value: "大城县",
          },
          {
            areaCode: 131026000000,
            label: "文安县",
            value: "文安县",
          },
          {
            areaCode: 131028000000,
            label: "大厂回族自治县",
            value: "大厂回族自治县",
          },
          {
            areaCode: 131081000000,
            label: "霸州市",
            value: "霸州市",
          },
          {
            areaCode: 131082000000,
            label: "三河市",
            value: "三河市",
          },
        ],
        label: "廊坊市",
        value: "廊坊市",
      },
      {
        areaCode: 131100000000,
        children: [
          {
            areaCode: 131102000000,
            label: "桃城区",
            value: "桃城区",
          },
          {
            areaCode: 131103000000,
            label: "冀州区",
            value: "冀州区",
          },
          {
            areaCode: 131121000000,
            label: "枣强县",
            value: "枣强县",
          },
          {
            areaCode: 131122000000,
            label: "武邑县",
            value: "武邑县",
          },
          {
            areaCode: 131123000000,
            label: "武强县",
            value: "武强县",
          },
          {
            areaCode: 131124000000,
            label: "饶阳县",
            value: "饶阳县",
          },
          {
            areaCode: 131125000000,
            label: "安平县",
            value: "安平县",
          },
          {
            areaCode: 131126000000,
            label: "故城县",
            value: "故城县",
          },
          {
            areaCode: 131127000000,
            label: "景县",
            value: "景县",
          },
          {
            areaCode: 131128000000,
            label: "阜城县",
            value: "阜城县",
          },
          {
            areaCode: 131182000000,
            label: "深州市",
            value: "深州市",
          },
        ],
        label: "衡水市",
        value: "衡水市",
      },
      {
        areaCode: 139000000000,
        children: [
          {
            areaCode: 139001000000,
            label: "定州市",
            value: "定州市",
          },
          {
            areaCode: 139002000000,
            label: "辛集市",
            value: "辛集市",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "河北省",
    value: "河北省",
  },
  {
    areaCode: 140000000000,
    children: [
      {
        areaCode: 140100000000,
        children: [
          {
            areaCode: 140105000000,
            label: "小店区",
            value: "小店区",
          },
          {
            areaCode: 140106000000,
            label: "迎泽区",
            value: "迎泽区",
          },
          {
            areaCode: 140107000000,
            label: "杏花岭区",
            value: "杏花岭区",
          },
          {
            areaCode: 140108000000,
            label: "尖草坪区",
            value: "尖草坪区",
          },
          {
            areaCode: 140109000000,
            label: "万柏林区",
            value: "万柏林区",
          },
          {
            areaCode: 140110000000,
            label: "晋源区",
            value: "晋源区",
          },
          {
            areaCode: 140121000000,
            label: "清徐县",
            value: "清徐县",
          },
          {
            areaCode: 140122000000,
            label: "阳曲县",
            value: "阳曲县",
          },
          {
            areaCode: 140123000000,
            label: "娄烦县",
            value: "娄烦县",
          },
          {
            areaCode: 140181000000,
            label: "古交市",
            value: "古交市",
          },
        ],
        label: "太原市",
        value: "太原市",
      },
      {
        areaCode: 140200000000,
        children: [
          {
            areaCode: 140202000000,
            label: "城区",
            value: "城区",
          },
          {
            areaCode: 140203000000,
            label: "矿区",
            value: "矿区",
          },
          {
            areaCode: 140211000000,
            label: "南郊区",
            value: "南郊区",
          },
          {
            areaCode: 140212000000,
            label: "新荣区",
            value: "新荣区",
          },
          {
            areaCode: 140221000000,
            label: "阳高县",
            value: "阳高县",
          },
          {
            areaCode: 140222000000,
            label: "天镇县",
            value: "天镇县",
          },
          {
            areaCode: 140223000000,
            label: "广灵县",
            value: "广灵县",
          },
          {
            areaCode: 140224000000,
            label: "灵丘县",
            value: "灵丘县",
          },
          {
            areaCode: 140225000000,
            label: "浑源县",
            value: "浑源县",
          },
          {
            areaCode: 140226000000,
            label: "左云县",
            value: "左云县",
          },
          {
            areaCode: 140227000000,
            label: "大同县",
            value: "大同县",
          },
        ],
        label: "大同市",
        value: "大同市",
      },
      {
        areaCode: 140300000000,
        children: [
          {
            areaCode: 140302000000,
            label: "城区",
            value: "城区",
          },
          {
            areaCode: 140303000000,
            label: "矿区",
            value: "矿区",
          },
          {
            areaCode: 140311000000,
            label: "郊区",
            value: "郊区",
          },
          {
            areaCode: 140321000000,
            label: "平定县",
            value: "平定县",
          },
          {
            areaCode: 140322000000,
            label: "盂县",
            value: "盂县",
          },
        ],
        label: "阳泉市",
        value: "阳泉市",
      },
      {
        areaCode: 140400000000,
        children: [
          {
            areaCode: 140402000000,
            label: "城区",
            value: "城区",
          },
          {
            areaCode: 140411000000,
            label: "郊区",
            value: "郊区",
          },
          {
            areaCode: 140421000000,
            label: "长治县",
            value: "长治县",
          },
          {
            areaCode: 140423000000,
            label: "襄垣县",
            value: "襄垣县",
          },
          {
            areaCode: 140424000000,
            label: "屯留县",
            value: "屯留县",
          },
          {
            areaCode: 140425000000,
            label: "平顺县",
            value: "平顺县",
          },
          {
            areaCode: 140426000000,
            label: "黎城县",
            value: "黎城县",
          },
          {
            areaCode: 140427000000,
            label: "壶关县",
            value: "壶关县",
          },
          {
            areaCode: 140428000000,
            label: "长子县",
            value: "长子县",
          },
          {
            areaCode: 140429000000,
            label: "武乡县",
            value: "武乡县",
          },
          {
            areaCode: 140430000000,
            label: "沁县",
            value: "沁县",
          },
          {
            areaCode: 140431000000,
            label: "沁源县",
            value: "沁源县",
          },
          {
            areaCode: 140481000000,
            label: "潞城市",
            value: "潞城市",
          },
        ],
        label: "长治市",
        value: "长治市",
      },
      {
        areaCode: 140500000000,
        children: [
          {
            areaCode: 140502000000,
            label: "城区",
            value: "城区",
          },
          {
            areaCode: 140521000000,
            label: "沁水县",
            value: "沁水县",
          },
          {
            areaCode: 140522000000,
            label: "阳城县",
            value: "阳城县",
          },
          {
            areaCode: 140524000000,
            label: "陵川县",
            value: "陵川县",
          },
          {
            areaCode: 140525000000,
            label: "泽州县",
            value: "泽州县",
          },
          {
            areaCode: 140581000000,
            label: "高平市",
            value: "高平市",
          },
        ],
        label: "晋城市",
        value: "晋城市",
      },
      {
        areaCode: 140600000000,
        children: [
          {
            areaCode: 140602000000,
            label: "朔城区",
            value: "朔城区",
          },
          {
            areaCode: 140603000000,
            label: "平鲁区",
            value: "平鲁区",
          },
          {
            areaCode: 140621000000,
            label: "山阴县",
            value: "山阴县",
          },
          {
            areaCode: 140622000000,
            label: "应县",
            value: "应县",
          },
          {
            areaCode: 140623000000,
            label: "右玉县",
            value: "右玉县",
          },
          {
            areaCode: 140624000000,
            label: "怀仁县",
            value: "怀仁县",
          },
        ],
        label: "朔州市",
        value: "朔州市",
      },
      {
        areaCode: 140700000000,
        children: [
          {
            areaCode: 140702000000,
            label: "榆次区",
            value: "榆次区",
          },
          {
            areaCode: 140721000000,
            label: "榆社县",
            value: "榆社县",
          },
          {
            areaCode: 140722000000,
            label: "左权县",
            value: "左权县",
          },
          {
            areaCode: 140723000000,
            label: "和顺县",
            value: "和顺县",
          },
          {
            areaCode: 140724000000,
            label: "昔阳县",
            value: "昔阳县",
          },
          {
            areaCode: 140725000000,
            label: "寿阳县",
            value: "寿阳县",
          },
          {
            areaCode: 140726000000,
            label: "太谷县",
            value: "太谷县",
          },
          {
            areaCode: 140727000000,
            label: "祁县",
            value: "祁县",
          },
          {
            areaCode: 140728000000,
            label: "平遥县",
            value: "平遥县",
          },
          {
            areaCode: 140729000000,
            label: "灵石县",
            value: "灵石县",
          },
          {
            areaCode: 140781000000,
            label: "介休市",
            value: "介休市",
          },
        ],
        label: "晋中市",
        value: "晋中市",
      },
      {
        areaCode: 140800000000,
        children: [
          {
            areaCode: 140802000000,
            label: "盐湖区",
            value: "盐湖区",
          },
          {
            areaCode: 140821000000,
            label: "临猗县",
            value: "临猗县",
          },
          {
            areaCode: 140822000000,
            label: "万荣县",
            value: "万荣县",
          },
          {
            areaCode: 140823000000,
            label: "闻喜县",
            value: "闻喜县",
          },
          {
            areaCode: 140824000000,
            label: "稷山县",
            value: "稷山县",
          },
          {
            areaCode: 140825000000,
            label: "新绛县",
            value: "新绛县",
          },
          {
            areaCode: 140826000000,
            label: "绛县",
            value: "绛县",
          },
          {
            areaCode: 140827000000,
            label: "垣曲县",
            value: "垣曲县",
          },
          {
            areaCode: 140828000000,
            label: "夏县",
            value: "夏县",
          },
          {
            areaCode: 140829000000,
            label: "平陆县",
            value: "平陆县",
          },
          {
            areaCode: 140830000000,
            label: "芮城县",
            value: "芮城县",
          },
          {
            areaCode: 140881000000,
            label: "永济市",
            value: "永济市",
          },
          {
            areaCode: 140882000000,
            label: "河津市",
            value: "河津市",
          },
        ],
        label: "运城市",
        value: "运城市",
      },
      {
        areaCode: 140900000000,
        children: [
          {
            areaCode: 140902000000,
            label: "忻府区",
            value: "忻府区",
          },
          {
            areaCode: 140921000000,
            label: "定襄县",
            value: "定襄县",
          },
          {
            areaCode: 140922000000,
            label: "五台县",
            value: "五台县",
          },
          {
            areaCode: 140923000000,
            label: "代县",
            value: "代县",
          },
          {
            areaCode: 140924000000,
            label: "繁峙县",
            value: "繁峙县",
          },
          {
            areaCode: 140925000000,
            label: "宁武县",
            value: "宁武县",
          },
          {
            areaCode: 140926000000,
            label: "静乐县",
            value: "静乐县",
          },
          {
            areaCode: 140927000000,
            label: "神池县",
            value: "神池县",
          },
          {
            areaCode: 140928000000,
            label: "五寨县",
            value: "五寨县",
          },
          {
            areaCode: 140929000000,
            label: "岢岚县",
            value: "岢岚县",
          },
          {
            areaCode: 140930000000,
            label: "河曲县",
            value: "河曲县",
          },
          {
            areaCode: 140931000000,
            label: "保德县",
            value: "保德县",
          },
          {
            areaCode: 140932000000,
            label: "偏关县",
            value: "偏关县",
          },
          {
            areaCode: 140981000000,
            label: "原平市",
            value: "原平市",
          },
        ],
        label: "忻州市",
        value: "忻州市",
      },
      {
        areaCode: 141000000000,
        children: [
          {
            areaCode: 141002000000,
            label: "尧都区",
            value: "尧都区",
          },
          {
            areaCode: 141021000000,
            label: "曲沃县",
            value: "曲沃县",
          },
          {
            areaCode: 141022000000,
            label: "翼城县",
            value: "翼城县",
          },
          {
            areaCode: 141023000000,
            label: "襄汾县",
            value: "襄汾县",
          },
          {
            areaCode: 141024000000,
            label: "洪洞县",
            value: "洪洞县",
          },
          {
            areaCode: 141025000000,
            label: "古县",
            value: "古县",
          },
          {
            areaCode: 141026000000,
            label: "安泽县",
            value: "安泽县",
          },
          {
            areaCode: 141027000000,
            label: "浮山县",
            value: "浮山县",
          },
          {
            areaCode: 141028000000,
            label: "吉县",
            value: "吉县",
          },
          {
            areaCode: 141029000000,
            label: "乡宁县",
            value: "乡宁县",
          },
          {
            areaCode: 141030000000,
            label: "大宁县",
            value: "大宁县",
          },
          {
            areaCode: 141031000000,
            label: "隰县",
            value: "隰县",
          },
          {
            areaCode: 141032000000,
            label: "永和县",
            value: "永和县",
          },
          {
            areaCode: 141033000000,
            label: "蒲县",
            value: "蒲县",
          },
          {
            areaCode: 141034000000,
            label: "汾西县",
            value: "汾西县",
          },
          {
            areaCode: 141081000000,
            label: "侯马市",
            value: "侯马市",
          },
          {
            areaCode: 141082000000,
            label: "霍州市",
            value: "霍州市",
          },
        ],
        label: "临汾市",
        value: "临汾市",
      },
      {
        areaCode: 141100000000,
        children: [
          {
            areaCode: 141102000000,
            label: "离石区",
            value: "离石区",
          },
          {
            areaCode: 141121000000,
            label: "文水县",
            value: "文水县",
          },
          {
            areaCode: 141122000000,
            label: "交城县",
            value: "交城县",
          },
          {
            areaCode: 141123000000,
            label: "兴县",
            value: "兴县",
          },
          {
            areaCode: 141124000000,
            label: "临县",
            value: "临县",
          },
          {
            areaCode: 141125000000,
            label: "柳林县",
            value: "柳林县",
          },
          {
            areaCode: 141126000000,
            label: "石楼县",
            value: "石楼县",
          },
          {
            areaCode: 141127000000,
            label: "岚县",
            value: "岚县",
          },
          {
            areaCode: 141128000000,
            label: "方山县",
            value: "方山县",
          },
          {
            areaCode: 141129000000,
            label: "中阳县",
            value: "中阳县",
          },
          {
            areaCode: 141130000000,
            label: "交口县",
            value: "交口县",
          },
          {
            areaCode: 141181000000,
            label: "孝义市",
            value: "孝义市",
          },
          {
            areaCode: 141182000000,
            label: "汾阳市",
            value: "汾阳市",
          },
        ],
        label: "吕梁市",
        value: "吕梁市",
      },
    ],
    label: "山西省",
    value: "山西省",
  },
  {
    areaCode: 150000000000,
    children: [
      {
        areaCode: 150100000000,
        children: [
          {
            areaCode: 150102000000,
            label: "新城区",
            value: "新城区",
          },
          {
            areaCode: 150103000000,
            label: "回民区",
            value: "回民区",
          },
          {
            areaCode: 150104000000,
            label: "玉泉区",
            value: "玉泉区",
          },
          {
            areaCode: 150105000000,
            label: "赛罕区",
            value: "赛罕区",
          },
          {
            areaCode: 150121000000,
            label: "土默特左旗",
            value: "土默特左旗",
          },
          {
            areaCode: 150122000000,
            label: "托克托县",
            value: "托克托县",
          },
          {
            areaCode: 150123000000,
            label: "和林格尔县",
            value: "和林格尔县",
          },
          {
            areaCode: 150124000000,
            label: "清水河县",
            value: "清水河县",
          },
          {
            areaCode: 150125000000,
            label: "武川县",
            value: "武川县",
          },
        ],
        label: "呼和浩特市",
        value: "呼和浩特市",
      },
      {
        areaCode: 150200000000,
        children: [
          {
            areaCode: 150202000000,
            label: "东河区",
            value: "东河区",
          },
          {
            areaCode: 150203000000,
            label: "昆都仑区",
            value: "昆都仑区",
          },
          {
            areaCode: 150204000000,
            label: "青山区",
            value: "青山区",
          },
          {
            areaCode: 150205000000,
            label: "石拐区",
            value: "石拐区",
          },
          {
            areaCode: 150206000000,
            label: "白云鄂博矿区",
            value: "白云鄂博矿区",
          },
          {
            areaCode: 150207000000,
            label: "九原区",
            value: "九原区",
          },
          {
            areaCode: 150221000000,
            label: "土默特右旗",
            value: "土默特右旗",
          },
          {
            areaCode: 150222000000,
            label: "固阳县",
            value: "固阳县",
          },
          {
            areaCode: 150223000000,
            label: "达尔罕茂明安联合旗",
            value: "达尔罕茂明安联合旗",
          },
        ],
        label: "包头市",
        value: "包头市",
      },
      {
        areaCode: 150300000000,
        children: [
          {
            areaCode: 150302000000,
            label: "海勃湾区",
            value: "海勃湾区",
          },
          {
            areaCode: 150303000000,
            label: "海南区",
            value: "海南区",
          },
          {
            areaCode: 150304000000,
            label: "乌达区",
            value: "乌达区",
          },
        ],
        label: "乌海市",
        value: "乌海市",
      },
      {
        areaCode: 150400000000,
        children: [
          {
            areaCode: 150402000000,
            label: "红山区",
            value: "红山区",
          },
          {
            areaCode: 150403000000,
            label: "元宝山区",
            value: "元宝山区",
          },
          {
            areaCode: 150404000000,
            label: "松山区",
            value: "松山区",
          },
          {
            areaCode: 150421000000,
            label: "阿鲁科尔沁旗",
            value: "阿鲁科尔沁旗",
          },
          {
            areaCode: 150422000000,
            label: "巴林左旗",
            value: "巴林左旗",
          },
          {
            areaCode: 150423000000,
            label: "巴林右旗",
            value: "巴林右旗",
          },
          {
            areaCode: 150424000000,
            label: "林西县",
            value: "林西县",
          },
          {
            areaCode: 150425000000,
            label: "克什克腾旗",
            value: "克什克腾旗",
          },
          {
            areaCode: 150426000000,
            label: "翁牛特旗",
            value: "翁牛特旗",
          },
          {
            areaCode: 150428000000,
            label: "喀喇沁旗",
            value: "喀喇沁旗",
          },
          {
            areaCode: 150429000000,
            label: "宁城县",
            value: "宁城县",
          },
          {
            areaCode: 150430000000,
            label: "敖汉旗",
            value: "敖汉旗",
          },
        ],
        label: "赤峰市",
        value: "赤峰市",
      },
      {
        areaCode: 150500000000,
        children: [
          {
            areaCode: 150502000000,
            label: "科尔沁区",
            value: "科尔沁区",
          },
          {
            areaCode: 150521000000,
            label: "科尔沁左翼中旗",
            value: "科尔沁左翼中旗",
          },
          {
            areaCode: 150522000000,
            label: "科尔沁左翼后旗",
            value: "科尔沁左翼后旗",
          },
          {
            areaCode: 150523000000,
            label: "开鲁县",
            value: "开鲁县",
          },
          {
            areaCode: 150524000000,
            label: "库伦旗",
            value: "库伦旗",
          },
          {
            areaCode: 150525000000,
            label: "奈曼旗",
            value: "奈曼旗",
          },
          {
            areaCode: 150526000000,
            label: "扎鲁特旗",
            value: "扎鲁特旗",
          },
          {
            areaCode: 150581000000,
            label: "霍林郭勒市",
            value: "霍林郭勒市",
          },
        ],
        label: "通辽市",
        value: "通辽市",
      },
      {
        areaCode: 150600000000,
        children: [
          {
            areaCode: 150602000000,
            label: "东胜区",
            value: "东胜区",
          },
          {
            areaCode: 150603000000,
            label: "康巴什区",
            value: "康巴什区",
          },
          {
            areaCode: 150621000000,
            label: "达拉特旗",
            value: "达拉特旗",
          },
          {
            areaCode: 150622000000,
            label: "准格尔旗",
            value: "准格尔旗",
          },
          {
            areaCode: 150623000000,
            label: "鄂托克前旗",
            value: "鄂托克前旗",
          },
          {
            areaCode: 150624000000,
            label: "鄂托克旗",
            value: "鄂托克旗",
          },
          {
            areaCode: 150625000000,
            label: "杭锦旗",
            value: "杭锦旗",
          },
          {
            areaCode: 150626000000,
            label: "乌审旗",
            value: "乌审旗",
          },
          {
            areaCode: 150627000000,
            label: "伊金霍洛旗",
            value: "伊金霍洛旗",
          },
        ],
        label: "鄂尔多斯市",
        value: "鄂尔多斯市",
      },
      {
        areaCode: 150700000000,
        children: [
          {
            areaCode: 150702000000,
            label: "海拉尔区",
            value: "海拉尔区",
          },
          {
            areaCode: 150703000000,
            label: "扎赉诺尔区",
            value: "扎赉诺尔区",
          },
          {
            areaCode: 150721000000,
            label: "阿荣旗",
            value: "阿荣旗",
          },
          {
            areaCode: 150722000000,
            label: "莫力达瓦达斡尔族自治旗",
            value: "莫力达瓦达斡尔族自治旗",
          },
          {
            areaCode: 150723000000,
            label: "鄂伦春自治旗",
            value: "鄂伦春自治旗",
          },
          {
            areaCode: 150724000000,
            label: "鄂温克族自治旗",
            value: "鄂温克族自治旗",
          },
          {
            areaCode: 150725000000,
            label: "陈巴尔虎旗",
            value: "陈巴尔虎旗",
          },
          {
            areaCode: 150726000000,
            label: "新巴尔虎左旗",
            value: "新巴尔虎左旗",
          },
          {
            areaCode: 150727000000,
            label: "新巴尔虎右旗",
            value: "新巴尔虎右旗",
          },
          {
            areaCode: 150781000000,
            label: "满洲里市",
            value: "满洲里市",
          },
          {
            areaCode: 150782000000,
            label: "牙克石市",
            value: "牙克石市",
          },
          {
            areaCode: 150783000000,
            label: "扎兰屯市",
            value: "扎兰屯市",
          },
          {
            areaCode: 150784000000,
            label: "额尔古纳市",
            value: "额尔古纳市",
          },
          {
            areaCode: 150785000000,
            label: "根河市",
            value: "根河市",
          },
        ],
        label: "呼伦贝尔市",
        value: "呼伦贝尔市",
      },
      {
        areaCode: 150800000000,
        children: [
          {
            areaCode: 150802000000,
            label: "临河区",
            value: "临河区",
          },
          {
            areaCode: 150821000000,
            label: "五原县",
            value: "五原县",
          },
          {
            areaCode: 150822000000,
            label: "磴口县",
            value: "磴口县",
          },
          {
            areaCode: 150823000000,
            label: "乌拉特前旗",
            value: "乌拉特前旗",
          },
          {
            areaCode: 150824000000,
            label: "乌拉特中旗",
            value: "乌拉特中旗",
          },
          {
            areaCode: 150825000000,
            label: "乌拉特后旗",
            value: "乌拉特后旗",
          },
          {
            areaCode: 150826000000,
            label: "杭锦后旗",
            value: "杭锦后旗",
          },
        ],
        label: "巴彦淖尔市",
        value: "巴彦淖尔市",
      },
      {
        areaCode: 150900000000,
        children: [
          {
            areaCode: 150902000000,
            label: "集宁区",
            value: "集宁区",
          },
          {
            areaCode: 150921000000,
            label: "卓资县",
            value: "卓资县",
          },
          {
            areaCode: 150922000000,
            label: "化德县",
            value: "化德县",
          },
          {
            areaCode: 150923000000,
            label: "商都县",
            value: "商都县",
          },
          {
            areaCode: 150924000000,
            label: "兴和县",
            value: "兴和县",
          },
          {
            areaCode: 150925000000,
            label: "凉城县",
            value: "凉城县",
          },
          {
            areaCode: 150926000000,
            label: "察哈尔右翼前旗",
            value: "察哈尔右翼前旗",
          },
          {
            areaCode: 150927000000,
            label: "察哈尔右翼中旗",
            value: "察哈尔右翼中旗",
          },
          {
            areaCode: 150928000000,
            label: "察哈尔右翼后旗",
            value: "察哈尔右翼后旗",
          },
          {
            areaCode: 150929000000,
            label: "四子王旗",
            value: "四子王旗",
          },
          {
            areaCode: 150981000000,
            label: "丰镇市",
            value: "丰镇市",
          },
        ],
        label: "乌兰察布市",
        value: "乌兰察布市",
      },
      {
        areaCode: 152200000000,
        children: [
          {
            areaCode: 152201000000,
            label: "乌兰浩特市",
            value: "乌兰浩特市",
          },
          {
            areaCode: 152202000000,
            label: "阿尔山市",
            value: "阿尔山市",
          },
          {
            areaCode: 152221000000,
            label: "科尔沁右翼前旗",
            value: "科尔沁右翼前旗",
          },
          {
            areaCode: 152222000000,
            label: "科尔沁右翼中旗",
            value: "科尔沁右翼中旗",
          },
          {
            areaCode: 152223000000,
            label: "扎赉特旗",
            value: "扎赉特旗",
          },
          {
            areaCode: 152224000000,
            label: "突泉县",
            value: "突泉县",
          },
        ],
        label: "兴安盟",
        value: "兴安盟",
      },
      {
        areaCode: 152500000000,
        children: [
          {
            areaCode: 152501000000,
            label: "二连浩特市",
            value: "二连浩特市",
          },
          {
            areaCode: 152502000000,
            label: "锡林浩特市",
            value: "锡林浩特市",
          },
          {
            areaCode: 152522000000,
            label: "阿巴嘎旗",
            value: "阿巴嘎旗",
          },
          {
            areaCode: 152523000000,
            label: "苏尼特左旗",
            value: "苏尼特左旗",
          },
          {
            areaCode: 152524000000,
            label: "苏尼特右旗",
            value: "苏尼特右旗",
          },
          {
            areaCode: 152525000000,
            label: "东乌珠穆沁旗",
            value: "东乌珠穆沁旗",
          },
          {
            areaCode: 152526000000,
            label: "西乌珠穆沁旗",
            value: "西乌珠穆沁旗",
          },
          {
            areaCode: 152527000000,
            label: "太仆寺旗",
            value: "太仆寺旗",
          },
          {
            areaCode: 152528000000,
            label: "镶黄旗",
            value: "镶黄旗",
          },
          {
            areaCode: 152529000000,
            label: "正镶白旗",
            value: "正镶白旗",
          },
          {
            areaCode: 152530000000,
            label: "正蓝旗",
            value: "正蓝旗",
          },
          {
            areaCode: 152531000000,
            label: "多伦县",
            value: "多伦县",
          },
        ],
        label: "锡林郭勒盟",
        value: "锡林郭勒盟",
      },
      {
        areaCode: 152900000000,
        children: [
          {
            areaCode: 152921000000,
            label: "阿拉善左旗",
            value: "阿拉善左旗",
          },
          {
            areaCode: 152922000000,
            label: "阿拉善右旗",
            value: "阿拉善右旗",
          },
          {
            areaCode: 152923000000,
            label: "额济纳旗",
            value: "额济纳旗",
          },
        ],
        label: "阿拉善盟",
        value: "阿拉善盟",
      },
    ],
    label: "内蒙古自治区",
    value: "内蒙古自治区",
  },
  {
    areaCode: 210000000000,
    children: [
      {
        areaCode: 210100000000,
        children: [
          {
            areaCode: 210102000000,
            label: "和平区",
            value: "和平区",
          },
          {
            areaCode: 210103000000,
            label: "沈河区",
            value: "沈河区",
          },
          {
            areaCode: 210104000000,
            label: "大东区",
            value: "大东区",
          },
          {
            areaCode: 210105000000,
            label: "皇姑区",
            value: "皇姑区",
          },
          {
            areaCode: 210106000000,
            label: "铁西区",
            value: "铁西区",
          },
          {
            areaCode: 210111000000,
            label: "苏家屯区",
            value: "苏家屯区",
          },
          {
            areaCode: 210112000000,
            label: "浑南区",
            value: "浑南区",
          },
          {
            areaCode: 210113000000,
            label: "沈北新区",
            value: "沈北新区",
          },
          {
            areaCode: 210114000000,
            label: "于洪区",
            value: "于洪区",
          },
          {
            areaCode: 210115000000,
            label: "辽中区",
            value: "辽中区",
          },
          {
            areaCode: 210123000000,
            label: "康平县",
            value: "康平县",
          },
          {
            areaCode: 210124000000,
            label: "法库县",
            value: "法库县",
          },
          {
            areaCode: 210181000000,
            label: "新民市",
            value: "新民市",
          },
        ],
        label: "沈阳市",
        value: "沈阳市",
      },
      {
        areaCode: 210200000000,
        children: [
          {
            areaCode: 210202000000,
            label: "中山区",
            value: "中山区",
          },
          {
            areaCode: 210203000000,
            label: "西岗区",
            value: "西岗区",
          },
          {
            areaCode: 210204000000,
            label: "沙河口区",
            value: "沙河口区",
          },
          {
            areaCode: 210211000000,
            label: "甘井子区",
            value: "甘井子区",
          },
          {
            areaCode: 210212000000,
            label: "旅顺口区",
            value: "旅顺口区",
          },
          {
            areaCode: 210213000000,
            label: "金州区",
            value: "金州区",
          },
          {
            areaCode: 210214000000,
            label: "普兰店区",
            value: "普兰店区",
          },
          {
            areaCode: 210224000000,
            label: "长海县",
            value: "长海县",
          },
          {
            areaCode: 210281000000,
            label: "瓦房店市",
            value: "瓦房店市",
          },
          {
            areaCode: 210283000000,
            label: "庄河市",
            value: "庄河市",
          },
        ],
        label: "大连市",
        value: "大连市",
      },
      {
        areaCode: 210300000000,
        children: [
          {
            areaCode: 210302000000,
            label: "铁东区",
            value: "铁东区",
          },
          {
            areaCode: 210303000000,
            label: "铁西区",
            value: "铁西区",
          },
          {
            areaCode: 210304000000,
            label: "立山区",
            value: "立山区",
          },
          {
            areaCode: 210311000000,
            label: "千山区",
            value: "千山区",
          },
          {
            areaCode: 210321000000,
            label: "台安县",
            value: "台安县",
          },
          {
            areaCode: 210323000000,
            label: "岫岩满族自治县",
            value: "岫岩满族自治县",
          },
          {
            areaCode: 210381000000,
            label: "海城市",
            value: "海城市",
          },
        ],
        label: "鞍山市",
        value: "鞍山市",
      },
      {
        areaCode: 210400000000,
        children: [
          {
            areaCode: 210402000000,
            label: "新抚区",
            value: "新抚区",
          },
          {
            areaCode: 210403000000,
            label: "东洲区",
            value: "东洲区",
          },
          {
            areaCode: 210404000000,
            label: "望花区",
            value: "望花区",
          },
          {
            areaCode: 210411000000,
            label: "顺城区",
            value: "顺城区",
          },
          {
            areaCode: 210421000000,
            label: "抚顺县",
            value: "抚顺县",
          },
          {
            areaCode: 210422000000,
            label: "新宾满族自治县",
            value: "新宾满族自治县",
          },
          {
            areaCode: 210423000000,
            label: "清原满族自治县",
            value: "清原满族自治县",
          },
        ],
        label: "抚顺市",
        value: "抚顺市",
      },
      {
        areaCode: 210500000000,
        children: [
          {
            areaCode: 210502000000,
            label: "平山区",
            value: "平山区",
          },
          {
            areaCode: 210503000000,
            label: "溪湖区",
            value: "溪湖区",
          },
          {
            areaCode: 210504000000,
            label: "明山区",
            value: "明山区",
          },
          {
            areaCode: 210505000000,
            label: "南芬区",
            value: "南芬区",
          },
          {
            areaCode: 210521000000,
            label: "本溪满族自治县",
            value: "本溪满族自治县",
          },
          {
            areaCode: 210522000000,
            label: "桓仁满族自治县",
            value: "桓仁满族自治县",
          },
        ],
        label: "本溪市",
        value: "本溪市",
      },
      {
        areaCode: 210600000000,
        children: [
          {
            areaCode: 210602000000,
            label: "元宝区",
            value: "元宝区",
          },
          {
            areaCode: 210603000000,
            label: "振兴区",
            value: "振兴区",
          },
          {
            areaCode: 210604000000,
            label: "振安区",
            value: "振安区",
          },
          {
            areaCode: 210624000000,
            label: "宽甸满族自治县",
            value: "宽甸满族自治县",
          },
          {
            areaCode: 210681000000,
            label: "东港市",
            value: "东港市",
          },
          {
            areaCode: 210682000000,
            label: "凤城市",
            value: "凤城市",
          },
        ],
        label: "丹东市",
        value: "丹东市",
      },
      {
        areaCode: 210700000000,
        children: [
          {
            areaCode: 210702000000,
            label: "古塔区",
            value: "古塔区",
          },
          {
            areaCode: 210703000000,
            label: "凌河区",
            value: "凌河区",
          },
          {
            areaCode: 210711000000,
            label: "太和区",
            value: "太和区",
          },
          {
            areaCode: 210726000000,
            label: "黑山县",
            value: "黑山县",
          },
          {
            areaCode: 210727000000,
            label: "义县",
            value: "义县",
          },
          {
            areaCode: 210781000000,
            label: "凌海市",
            value: "凌海市",
          },
          {
            areaCode: 210782000000,
            label: "北镇市",
            value: "北镇市",
          },
        ],
        label: "锦州市",
        value: "锦州市",
      },
      {
        areaCode: 210800000000,
        children: [
          {
            areaCode: 210802000000,
            label: "站前区",
            value: "站前区",
          },
          {
            areaCode: 210803000000,
            label: "西市区",
            value: "西市区",
          },
          {
            areaCode: 210804000000,
            label: "鲅鱼圈区",
            value: "鲅鱼圈区",
          },
          {
            areaCode: 210811000000,
            label: "老边区",
            value: "老边区",
          },
          {
            areaCode: 210881000000,
            label: "盖州市",
            value: "盖州市",
          },
          {
            areaCode: 210882000000,
            label: "大石桥市",
            value: "大石桥市",
          },
        ],
        label: "营口市",
        value: "营口市",
      },
      {
        areaCode: 210900000000,
        children: [
          {
            areaCode: 210902000000,
            label: "海州区",
            value: "海州区",
          },
          {
            areaCode: 210903000000,
            label: "新邱区",
            value: "新邱区",
          },
          {
            areaCode: 210904000000,
            label: "太平区",
            value: "太平区",
          },
          {
            areaCode: 210905000000,
            label: "清河门区",
            value: "清河门区",
          },
          {
            areaCode: 210911000000,
            label: "细河区",
            value: "细河区",
          },
          {
            areaCode: 210921000000,
            label: "阜新蒙古族自治县",
            value: "阜新蒙古族自治县",
          },
          {
            areaCode: 210922000000,
            label: "彰武县",
            value: "彰武县",
          },
        ],
        label: "阜新市",
        value: "阜新市",
      },
      {
        areaCode: 211000000000,
        children: [
          {
            areaCode: 211002000000,
            label: "白塔区",
            value: "白塔区",
          },
          {
            areaCode: 211003000000,
            label: "文圣区",
            value: "文圣区",
          },
          {
            areaCode: 211004000000,
            label: "宏伟区",
            value: "宏伟区",
          },
          {
            areaCode: 211005000000,
            label: "弓长岭区",
            value: "弓长岭区",
          },
          {
            areaCode: 211011000000,
            label: "太子河区",
            value: "太子河区",
          },
          {
            areaCode: 211021000000,
            label: "辽阳县",
            value: "辽阳县",
          },
          {
            areaCode: 211081000000,
            label: "灯塔市",
            value: "灯塔市",
          },
        ],
        label: "辽阳市",
        value: "辽阳市",
      },
      {
        areaCode: 211100000000,
        children: [
          {
            areaCode: 211102000000,
            label: "双台子区",
            value: "双台子区",
          },
          {
            areaCode: 211103000000,
            label: "兴隆台区",
            value: "兴隆台区",
          },
          {
            areaCode: 211104000000,
            label: "大洼区",
            value: "大洼区",
          },
          {
            areaCode: 211122000000,
            label: "盘山县",
            value: "盘山县",
          },
        ],
        label: "盘锦市",
        value: "盘锦市",
      },
      {
        areaCode: 211200000000,
        children: [
          {
            areaCode: 211202000000,
            label: "银州区",
            value: "银州区",
          },
          {
            areaCode: 211204000000,
            label: "清河区",
            value: "清河区",
          },
          {
            areaCode: 211221000000,
            label: "铁岭县",
            value: "铁岭县",
          },
          {
            areaCode: 211223000000,
            label: "西丰县",
            value: "西丰县",
          },
          {
            areaCode: 211224000000,
            label: "昌图县",
            value: "昌图县",
          },
          {
            areaCode: 211281000000,
            label: "调兵山市",
            value: "调兵山市",
          },
          {
            areaCode: 211282000000,
            label: "开原市",
            value: "开原市",
          },
        ],
        label: "铁岭市",
        value: "铁岭市",
      },
      {
        areaCode: 211300000000,
        children: [
          {
            areaCode: 211302000000,
            label: "双塔区",
            value: "双塔区",
          },
          {
            areaCode: 211303000000,
            label: "龙城区",
            value: "龙城区",
          },
          {
            areaCode: 211321000000,
            label: "朝阳县",
            value: "朝阳县",
          },
          {
            areaCode: 211322000000,
            label: "建平县",
            value: "建平县",
          },
          {
            areaCode: 211324000000,
            label: "喀喇沁左翼蒙古族自治县",
            value: "喀喇沁左翼蒙古族自治县",
          },
          {
            areaCode: 211381000000,
            label: "北票市",
            value: "北票市",
          },
          {
            areaCode: 211382000000,
            label: "凌源市",
            value: "凌源市",
          },
        ],
        label: "朝阳市",
        value: "朝阳市",
      },
      {
        areaCode: 211400000000,
        children: [
          {
            areaCode: 211402000000,
            label: "连山区",
            value: "连山区",
          },
          {
            areaCode: 211403000000,
            label: "龙港区",
            value: "龙港区",
          },
          {
            areaCode: 211404000000,
            label: "南票区",
            value: "南票区",
          },
          {
            areaCode: 211421000000,
            label: "绥中县",
            value: "绥中县",
          },
          {
            areaCode: 211422000000,
            label: "建昌县",
            value: "建昌县",
          },
          {
            areaCode: 211481000000,
            label: "兴城市",
            value: "兴城市",
          },
        ],
        label: "葫芦岛市",
        value: "葫芦岛市",
      },
    ],
    label: "辽宁省",
    value: "辽宁省",
  },
  {
    areaCode: 220000000000,
    children: [
      {
        areaCode: 220100000000,
        children: [
          {
            areaCode: 220102000000,
            label: "南关区",
            value: "南关区",
          },
          {
            areaCode: 220103000000,
            label: "宽城区",
            value: "宽城区",
          },
          {
            areaCode: 220104000000,
            label: "朝阳区",
            value: "朝阳区",
          },
          {
            areaCode: 220105000000,
            label: "二道区",
            value: "二道区",
          },
          {
            areaCode: 220106000000,
            label: "绿园区",
            value: "绿园区",
          },
          {
            areaCode: 220112000000,
            label: "双阳区",
            value: "双阳区",
          },
          {
            areaCode: 220113000000,
            label: "九台区",
            value: "九台区",
          },
          {
            areaCode: 220122000000,
            label: "农安县",
            value: "农安县",
          },
          {
            areaCode: 220182000000,
            label: "榆树市",
            value: "榆树市",
          },
          {
            areaCode: 220183000000,
            label: "德惠市",
            value: "德惠市",
          },
        ],
        label: "长春市",
        value: "长春市",
      },
      {
        areaCode: 220200000000,
        children: [
          {
            areaCode: 220202000000,
            label: "昌邑区",
            value: "昌邑区",
          },
          {
            areaCode: 220203000000,
            label: "龙潭区",
            value: "龙潭区",
          },
          {
            areaCode: 220204000000,
            label: "船营区",
            value: "船营区",
          },
          {
            areaCode: 220211000000,
            label: "丰满区",
            value: "丰满区",
          },
          {
            areaCode: 220221000000,
            label: "永吉县",
            value: "永吉县",
          },
          {
            areaCode: 220281000000,
            label: "蛟河市",
            value: "蛟河市",
          },
          {
            areaCode: 220282000000,
            label: "桦甸市",
            value: "桦甸市",
          },
          {
            areaCode: 220283000000,
            label: "舒兰市",
            value: "舒兰市",
          },
          {
            areaCode: 220284000000,
            label: "磐石市",
            value: "磐石市",
          },
        ],
        label: "吉林市",
        value: "吉林市",
      },
      {
        areaCode: 220300000000,
        children: [
          {
            areaCode: 220302000000,
            label: "铁西区",
            value: "铁西区",
          },
          {
            areaCode: 220303000000,
            label: "铁东区",
            value: "铁东区",
          },
          {
            areaCode: 220322000000,
            label: "梨树县",
            value: "梨树县",
          },
          {
            areaCode: 220323000000,
            label: "伊通满族自治县",
            value: "伊通满族自治县",
          },
          {
            areaCode: 220381000000,
            label: "公主岭市",
            value: "公主岭市",
          },
          {
            areaCode: 220382000000,
            label: "双辽市",
            value: "双辽市",
          },
        ],
        label: "四平市",
        value: "四平市",
      },
      {
        areaCode: 220400000000,
        children: [
          {
            areaCode: 220402000000,
            label: "龙山区",
            value: "龙山区",
          },
          {
            areaCode: 220403000000,
            label: "西安区",
            value: "西安区",
          },
          {
            areaCode: 220421000000,
            label: "东丰县",
            value: "东丰县",
          },
          {
            areaCode: 220422000000,
            label: "东辽县",
            value: "东辽县",
          },
        ],
        label: "辽源市",
        value: "辽源市",
      },
      {
        areaCode: 220500000000,
        children: [
          {
            areaCode: 220502000000,
            label: "东昌区",
            value: "东昌区",
          },
          {
            areaCode: 220503000000,
            label: "二道江区",
            value: "二道江区",
          },
          {
            areaCode: 220521000000,
            label: "通化县",
            value: "通化县",
          },
          {
            areaCode: 220523000000,
            label: "辉南县",
            value: "辉南县",
          },
          {
            areaCode: 220524000000,
            label: "柳河县",
            value: "柳河县",
          },
          {
            areaCode: 220581000000,
            label: "梅河口市",
            value: "梅河口市",
          },
          {
            areaCode: 220582000000,
            label: "集安市",
            value: "集安市",
          },
        ],
        label: "通化市",
        value: "通化市",
      },
      {
        areaCode: 220600000000,
        children: [
          {
            areaCode: 220602000000,
            label: "浑江区",
            value: "浑江区",
          },
          {
            areaCode: 220605000000,
            label: "江源区",
            value: "江源区",
          },
          {
            areaCode: 220621000000,
            label: "抚松县",
            value: "抚松县",
          },
          {
            areaCode: 220622000000,
            label: "靖宇县",
            value: "靖宇县",
          },
          {
            areaCode: 220623000000,
            label: "长白朝鲜族自治县",
            value: "长白朝鲜族自治县",
          },
          {
            areaCode: 220681000000,
            label: "临江市",
            value: "临江市",
          },
        ],
        label: "白山市",
        value: "白山市",
      },
      {
        areaCode: 220700000000,
        children: [
          {
            areaCode: 220702000000,
            label: "宁江区",
            value: "宁江区",
          },
          {
            areaCode: 220721000000,
            label: "前郭尔罗斯蒙古族自治县",
            value: "前郭尔罗斯蒙古族自治县",
          },
          {
            areaCode: 220722000000,
            label: "长岭县",
            value: "长岭县",
          },
          {
            areaCode: 220723000000,
            label: "乾安县",
            value: "乾安县",
          },
          {
            areaCode: 220781000000,
            label: "扶余市",
            value: "扶余市",
          },
        ],
        label: "松原市",
        value: "松原市",
      },
      {
        areaCode: 220800000000,
        children: [
          {
            areaCode: 220802000000,
            label: "洮北区",
            value: "洮北区",
          },
          {
            areaCode: 220821000000,
            label: "镇赉县",
            value: "镇赉县",
          },
          {
            areaCode: 220822000000,
            label: "通榆县",
            value: "通榆县",
          },
          {
            areaCode: 220881000000,
            label: "洮南市",
            value: "洮南市",
          },
          {
            areaCode: 220882000000,
            label: "大安市",
            value: "大安市",
          },
        ],
        label: "白城市",
        value: "白城市",
      },
      {
        areaCode: 222400000000,
        children: [
          {
            areaCode: 222401000000,
            label: "延吉市",
            value: "延吉市",
          },
          {
            areaCode: 222402000000,
            label: "图们市",
            value: "图们市",
          },
          {
            areaCode: 222403000000,
            label: "敦化市",
            value: "敦化市",
          },
          {
            areaCode: 222404000000,
            label: "珲春市",
            value: "珲春市",
          },
          {
            areaCode: 222405000000,
            label: "龙井市",
            value: "龙井市",
          },
          {
            areaCode: 222406000000,
            label: "和龙市",
            value: "和龙市",
          },
          {
            areaCode: 222424000000,
            label: "汪清县",
            value: "汪清县",
          },
          {
            areaCode: 222426000000,
            label: "安图县",
            value: "安图县",
          },
        ],
        label: "延边朝鲜族自治州",
        value: "延边朝鲜族自治州",
      },
    ],
    label: "吉林省",
    value: "吉林省",
  },
  {
    areaCode: 230000000000,
    children: [
      {
        areaCode: 230100000000,
        children: [
          {
            areaCode: 230102000000,
            label: "道里区",
            value: "道里区",
          },
          {
            areaCode: 230103000000,
            label: "南岗区",
            value: "南岗区",
          },
          {
            areaCode: 230104000000,
            label: "道外区",
            value: "道外区",
          },
          {
            areaCode: 230108000000,
            label: "平房区",
            value: "平房区",
          },
          {
            areaCode: 230109000000,
            label: "松北区",
            value: "松北区",
          },
          {
            areaCode: 230110000000,
            label: "香坊区",
            value: "香坊区",
          },
          {
            areaCode: 230111000000,
            label: "呼兰区",
            value: "呼兰区",
          },
          {
            areaCode: 230112000000,
            label: "阿城区",
            value: "阿城区",
          },
          {
            areaCode: 230113000000,
            label: "双城区",
            value: "双城区",
          },
          {
            areaCode: 230123000000,
            label: "依兰县",
            value: "依兰县",
          },
          {
            areaCode: 230124000000,
            label: "方正县",
            value: "方正县",
          },
          {
            areaCode: 230125000000,
            label: "宾县",
            value: "宾县",
          },
          {
            areaCode: 230126000000,
            label: "巴彦县",
            value: "巴彦县",
          },
          {
            areaCode: 230127000000,
            label: "木兰县",
            value: "木兰县",
          },
          {
            areaCode: 230128000000,
            label: "通河县",
            value: "通河县",
          },
          {
            areaCode: 230129000000,
            label: "延寿县",
            value: "延寿县",
          },
          {
            areaCode: 230183000000,
            label: "尚志市",
            value: "尚志市",
          },
          {
            areaCode: 230184000000,
            label: "五常市",
            value: "五常市",
          },
        ],
        label: "哈尔滨市",
        value: "哈尔滨市",
      },
      {
        areaCode: 230200000000,
        children: [
          {
            areaCode: 230202000000,
            label: "龙沙区",
            value: "龙沙区",
          },
          {
            areaCode: 230203000000,
            label: "建华区",
            value: "建华区",
          },
          {
            areaCode: 230204000000,
            label: "铁锋区",
            value: "铁锋区",
          },
          {
            areaCode: 230205000000,
            label: "昂昂溪区",
            value: "昂昂溪区",
          },
          {
            areaCode: 230206000000,
            label: "富拉尔基区",
            value: "富拉尔基区",
          },
          {
            areaCode: 230207000000,
            label: "碾子山区",
            value: "碾子山区",
          },
          {
            areaCode: 230208000000,
            label: "梅里斯达斡尔族区",
            value: "梅里斯达斡尔族区",
          },
          {
            areaCode: 230221000000,
            label: "龙江县",
            value: "龙江县",
          },
          {
            areaCode: 230223000000,
            label: "依安县",
            value: "依安县",
          },
          {
            areaCode: 230224000000,
            label: "泰来县",
            value: "泰来县",
          },
          {
            areaCode: 230225000000,
            label: "甘南县",
            value: "甘南县",
          },
          {
            areaCode: 230227000000,
            label: "富裕县",
            value: "富裕县",
          },
          {
            areaCode: 230229000000,
            label: "克山县",
            value: "克山县",
          },
          {
            areaCode: 230230000000,
            label: "克东县",
            value: "克东县",
          },
          {
            areaCode: 230231000000,
            label: "拜泉县",
            value: "拜泉县",
          },
          {
            areaCode: 230281000000,
            label: "讷河市",
            value: "讷河市",
          },
        ],
        label: "齐齐哈尔市",
        value: "齐齐哈尔市",
      },
      {
        areaCode: 230300000000,
        children: [
          {
            areaCode: 230302000000,
            label: "鸡冠区",
            value: "鸡冠区",
          },
          {
            areaCode: 230303000000,
            label: "恒山区",
            value: "恒山区",
          },
          {
            areaCode: 230304000000,
            label: "滴道区",
            value: "滴道区",
          },
          {
            areaCode: 230305000000,
            label: "梨树区",
            value: "梨树区",
          },
          {
            areaCode: 230306000000,
            label: "城子河区",
            value: "城子河区",
          },
          {
            areaCode: 230307000000,
            label: "麻山区",
            value: "麻山区",
          },
          {
            areaCode: 230321000000,
            label: "鸡东县",
            value: "鸡东县",
          },
          {
            areaCode: 230381000000,
            label: "虎林市",
            value: "虎林市",
          },
          {
            areaCode: 230382000000,
            label: "密山市",
            value: "密山市",
          },
        ],
        label: "鸡西市",
        value: "鸡西市",
      },
      {
        areaCode: 230400000000,
        children: [
          {
            areaCode: 230402000000,
            label: "向阳区",
            value: "向阳区",
          },
          {
            areaCode: 230403000000,
            label: "工农区",
            value: "工农区",
          },
          {
            areaCode: 230404000000,
            label: "南山区",
            value: "南山区",
          },
          {
            areaCode: 230405000000,
            label: "兴安区",
            value: "兴安区",
          },
          {
            areaCode: 230406000000,
            label: "东山区",
            value: "东山区",
          },
          {
            areaCode: 230407000000,
            label: "兴山区",
            value: "兴山区",
          },
          {
            areaCode: 230421000000,
            label: "萝北县",
            value: "萝北县",
          },
          {
            areaCode: 230422000000,
            label: "绥滨县",
            value: "绥滨县",
          },
        ],
        label: "鹤岗市",
        value: "鹤岗市",
      },
      {
        areaCode: 230500000000,
        children: [
          {
            areaCode: 230502000000,
            label: "尖山区",
            value: "尖山区",
          },
          {
            areaCode: 230503000000,
            label: "岭东区",
            value: "岭东区",
          },
          {
            areaCode: 230505000000,
            label: "四方台区",
            value: "四方台区",
          },
          {
            areaCode: 230506000000,
            label: "宝山区",
            value: "宝山区",
          },
          {
            areaCode: 230521000000,
            label: "集贤县",
            value: "集贤县",
          },
          {
            areaCode: 230522000000,
            label: "友谊县",
            value: "友谊县",
          },
          {
            areaCode: 230523000000,
            label: "宝清县",
            value: "宝清县",
          },
          {
            areaCode: 230524000000,
            label: "饶河县",
            value: "饶河县",
          },
        ],
        label: "双鸭山市",
        value: "双鸭山市",
      },
      {
        areaCode: 230600000000,
        children: [
          {
            areaCode: 230602000000,
            label: "萨尔图区",
            value: "萨尔图区",
          },
          {
            areaCode: 230603000000,
            label: "龙凤区",
            value: "龙凤区",
          },
          {
            areaCode: 230604000000,
            label: "让胡路区",
            value: "让胡路区",
          },
          {
            areaCode: 230605000000,
            label: "红岗区",
            value: "红岗区",
          },
          {
            areaCode: 230606000000,
            label: "大同区",
            value: "大同区",
          },
          {
            areaCode: 230621000000,
            label: "肇州县",
            value: "肇州县",
          },
          {
            areaCode: 230622000000,
            label: "肇源县",
            value: "肇源县",
          },
          {
            areaCode: 230623000000,
            label: "林甸县",
            value: "林甸县",
          },
          {
            areaCode: 230624000000,
            label: "杜尔伯特蒙古族自治县",
            value: "杜尔伯特蒙古族自治县",
          },
        ],
        label: "大庆市",
        value: "大庆市",
      },
      {
        areaCode: 230700000000,
        children: [
          {
            areaCode: 230702000000,
            label: "伊春区",
            value: "伊春区",
          },
          {
            areaCode: 230703000000,
            label: "南岔区",
            value: "南岔区",
          },
          {
            areaCode: 230704000000,
            label: "友好区",
            value: "友好区",
          },
          {
            areaCode: 230705000000,
            label: "西林区",
            value: "西林区",
          },
          {
            areaCode: 230706000000,
            label: "翠峦区",
            value: "翠峦区",
          },
          {
            areaCode: 230707000000,
            label: "新青区",
            value: "新青区",
          },
          {
            areaCode: 230708000000,
            label: "美溪区",
            value: "美溪区",
          },
          {
            areaCode: 230709000000,
            label: "金山屯区",
            value: "金山屯区",
          },
          {
            areaCode: 230710000000,
            label: "五营区",
            value: "五营区",
          },
          {
            areaCode: 230711000000,
            label: "乌马河区",
            value: "乌马河区",
          },
          {
            areaCode: 230712000000,
            label: "汤旺河区",
            value: "汤旺河区",
          },
          {
            areaCode: 230713000000,
            label: "带岭区",
            value: "带岭区",
          },
          {
            areaCode: 230714000000,
            label: "乌伊岭区",
            value: "乌伊岭区",
          },
          {
            areaCode: 230715000000,
            label: "红星区",
            value: "红星区",
          },
          {
            areaCode: 230716000000,
            label: "上甘岭区",
            value: "上甘岭区",
          },
          {
            areaCode: 230722000000,
            label: "嘉荫县",
            value: "嘉荫县",
          },
          {
            areaCode: 230781000000,
            label: "铁力市",
            value: "铁力市",
          },
        ],
        label: "伊春市",
        value: "伊春市",
      },
      {
        areaCode: 230800000000,
        children: [
          {
            areaCode: 230803000000,
            label: "向阳区",
            value: "向阳区",
          },
          {
            areaCode: 230804000000,
            label: "前进区",
            value: "前进区",
          },
          {
            areaCode: 230805000000,
            label: "东风区",
            value: "东风区",
          },
          {
            areaCode: 230811000000,
            label: "郊区",
            value: "郊区",
          },
          {
            areaCode: 230822000000,
            label: "桦南县",
            value: "桦南县",
          },
          {
            areaCode: 230826000000,
            label: "桦川县",
            value: "桦川县",
          },
          {
            areaCode: 230828000000,
            label: "汤原县",
            value: "汤原县",
          },
          {
            areaCode: 230881000000,
            label: "同江市",
            value: "同江市",
          },
          {
            areaCode: 230882000000,
            label: "富锦市",
            value: "富锦市",
          },
          {
            areaCode: 230883000000,
            label: "抚远市",
            value: "抚远市",
          },
        ],
        label: "佳木斯市",
        value: "佳木斯市",
      },
      {
        areaCode: 230900000000,
        children: [
          {
            areaCode: 230902000000,
            label: "新兴区",
            value: "新兴区",
          },
          {
            areaCode: 230903000000,
            label: "桃山区",
            value: "桃山区",
          },
          {
            areaCode: 230904000000,
            label: "茄子河区",
            value: "茄子河区",
          },
          {
            areaCode: 230921000000,
            label: "勃利县",
            value: "勃利县",
          },
        ],
        label: "七台河市",
        value: "七台河市",
      },
      {
        areaCode: 231000000000,
        children: [
          {
            areaCode: 231002000000,
            label: "东安区",
            value: "东安区",
          },
          {
            areaCode: 231003000000,
            label: "阳明区",
            value: "阳明区",
          },
          {
            areaCode: 231004000000,
            label: "爱民区",
            value: "爱民区",
          },
          {
            areaCode: 231005000000,
            label: "西安区",
            value: "西安区",
          },
          {
            areaCode: 231025000000,
            label: "林口县",
            value: "林口县",
          },
          {
            areaCode: 231081000000,
            label: "绥芬河市",
            value: "绥芬河市",
          },
          {
            areaCode: 231083000000,
            label: "海林市",
            value: "海林市",
          },
          {
            areaCode: 231084000000,
            label: "宁安市",
            value: "宁安市",
          },
          {
            areaCode: 231085000000,
            label: "穆棱市",
            value: "穆棱市",
          },
          {
            areaCode: 231086000000,
            label: "东宁市",
            value: "东宁市",
          },
        ],
        label: "牡丹江市",
        value: "牡丹江市",
      },
      {
        areaCode: 231100000000,
        children: [
          {
            areaCode: 231102000000,
            label: "爱辉区",
            value: "爱辉区",
          },
          {
            areaCode: 231121000000,
            label: "嫩江县",
            value: "嫩江县",
          },
          {
            areaCode: 231123000000,
            label: "逊克县",
            value: "逊克县",
          },
          {
            areaCode: 231124000000,
            label: "孙吴县",
            value: "孙吴县",
          },
          {
            areaCode: 231181000000,
            label: "北安市",
            value: "北安市",
          },
          {
            areaCode: 231182000000,
            label: "五大连池市",
            value: "五大连池市",
          },
        ],
        label: "黑河市",
        value: "黑河市",
      },
      {
        areaCode: 231200000000,
        children: [
          {
            areaCode: 231202000000,
            label: "北林区",
            value: "北林区",
          },
          {
            areaCode: 231221000000,
            label: "望奎县",
            value: "望奎县",
          },
          {
            areaCode: 231222000000,
            label: "兰西县",
            value: "兰西县",
          },
          {
            areaCode: 231223000000,
            label: "青冈县",
            value: "青冈县",
          },
          {
            areaCode: 231224000000,
            label: "庆安县",
            value: "庆安县",
          },
          {
            areaCode: 231225000000,
            label: "明水县",
            value: "明水县",
          },
          {
            areaCode: 231226000000,
            label: "绥棱县",
            value: "绥棱县",
          },
          {
            areaCode: 231281000000,
            label: "安达市",
            value: "安达市",
          },
          {
            areaCode: 231282000000,
            label: "肇东市",
            value: "肇东市",
          },
          {
            areaCode: 231283000000,
            label: "海伦市",
            value: "海伦市",
          },
        ],
        label: "绥化市",
        value: "绥化市",
      },
      {
        areaCode: 232700000000,
        children: [
          {
            areaCode: 232721000000,
            label: "呼玛县",
            value: "呼玛县",
          },
          {
            areaCode: 232722000000,
            label: "塔河县",
            value: "塔河县",
          },
          {
            areaCode: 232723000000,
            label: "漠河县",
            value: "漠河县",
          },
        ],
        label: "大兴安岭地区",
        value: "大兴安岭地区",
      },
    ],
    label: "黑龙江省",
    value: "黑龙江省",
  },
  {
    areaCode: 310000000000,
    children: [
      {
        areaCode: 310100000000,
        children: [
          {
            areaCode: 310101000000,
            label: "黄浦区",
            value: "黄浦区",
          },
          {
            areaCode: 310104000000,
            label: "徐汇区",
            value: "徐汇区",
          },
          {
            areaCode: 310105000000,
            label: "长宁区",
            value: "长宁区",
          },
          {
            areaCode: 310106000000,
            label: "静安区",
            value: "静安区",
          },
          {
            areaCode: 310107000000,
            label: "普陀区",
            value: "普陀区",
          },
          {
            areaCode: 310109000000,
            label: "虹口区",
            value: "虹口区",
          },
          {
            areaCode: 310110000000,
            label: "杨浦区",
            value: "杨浦区",
          },
          {
            areaCode: 310112000000,
            label: "闵行区",
            value: "闵行区",
          },
          {
            areaCode: 310113000000,
            label: "宝山区",
            value: "宝山区",
          },
          {
            areaCode: 310114000000,
            label: "嘉定区",
            value: "嘉定区",
          },
          {
            areaCode: 310115000000,
            label: "浦东新区",
            value: "浦东新区",
          },
          {
            areaCode: 310116000000,
            label: "金山区",
            value: "金山区",
          },
          {
            areaCode: 310117000000,
            label: "松江区",
            value: "松江区",
          },
          {
            areaCode: 310118000000,
            label: "青浦区",
            value: "青浦区",
          },
          {
            areaCode: 310120000000,
            label: "奉贤区",
            value: "奉贤区",
          },
          {
            areaCode: 310151000000,
            label: "崇明区",
            value: "崇明区",
          },
        ],
        label: "直辖区",
        value: "直辖区",
      },
    ],
    label: "上海市",
    value: "上海市",
  },
  {
    areaCode: 320000000000,
    children: [
      {
        areaCode: 320100000000,
        children: [
          {
            areaCode: 320102000000,
            label: "玄武区",
            value: "玄武区",
          },
          {
            areaCode: 320104000000,
            label: "秦淮区",
            value: "秦淮区",
          },
          {
            areaCode: 320105000000,
            label: "建邺区",
            value: "建邺区",
          },
          {
            areaCode: 320106000000,
            label: "鼓楼区",
            value: "鼓楼区",
          },
          {
            areaCode: 320111000000,
            label: "浦口区",
            value: "浦口区",
          },
          {
            areaCode: 320113000000,
            label: "栖霞区",
            value: "栖霞区",
          },
          {
            areaCode: 320114000000,
            label: "雨花台区",
            value: "雨花台区",
          },
          {
            areaCode: 320115000000,
            label: "江宁区",
            value: "江宁区",
          },
          {
            areaCode: 320116000000,
            label: "六合区",
            value: "六合区",
          },
          {
            areaCode: 320117000000,
            label: "溧水区",
            value: "溧水区",
          },
          {
            areaCode: 320118000000,
            label: "高淳区",
            value: "高淳区",
          },
        ],
        label: "南京市",
        value: "南京市",
      },
      {
        areaCode: 320200000000,
        children: [
          {
            areaCode: 320205000000,
            label: "锡山区",
            value: "锡山区",
          },
          {
            areaCode: 320206000000,
            label: "惠山区",
            value: "惠山区",
          },
          {
            areaCode: 320211000000,
            label: "滨湖区",
            value: "滨湖区",
          },
          {
            areaCode: 320213000000,
            label: "梁溪区",
            value: "梁溪区",
          },
          {
            areaCode: 320214000000,
            label: "新吴区",
            value: "新吴区",
          },
          {
            areaCode: 320281000000,
            label: "江阴市",
            value: "江阴市",
          },
          {
            areaCode: 320282000000,
            label: "宜兴市",
            value: "宜兴市",
          },
        ],
        label: "无锡市",
        value: "无锡市",
      },
      {
        areaCode: 320300000000,
        children: [
          {
            areaCode: 320302000000,
            label: "鼓楼区",
            value: "鼓楼区",
          },
          {
            areaCode: 320303000000,
            label: "云龙区",
            value: "云龙区",
          },
          {
            areaCode: 320305000000,
            label: "贾汪区",
            value: "贾汪区",
          },
          {
            areaCode: 320311000000,
            label: "泉山区",
            value: "泉山区",
          },
          {
            areaCode: 320312000000,
            label: "铜山区",
            value: "铜山区",
          },
          {
            areaCode: 320321000000,
            label: "丰县",
            value: "丰县",
          },
          {
            areaCode: 320322000000,
            label: "沛县",
            value: "沛县",
          },
          {
            areaCode: 320324000000,
            label: "睢宁县",
            value: "睢宁县",
          },
          {
            areaCode: 320381000000,
            label: "新沂市",
            value: "新沂市",
          },
          {
            areaCode: 320382000000,
            label: "邳州市",
            value: "邳州市",
          },
        ],
        label: "徐州市",
        value: "徐州市",
      },
      {
        areaCode: 320400000000,
        children: [
          {
            areaCode: 320402000000,
            label: "天宁区",
            value: "天宁区",
          },
          {
            areaCode: 320404000000,
            label: "钟楼区",
            value: "钟楼区",
          },
          {
            areaCode: 320411000000,
            label: "新北区",
            value: "新北区",
          },
          {
            areaCode: 320412000000,
            label: "武进区",
            value: "武进区",
          },
          {
            areaCode: 320413000000,
            label: "金坛区",
            value: "金坛区",
          },
          {
            areaCode: 320481000000,
            label: "溧阳市",
            value: "溧阳市",
          },
        ],
        label: "常州市",
        value: "常州市",
      },
      {
        areaCode: 320500000000,
        children: [
          {
            areaCode: 320505000000,
            label: "虎丘区",
            value: "虎丘区",
          },
          {
            areaCode: 320506000000,
            label: "吴中区",
            value: "吴中区",
          },
          {
            areaCode: 320507000000,
            label: "相城区",
            value: "相城区",
          },
          {
            areaCode: 320508000000,
            label: "姑苏区",
            value: "姑苏区",
          },
          {
            areaCode: 320509000000,
            label: "吴江区",
            value: "吴江区",
          },
          {
            areaCode: 320581000000,
            label: "常熟市",
            value: "常熟市",
          },
          {
            areaCode: 320582000000,
            label: "张家港市",
            value: "张家港市",
          },
          {
            areaCode: 320583000000,
            label: "昆山市",
            value: "昆山市",
          },
          {
            areaCode: 320585000000,
            label: "太仓市",
            value: "太仓市",
          },
        ],
        label: "苏州市",
        value: "苏州市",
      },
      {
        areaCode: 320600000000,
        children: [
          {
            areaCode: 320602000000,
            label: "崇川区",
            value: "崇川区",
          },
          {
            areaCode: 320611000000,
            label: "港闸区",
            value: "港闸区",
          },
          {
            areaCode: 320612000000,
            label: "通州区",
            value: "通州区",
          },
          {
            areaCode: 320621000000,
            label: "海安县",
            value: "海安县",
          },
          {
            areaCode: 320623000000,
            label: "如东县",
            value: "如东县",
          },
          {
            areaCode: 320681000000,
            label: "启东市",
            value: "启东市",
          },
          {
            areaCode: 320682000000,
            label: "如皋市",
            value: "如皋市",
          },
          {
            areaCode: 320684000000,
            label: "海门市",
            value: "海门市",
          },
        ],
        label: "南通市",
        value: "南通市",
      },
      {
        areaCode: 320700000000,
        children: [
          {
            areaCode: 320703000000,
            label: "连云区",
            value: "连云区",
          },
          {
            areaCode: 320706000000,
            label: "海州区",
            value: "海州区",
          },
          {
            areaCode: 320707000000,
            label: "赣榆区",
            value: "赣榆区",
          },
          {
            areaCode: 320722000000,
            label: "东海县",
            value: "东海县",
          },
          {
            areaCode: 320723000000,
            label: "灌云县",
            value: "灌云县",
          },
          {
            areaCode: 320724000000,
            label: "灌南县",
            value: "灌南县",
          },
        ],
        label: "连云港市",
        value: "连云港市",
      },
      {
        areaCode: 320800000000,
        children: [
          {
            areaCode: 320803000000,
            label: "淮安区",
            value: "淮安区",
          },
          {
            areaCode: 320804000000,
            label: "淮阴区",
            value: "淮阴区",
          },
          {
            areaCode: 320812000000,
            label: "清江浦区",
            value: "清江浦区",
          },
          {
            areaCode: 320813000000,
            label: "洪泽区",
            value: "洪泽区",
          },
          {
            areaCode: 320826000000,
            label: "涟水县",
            value: "涟水县",
          },
          {
            areaCode: 320830000000,
            label: "盱眙县",
            value: "盱眙县",
          },
          {
            areaCode: 320831000000,
            label: "金湖县",
            value: "金湖县",
          },
        ],
        label: "淮安市",
        value: "淮安市",
      },
      {
        areaCode: 320900000000,
        children: [
          {
            areaCode: 320902000000,
            label: "亭湖区",
            value: "亭湖区",
          },
          {
            areaCode: 320903000000,
            label: "盐都区",
            value: "盐都区",
          },
          {
            areaCode: 320904000000,
            label: "大丰区",
            value: "大丰区",
          },
          {
            areaCode: 320921000000,
            label: "响水县",
            value: "响水县",
          },
          {
            areaCode: 320922000000,
            label: "滨海县",
            value: "滨海县",
          },
          {
            areaCode: 320923000000,
            label: "阜宁县",
            value: "阜宁县",
          },
          {
            areaCode: 320924000000,
            label: "射阳县",
            value: "射阳县",
          },
          {
            areaCode: 320925000000,
            label: "建湖县",
            value: "建湖县",
          },
          {
            areaCode: 320981000000,
            label: "东台市",
            value: "东台市",
          },
        ],
        label: "盐城市",
        value: "盐城市",
      },
      {
        areaCode: 321000000000,
        children: [
          {
            areaCode: 321002000000,
            label: "广陵区",
            value: "广陵区",
          },
          {
            areaCode: 321003000000,
            label: "邗江区",
            value: "邗江区",
          },
          {
            areaCode: 321012000000,
            label: "江都区",
            value: "江都区",
          },
          {
            areaCode: 321023000000,
            label: "宝应县",
            value: "宝应县",
          },
          {
            areaCode: 321081000000,
            label: "仪征市",
            value: "仪征市",
          },
          {
            areaCode: 321084000000,
            label: "高邮市",
            value: "高邮市",
          },
        ],
        label: "扬州市",
        value: "扬州市",
      },
      {
        areaCode: 321100000000,
        children: [
          {
            areaCode: 321102000000,
            label: "京口区",
            value: "京口区",
          },
          {
            areaCode: 321111000000,
            label: "润州区",
            value: "润州区",
          },
          {
            areaCode: 321112000000,
            label: "丹徒区",
            value: "丹徒区",
          },
          {
            areaCode: 321181000000,
            label: "丹阳市",
            value: "丹阳市",
          },
          {
            areaCode: 321182000000,
            label: "扬中市",
            value: "扬中市",
          },
          {
            areaCode: 321183000000,
            label: "句容市",
            value: "句容市",
          },
        ],
        label: "镇江市",
        value: "镇江市",
      },
      {
        areaCode: 321200000000,
        children: [
          {
            areaCode: 321202000000,
            label: "海陵区",
            value: "海陵区",
          },
          {
            areaCode: 321203000000,
            label: "高港区",
            value: "高港区",
          },
          {
            areaCode: 321204000000,
            label: "姜堰区",
            value: "姜堰区",
          },
          {
            areaCode: 321281000000,
            label: "兴化市",
            value: "兴化市",
          },
          {
            areaCode: 321282000000,
            label: "靖江市",
            value: "靖江市",
          },
          {
            areaCode: 321283000000,
            label: "泰兴市",
            value: "泰兴市",
          },
        ],
        label: "泰州市",
        value: "泰州市",
      },
      {
        areaCode: 321300000000,
        children: [
          {
            areaCode: 321302000000,
            label: "宿城区",
            value: "宿城区",
          },
          {
            areaCode: 321311000000,
            label: "宿豫区",
            value: "宿豫区",
          },
          {
            areaCode: 321322000000,
            label: "沭阳县",
            value: "沭阳县",
          },
          {
            areaCode: 321323000000,
            label: "泗阳县",
            value: "泗阳县",
          },
          {
            areaCode: 321324000000,
            label: "泗洪县",
            value: "泗洪县",
          },
        ],
        label: "宿迁市",
        value: "宿迁市",
      },
    ],
    label: "江苏省",
    value: "江苏省",
  },
  {
    areaCode: 330000000000,
    children: [
      {
        areaCode: 330100000000,
        children: [
          {
            areaCode: 330102000000,
            label: "上城区",
            value: "上城区",
          },
          {
            areaCode: 330103000000,
            label: "下城区",
            value: "下城区",
          },
          {
            areaCode: 330104000000,
            label: "江干区",
            value: "江干区",
          },
          {
            areaCode: 330105000000,
            label: "拱墅区",
            value: "拱墅区",
          },
          {
            areaCode: 330106000000,
            label: "西湖区",
            value: "西湖区",
          },
          {
            areaCode: 330108000000,
            label: "滨江区",
            value: "滨江区",
          },
          {
            areaCode: 330109000000,
            label: "萧山区",
            value: "萧山区",
          },
          {
            areaCode: 330110000000,
            label: "余杭区",
            value: "余杭区",
          },
          {
            areaCode: 330111000000,
            label: "富阳区",
            value: "富阳区",
          },
          {
            areaCode: 330122000000,
            label: "桐庐县",
            value: "桐庐县",
          },
          {
            areaCode: 330127000000,
            label: "淳安县",
            value: "淳安县",
          },
          {
            areaCode: 330182000000,
            label: "建德市",
            value: "建德市",
          },
          {
            areaCode: 330185000000,
            label: "临安市",
            value: "临安市",
          },
        ],
        label: "杭州市",
        value: "杭州市",
      },
      {
        areaCode: 330200000000,
        children: [
          {
            areaCode: 330203000000,
            label: "海曙区",
            value: "海曙区",
          },
          {
            areaCode: 330204000000,
            label: "江东区",
            value: "江东区",
          },
          {
            areaCode: 330205000000,
            label: "江北区",
            value: "江北区",
          },
          {
            areaCode: 330206000000,
            label: "北仑区",
            value: "北仑区",
          },
          {
            areaCode: 330211000000,
            label: "镇海区",
            value: "镇海区",
          },
          {
            areaCode: 330212000000,
            label: "鄞州区",
            value: "鄞州区",
          },
          {
            areaCode: 330225000000,
            label: "象山县",
            value: "象山县",
          },
          {
            areaCode: 330226000000,
            label: "宁海县",
            value: "宁海县",
          },
          {
            areaCode: 330281000000,
            label: "余姚市",
            value: "余姚市",
          },
          {
            areaCode: 330282000000,
            label: "慈溪市",
            value: "慈溪市",
          },
          {
            areaCode: 330283000000,
            label: "奉化市",
            value: "奉化市",
          },
        ],
        label: "宁波市",
        value: "宁波市",
      },
      {
        areaCode: 330300000000,
        children: [
          {
            areaCode: 330302000000,
            label: "鹿城区",
            value: "鹿城区",
          },
          {
            areaCode: 330303000000,
            label: "龙湾区",
            value: "龙湾区",
          },
          {
            areaCode: 330304000000,
            label: "瓯海区",
            value: "瓯海区",
          },
          {
            areaCode: 330305000000,
            label: "洞头区",
            value: "洞头区",
          },
          {
            areaCode: 330324000000,
            label: "永嘉县",
            value: "永嘉县",
          },
          {
            areaCode: 330326000000,
            label: "平阳县",
            value: "平阳县",
          },
          {
            areaCode: 330327000000,
            label: "苍南县",
            value: "苍南县",
          },
          {
            areaCode: 330328000000,
            label: "文成县",
            value: "文成县",
          },
          {
            areaCode: 330329000000,
            label: "泰顺县",
            value: "泰顺县",
          },
          {
            areaCode: 330381000000,
            label: "瑞安市",
            value: "瑞安市",
          },
          {
            areaCode: 330382000000,
            label: "乐清市",
            value: "乐清市",
          },
        ],
        label: "温州市",
        value: "温州市",
      },
      {
        areaCode: 330400000000,
        children: [
          {
            areaCode: 330402000000,
            label: "南湖区",
            value: "南湖区",
          },
          {
            areaCode: 330411000000,
            label: "秀洲区",
            value: "秀洲区",
          },
          {
            areaCode: 330421000000,
            label: "嘉善县",
            value: "嘉善县",
          },
          {
            areaCode: 330424000000,
            label: "海盐县",
            value: "海盐县",
          },
          {
            areaCode: 330481000000,
            label: "海宁市",
            value: "海宁市",
          },
          {
            areaCode: 330482000000,
            label: "平湖市",
            value: "平湖市",
          },
          {
            areaCode: 330483000000,
            label: "桐乡市",
            value: "桐乡市",
          },
        ],
        label: "嘉兴市",
        value: "嘉兴市",
      },
      {
        areaCode: 330500000000,
        children: [
          {
            areaCode: 330502000000,
            label: "吴兴区",
            value: "吴兴区",
          },
          {
            areaCode: 330503000000,
            label: "南浔区",
            value: "南浔区",
          },
          {
            areaCode: 330521000000,
            label: "德清县",
            value: "德清县",
          },
          {
            areaCode: 330522000000,
            label: "长兴县",
            value: "长兴县",
          },
          {
            areaCode: 330523000000,
            label: "安吉县",
            value: "安吉县",
          },
        ],
        label: "湖州市",
        value: "湖州市",
      },
      {
        areaCode: 330600000000,
        children: [
          {
            areaCode: 330602000000,
            label: "越城区",
            value: "越城区",
          },
          {
            areaCode: 330603000000,
            label: "柯桥区",
            value: "柯桥区",
          },
          {
            areaCode: 330604000000,
            label: "上虞区",
            value: "上虞区",
          },
          {
            areaCode: 330624000000,
            label: "新昌县",
            value: "新昌县",
          },
          {
            areaCode: 330681000000,
            label: "诸暨市",
            value: "诸暨市",
          },
          {
            areaCode: 330683000000,
            label: "嵊州市",
            value: "嵊州市",
          },
        ],
        label: "绍兴市",
        value: "绍兴市",
      },
      {
        areaCode: 330700000000,
        children: [
          {
            areaCode: 330702000000,
            label: "婺城区",
            value: "婺城区",
          },
          {
            areaCode: 330703000000,
            label: "金东区",
            value: "金东区",
          },
          {
            areaCode: 330723000000,
            label: "武义县",
            value: "武义县",
          },
          {
            areaCode: 330726000000,
            label: "浦江县",
            value: "浦江县",
          },
          {
            areaCode: 330727000000,
            label: "磐安县",
            value: "磐安县",
          },
          {
            areaCode: 330781000000,
            label: "兰溪市",
            value: "兰溪市",
          },
          {
            areaCode: 330782000000,
            label: "义乌市",
            value: "义乌市",
          },
          {
            areaCode: 330783000000,
            label: "东阳市",
            value: "东阳市",
          },
          {
            areaCode: 330784000000,
            label: "永康市",
            value: "永康市",
          },
        ],
        label: "金华市",
        value: "金华市",
      },
      {
        areaCode: 330800000000,
        children: [
          {
            areaCode: 330802000000,
            label: "柯城区",
            value: "柯城区",
          },
          {
            areaCode: 330803000000,
            label: "衢江区",
            value: "衢江区",
          },
          {
            areaCode: 330822000000,
            label: "常山县",
            value: "常山县",
          },
          {
            areaCode: 330824000000,
            label: "开化县",
            value: "开化县",
          },
          {
            areaCode: 330825000000,
            label: "龙游县",
            value: "龙游县",
          },
          {
            areaCode: 330881000000,
            label: "江山市",
            value: "江山市",
          },
        ],
        label: "衢州市",
        value: "衢州市",
      },
      {
        areaCode: 330900000000,
        children: [
          {
            areaCode: 330902000000,
            label: "定海区",
            value: "定海区",
          },
          {
            areaCode: 330903000000,
            label: "普陀区",
            value: "普陀区",
          },
          {
            areaCode: 330921000000,
            label: "岱山县",
            value: "岱山县",
          },
          {
            areaCode: 330922000000,
            label: "嵊泗县",
            value: "嵊泗县",
          },
        ],
        label: "舟山市",
        value: "舟山市",
      },
      {
        areaCode: 331000000000,
        children: [
          {
            areaCode: 331002000000,
            label: "椒江区",
            value: "椒江区",
          },
          {
            areaCode: 331003000000,
            label: "黄岩区",
            value: "黄岩区",
          },
          {
            areaCode: 331004000000,
            label: "路桥区",
            value: "路桥区",
          },
          {
            areaCode: 331021000000,
            label: "玉环县",
            value: "玉环县",
          },
          {
            areaCode: 331022000000,
            label: "三门县",
            value: "三门县",
          },
          {
            areaCode: 331023000000,
            label: "天台县",
            value: "天台县",
          },
          {
            areaCode: 331024000000,
            label: "仙居县",
            value: "仙居县",
          },
          {
            areaCode: 331081000000,
            label: "温岭市",
            value: "温岭市",
          },
          {
            areaCode: 331082000000,
            label: "临海市",
            value: "临海市",
          },
        ],
        label: "台州市",
        value: "台州市",
      },
      {
        areaCode: 331100000000,
        children: [
          {
            areaCode: 331102000000,
            label: "莲都区",
            value: "莲都区",
          },
          {
            areaCode: 331121000000,
            label: "青田县",
            value: "青田县",
          },
          {
            areaCode: 331122000000,
            label: "缙云县",
            value: "缙云县",
          },
          {
            areaCode: 331123000000,
            label: "遂昌县",
            value: "遂昌县",
          },
          {
            areaCode: 331124000000,
            label: "松阳县",
            value: "松阳县",
          },
          {
            areaCode: 331125000000,
            label: "云和县",
            value: "云和县",
          },
          {
            areaCode: 331126000000,
            label: "庆元县",
            value: "庆元县",
          },
          {
            areaCode: 331127000000,
            label: "景宁畲族自治县",
            value: "景宁畲族自治县",
          },
          {
            areaCode: 331181000000,
            label: "龙泉市",
            value: "龙泉市",
          },
        ],
        label: "丽水市",
        value: "丽水市",
      },
    ],
    label: "浙江省",
    value: "浙江省",
  },
  {
    areaCode: 340000000000,
    children: [
      {
        areaCode: 340100000000,
        children: [
          {
            areaCode: 340102000000,
            label: "瑶海区",
            value: "瑶海区",
          },
          {
            areaCode: 340103000000,
            label: "庐阳区",
            value: "庐阳区",
          },
          {
            areaCode: 340104000000,
            label: "蜀山区",
            value: "蜀山区",
          },
          {
            areaCode: 340111000000,
            label: "包河区",
            value: "包河区",
          },
          {
            areaCode: 340121000000,
            label: "长丰县",
            value: "长丰县",
          },
          {
            areaCode: 340122000000,
            label: "肥东县",
            value: "肥东县",
          },
          {
            areaCode: 340123000000,
            label: "肥西县",
            value: "肥西县",
          },
          {
            areaCode: 340124000000,
            label: "庐江县",
            value: "庐江县",
          },
          {
            areaCode: 340181000000,
            label: "巢湖市",
            value: "巢湖市",
          },
        ],
        label: "合肥市",
        value: "合肥市",
      },
      {
        areaCode: 340200000000,
        children: [
          {
            areaCode: 340202000000,
            label: "镜湖区",
            value: "镜湖区",
          },
          {
            areaCode: 340203000000,
            label: "弋江区",
            value: "弋江区",
          },
          {
            areaCode: 340207000000,
            label: "鸠江区",
            value: "鸠江区",
          },
          {
            areaCode: 340208000000,
            label: "三山区",
            value: "三山区",
          },
          {
            areaCode: 340221000000,
            label: "芜湖县",
            value: "芜湖县",
          },
          {
            areaCode: 340222000000,
            label: "繁昌县",
            value: "繁昌县",
          },
          {
            areaCode: 340223000000,
            label: "南陵县",
            value: "南陵县",
          },
          {
            areaCode: 340225000000,
            label: "无为县",
            value: "无为县",
          },
        ],
        label: "芜湖市",
        value: "芜湖市",
      },
      {
        areaCode: 340300000000,
        children: [
          {
            areaCode: 340302000000,
            label: "龙子湖区",
            value: "龙子湖区",
          },
          {
            areaCode: 340303000000,
            label: "蚌山区",
            value: "蚌山区",
          },
          {
            areaCode: 340304000000,
            label: "禹会区",
            value: "禹会区",
          },
          {
            areaCode: 340311000000,
            label: "淮上区",
            value: "淮上区",
          },
          {
            areaCode: 340321000000,
            label: "怀远县",
            value: "怀远县",
          },
          {
            areaCode: 340322000000,
            label: "五河县",
            value: "五河县",
          },
          {
            areaCode: 340323000000,
            label: "固镇县",
            value: "固镇县",
          },
        ],
        label: "蚌埠市",
        value: "蚌埠市",
      },
      {
        areaCode: 340400000000,
        children: [
          {
            areaCode: 340402000000,
            label: "大通区",
            value: "大通区",
          },
          {
            areaCode: 340403000000,
            label: "田家庵区",
            value: "田家庵区",
          },
          {
            areaCode: 340404000000,
            label: "谢家集区",
            value: "谢家集区",
          },
          {
            areaCode: 340405000000,
            label: "八公山区",
            value: "八公山区",
          },
          {
            areaCode: 340406000000,
            label: "潘集区",
            value: "潘集区",
          },
          {
            areaCode: 340421000000,
            label: "凤台县",
            value: "凤台县",
          },
          {
            areaCode: 340422000000,
            label: "寿县",
            value: "寿县",
          },
        ],
        label: "淮南市",
        value: "淮南市",
      },
      {
        areaCode: 340500000000,
        children: [
          {
            areaCode: 340503000000,
            label: "花山区",
            value: "花山区",
          },
          {
            areaCode: 340504000000,
            label: "雨山区",
            value: "雨山区",
          },
          {
            areaCode: 340506000000,
            label: "博望区",
            value: "博望区",
          },
          {
            areaCode: 340521000000,
            label: "当涂县",
            value: "当涂县",
          },
          {
            areaCode: 340522000000,
            label: "含山县",
            value: "含山县",
          },
          {
            areaCode: 340523000000,
            label: "和县",
            value: "和县",
          },
        ],
        label: "马鞍山市",
        value: "马鞍山市",
      },
      {
        areaCode: 340600000000,
        children: [
          {
            areaCode: 340602000000,
            label: "杜集区",
            value: "杜集区",
          },
          {
            areaCode: 340603000000,
            label: "相山区",
            value: "相山区",
          },
          {
            areaCode: 340604000000,
            label: "烈山区",
            value: "烈山区",
          },
          {
            areaCode: 340621000000,
            label: "濉溪县",
            value: "濉溪县",
          },
        ],
        label: "淮北市",
        value: "淮北市",
      },
      {
        areaCode: 340700000000,
        children: [
          {
            areaCode: 340705000000,
            label: "铜官区",
            value: "铜官区",
          },
          {
            areaCode: 340706000000,
            label: "义安区",
            value: "义安区",
          },
          {
            areaCode: 340711000000,
            label: "郊区",
            value: "郊区",
          },
          {
            areaCode: 340722000000,
            label: "枞阳县",
            value: "枞阳县",
          },
        ],
        label: "铜陵市",
        value: "铜陵市",
      },
      {
        areaCode: 340800000000,
        children: [
          {
            areaCode: 340802000000,
            label: "迎江区",
            value: "迎江区",
          },
          {
            areaCode: 340803000000,
            label: "大观区",
            value: "大观区",
          },
          {
            areaCode: 340811000000,
            label: "宜秀区",
            value: "宜秀区",
          },
          {
            areaCode: 340822000000,
            label: "怀宁县",
            value: "怀宁县",
          },
          {
            areaCode: 340824000000,
            label: "潜山县",
            value: "潜山县",
          },
          {
            areaCode: 340825000000,
            label: "太湖县",
            value: "太湖县",
          },
          {
            areaCode: 340826000000,
            label: "宿松县",
            value: "宿松县",
          },
          {
            areaCode: 340827000000,
            label: "望江县",
            value: "望江县",
          },
          {
            areaCode: 340828000000,
            label: "岳西县",
            value: "岳西县",
          },
          {
            areaCode: 340881000000,
            label: "桐城市",
            value: "桐城市",
          },
        ],
        label: "安庆市",
        value: "安庆市",
      },
      {
        areaCode: 341000000000,
        children: [
          {
            areaCode: 341002000000,
            label: "屯溪区",
            value: "屯溪区",
          },
          {
            areaCode: 341003000000,
            label: "黄山区",
            value: "黄山区",
          },
          {
            areaCode: 341004000000,
            label: "徽州区",
            value: "徽州区",
          },
          {
            areaCode: 341021000000,
            label: "歙县",
            value: "歙县",
          },
          {
            areaCode: 341022000000,
            label: "休宁县",
            value: "休宁县",
          },
          {
            areaCode: 341023000000,
            label: "黟县",
            value: "黟县",
          },
          {
            areaCode: 341024000000,
            label: "祁门县",
            value: "祁门县",
          },
        ],
        label: "黄山市",
        value: "黄山市",
      },
      {
        areaCode: 341100000000,
        children: [
          {
            areaCode: 341102000000,
            label: "琅琊区",
            value: "琅琊区",
          },
          {
            areaCode: 341103000000,
            label: "南谯区",
            value: "南谯区",
          },
          {
            areaCode: 341122000000,
            label: "来安县",
            value: "来安县",
          },
          {
            areaCode: 341124000000,
            label: "全椒县",
            value: "全椒县",
          },
          {
            areaCode: 341125000000,
            label: "定远县",
            value: "定远县",
          },
          {
            areaCode: 341126000000,
            label: "凤阳县",
            value: "凤阳县",
          },
          {
            areaCode: 341181000000,
            label: "天长市",
            value: "天长市",
          },
          {
            areaCode: 341182000000,
            label: "明光市",
            value: "明光市",
          },
        ],
        label: "滁州市",
        value: "滁州市",
      },
      {
        areaCode: 341200000000,
        children: [
          {
            areaCode: 341202000000,
            label: "颍州区",
            value: "颍州区",
          },
          {
            areaCode: 341203000000,
            label: "颍东区",
            value: "颍东区",
          },
          {
            areaCode: 341204000000,
            label: "颍泉区",
            value: "颍泉区",
          },
          {
            areaCode: 341221000000,
            label: "临泉县",
            value: "临泉县",
          },
          {
            areaCode: 341222000000,
            label: "太和县",
            value: "太和县",
          },
          {
            areaCode: 341225000000,
            label: "阜南县",
            value: "阜南县",
          },
          {
            areaCode: 341226000000,
            label: "颍上县",
            value: "颍上县",
          },
          {
            areaCode: 341282000000,
            label: "界首市",
            value: "界首市",
          },
        ],
        label: "阜阳市",
        value: "阜阳市",
      },
      {
        areaCode: 341300000000,
        children: [
          {
            areaCode: 341302000000,
            label: "埇桥区",
            value: "埇桥区",
          },
          {
            areaCode: 341321000000,
            label: "砀山县",
            value: "砀山县",
          },
          {
            areaCode: 341322000000,
            label: "萧县",
            value: "萧县",
          },
          {
            areaCode: 341323000000,
            label: "灵璧县",
            value: "灵璧县",
          },
          {
            areaCode: 341324000000,
            label: "泗县",
            value: "泗县",
          },
        ],
        label: "宿州市",
        value: "宿州市",
      },
      {
        areaCode: 341500000000,
        children: [
          {
            areaCode: 341502000000,
            label: "金安区",
            value: "金安区",
          },
          {
            areaCode: 341503000000,
            label: "裕安区",
            value: "裕安区",
          },
          {
            areaCode: 341504000000,
            label: "叶集区",
            value: "叶集区",
          },
          {
            areaCode: 341522000000,
            label: "霍邱县",
            value: "霍邱县",
          },
          {
            areaCode: 341523000000,
            label: "舒城县",
            value: "舒城县",
          },
          {
            areaCode: 341524000000,
            label: "金寨县",
            value: "金寨县",
          },
          {
            areaCode: 341525000000,
            label: "霍山县",
            value: "霍山县",
          },
        ],
        label: "六安市",
        value: "六安市",
      },
      {
        areaCode: 341600000000,
        children: [
          {
            areaCode: 341602000000,
            label: "谯城区",
            value: "谯城区",
          },
          {
            areaCode: 341621000000,
            label: "涡阳县",
            value: "涡阳县",
          },
          {
            areaCode: 341622000000,
            label: "蒙城县",
            value: "蒙城县",
          },
          {
            areaCode: 341623000000,
            label: "利辛县",
            value: "利辛县",
          },
        ],
        label: "亳州市",
        value: "亳州市",
      },
      {
        areaCode: 341700000000,
        children: [
          {
            areaCode: 341702000000,
            label: "贵池区",
            value: "贵池区",
          },
          {
            areaCode: 341721000000,
            label: "东至县",
            value: "东至县",
          },
          {
            areaCode: 341722000000,
            label: "石台县",
            value: "石台县",
          },
          {
            areaCode: 341723000000,
            label: "青阳县",
            value: "青阳县",
          },
        ],
        label: "池州市",
        value: "池州市",
      },
      {
        areaCode: 341800000000,
        children: [
          {
            areaCode: 341802000000,
            label: "宣州区",
            value: "宣州区",
          },
          {
            areaCode: 341821000000,
            label: "郎溪县",
            value: "郎溪县",
          },
          {
            areaCode: 341822000000,
            label: "广德县",
            value: "广德县",
          },
          {
            areaCode: 341823000000,
            label: "泾县",
            value: "泾县",
          },
          {
            areaCode: 341824000000,
            label: "绩溪县",
            value: "绩溪县",
          },
          {
            areaCode: 341825000000,
            label: "旌德县",
            value: "旌德县",
          },
          {
            areaCode: 341881000000,
            label: "宁国市",
            value: "宁国市",
          },
        ],
        label: "宣城市",
        value: "宣城市",
      },
    ],
    label: "安徽省",
    value: "安徽省",
  },
  {
    areaCode: 350000000000,
    children: [
      {
        areaCode: 350100000000,
        children: [
          {
            areaCode: 350102000000,
            label: "鼓楼区",
            value: "鼓楼区",
          },
          {
            areaCode: 350103000000,
            label: "台江区",
            value: "台江区",
          },
          {
            areaCode: 350104000000,
            label: "仓山区",
            value: "仓山区",
          },
          {
            areaCode: 350105000000,
            label: "马尾区",
            value: "马尾区",
          },
          {
            areaCode: 350111000000,
            label: "晋安区",
            value: "晋安区",
          },
          {
            areaCode: 350121000000,
            label: "闽侯县",
            value: "闽侯县",
          },
          {
            areaCode: 350122000000,
            label: "连江县",
            value: "连江县",
          },
          {
            areaCode: 350123000000,
            label: "罗源县",
            value: "罗源县",
          },
          {
            areaCode: 350124000000,
            label: "闽清县",
            value: "闽清县",
          },
          {
            areaCode: 350125000000,
            label: "永泰县",
            value: "永泰县",
          },
          {
            areaCode: 350128000000,
            label: "平潭县",
            value: "平潭县",
          },
          {
            areaCode: 350181000000,
            label: "福清市",
            value: "福清市",
          },
          {
            areaCode: 350182000000,
            label: "长乐市",
            value: "长乐市",
          },
        ],
        label: "福州市",
        value: "福州市",
      },
      {
        areaCode: 350200000000,
        children: [
          {
            areaCode: 350203000000,
            label: "思明区",
            value: "思明区",
          },
          {
            areaCode: 350205000000,
            label: "海沧区",
            value: "海沧区",
          },
          {
            areaCode: 350206000000,
            label: "湖里区",
            value: "湖里区",
          },
          {
            areaCode: 350211000000,
            label: "集美区",
            value: "集美区",
          },
          {
            areaCode: 350212000000,
            label: "同安区",
            value: "同安区",
          },
          {
            areaCode: 350213000000,
            label: "翔安区",
            value: "翔安区",
          },
        ],
        label: "厦门市",
        value: "厦门市",
      },
      {
        areaCode: 350300000000,
        children: [
          {
            areaCode: 350302000000,
            label: "城厢区",
            value: "城厢区",
          },
          {
            areaCode: 350303000000,
            label: "涵江区",
            value: "涵江区",
          },
          {
            areaCode: 350304000000,
            label: "荔城区",
            value: "荔城区",
          },
          {
            areaCode: 350305000000,
            label: "秀屿区",
            value: "秀屿区",
          },
          {
            areaCode: 350322000000,
            label: "仙游县",
            value: "仙游县",
          },
        ],
        label: "莆田市",
        value: "莆田市",
      },
      {
        areaCode: 350400000000,
        children: [
          {
            areaCode: 350402000000,
            label: "梅列区",
            value: "梅列区",
          },
          {
            areaCode: 350403000000,
            label: "三元区",
            value: "三元区",
          },
          {
            areaCode: 350421000000,
            label: "明溪县",
            value: "明溪县",
          },
          {
            areaCode: 350423000000,
            label: "清流县",
            value: "清流县",
          },
          {
            areaCode: 350424000000,
            label: "宁化县",
            value: "宁化县",
          },
          {
            areaCode: 350425000000,
            label: "大田县",
            value: "大田县",
          },
          {
            areaCode: 350426000000,
            label: "尤溪县",
            value: "尤溪县",
          },
          {
            areaCode: 350427000000,
            label: "沙县",
            value: "沙县",
          },
          {
            areaCode: 350428000000,
            label: "将乐县",
            value: "将乐县",
          },
          {
            areaCode: 350429000000,
            label: "泰宁县",
            value: "泰宁县",
          },
          {
            areaCode: 350430000000,
            label: "建宁县",
            value: "建宁县",
          },
          {
            areaCode: 350481000000,
            label: "永安市",
            value: "永安市",
          },
        ],
        label: "三明市",
        value: "三明市",
      },
      {
        areaCode: 350500000000,
        children: [
          {
            areaCode: 350502000000,
            label: "鲤城区",
            value: "鲤城区",
          },
          {
            areaCode: 350503000000,
            label: "丰泽区",
            value: "丰泽区",
          },
          {
            areaCode: 350504000000,
            label: "洛江区",
            value: "洛江区",
          },
          {
            areaCode: 350505000000,
            label: "泉港区",
            value: "泉港区",
          },
          {
            areaCode: 350521000000,
            label: "惠安县",
            value: "惠安县",
          },
          {
            areaCode: 350524000000,
            label: "安溪县",
            value: "安溪县",
          },
          {
            areaCode: 350525000000,
            label: "永春县",
            value: "永春县",
          },
          {
            areaCode: 350526000000,
            label: "德化县",
            value: "德化县",
          },
          {
            areaCode: 350527000000,
            label: "金门县",
            value: "金门县",
          },
          {
            areaCode: 350581000000,
            label: "石狮市",
            value: "石狮市",
          },
          {
            areaCode: 350582000000,
            label: "晋江市",
            value: "晋江市",
          },
          {
            areaCode: 350583000000,
            label: "南安市",
            value: "南安市",
          },
        ],
        label: "泉州市",
        value: "泉州市",
      },
      {
        areaCode: 350600000000,
        children: [
          {
            areaCode: 350602000000,
            label: "芗城区",
            value: "芗城区",
          },
          {
            areaCode: 350603000000,
            label: "龙文区",
            value: "龙文区",
          },
          {
            areaCode: 350622000000,
            label: "云霄县",
            value: "云霄县",
          },
          {
            areaCode: 350623000000,
            label: "漳浦县",
            value: "漳浦县",
          },
          {
            areaCode: 350624000000,
            label: "诏安县",
            value: "诏安县",
          },
          {
            areaCode: 350625000000,
            label: "长泰县",
            value: "长泰县",
          },
          {
            areaCode: 350626000000,
            label: "东山县",
            value: "东山县",
          },
          {
            areaCode: 350627000000,
            label: "南靖县",
            value: "南靖县",
          },
          {
            areaCode: 350628000000,
            label: "平和县",
            value: "平和县",
          },
          {
            areaCode: 350629000000,
            label: "华安县",
            value: "华安县",
          },
          {
            areaCode: 350681000000,
            label: "龙海市",
            value: "龙海市",
          },
        ],
        label: "漳州市",
        value: "漳州市",
      },
      {
        areaCode: 350700000000,
        children: [
          {
            areaCode: 350702000000,
            label: "延平区",
            value: "延平区",
          },
          {
            areaCode: 350703000000,
            label: "建阳区",
            value: "建阳区",
          },
          {
            areaCode: 350721000000,
            label: "顺昌县",
            value: "顺昌县",
          },
          {
            areaCode: 350722000000,
            label: "浦城县",
            value: "浦城县",
          },
          {
            areaCode: 350723000000,
            label: "光泽县",
            value: "光泽县",
          },
          {
            areaCode: 350724000000,
            label: "松溪县",
            value: "松溪县",
          },
          {
            areaCode: 350725000000,
            label: "政和县",
            value: "政和县",
          },
          {
            areaCode: 350781000000,
            label: "邵武市",
            value: "邵武市",
          },
          {
            areaCode: 350782000000,
            label: "武夷山市",
            value: "武夷山市",
          },
          {
            areaCode: 350783000000,
            label: "建瓯市",
            value: "建瓯市",
          },
        ],
        label: "南平市",
        value: "南平市",
      },
      {
        areaCode: 350800000000,
        children: [
          {
            areaCode: 350802000000,
            label: "新罗区",
            value: "新罗区",
          },
          {
            areaCode: 350803000000,
            label: "永定区",
            value: "永定区",
          },
          {
            areaCode: 350821000000,
            label: "长汀县",
            value: "长汀县",
          },
          {
            areaCode: 350823000000,
            label: "上杭县",
            value: "上杭县",
          },
          {
            areaCode: 350824000000,
            label: "武平县",
            value: "武平县",
          },
          {
            areaCode: 350825000000,
            label: "连城县",
            value: "连城县",
          },
          {
            areaCode: 350881000000,
            label: "漳平市",
            value: "漳平市",
          },
        ],
        label: "龙岩市",
        value: "龙岩市",
      },
      {
        areaCode: 350900000000,
        children: [
          {
            areaCode: 350902000000,
            label: "蕉城区",
            value: "蕉城区",
          },
          {
            areaCode: 350921000000,
            label: "霞浦县",
            value: "霞浦县",
          },
          {
            areaCode: 350922000000,
            label: "古田县",
            value: "古田县",
          },
          {
            areaCode: 350923000000,
            label: "屏南县",
            value: "屏南县",
          },
          {
            areaCode: 350924000000,
            label: "寿宁县",
            value: "寿宁县",
          },
          {
            areaCode: 350925000000,
            label: "周宁县",
            value: "周宁县",
          },
          {
            areaCode: 350926000000,
            label: "柘荣县",
            value: "柘荣县",
          },
          {
            areaCode: 350981000000,
            label: "福安市",
            value: "福安市",
          },
          {
            areaCode: 350982000000,
            label: "福鼎市",
            value: "福鼎市",
          },
        ],
        label: "宁德市",
        value: "宁德市",
      },
    ],
    label: "福建省",
    value: "福建省",
  },
  {
    areaCode: 360000000000,
    children: [
      {
        areaCode: 360100000000,
        children: [
          {
            areaCode: 360102000000,
            label: "东湖区",
            value: "东湖区",
          },
          {
            areaCode: 360103000000,
            label: "西湖区",
            value: "西湖区",
          },
          {
            areaCode: 360104000000,
            label: "青云谱区",
            value: "青云谱区",
          },
          {
            areaCode: 360105000000,
            label: "湾里区",
            value: "湾里区",
          },
          {
            areaCode: 360111000000,
            label: "青山湖区",
            value: "青山湖区",
          },
          {
            areaCode: 360112000000,
            label: "新建区",
            value: "新建区",
          },
          {
            areaCode: 360121000000,
            label: "南昌县",
            value: "南昌县",
          },
          {
            areaCode: 360123000000,
            label: "安义县",
            value: "安义县",
          },
          {
            areaCode: 360124000000,
            label: "进贤县",
            value: "进贤县",
          },
        ],
        label: "南昌市",
        value: "南昌市",
      },
      {
        areaCode: 360200000000,
        children: [
          {
            areaCode: 360202000000,
            label: "昌江区",
            value: "昌江区",
          },
          {
            areaCode: 360203000000,
            label: "珠山区",
            value: "珠山区",
          },
          {
            areaCode: 360222000000,
            label: "浮梁县",
            value: "浮梁县",
          },
          {
            areaCode: 360281000000,
            label: "乐平市",
            value: "乐平市",
          },
        ],
        label: "景德镇市",
        value: "景德镇市",
      },
      {
        areaCode: 360300000000,
        children: [
          {
            areaCode: 360302000000,
            label: "安源区",
            value: "安源区",
          },
          {
            areaCode: 360313000000,
            label: "湘东区",
            value: "湘东区",
          },
          {
            areaCode: 360321000000,
            label: "莲花县",
            value: "莲花县",
          },
          {
            areaCode: 360322000000,
            label: "上栗县",
            value: "上栗县",
          },
          {
            areaCode: 360323000000,
            label: "芦溪县",
            value: "芦溪县",
          },
        ],
        label: "萍乡市",
        value: "萍乡市",
      },
      {
        areaCode: 360400000000,
        children: [
          {
            areaCode: 360402000000,
            label: "濂溪区",
            value: "濂溪区",
          },
          {
            areaCode: 360403000000,
            label: "浔阳区",
            value: "浔阳区",
          },
          {
            areaCode: 360421000000,
            label: "九江县",
            value: "九江县",
          },
          {
            areaCode: 360423000000,
            label: "武宁县",
            value: "武宁县",
          },
          {
            areaCode: 360424000000,
            label: "修水县",
            value: "修水县",
          },
          {
            areaCode: 360425000000,
            label: "永修县",
            value: "永修县",
          },
          {
            areaCode: 360426000000,
            label: "德安县",
            value: "德安县",
          },
          {
            areaCode: 360428000000,
            label: "都昌县",
            value: "都昌县",
          },
          {
            areaCode: 360429000000,
            label: "湖口县",
            value: "湖口县",
          },
          {
            areaCode: 360430000000,
            label: "彭泽县",
            value: "彭泽县",
          },
          {
            areaCode: 360481000000,
            label: "瑞昌市",
            value: "瑞昌市",
          },
          {
            areaCode: 360482000000,
            label: "共青城市",
            value: "共青城市",
          },
          {
            areaCode: 360483000000,
            label: "庐山市",
            value: "庐山市",
          },
        ],
        label: "九江市",
        value: "九江市",
      },
      {
        areaCode: 360500000000,
        children: [
          {
            areaCode: 360502000000,
            label: "渝水区",
            value: "渝水区",
          },
          {
            areaCode: 360521000000,
            label: "分宜县",
            value: "分宜县",
          },
        ],
        label: "新余市",
        value: "新余市",
      },
      {
        areaCode: 360600000000,
        children: [
          {
            areaCode: 360602000000,
            label: "月湖区",
            value: "月湖区",
          },
          {
            areaCode: 360622000000,
            label: "余江县",
            value: "余江县",
          },
          {
            areaCode: 360681000000,
            label: "贵溪市",
            value: "贵溪市",
          },
        ],
        label: "鹰潭市",
        value: "鹰潭市",
      },
      {
        areaCode: 360700000000,
        children: [
          {
            areaCode: 360702000000,
            label: "章贡区",
            value: "章贡区",
          },
          {
            areaCode: 360703000000,
            label: "南康区",
            value: "南康区",
          },
          {
            areaCode: 360721000000,
            label: "赣县",
            value: "赣县",
          },
          {
            areaCode: 360722000000,
            label: "信丰县",
            value: "信丰县",
          },
          {
            areaCode: 360723000000,
            label: "大余县",
            value: "大余县",
          },
          {
            areaCode: 360724000000,
            label: "上犹县",
            value: "上犹县",
          },
          {
            areaCode: 360725000000,
            label: "崇义县",
            value: "崇义县",
          },
          {
            areaCode: 360726000000,
            label: "安远县",
            value: "安远县",
          },
          {
            areaCode: 360727000000,
            label: "龙南县",
            value: "龙南县",
          },
          {
            areaCode: 360728000000,
            label: "定南县",
            value: "定南县",
          },
          {
            areaCode: 360729000000,
            label: "全南县",
            value: "全南县",
          },
          {
            areaCode: 360730000000,
            label: "宁都县",
            value: "宁都县",
          },
          {
            areaCode: 360731000000,
            label: "于都县",
            value: "于都县",
          },
          {
            areaCode: 360732000000,
            label: "兴国县",
            value: "兴国县",
          },
          {
            areaCode: 360733000000,
            label: "会昌县",
            value: "会昌县",
          },
          {
            areaCode: 360734000000,
            label: "寻乌县",
            value: "寻乌县",
          },
          {
            areaCode: 360735000000,
            label: "石城县",
            value: "石城县",
          },
          {
            areaCode: 360781000000,
            label: "瑞金市",
            value: "瑞金市",
          },
        ],
        label: "赣州市",
        value: "赣州市",
      },
      {
        areaCode: 360800000000,
        children: [
          {
            areaCode: 360802000000,
            label: "吉州区",
            value: "吉州区",
          },
          {
            areaCode: 360803000000,
            label: "青原区",
            value: "青原区",
          },
          {
            areaCode: 360821000000,
            label: "吉安县",
            value: "吉安县",
          },
          {
            areaCode: 360822000000,
            label: "吉水县",
            value: "吉水县",
          },
          {
            areaCode: 360823000000,
            label: "峡江县",
            value: "峡江县",
          },
          {
            areaCode: 360824000000,
            label: "新干县",
            value: "新干县",
          },
          {
            areaCode: 360825000000,
            label: "永丰县",
            value: "永丰县",
          },
          {
            areaCode: 360826000000,
            label: "泰和县",
            value: "泰和县",
          },
          {
            areaCode: 360827000000,
            label: "遂川县",
            value: "遂川县",
          },
          {
            areaCode: 360828000000,
            label: "万安县",
            value: "万安县",
          },
          {
            areaCode: 360829000000,
            label: "安福县",
            value: "安福县",
          },
          {
            areaCode: 360830000000,
            label: "永新县",
            value: "永新县",
          },
          {
            areaCode: 360881000000,
            label: "井冈山市",
            value: "井冈山市",
          },
        ],
        label: "吉安市",
        value: "吉安市",
      },
      {
        areaCode: 360900000000,
        children: [
          {
            areaCode: 360902000000,
            label: "袁州区",
            value: "袁州区",
          },
          {
            areaCode: 360921000000,
            label: "奉新县",
            value: "奉新县",
          },
          {
            areaCode: 360922000000,
            label: "万载县",
            value: "万载县",
          },
          {
            areaCode: 360923000000,
            label: "上高县",
            value: "上高县",
          },
          {
            areaCode: 360924000000,
            label: "宜丰县",
            value: "宜丰县",
          },
          {
            areaCode: 360925000000,
            label: "靖安县",
            value: "靖安县",
          },
          {
            areaCode: 360926000000,
            label: "铜鼓县",
            value: "铜鼓县",
          },
          {
            areaCode: 360981000000,
            label: "丰城市",
            value: "丰城市",
          },
          {
            areaCode: 360982000000,
            label: "樟树市",
            value: "樟树市",
          },
          {
            areaCode: 360983000000,
            label: "高安市",
            value: "高安市",
          },
        ],
        label: "宜春市",
        value: "宜春市",
      },
      {
        areaCode: 361000000000,
        children: [
          {
            areaCode: 361002000000,
            label: "临川区",
            value: "临川区",
          },
          {
            areaCode: 361021000000,
            label: "南城县",
            value: "南城县",
          },
          {
            areaCode: 361022000000,
            label: "黎川县",
            value: "黎川县",
          },
          {
            areaCode: 361023000000,
            label: "南丰县",
            value: "南丰县",
          },
          {
            areaCode: 361024000000,
            label: "崇仁县",
            value: "崇仁县",
          },
          {
            areaCode: 361025000000,
            label: "乐安县",
            value: "乐安县",
          },
          {
            areaCode: 361026000000,
            label: "宜黄县",
            value: "宜黄县",
          },
          {
            areaCode: 361027000000,
            label: "金溪县",
            value: "金溪县",
          },
          {
            areaCode: 361028000000,
            label: "资溪县",
            value: "资溪县",
          },
          {
            areaCode: 361029000000,
            label: "东乡县",
            value: "东乡县",
          },
          {
            areaCode: 361030000000,
            label: "广昌县",
            value: "广昌县",
          },
        ],
        label: "抚州市",
        value: "抚州市",
      },
      {
        areaCode: 361100000000,
        children: [
          {
            areaCode: 361102000000,
            label: "信州区",
            value: "信州区",
          },
          {
            areaCode: 361103000000,
            label: "广丰区",
            value: "广丰区",
          },
          {
            areaCode: 361121000000,
            label: "上饶县",
            value: "上饶县",
          },
          {
            areaCode: 361123000000,
            label: "玉山县",
            value: "玉山县",
          },
          {
            areaCode: 361124000000,
            label: "铅山县",
            value: "铅山县",
          },
          {
            areaCode: 361125000000,
            label: "横峰县",
            value: "横峰县",
          },
          {
            areaCode: 361126000000,
            label: "弋阳县",
            value: "弋阳县",
          },
          {
            areaCode: 361127000000,
            label: "余干县",
            value: "余干县",
          },
          {
            areaCode: 361128000000,
            label: "鄱阳县",
            value: "鄱阳县",
          },
          {
            areaCode: 361129000000,
            label: "万年县",
            value: "万年县",
          },
          {
            areaCode: 361130000000,
            label: "婺源县",
            value: "婺源县",
          },
          {
            areaCode: 361181000000,
            label: "德兴市",
            value: "德兴市",
          },
        ],
        label: "上饶市",
        value: "上饶市",
      },
    ],
    label: "江西省",
    value: "江西省",
  },
  {
    areaCode: 370000000000,
    children: [
      {
        areaCode: 370100000000,
        children: [
          {
            areaCode: 370102000000,
            label: "历下区",
            value: "历下区",
          },
          {
            areaCode: 370103000000,
            label: "市中区",
            value: "市中区",
          },
          {
            areaCode: 370104000000,
            label: "槐荫区",
            value: "槐荫区",
          },
          {
            areaCode: 370105000000,
            label: "天桥区",
            value: "天桥区",
          },
          {
            areaCode: 370112000000,
            label: "历城区",
            value: "历城区",
          },
          {
            areaCode: 370113000000,
            label: "长清区",
            value: "长清区",
          },
          {
            areaCode: 370124000000,
            label: "平阴县",
            value: "平阴县",
          },
          {
            areaCode: 370125000000,
            label: "济阳县",
            value: "济阳县",
          },
          {
            areaCode: 370126000000,
            label: "商河县",
            value: "商河县",
          },
          {
            areaCode: 370181000000,
            label: "章丘市",
            value: "章丘市",
          },
        ],
        label: "济南市",
        value: "济南市",
      },
      {
        areaCode: 370200000000,
        children: [
          {
            areaCode: 370202000000,
            label: "市南区",
            value: "市南区",
          },
          {
            areaCode: 370203000000,
            label: "市北区",
            value: "市北区",
          },
          {
            areaCode: 370211000000,
            label: "黄岛区",
            value: "黄岛区",
          },
          {
            areaCode: 370212000000,
            label: "崂山区",
            value: "崂山区",
          },
          {
            areaCode: 370213000000,
            label: "李沧区",
            value: "李沧区",
          },
          {
            areaCode: 370214000000,
            label: "城阳区",
            value: "城阳区",
          },
          {
            areaCode: 370281000000,
            label: "胶州市",
            value: "胶州市",
          },
          {
            areaCode: 370282000000,
            label: "即墨市",
            value: "即墨市",
          },
          {
            areaCode: 370283000000,
            label: "平度市",
            value: "平度市",
          },
          {
            areaCode: 370285000000,
            label: "莱西市",
            value: "莱西市",
          },
        ],
        label: "青岛市",
        value: "青岛市",
      },
      {
        areaCode: 370300000000,
        children: [
          {
            areaCode: 370302000000,
            label: "淄川区",
            value: "淄川区",
          },
          {
            areaCode: 370303000000,
            label: "张店区",
            value: "张店区",
          },
          {
            areaCode: 370304000000,
            label: "博山区",
            value: "博山区",
          },
          {
            areaCode: 370305000000,
            label: "临淄区",
            value: "临淄区",
          },
          {
            areaCode: 370306000000,
            label: "周村区",
            value: "周村区",
          },
          {
            areaCode: 370321000000,
            label: "桓台县",
            value: "桓台县",
          },
          {
            areaCode: 370322000000,
            label: "高青县",
            value: "高青县",
          },
          {
            areaCode: 370323000000,
            label: "沂源县",
            value: "沂源县",
          },
        ],
        label: "淄博市",
        value: "淄博市",
      },
      {
        areaCode: 370400000000,
        children: [
          {
            areaCode: 370402000000,
            label: "市中区",
            value: "市中区",
          },
          {
            areaCode: 370403000000,
            label: "薛城区",
            value: "薛城区",
          },
          {
            areaCode: 370404000000,
            label: "峄城区",
            value: "峄城区",
          },
          {
            areaCode: 370405000000,
            label: "台儿庄区",
            value: "台儿庄区",
          },
          {
            areaCode: 370406000000,
            label: "山亭区",
            value: "山亭区",
          },
          {
            areaCode: 370481000000,
            label: "滕州市",
            value: "滕州市",
          },
        ],
        label: "枣庄市",
        value: "枣庄市",
      },
      {
        areaCode: 370500000000,
        children: [
          {
            areaCode: 370502000000,
            label: "东营区",
            value: "东营区",
          },
          {
            areaCode: 370503000000,
            label: "河口区",
            value: "河口区",
          },
          {
            areaCode: 370505000000,
            label: "垦利区",
            value: "垦利区",
          },
          {
            areaCode: 370522000000,
            label: "利津县",
            value: "利津县",
          },
          {
            areaCode: 370523000000,
            label: "广饶县",
            value: "广饶县",
          },
        ],
        label: "东营市",
        value: "东营市",
      },
      {
        areaCode: 370600000000,
        children: [
          {
            areaCode: 370602000000,
            label: "芝罘区",
            value: "芝罘区",
          },
          {
            areaCode: 370611000000,
            label: "福山区",
            value: "福山区",
          },
          {
            areaCode: 370612000000,
            label: "牟平区",
            value: "牟平区",
          },
          {
            areaCode: 370613000000,
            label: "莱山区",
            value: "莱山区",
          },
          {
            areaCode: 370634000000,
            label: "长岛县",
            value: "长岛县",
          },
          {
            areaCode: 370681000000,
            label: "龙口市",
            value: "龙口市",
          },
          {
            areaCode: 370682000000,
            label: "莱阳市",
            value: "莱阳市",
          },
          {
            areaCode: 370683000000,
            label: "莱州市",
            value: "莱州市",
          },
          {
            areaCode: 370684000000,
            label: "蓬莱市",
            value: "蓬莱市",
          },
          {
            areaCode: 370685000000,
            label: "招远市",
            value: "招远市",
          },
          {
            areaCode: 370686000000,
            label: "栖霞市",
            value: "栖霞市",
          },
          {
            areaCode: 370687000000,
            label: "海阳市",
            value: "海阳市",
          },
        ],
        label: "烟台市",
        value: "烟台市",
      },
      {
        areaCode: 370700000000,
        children: [
          {
            areaCode: 370702000000,
            label: "潍城区",
            value: "潍城区",
          },
          {
            areaCode: 370703000000,
            label: "寒亭区",
            value: "寒亭区",
          },
          {
            areaCode: 370704000000,
            label: "坊子区",
            value: "坊子区",
          },
          {
            areaCode: 370705000000,
            label: "奎文区",
            value: "奎文区",
          },
          {
            areaCode: 370724000000,
            label: "临朐县",
            value: "临朐县",
          },
          {
            areaCode: 370725000000,
            label: "昌乐县",
            value: "昌乐县",
          },
          {
            areaCode: 370781000000,
            label: "青州市",
            value: "青州市",
          },
          {
            areaCode: 370782000000,
            label: "诸城市",
            value: "诸城市",
          },
          {
            areaCode: 370783000000,
            label: "寿光市",
            value: "寿光市",
          },
          {
            areaCode: 370784000000,
            label: "安丘市",
            value: "安丘市",
          },
          {
            areaCode: 370785000000,
            label: "高密市",
            value: "高密市",
          },
          {
            areaCode: 370786000000,
            label: "昌邑市",
            value: "昌邑市",
          },
        ],
        label: "潍坊市",
        value: "潍坊市",
      },
      {
        areaCode: 370800000000,
        children: [
          {
            areaCode: 370811000000,
            label: "任城区",
            value: "任城区",
          },
          {
            areaCode: 370812000000,
            label: "兖州区",
            value: "兖州区",
          },
          {
            areaCode: 370826000000,
            label: "微山县",
            value: "微山县",
          },
          {
            areaCode: 370827000000,
            label: "鱼台县",
            value: "鱼台县",
          },
          {
            areaCode: 370828000000,
            label: "金乡县",
            value: "金乡县",
          },
          {
            areaCode: 370829000000,
            label: "嘉祥县",
            value: "嘉祥县",
          },
          {
            areaCode: 370830000000,
            label: "汶上县",
            value: "汶上县",
          },
          {
            areaCode: 370831000000,
            label: "泗水县",
            value: "泗水县",
          },
          {
            areaCode: 370832000000,
            label: "梁山县",
            value: "梁山县",
          },
          {
            areaCode: 370881000000,
            label: "曲阜市",
            value: "曲阜市",
          },
          {
            areaCode: 370883000000,
            label: "邹城市",
            value: "邹城市",
          },
        ],
        label: "济宁市",
        value: "济宁市",
      },
      {
        areaCode: 370900000000,
        children: [
          {
            areaCode: 370902000000,
            label: "泰山区",
            value: "泰山区",
          },
          {
            areaCode: 370911000000,
            label: "岱岳区",
            value: "岱岳区",
          },
          {
            areaCode: 370921000000,
            label: "宁阳县",
            value: "宁阳县",
          },
          {
            areaCode: 370923000000,
            label: "东平县",
            value: "东平县",
          },
          {
            areaCode: 370982000000,
            label: "新泰市",
            value: "新泰市",
          },
          {
            areaCode: 370983000000,
            label: "肥城市",
            value: "肥城市",
          },
        ],
        label: "泰安市",
        value: "泰安市",
      },
      {
        areaCode: 371000000000,
        children: [
          {
            areaCode: 371002000000,
            label: "环翠区",
            value: "环翠区",
          },
          {
            areaCode: 371003000000,
            label: "文登区",
            value: "文登区",
          },
          {
            areaCode: 371082000000,
            label: "荣成市",
            value: "荣成市",
          },
          {
            areaCode: 371083000000,
            label: "乳山市",
            value: "乳山市",
          },
        ],
        label: "威海市",
        value: "威海市",
      },
      {
        areaCode: 371100000000,
        children: [
          {
            areaCode: 371102000000,
            label: "东港区",
            value: "东港区",
          },
          {
            areaCode: 371103000000,
            label: "岚山区",
            value: "岚山区",
          },
          {
            areaCode: 371121000000,
            label: "五莲县",
            value: "五莲县",
          },
          {
            areaCode: 371122000000,
            label: "莒县",
            value: "莒县",
          },
        ],
        label: "日照市",
        value: "日照市",
      },
      {
        areaCode: 371200000000,
        children: [
          {
            areaCode: 371202000000,
            label: "莱城区",
            value: "莱城区",
          },
          {
            areaCode: 371203000000,
            label: "钢城区",
            value: "钢城区",
          },
        ],
        label: "莱芜市",
        value: "莱芜市",
      },
      {
        areaCode: 371300000000,
        children: [
          {
            areaCode: 371302000000,
            label: "兰山区",
            value: "兰山区",
          },
          {
            areaCode: 371311000000,
            label: "罗庄区",
            value: "罗庄区",
          },
          {
            areaCode: 371312000000,
            label: "河东区",
            value: "河东区",
          },
          {
            areaCode: 371321000000,
            label: "沂南县",
            value: "沂南县",
          },
          {
            areaCode: 371322000000,
            label: "郯城县",
            value: "郯城县",
          },
          {
            areaCode: 371323000000,
            label: "沂水县",
            value: "沂水县",
          },
          {
            areaCode: 371324000000,
            label: "兰陵县",
            value: "兰陵县",
          },
          {
            areaCode: 371325000000,
            label: "费县",
            value: "费县",
          },
          {
            areaCode: 371326000000,
            label: "平邑县",
            value: "平邑县",
          },
          {
            areaCode: 371327000000,
            label: "莒南县",
            value: "莒南县",
          },
          {
            areaCode: 371328000000,
            label: "蒙阴县",
            value: "蒙阴县",
          },
          {
            areaCode: 371329000000,
            label: "临沭县",
            value: "临沭县",
          },
        ],
        label: "临沂市",
        value: "临沂市",
      },
      {
        areaCode: 371400000000,
        children: [
          {
            areaCode: 371402000000,
            label: "德城区",
            value: "德城区",
          },
          {
            areaCode: 371403000000,
            label: "陵城区",
            value: "陵城区",
          },
          {
            areaCode: 371422000000,
            label: "宁津县",
            value: "宁津县",
          },
          {
            areaCode: 371423000000,
            label: "庆云县",
            value: "庆云县",
          },
          {
            areaCode: 371424000000,
            label: "临邑县",
            value: "临邑县",
          },
          {
            areaCode: 371425000000,
            label: "齐河县",
            value: "齐河县",
          },
          {
            areaCode: 371426000000,
            label: "平原县",
            value: "平原县",
          },
          {
            areaCode: 371427000000,
            label: "夏津县",
            value: "夏津县",
          },
          {
            areaCode: 371428000000,
            label: "武城县",
            value: "武城县",
          },
          {
            areaCode: 371481000000,
            label: "乐陵市",
            value: "乐陵市",
          },
          {
            areaCode: 371482000000,
            label: "禹城市",
            value: "禹城市",
          },
        ],
        label: "德州市",
        value: "德州市",
      },
      {
        areaCode: 371500000000,
        children: [
          {
            areaCode: 371502000000,
            label: "东昌府区",
            value: "东昌府区",
          },
          {
            areaCode: 371521000000,
            label: "阳谷县",
            value: "阳谷县",
          },
          {
            areaCode: 371522000000,
            label: "莘县",
            value: "莘县",
          },
          {
            areaCode: 371523000000,
            label: "茌平县",
            value: "茌平县",
          },
          {
            areaCode: 371524000000,
            label: "东阿县",
            value: "东阿县",
          },
          {
            areaCode: 371525000000,
            label: "冠县",
            value: "冠县",
          },
          {
            areaCode: 371526000000,
            label: "高唐县",
            value: "高唐县",
          },
          {
            areaCode: 371581000000,
            label: "临清市",
            value: "临清市",
          },
        ],
        label: "聊城市",
        value: "聊城市",
      },
      {
        areaCode: 371600000000,
        children: [
          {
            areaCode: 371602000000,
            label: "滨城区",
            value: "滨城区",
          },
          {
            areaCode: 371603000000,
            label: "沾化区",
            value: "沾化区",
          },
          {
            areaCode: 371621000000,
            label: "惠民县",
            value: "惠民县",
          },
          {
            areaCode: 371622000000,
            label: "阳信县",
            value: "阳信县",
          },
          {
            areaCode: 371623000000,
            label: "无棣县",
            value: "无棣县",
          },
          {
            areaCode: 371625000000,
            label: "博兴县",
            value: "博兴县",
          },
          {
            areaCode: 371626000000,
            label: "邹平县",
            value: "邹平县",
          },
        ],
        label: "滨州市",
        value: "滨州市",
      },
      {
        areaCode: 371700000000,
        children: [
          {
            areaCode: 371702000000,
            label: "牡丹区",
            value: "牡丹区",
          },
          {
            areaCode: 371703000000,
            label: "定陶区",
            value: "定陶区",
          },
          {
            areaCode: 371721000000,
            label: "曹县",
            value: "曹县",
          },
          {
            areaCode: 371722000000,
            label: "单县",
            value: "单县",
          },
          {
            areaCode: 371723000000,
            label: "成武县",
            value: "成武县",
          },
          {
            areaCode: 371724000000,
            label: "巨野县",
            value: "巨野县",
          },
          {
            areaCode: 371725000000,
            label: "郓城县",
            value: "郓城县",
          },
          {
            areaCode: 371726000000,
            label: "鄄城县",
            value: "鄄城县",
          },
          {
            areaCode: 371728000000,
            label: "东明县",
            value: "东明县",
          },
        ],
        label: "菏泽市",
        value: "菏泽市",
      },
    ],
    label: "山东省",
    value: "山东省",
  },
  {
    areaCode: 410000000000,
    children: [
      {
        areaCode: 410100000000,
        children: [
          {
            areaCode: 410102000000,
            label: "中原区",
            value: "中原区",
          },
          {
            areaCode: 410103000000,
            label: "二七区",
            value: "二七区",
          },
          {
            areaCode: 410104000000,
            label: "管城回族区",
            value: "管城回族区",
          },
          {
            areaCode: 410105000000,
            label: "金水区",
            value: "金水区",
          },
          {
            areaCode: 410106000000,
            label: "上街区",
            value: "上街区",
          },
          {
            areaCode: 410108000000,
            label: "惠济区",
            value: "惠济区",
          },
          {
            areaCode: 410122000000,
            label: "中牟县",
            value: "中牟县",
          },
          {
            areaCode: 410181000000,
            label: "巩义市",
            value: "巩义市",
          },
          {
            areaCode: 410182000000,
            label: "荥阳市",
            value: "荥阳市",
          },
          {
            areaCode: 410183000000,
            label: "新密市",
            value: "新密市",
          },
          {
            areaCode: 410184000000,
            label: "新郑市",
            value: "新郑市",
          },
          {
            areaCode: 410185000000,
            label: "登封市",
            value: "登封市",
          },
        ],
        label: "郑州市",
        value: "郑州市",
      },
      {
        areaCode: 410200000000,
        children: [
          {
            areaCode: 410202000000,
            label: "龙亭区",
            value: "龙亭区",
          },
          {
            areaCode: 410203000000,
            label: "顺河回族区",
            value: "顺河回族区",
          },
          {
            areaCode: 410204000000,
            label: "鼓楼区",
            value: "鼓楼区",
          },
          {
            areaCode: 410205000000,
            label: "禹王台区",
            value: "禹王台区",
          },
          {
            areaCode: 410211000000,
            label: "金明区",
            value: "金明区",
          },
          {
            areaCode: 410212000000,
            label: "祥符区",
            value: "祥符区",
          },
          {
            areaCode: 410221000000,
            label: "杞县",
            value: "杞县",
          },
          {
            areaCode: 410222000000,
            label: "通许县",
            value: "通许县",
          },
          {
            areaCode: 410223000000,
            label: "尉氏县",
            value: "尉氏县",
          },
          {
            areaCode: 410225000000,
            label: "兰考县",
            value: "兰考县",
          },
        ],
        label: "开封市",
        value: "开封市",
      },
      {
        areaCode: 410300000000,
        children: [
          {
            areaCode: 410302000000,
            label: "老城区",
            value: "老城区",
          },
          {
            areaCode: 410303000000,
            label: "西工区",
            value: "西工区",
          },
          {
            areaCode: 410304000000,
            label: "瀍河回族区",
            value: "瀍河回族区",
          },
          {
            areaCode: 410305000000,
            label: "涧西区",
            value: "涧西区",
          },
          {
            areaCode: 410306000000,
            label: "吉利区",
            value: "吉利区",
          },
          {
            areaCode: 410311000000,
            label: "洛龙区",
            value: "洛龙区",
          },
          {
            areaCode: 410322000000,
            label: "孟津县",
            value: "孟津县",
          },
          {
            areaCode: 410323000000,
            label: "新安县",
            value: "新安县",
          },
          {
            areaCode: 410324000000,
            label: "栾川县",
            value: "栾川县",
          },
          {
            areaCode: 410325000000,
            label: "嵩县",
            value: "嵩县",
          },
          {
            areaCode: 410326000000,
            label: "汝阳县",
            value: "汝阳县",
          },
          {
            areaCode: 410327000000,
            label: "宜阳县",
            value: "宜阳县",
          },
          {
            areaCode: 410328000000,
            label: "洛宁县",
            value: "洛宁县",
          },
          {
            areaCode: 410329000000,
            label: "伊川县",
            value: "伊川县",
          },
          {
            areaCode: 410381000000,
            label: "偃师市",
            value: "偃师市",
          },
        ],
        label: "洛阳市",
        value: "洛阳市",
      },
      {
        areaCode: 410400000000,
        children: [
          {
            areaCode: 410402000000,
            label: "新华区",
            value: "新华区",
          },
          {
            areaCode: 410403000000,
            label: "卫东区",
            value: "卫东区",
          },
          {
            areaCode: 410404000000,
            label: "石龙区",
            value: "石龙区",
          },
          {
            areaCode: 410411000000,
            label: "湛河区",
            value: "湛河区",
          },
          {
            areaCode: 410421000000,
            label: "宝丰县",
            value: "宝丰县",
          },
          {
            areaCode: 410422000000,
            label: "叶县",
            value: "叶县",
          },
          {
            areaCode: 410423000000,
            label: "鲁山县",
            value: "鲁山县",
          },
          {
            areaCode: 410425000000,
            label: "郏县",
            value: "郏县",
          },
          {
            areaCode: 410481000000,
            label: "舞钢市",
            value: "舞钢市",
          },
          {
            areaCode: 410482000000,
            label: "汝州市",
            value: "汝州市",
          },
        ],
        label: "平顶山市",
        value: "平顶山市",
      },
      {
        areaCode: 410500000000,
        children: [
          {
            areaCode: 410502000000,
            label: "文峰区",
            value: "文峰区",
          },
          {
            areaCode: 410503000000,
            label: "北关区",
            value: "北关区",
          },
          {
            areaCode: 410505000000,
            label: "殷都区",
            value: "殷都区",
          },
          {
            areaCode: 410506000000,
            label: "龙安区",
            value: "龙安区",
          },
          {
            areaCode: 410522000000,
            label: "安阳县",
            value: "安阳县",
          },
          {
            areaCode: 410523000000,
            label: "汤阴县",
            value: "汤阴县",
          },
          {
            areaCode: 410526000000,
            label: "滑县",
            value: "滑县",
          },
          {
            areaCode: 410527000000,
            label: "内黄县",
            value: "内黄县",
          },
          {
            areaCode: 410581000000,
            label: "林州市",
            value: "林州市",
          },
        ],
        label: "安阳市",
        value: "安阳市",
      },
      {
        areaCode: 410600000000,
        children: [
          {
            areaCode: 410602000000,
            label: "鹤山区",
            value: "鹤山区",
          },
          {
            areaCode: 410603000000,
            label: "山城区",
            value: "山城区",
          },
          {
            areaCode: 410611000000,
            label: "淇滨区",
            value: "淇滨区",
          },
          {
            areaCode: 410621000000,
            label: "浚县",
            value: "浚县",
          },
          {
            areaCode: 410622000000,
            label: "淇县",
            value: "淇县",
          },
        ],
        label: "鹤壁市",
        value: "鹤壁市",
      },
      {
        areaCode: 410700000000,
        children: [
          {
            areaCode: 410702000000,
            label: "红旗区",
            value: "红旗区",
          },
          {
            areaCode: 410703000000,
            label: "卫滨区",
            value: "卫滨区",
          },
          {
            areaCode: 410704000000,
            label: "凤泉区",
            value: "凤泉区",
          },
          {
            areaCode: 410711000000,
            label: "牧野区",
            value: "牧野区",
          },
          {
            areaCode: 410721000000,
            label: "新乡县",
            value: "新乡县",
          },
          {
            areaCode: 410724000000,
            label: "获嘉县",
            value: "获嘉县",
          },
          {
            areaCode: 410725000000,
            label: "原阳县",
            value: "原阳县",
          },
          {
            areaCode: 410726000000,
            label: "延津县",
            value: "延津县",
          },
          {
            areaCode: 410727000000,
            label: "封丘县",
            value: "封丘县",
          },
          {
            areaCode: 410728000000,
            label: "长垣县",
            value: "长垣县",
          },
          {
            areaCode: 410781000000,
            label: "卫辉市",
            value: "卫辉市",
          },
          {
            areaCode: 410782000000,
            label: "辉县市",
            value: "辉县市",
          },
        ],
        label: "新乡市",
        value: "新乡市",
      },
      {
        areaCode: 410800000000,
        children: [
          {
            areaCode: 410802000000,
            label: "解放区",
            value: "解放区",
          },
          {
            areaCode: 410803000000,
            label: "中站区",
            value: "中站区",
          },
          {
            areaCode: 410804000000,
            label: "马村区",
            value: "马村区",
          },
          {
            areaCode: 410811000000,
            label: "山阳区",
            value: "山阳区",
          },
          {
            areaCode: 410821000000,
            label: "修武县",
            value: "修武县",
          },
          {
            areaCode: 410822000000,
            label: "博爱县",
            value: "博爱县",
          },
          {
            areaCode: 410823000000,
            label: "武陟县",
            value: "武陟县",
          },
          {
            areaCode: 410825000000,
            label: "温县",
            value: "温县",
          },
          {
            areaCode: 410882000000,
            label: "沁阳市",
            value: "沁阳市",
          },
          {
            areaCode: 410883000000,
            label: "孟州市",
            value: "孟州市",
          },
        ],
        label: "焦作市",
        value: "焦作市",
      },
      {
        areaCode: 410900000000,
        children: [
          {
            areaCode: 410902000000,
            label: "华龙区",
            value: "华龙区",
          },
          {
            areaCode: 410922000000,
            label: "清丰县",
            value: "清丰县",
          },
          {
            areaCode: 410923000000,
            label: "南乐县",
            value: "南乐县",
          },
          {
            areaCode: 410926000000,
            label: "范县",
            value: "范县",
          },
          {
            areaCode: 410927000000,
            label: "台前县",
            value: "台前县",
          },
          {
            areaCode: 410928000000,
            label: "濮阳县",
            value: "濮阳县",
          },
        ],
        label: "濮阳市",
        value: "濮阳市",
      },
      {
        areaCode: 411000000000,
        children: [
          {
            areaCode: 411002000000,
            label: "魏都区",
            value: "魏都区",
          },
          {
            areaCode: 411023000000,
            label: "许昌县",
            value: "许昌县",
          },
          {
            areaCode: 411024000000,
            label: "鄢陵县",
            value: "鄢陵县",
          },
          {
            areaCode: 411025000000,
            label: "襄城县",
            value: "襄城县",
          },
          {
            areaCode: 411081000000,
            label: "禹州市",
            value: "禹州市",
          },
          {
            areaCode: 411082000000,
            label: "长葛市",
            value: "长葛市",
          },
        ],
        label: "许昌市",
        value: "许昌市",
      },
      {
        areaCode: 411100000000,
        children: [
          {
            areaCode: 411102000000,
            label: "源汇区",
            value: "源汇区",
          },
          {
            areaCode: 411103000000,
            label: "郾城区",
            value: "郾城区",
          },
          {
            areaCode: 411104000000,
            label: "召陵区",
            value: "召陵区",
          },
          {
            areaCode: 411121000000,
            label: "舞阳县",
            value: "舞阳县",
          },
          {
            areaCode: 411122000000,
            label: "临颍县",
            value: "临颍县",
          },
        ],
        label: "漯河市",
        value: "漯河市",
      },
      {
        areaCode: 411200000000,
        children: [
          {
            areaCode: 411202000000,
            label: "湖滨区",
            value: "湖滨区",
          },
          {
            areaCode: 411203000000,
            label: "陕州区",
            value: "陕州区",
          },
          {
            areaCode: 411221000000,
            label: "渑池县",
            value: "渑池县",
          },
          {
            areaCode: 411224000000,
            label: "卢氏县",
            value: "卢氏县",
          },
          {
            areaCode: 411281000000,
            label: "义马市",
            value: "义马市",
          },
          {
            areaCode: 411282000000,
            label: "灵宝市",
            value: "灵宝市",
          },
        ],
        label: "三门峡市",
        value: "三门峡市",
      },
      {
        areaCode: 411300000000,
        children: [
          {
            areaCode: 411302000000,
            label: "宛城区",
            value: "宛城区",
          },
          {
            areaCode: 411303000000,
            label: "卧龙区",
            value: "卧龙区",
          },
          {
            areaCode: 411321000000,
            label: "南召县",
            value: "南召县",
          },
          {
            areaCode: 411322000000,
            label: "方城县",
            value: "方城县",
          },
          {
            areaCode: 411323000000,
            label: "西峡县",
            value: "西峡县",
          },
          {
            areaCode: 411324000000,
            label: "镇平县",
            value: "镇平县",
          },
          {
            areaCode: 411325000000,
            label: "内乡县",
            value: "内乡县",
          },
          {
            areaCode: 411326000000,
            label: "淅川县",
            value: "淅川县",
          },
          {
            areaCode: 411327000000,
            label: "社旗县",
            value: "社旗县",
          },
          {
            areaCode: 411328000000,
            label: "唐河县",
            value: "唐河县",
          },
          {
            areaCode: 411329000000,
            label: "新野县",
            value: "新野县",
          },
          {
            areaCode: 411330000000,
            label: "桐柏县",
            value: "桐柏县",
          },
          {
            areaCode: 411381000000,
            label: "邓州市",
            value: "邓州市",
          },
        ],
        label: "南阳市",
        value: "南阳市",
      },
      {
        areaCode: 411400000000,
        children: [
          {
            areaCode: 411402000000,
            label: "梁园区",
            value: "梁园区",
          },
          {
            areaCode: 411403000000,
            label: "睢阳区",
            value: "睢阳区",
          },
          {
            areaCode: 411421000000,
            label: "民权县",
            value: "民权县",
          },
          {
            areaCode: 411422000000,
            label: "睢县",
            value: "睢县",
          },
          {
            areaCode: 411423000000,
            label: "宁陵县",
            value: "宁陵县",
          },
          {
            areaCode: 411424000000,
            label: "柘城县",
            value: "柘城县",
          },
          {
            areaCode: 411425000000,
            label: "虞城县",
            value: "虞城县",
          },
          {
            areaCode: 411426000000,
            label: "夏邑县",
            value: "夏邑县",
          },
          {
            areaCode: 411481000000,
            label: "永城市",
            value: "永城市",
          },
        ],
        label: "商丘市",
        value: "商丘市",
      },
      {
        areaCode: 411500000000,
        children: [
          {
            areaCode: 411502000000,
            label: "浉河区",
            value: "浉河区",
          },
          {
            areaCode: 411503000000,
            label: "平桥区",
            value: "平桥区",
          },
          {
            areaCode: 411521000000,
            label: "罗山县",
            value: "罗山县",
          },
          {
            areaCode: 411522000000,
            label: "光山县",
            value: "光山县",
          },
          {
            areaCode: 411523000000,
            label: "新县",
            value: "新县",
          },
          {
            areaCode: 411524000000,
            label: "商城县",
            value: "商城县",
          },
          {
            areaCode: 411525000000,
            label: "固始县",
            value: "固始县",
          },
          {
            areaCode: 411526000000,
            label: "潢川县",
            value: "潢川县",
          },
          {
            areaCode: 411527000000,
            label: "淮滨县",
            value: "淮滨县",
          },
          {
            areaCode: 411528000000,
            label: "息县",
            value: "息县",
          },
        ],
        label: "信阳市",
        value: "信阳市",
      },
      {
        areaCode: 411600000000,
        children: [
          {
            areaCode: 411602000000,
            label: "川汇区",
            value: "川汇区",
          },
          {
            areaCode: 411621000000,
            label: "扶沟县",
            value: "扶沟县",
          },
          {
            areaCode: 411622000000,
            label: "西华县",
            value: "西华县",
          },
          {
            areaCode: 411623000000,
            label: "商水县",
            value: "商水县",
          },
          {
            areaCode: 411624000000,
            label: "沈丘县",
            value: "沈丘县",
          },
          {
            areaCode: 411625000000,
            label: "郸城县",
            value: "郸城县",
          },
          {
            areaCode: 411626000000,
            label: "淮阳县",
            value: "淮阳县",
          },
          {
            areaCode: 411627000000,
            label: "太康县",
            value: "太康县",
          },
          {
            areaCode: 411628000000,
            label: "鹿邑县",
            value: "鹿邑县",
          },
          {
            areaCode: 411681000000,
            label: "项城市",
            value: "项城市",
          },
        ],
        label: "周口市",
        value: "周口市",
      },
      {
        areaCode: 411700000000,
        children: [
          {
            areaCode: 411702000000,
            label: "驿城区",
            value: "驿城区",
          },
          {
            areaCode: 411721000000,
            label: "西平县",
            value: "西平县",
          },
          {
            areaCode: 411722000000,
            label: "上蔡县",
            value: "上蔡县",
          },
          {
            areaCode: 411723000000,
            label: "平舆县",
            value: "平舆县",
          },
          {
            areaCode: 411724000000,
            label: "正阳县",
            value: "正阳县",
          },
          {
            areaCode: 411725000000,
            label: "确山县",
            value: "确山县",
          },
          {
            areaCode: 411726000000,
            label: "泌阳县",
            value: "泌阳县",
          },
          {
            areaCode: 411727000000,
            label: "汝南县",
            value: "汝南县",
          },
          {
            areaCode: 411728000000,
            label: "遂平县",
            value: "遂平县",
          },
          {
            areaCode: 411729000000,
            label: "新蔡县",
            value: "新蔡县",
          },
        ],
        label: "驻马店市",
        value: "驻马店市",
      },
      {
        areaCode: 419000000000,
        children: [
          {
            areaCode: 419001000000,
            label: "济源市",
            value: "济源市",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "河南省",
    value: "河南省",
  },
  {
    areaCode: 420000000000,
    children: [
      {
        areaCode: 420100000000,
        children: [
          {
            areaCode: 420102000000,
            label: "江岸区",
            value: "江岸区",
          },
          {
            areaCode: 420103000000,
            label: "江汉区",
            value: "江汉区",
          },
          {
            areaCode: 420104000000,
            label: "硚口区",
            value: "硚口区",
          },
          {
            areaCode: 420105000000,
            label: "汉阳区",
            value: "汉阳区",
          },
          {
            areaCode: 420106000000,
            label: "武昌区",
            value: "武昌区",
          },
          {
            areaCode: 420107000000,
            label: "青山区",
            value: "青山区",
          },
          {
            areaCode: 420111000000,
            label: "洪山区",
            value: "洪山区",
          },
          {
            areaCode: 420112000000,
            label: "东西湖区",
            value: "东西湖区",
          },
          {
            areaCode: 420113000000,
            label: "汉南区",
            value: "汉南区",
          },
          {
            areaCode: 420114000000,
            label: "蔡甸区",
            value: "蔡甸区",
          },
          {
            areaCode: 420115000000,
            label: "江夏区",
            value: "江夏区",
          },
          {
            areaCode: 420116000000,
            label: "黄陂区",
            value: "黄陂区",
          },
          {
            areaCode: 420117000000,
            label: "新洲区",
            value: "新洲区",
          },
        ],
        label: "武汉市",
        value: "武汉市",
      },
      {
        areaCode: 420200000000,
        children: [
          {
            areaCode: 420202000000,
            label: "黄石港区",
            value: "黄石港区",
          },
          {
            areaCode: 420203000000,
            label: "西塞山区",
            value: "西塞山区",
          },
          {
            areaCode: 420204000000,
            label: "下陆区",
            value: "下陆区",
          },
          {
            areaCode: 420205000000,
            label: "铁山区",
            value: "铁山区",
          },
          {
            areaCode: 420222000000,
            label: "阳新县",
            value: "阳新县",
          },
          {
            areaCode: 420281000000,
            label: "大冶市",
            value: "大冶市",
          },
        ],
        label: "黄石市",
        value: "黄石市",
      },
      {
        areaCode: 420300000000,
        children: [
          {
            areaCode: 420302000000,
            label: "茅箭区",
            value: "茅箭区",
          },
          {
            areaCode: 420303000000,
            label: "张湾区",
            value: "张湾区",
          },
          {
            areaCode: 420304000000,
            label: "郧阳区",
            value: "郧阳区",
          },
          {
            areaCode: 420322000000,
            label: "郧西县",
            value: "郧西县",
          },
          {
            areaCode: 420323000000,
            label: "竹山县",
            value: "竹山县",
          },
          {
            areaCode: 420324000000,
            label: "竹溪县",
            value: "竹溪县",
          },
          {
            areaCode: 420325000000,
            label: "房县",
            value: "房县",
          },
          {
            areaCode: 420381000000,
            label: "丹江口市",
            value: "丹江口市",
          },
        ],
        label: "十堰市",
        value: "十堰市",
      },
      {
        areaCode: 420500000000,
        children: [
          {
            areaCode: 420502000000,
            label: "西陵区",
            value: "西陵区",
          },
          {
            areaCode: 420503000000,
            label: "伍家岗区",
            value: "伍家岗区",
          },
          {
            areaCode: 420504000000,
            label: "点军区",
            value: "点军区",
          },
          {
            areaCode: 420505000000,
            label: "猇亭区",
            value: "猇亭区",
          },
          {
            areaCode: 420506000000,
            label: "夷陵区",
            value: "夷陵区",
          },
          {
            areaCode: 420525000000,
            label: "远安县",
            value: "远安县",
          },
          {
            areaCode: 420526000000,
            label: "兴山县",
            value: "兴山县",
          },
          {
            areaCode: 420527000000,
            label: "秭归县",
            value: "秭归县",
          },
          {
            areaCode: 420528000000,
            label: "长阳土家族自治县",
            value: "长阳土家族自治县",
          },
          {
            areaCode: 420529000000,
            label: "五峰土家族自治县",
            value: "五峰土家族自治县",
          },
          {
            areaCode: 420581000000,
            label: "宜都市",
            value: "宜都市",
          },
          {
            areaCode: 420582000000,
            label: "当阳市",
            value: "当阳市",
          },
          {
            areaCode: 420583000000,
            label: "枝江市",
            value: "枝江市",
          },
        ],
        label: "宜昌市",
        value: "宜昌市",
      },
      {
        areaCode: 420600000000,
        children: [
          {
            areaCode: 420602000000,
            label: "襄城区",
            value: "襄城区",
          },
          {
            areaCode: 420606000000,
            label: "樊城区",
            value: "樊城区",
          },
          {
            areaCode: 420607000000,
            label: "襄州区",
            value: "襄州区",
          },
          {
            areaCode: 420624000000,
            label: "南漳县",
            value: "南漳县",
          },
          {
            areaCode: 420625000000,
            label: "谷城县",
            value: "谷城县",
          },
          {
            areaCode: 420626000000,
            label: "保康县",
            value: "保康县",
          },
          {
            areaCode: 420682000000,
            label: "老河口市",
            value: "老河口市",
          },
          {
            areaCode: 420683000000,
            label: "枣阳市",
            value: "枣阳市",
          },
          {
            areaCode: 420684000000,
            label: "宜城市",
            value: "宜城市",
          },
        ],
        label: "襄阳市",
        value: "襄阳市",
      },
      {
        areaCode: 420700000000,
        children: [
          {
            areaCode: 420702000000,
            label: "梁子湖区",
            value: "梁子湖区",
          },
          {
            areaCode: 420703000000,
            label: "华容区",
            value: "华容区",
          },
          {
            areaCode: 420704000000,
            label: "鄂城区",
            value: "鄂城区",
          },
        ],
        label: "鄂州市",
        value: "鄂州市",
      },
      {
        areaCode: 420800000000,
        children: [
          {
            areaCode: 420802000000,
            label: "东宝区",
            value: "东宝区",
          },
          {
            areaCode: 420804000000,
            label: "掇刀区",
            value: "掇刀区",
          },
          {
            areaCode: 420821000000,
            label: "京山县",
            value: "京山县",
          },
          {
            areaCode: 420822000000,
            label: "沙洋县",
            value: "沙洋县",
          },
          {
            areaCode: 420881000000,
            label: "钟祥市",
            value: "钟祥市",
          },
        ],
        label: "荆门市",
        value: "荆门市",
      },
      {
        areaCode: 420900000000,
        children: [
          {
            areaCode: 420902000000,
            label: "孝南区",
            value: "孝南区",
          },
          {
            areaCode: 420921000000,
            label: "孝昌县",
            value: "孝昌县",
          },
          {
            areaCode: 420922000000,
            label: "大悟县",
            value: "大悟县",
          },
          {
            areaCode: 420923000000,
            label: "云梦县",
            value: "云梦县",
          },
          {
            areaCode: 420981000000,
            label: "应城市",
            value: "应城市",
          },
          {
            areaCode: 420982000000,
            label: "安陆市",
            value: "安陆市",
          },
          {
            areaCode: 420984000000,
            label: "汉川市",
            value: "汉川市",
          },
        ],
        label: "孝感市",
        value: "孝感市",
      },
      {
        areaCode: 421000000000,
        children: [
          {
            areaCode: 421002000000,
            label: "沙市区",
            value: "沙市区",
          },
          {
            areaCode: 421003000000,
            label: "荆州区",
            value: "荆州区",
          },
          {
            areaCode: 421022000000,
            label: "公安县",
            value: "公安县",
          },
          {
            areaCode: 421023000000,
            label: "监利县",
            value: "监利县",
          },
          {
            areaCode: 421024000000,
            label: "江陵县",
            value: "江陵县",
          },
          {
            areaCode: 421081000000,
            label: "石首市",
            value: "石首市",
          },
          {
            areaCode: 421083000000,
            label: "洪湖市",
            value: "洪湖市",
          },
          {
            areaCode: 421087000000,
            label: "松滋市",
            value: "松滋市",
          },
        ],
        label: "荆州市",
        value: "荆州市",
      },
      {
        areaCode: 421100000000,
        children: [
          {
            areaCode: 421102000000,
            label: "黄州区",
            value: "黄州区",
          },
          {
            areaCode: 421121000000,
            label: "团风县",
            value: "团风县",
          },
          {
            areaCode: 421122000000,
            label: "红安县",
            value: "红安县",
          },
          {
            areaCode: 421123000000,
            label: "罗田县",
            value: "罗田县",
          },
          {
            areaCode: 421124000000,
            label: "英山县",
            value: "英山县",
          },
          {
            areaCode: 421125000000,
            label: "浠水县",
            value: "浠水县",
          },
          {
            areaCode: 421126000000,
            label: "蕲春县",
            value: "蕲春县",
          },
          {
            areaCode: 421127000000,
            label: "黄梅县",
            value: "黄梅县",
          },
          {
            areaCode: 421181000000,
            label: "麻城市",
            value: "麻城市",
          },
          {
            areaCode: 421182000000,
            label: "武穴市",
            value: "武穴市",
          },
        ],
        label: "黄冈市",
        value: "黄冈市",
      },
      {
        areaCode: 421200000000,
        children: [
          {
            areaCode: 421202000000,
            label: "咸安区",
            value: "咸安区",
          },
          {
            areaCode: 421221000000,
            label: "嘉鱼县",
            value: "嘉鱼县",
          },
          {
            areaCode: 421222000000,
            label: "通城县",
            value: "通城县",
          },
          {
            areaCode: 421223000000,
            label: "崇阳县",
            value: "崇阳县",
          },
          {
            areaCode: 421224000000,
            label: "通山县",
            value: "通山县",
          },
          {
            areaCode: 421281000000,
            label: "赤壁市",
            value: "赤壁市",
          },
        ],
        label: "咸宁市",
        value: "咸宁市",
      },
      {
        areaCode: 421300000000,
        children: [
          {
            areaCode: 421303000000,
            label: "曾都区",
            value: "曾都区",
          },
          {
            areaCode: 421321000000,
            label: "随县",
            value: "随县",
          },
          {
            areaCode: 421381000000,
            label: "广水市",
            value: "广水市",
          },
        ],
        label: "随州市",
        value: "随州市",
      },
      {
        areaCode: 422800000000,
        children: [
          {
            areaCode: 422801000000,
            label: "恩施市",
            value: "恩施市",
          },
          {
            areaCode: 422802000000,
            label: "利川市",
            value: "利川市",
          },
          {
            areaCode: 422822000000,
            label: "建始县",
            value: "建始县",
          },
          {
            areaCode: 422823000000,
            label: "巴东县",
            value: "巴东县",
          },
          {
            areaCode: 422825000000,
            label: "宣恩县",
            value: "宣恩县",
          },
          {
            areaCode: 422826000000,
            label: "咸丰县",
            value: "咸丰县",
          },
          {
            areaCode: 422827000000,
            label: "来凤县",
            value: "来凤县",
          },
          {
            areaCode: 422828000000,
            label: "鹤峰县",
            value: "鹤峰县",
          },
        ],
        label: "恩施土家族苗族自治州",
        value: "恩施土家族苗族自治州",
      },
      {
        areaCode: 429000000000,
        children: [
          {
            areaCode: 429004000000,
            label: "仙桃市",
            value: "仙桃市",
          },
          {
            areaCode: 429005000000,
            label: "潜江市",
            value: "潜江市",
          },
          {
            areaCode: 429006000000,
            label: "天门市",
            value: "天门市",
          },
          {
            areaCode: 429021000000,
            label: "神农架林区",
            value: "神农架林区",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "湖北省",
    value: "湖北省",
  },
  {
    areaCode: 430000000000,
    children: [
      {
        areaCode: 430100000000,
        children: [
          {
            areaCode: 430102000000,
            label: "芙蓉区",
            value: "芙蓉区",
          },
          {
            areaCode: 430103000000,
            label: "天心区",
            value: "天心区",
          },
          {
            areaCode: 430104000000,
            label: "岳麓区",
            value: "岳麓区",
          },
          {
            areaCode: 430105000000,
            label: "开福区",
            value: "开福区",
          },
          {
            areaCode: 430111000000,
            label: "雨花区",
            value: "雨花区",
          },
          {
            areaCode: 430112000000,
            label: "望城区",
            value: "望城区",
          },
          {
            areaCode: 430121000000,
            label: "长沙县",
            value: "长沙县",
          },
          {
            areaCode: 430124000000,
            label: "宁乡县",
            value: "宁乡县",
          },
          {
            areaCode: 430181000000,
            label: "浏阳市",
            value: "浏阳市",
          },
        ],
        label: "长沙市",
        value: "长沙市",
      },
      {
        areaCode: 430200000000,
        children: [
          {
            areaCode: 430202000000,
            label: "荷塘区",
            value: "荷塘区",
          },
          {
            areaCode: 430203000000,
            label: "芦淞区",
            value: "芦淞区",
          },
          {
            areaCode: 430204000000,
            label: "石峰区",
            value: "石峰区",
          },
          {
            areaCode: 430211000000,
            label: "天元区",
            value: "天元区",
          },
          {
            areaCode: 430221000000,
            label: "株洲县",
            value: "株洲县",
          },
          {
            areaCode: 430223000000,
            label: "攸县",
            value: "攸县",
          },
          {
            areaCode: 430224000000,
            label: "茶陵县",
            value: "茶陵县",
          },
          {
            areaCode: 430225000000,
            label: "炎陵县",
            value: "炎陵县",
          },
          {
            areaCode: 430281000000,
            label: "醴陵市",
            value: "醴陵市",
          },
        ],
        label: "株洲市",
        value: "株洲市",
      },
      {
        areaCode: 430300000000,
        children: [
          {
            areaCode: 430302000000,
            label: "雨湖区",
            value: "雨湖区",
          },
          {
            areaCode: 430304000000,
            label: "岳塘区",
            value: "岳塘区",
          },
          {
            areaCode: 430321000000,
            label: "湘潭县",
            value: "湘潭县",
          },
          {
            areaCode: 430381000000,
            label: "湘乡市",
            value: "湘乡市",
          },
          {
            areaCode: 430382000000,
            label: "韶山市",
            value: "韶山市",
          },
        ],
        label: "湘潭市",
        value: "湘潭市",
      },
      {
        areaCode: 430400000000,
        children: [
          {
            areaCode: 430405000000,
            label: "珠晖区",
            value: "珠晖区",
          },
          {
            areaCode: 430406000000,
            label: "雁峰区",
            value: "雁峰区",
          },
          {
            areaCode: 430407000000,
            label: "石鼓区",
            value: "石鼓区",
          },
          {
            areaCode: 430408000000,
            label: "蒸湘区",
            value: "蒸湘区",
          },
          {
            areaCode: 430412000000,
            label: "南岳区",
            value: "南岳区",
          },
          {
            areaCode: 430421000000,
            label: "衡阳县",
            value: "衡阳县",
          },
          {
            areaCode: 430422000000,
            label: "衡南县",
            value: "衡南县",
          },
          {
            areaCode: 430423000000,
            label: "衡山县",
            value: "衡山县",
          },
          {
            areaCode: 430424000000,
            label: "衡东县",
            value: "衡东县",
          },
          {
            areaCode: 430426000000,
            label: "祁东县",
            value: "祁东县",
          },
          {
            areaCode: 430481000000,
            label: "耒阳市",
            value: "耒阳市",
          },
          {
            areaCode: 430482000000,
            label: "常宁市",
            value: "常宁市",
          },
        ],
        label: "衡阳市",
        value: "衡阳市",
      },
      {
        areaCode: 430500000000,
        children: [
          {
            areaCode: 430502000000,
            label: "双清区",
            value: "双清区",
          },
          {
            areaCode: 430503000000,
            label: "大祥区",
            value: "大祥区",
          },
          {
            areaCode: 430511000000,
            label: "北塔区",
            value: "北塔区",
          },
          {
            areaCode: 430521000000,
            label: "邵东县",
            value: "邵东县",
          },
          {
            areaCode: 430522000000,
            label: "新邵县",
            value: "新邵县",
          },
          {
            areaCode: 430523000000,
            label: "邵阳县",
            value: "邵阳县",
          },
          {
            areaCode: 430524000000,
            label: "隆回县",
            value: "隆回县",
          },
          {
            areaCode: 430525000000,
            label: "洞口县",
            value: "洞口县",
          },
          {
            areaCode: 430527000000,
            label: "绥宁县",
            value: "绥宁县",
          },
          {
            areaCode: 430528000000,
            label: "新宁县",
            value: "新宁县",
          },
          {
            areaCode: 430529000000,
            label: "城步苗族自治县",
            value: "城步苗族自治县",
          },
          {
            areaCode: 430581000000,
            label: "武冈市",
            value: "武冈市",
          },
        ],
        label: "邵阳市",
        value: "邵阳市",
      },
      {
        areaCode: 430600000000,
        children: [
          {
            areaCode: 430602000000,
            label: "岳阳楼区",
            value: "岳阳楼区",
          },
          {
            areaCode: 430603000000,
            label: "云溪区",
            value: "云溪区",
          },
          {
            areaCode: 430611000000,
            label: "君山区",
            value: "君山区",
          },
          {
            areaCode: 430621000000,
            label: "岳阳县",
            value: "岳阳县",
          },
          {
            areaCode: 430623000000,
            label: "华容县",
            value: "华容县",
          },
          {
            areaCode: 430624000000,
            label: "湘阴县",
            value: "湘阴县",
          },
          {
            areaCode: 430626000000,
            label: "平江县",
            value: "平江县",
          },
          {
            areaCode: 430681000000,
            label: "汨罗市",
            value: "汨罗市",
          },
          {
            areaCode: 430682000000,
            label: "临湘市",
            value: "临湘市",
          },
        ],
        label: "岳阳市",
        value: "岳阳市",
      },
      {
        areaCode: 430700000000,
        children: [
          {
            areaCode: 430702000000,
            label: "武陵区",
            value: "武陵区",
          },
          {
            areaCode: 430703000000,
            label: "鼎城区",
            value: "鼎城区",
          },
          {
            areaCode: 430721000000,
            label: "安乡县",
            value: "安乡县",
          },
          {
            areaCode: 430722000000,
            label: "汉寿县",
            value: "汉寿县",
          },
          {
            areaCode: 430723000000,
            label: "澧县",
            value: "澧县",
          },
          {
            areaCode: 430724000000,
            label: "临澧县",
            value: "临澧县",
          },
          {
            areaCode: 430725000000,
            label: "桃源县",
            value: "桃源县",
          },
          {
            areaCode: 430726000000,
            label: "石门县",
            value: "石门县",
          },
          {
            areaCode: 430781000000,
            label: "津市市",
            value: "津市市",
          },
        ],
        label: "常德市",
        value: "常德市",
      },
      {
        areaCode: 430800000000,
        children: [
          {
            areaCode: 430802000000,
            label: "永定区",
            value: "永定区",
          },
          {
            areaCode: 430811000000,
            label: "武陵源区",
            value: "武陵源区",
          },
          {
            areaCode: 430821000000,
            label: "慈利县",
            value: "慈利县",
          },
          {
            areaCode: 430822000000,
            label: "桑植县",
            value: "桑植县",
          },
        ],
        label: "张家界市",
        value: "张家界市",
      },
      {
        areaCode: 430900000000,
        children: [
          {
            areaCode: 430902000000,
            label: "资阳区",
            value: "资阳区",
          },
          {
            areaCode: 430903000000,
            label: "赫山区",
            value: "赫山区",
          },
          {
            areaCode: 430921000000,
            label: "南县",
            value: "南县",
          },
          {
            areaCode: 430922000000,
            label: "桃江县",
            value: "桃江县",
          },
          {
            areaCode: 430923000000,
            label: "安化县",
            value: "安化县",
          },
          {
            areaCode: 430981000000,
            label: "沅江市",
            value: "沅江市",
          },
        ],
        label: "益阳市",
        value: "益阳市",
      },
      {
        areaCode: 431000000000,
        children: [
          {
            areaCode: 431002000000,
            label: "北湖区",
            value: "北湖区",
          },
          {
            areaCode: 431003000000,
            label: "苏仙区",
            value: "苏仙区",
          },
          {
            areaCode: 431021000000,
            label: "桂阳县",
            value: "桂阳县",
          },
          {
            areaCode: 431022000000,
            label: "宜章县",
            value: "宜章县",
          },
          {
            areaCode: 431023000000,
            label: "永兴县",
            value: "永兴县",
          },
          {
            areaCode: 431024000000,
            label: "嘉禾县",
            value: "嘉禾县",
          },
          {
            areaCode: 431025000000,
            label: "临武县",
            value: "临武县",
          },
          {
            areaCode: 431026000000,
            label: "汝城县",
            value: "汝城县",
          },
          {
            areaCode: 431027000000,
            label: "桂东县",
            value: "桂东县",
          },
          {
            areaCode: 431028000000,
            label: "安仁县",
            value: "安仁县",
          },
          {
            areaCode: 431081000000,
            label: "资兴市",
            value: "资兴市",
          },
        ],
        label: "郴州市",
        value: "郴州市",
      },
      {
        areaCode: 431100000000,
        children: [
          {
            areaCode: 431102000000,
            label: "零陵区",
            value: "零陵区",
          },
          {
            areaCode: 431103000000,
            label: "冷水滩区",
            value: "冷水滩区",
          },
          {
            areaCode: 431121000000,
            label: "祁阳县",
            value: "祁阳县",
          },
          {
            areaCode: 431122000000,
            label: "东安县",
            value: "东安县",
          },
          {
            areaCode: 431123000000,
            label: "双牌县",
            value: "双牌县",
          },
          {
            areaCode: 431124000000,
            label: "道县",
            value: "道县",
          },
          {
            areaCode: 431125000000,
            label: "江永县",
            value: "江永县",
          },
          {
            areaCode: 431126000000,
            label: "宁远县",
            value: "宁远县",
          },
          {
            areaCode: 431127000000,
            label: "蓝山县",
            value: "蓝山县",
          },
          {
            areaCode: 431128000000,
            label: "新田县",
            value: "新田县",
          },
          {
            areaCode: 431129000000,
            label: "江华瑶族自治县",
            value: "江华瑶族自治县",
          },
        ],
        label: "永州市",
        value: "永州市",
      },
      {
        areaCode: 431200000000,
        children: [
          {
            areaCode: 431202000000,
            label: "鹤城区",
            value: "鹤城区",
          },
          {
            areaCode: 431221000000,
            label: "中方县",
            value: "中方县",
          },
          {
            areaCode: 431222000000,
            label: "沅陵县",
            value: "沅陵县",
          },
          {
            areaCode: 431223000000,
            label: "辰溪县",
            value: "辰溪县",
          },
          {
            areaCode: 431224000000,
            label: "溆浦县",
            value: "溆浦县",
          },
          {
            areaCode: 431225000000,
            label: "会同县",
            value: "会同县",
          },
          {
            areaCode: 431226000000,
            label: "麻阳苗族自治县",
            value: "麻阳苗族自治县",
          },
          {
            areaCode: 431227000000,
            label: "新晃侗族自治县",
            value: "新晃侗族自治县",
          },
          {
            areaCode: 431228000000,
            label: "芷江侗族自治县",
            value: "芷江侗族自治县",
          },
          {
            areaCode: 431229000000,
            label: "靖州苗族侗族自治县",
            value: "靖州苗族侗族自治县",
          },
          {
            areaCode: 431230000000,
            label: "通道侗族自治县",
            value: "通道侗族自治县",
          },
          {
            areaCode: 431281000000,
            label: "洪江市",
            value: "洪江市",
          },
        ],
        label: "怀化市",
        value: "怀化市",
      },
      {
        areaCode: 431300000000,
        children: [
          {
            areaCode: 431302000000,
            label: "娄星区",
            value: "娄星区",
          },
          {
            areaCode: 431321000000,
            label: "双峰县",
            value: "双峰县",
          },
          {
            areaCode: 431322000000,
            label: "新化县",
            value: "新化县",
          },
          {
            areaCode: 431381000000,
            label: "冷水江市",
            value: "冷水江市",
          },
          {
            areaCode: 431382000000,
            label: "涟源市",
            value: "涟源市",
          },
        ],
        label: "娄底市",
        value: "娄底市",
      },
      {
        areaCode: 433100000000,
        children: [
          {
            areaCode: 433101000000,
            label: "吉首市",
            value: "吉首市",
          },
          {
            areaCode: 433122000000,
            label: "泸溪县",
            value: "泸溪县",
          },
          {
            areaCode: 433123000000,
            label: "凤凰县",
            value: "凤凰县",
          },
          {
            areaCode: 433124000000,
            label: "花垣县",
            value: "花垣县",
          },
          {
            areaCode: 433125000000,
            label: "保靖县",
            value: "保靖县",
          },
          {
            areaCode: 433126000000,
            label: "古丈县",
            value: "古丈县",
          },
          {
            areaCode: 433127000000,
            label: "永顺县",
            value: "永顺县",
          },
          {
            areaCode: 433130000000,
            label: "龙山县",
            value: "龙山县",
          },
        ],
        label: "湘西土家族苗族自治州",
        value: "湘西土家族苗族自治州",
      },
    ],
    label: "湖南省",
    value: "湖南省",
  },
  {
    areaCode: 440000000000,
    children: [
      {
        areaCode: 440100000000,
        children: [
          {
            areaCode: 440103000000,
            label: "荔湾区",
            value: "荔湾区",
          },
          {
            areaCode: 440104000000,
            label: "越秀区",
            value: "越秀区",
          },
          {
            areaCode: 440105000000,
            label: "海珠区",
            value: "海珠区",
          },
          {
            areaCode: 440106000000,
            label: "天河区",
            value: "天河区",
          },
          {
            areaCode: 440111000000,
            label: "白云区",
            value: "白云区",
          },
          {
            areaCode: 440112000000,
            label: "黄埔区",
            value: "黄埔区",
          },
          {
            areaCode: 440113000000,
            label: "番禺区",
            value: "番禺区",
          },
          {
            areaCode: 440114000000,
            label: "花都区",
            value: "花都区",
          },
          {
            areaCode: 440115000000,
            label: "南沙区",
            value: "南沙区",
          },
          {
            areaCode: 440117000000,
            label: "从化区",
            value: "从化区",
          },
          {
            areaCode: 440118000000,
            label: "增城区",
            value: "增城区",
          },
        ],
        label: "广州市",
        value: "广州市",
      },
      {
        areaCode: 440200000000,
        children: [
          {
            areaCode: 440203000000,
            label: "武江区",
            value: "武江区",
          },
          {
            areaCode: 440204000000,
            label: "浈江区",
            value: "浈江区",
          },
          {
            areaCode: 440205000000,
            label: "曲江区",
            value: "曲江区",
          },
          {
            areaCode: 440222000000,
            label: "始兴县",
            value: "始兴县",
          },
          {
            areaCode: 440224000000,
            label: "仁化县",
            value: "仁化县",
          },
          {
            areaCode: 440229000000,
            label: "翁源县",
            value: "翁源县",
          },
          {
            areaCode: 440232000000,
            label: "乳源瑶族自治县",
            value: "乳源瑶族自治县",
          },
          {
            areaCode: 440233000000,
            label: "新丰县",
            value: "新丰县",
          },
          {
            areaCode: 440281000000,
            label: "乐昌市",
            value: "乐昌市",
          },
          {
            areaCode: 440282000000,
            label: "南雄市",
            value: "南雄市",
          },
        ],
        label: "韶关市",
        value: "韶关市",
      },
      {
        areaCode: 440300000000,
        children: [
          {
            areaCode: 440303000000,
            label: "罗湖区",
            value: "罗湖区",
          },
          {
            areaCode: 440304000000,
            label: "福田区",
            value: "福田区",
          },
          {
            areaCode: 440305000000,
            label: "南山区",
            value: "南山区",
          },
          {
            areaCode: 440306000000,
            label: "宝安区",
            value: "宝安区",
          },
          {
            areaCode: 440307000000,
            label: "龙岗区",
            value: "龙岗区",
          },
          {
            areaCode: 440308000000,
            label: "盐田区",
            value: "盐田区",
          },
        ],
        label: "深圳市",
        value: "深圳市",
      },
      {
        areaCode: 440400000000,
        children: [
          {
            areaCode: 440402000000,
            label: "香洲区",
            value: "香洲区",
          },
          {
            areaCode: 440403000000,
            label: "斗门区",
            value: "斗门区",
          },
          {
            areaCode: 440404000000,
            label: "金湾区",
            value: "金湾区",
          },
        ],
        label: "珠海市",
        value: "珠海市",
      },
      {
        areaCode: 440500000000,
        children: [
          {
            areaCode: 440507000000,
            label: "龙湖区",
            value: "龙湖区",
          },
          {
            areaCode: 440511000000,
            label: "金平区",
            value: "金平区",
          },
          {
            areaCode: 440512000000,
            label: "濠江区",
            value: "濠江区",
          },
          {
            areaCode: 440513000000,
            label: "潮阳区",
            value: "潮阳区",
          },
          {
            areaCode: 440514000000,
            label: "潮南区",
            value: "潮南区",
          },
          {
            areaCode: 440515000000,
            label: "澄海区",
            value: "澄海区",
          },
          {
            areaCode: 440523000000,
            label: "南澳县",
            value: "南澳县",
          },
        ],
        label: "汕头市",
        value: "汕头市",
      },
      {
        areaCode: 440600000000,
        children: [
          {
            areaCode: 440604000000,
            label: "禅城区",
            value: "禅城区",
          },
          {
            areaCode: 440605000000,
            label: "南海区",
            value: "南海区",
          },
          {
            areaCode: 440606000000,
            label: "顺德区",
            value: "顺德区",
          },
          {
            areaCode: 440607000000,
            label: "三水区",
            value: "三水区",
          },
          {
            areaCode: 440608000000,
            label: "高明区",
            value: "高明区",
          },
        ],
        label: "佛山市",
        value: "佛山市",
      },
      {
        areaCode: 440700000000,
        children: [
          {
            areaCode: 440703000000,
            label: "蓬江区",
            value: "蓬江区",
          },
          {
            areaCode: 440704000000,
            label: "江海区",
            value: "江海区",
          },
          {
            areaCode: 440705000000,
            label: "新会区",
            value: "新会区",
          },
          {
            areaCode: 440781000000,
            label: "台山市",
            value: "台山市",
          },
          {
            areaCode: 440783000000,
            label: "开平市",
            value: "开平市",
          },
          {
            areaCode: 440784000000,
            label: "鹤山市",
            value: "鹤山市",
          },
          {
            areaCode: 440785000000,
            label: "恩平市",
            value: "恩平市",
          },
        ],
        label: "江门市",
        value: "江门市",
      },
      {
        areaCode: 440800000000,
        children: [
          {
            areaCode: 440802000000,
            label: "赤坎区",
            value: "赤坎区",
          },
          {
            areaCode: 440803000000,
            label: "霞山区",
            value: "霞山区",
          },
          {
            areaCode: 440804000000,
            label: "坡头区",
            value: "坡头区",
          },
          {
            areaCode: 440811000000,
            label: "麻章区",
            value: "麻章区",
          },
          {
            areaCode: 440823000000,
            label: "遂溪县",
            value: "遂溪县",
          },
          {
            areaCode: 440825000000,
            label: "徐闻县",
            value: "徐闻县",
          },
          {
            areaCode: 440881000000,
            label: "廉江市",
            value: "廉江市",
          },
          {
            areaCode: 440882000000,
            label: "雷州市",
            value: "雷州市",
          },
          {
            areaCode: 440883000000,
            label: "吴川市",
            value: "吴川市",
          },
        ],
        label: "湛江市",
        value: "湛江市",
      },
      {
        areaCode: 440900000000,
        children: [
          {
            areaCode: 440902000000,
            label: "茂南区",
            value: "茂南区",
          },
          {
            areaCode: 440904000000,
            label: "电白区",
            value: "电白区",
          },
          {
            areaCode: 440981000000,
            label: "高州市",
            value: "高州市",
          },
          {
            areaCode: 440982000000,
            label: "化州市",
            value: "化州市",
          },
          {
            areaCode: 440983000000,
            label: "信宜市",
            value: "信宜市",
          },
        ],
        label: "茂名市",
        value: "茂名市",
      },
      {
        areaCode: 441200000000,
        children: [
          {
            areaCode: 441202000000,
            label: "端州区",
            value: "端州区",
          },
          {
            areaCode: 441203000000,
            label: "鼎湖区",
            value: "鼎湖区",
          },
          {
            areaCode: 441204000000,
            label: "高要区",
            value: "高要区",
          },
          {
            areaCode: 441223000000,
            label: "广宁县",
            value: "广宁县",
          },
          {
            areaCode: 441224000000,
            label: "怀集县",
            value: "怀集县",
          },
          {
            areaCode: 441225000000,
            label: "封开县",
            value: "封开县",
          },
          {
            areaCode: 441226000000,
            label: "德庆县",
            value: "德庆县",
          },
          {
            areaCode: 441284000000,
            label: "四会市",
            value: "四会市",
          },
        ],
        label: "肇庆市",
        value: "肇庆市",
      },
      {
        areaCode: 441300000000,
        children: [
          {
            areaCode: 441302000000,
            label: "惠城区",
            value: "惠城区",
          },
          {
            areaCode: 441303000000,
            label: "惠阳区",
            value: "惠阳区",
          },
          {
            areaCode: 441322000000,
            label: "博罗县",
            value: "博罗县",
          },
          {
            areaCode: 441323000000,
            label: "惠东县",
            value: "惠东县",
          },
          {
            areaCode: 441324000000,
            label: "龙门县",
            value: "龙门县",
          },
        ],
        label: "惠州市",
        value: "惠州市",
      },
      {
        areaCode: 441400000000,
        children: [
          {
            areaCode: 441402000000,
            label: "梅江区",
            value: "梅江区",
          },
          {
            areaCode: 441403000000,
            label: "梅县区",
            value: "梅县区",
          },
          {
            areaCode: 441422000000,
            label: "大埔县",
            value: "大埔县",
          },
          {
            areaCode: 441423000000,
            label: "丰顺县",
            value: "丰顺县",
          },
          {
            areaCode: 441424000000,
            label: "五华县",
            value: "五华县",
          },
          {
            areaCode: 441426000000,
            label: "平远县",
            value: "平远县",
          },
          {
            areaCode: 441427000000,
            label: "蕉岭县",
            value: "蕉岭县",
          },
          {
            areaCode: 441481000000,
            label: "兴宁市",
            value: "兴宁市",
          },
        ],
        label: "梅州市",
        value: "梅州市",
      },
      {
        areaCode: 441500000000,
        children: [
          {
            areaCode: 441502000000,
            label: "城区",
            value: "城区",
          },
          {
            areaCode: 441521000000,
            label: "海丰县",
            value: "海丰县",
          },
          {
            areaCode: 441523000000,
            label: "陆河县",
            value: "陆河县",
          },
          {
            areaCode: 441581000000,
            label: "陆丰市",
            value: "陆丰市",
          },
        ],
        label: "汕尾市",
        value: "汕尾市",
      },
      {
        areaCode: 441600000000,
        children: [
          {
            areaCode: 441602000000,
            label: "源城区",
            value: "源城区",
          },
          {
            areaCode: 441621000000,
            label: "紫金县",
            value: "紫金县",
          },
          {
            areaCode: 441622000000,
            label: "龙川县",
            value: "龙川县",
          },
          {
            areaCode: 441623000000,
            label: "连平县",
            value: "连平县",
          },
          {
            areaCode: 441624000000,
            label: "和平县",
            value: "和平县",
          },
          {
            areaCode: 441625000000,
            label: "东源县",
            value: "东源县",
          },
        ],
        label: "河源市",
        value: "河源市",
      },
      {
        areaCode: 441700000000,
        children: [
          {
            areaCode: 441702000000,
            label: "江城区",
            value: "江城区",
          },
          {
            areaCode: 441704000000,
            label: "阳东区",
            value: "阳东区",
          },
          {
            areaCode: 441721000000,
            label: "阳西县",
            value: "阳西县",
          },
          {
            areaCode: 441781000000,
            label: "阳春市",
            value: "阳春市",
          },
        ],
        label: "阳江市",
        value: "阳江市",
      },
      {
        areaCode: 441800000000,
        children: [
          {
            areaCode: 441802000000,
            label: "清城区",
            value: "清城区",
          },
          {
            areaCode: 441803000000,
            label: "清新区",
            value: "清新区",
          },
          {
            areaCode: 441821000000,
            label: "佛冈县",
            value: "佛冈县",
          },
          {
            areaCode: 441823000000,
            label: "阳山县",
            value: "阳山县",
          },
          {
            areaCode: 441825000000,
            label: "连山壮族瑶族自治县",
            value: "连山壮族瑶族自治县",
          },
          {
            areaCode: 441826000000,
            label: "连南瑶族自治县",
            value: "连南瑶族自治县",
          },
          {
            areaCode: 441881000000,
            label: "英德市",
            value: "英德市",
          },
          {
            areaCode: 441882000000,
            label: "连州市",
            value: "连州市",
          },
        ],
        label: "清远市",
        value: "清远市",
      },
      {
        areaCode: 441900000000,
        children: [
          {
            areaCode: 441900003000,
            label: "东城街道办事处",
            value: "东城街道办事处",
          },
          {
            areaCode: 441900004000,
            label: "南城街道办事处",
            value: "南城街道办事处",
          },
          {
            areaCode: 441900005000,
            label: "万江街道办事处",
            value: "万江街道办事处",
          },
          {
            areaCode: 441900006000,
            label: "莞城街道办事处",
            value: "莞城街道办事处",
          },
          {
            areaCode: 441900101000,
            label: "石碣镇",
            value: "石碣镇",
          },
          {
            areaCode: 441900102000,
            label: "石龙镇",
            value: "石龙镇",
          },
          {
            areaCode: 441900103000,
            label: "茶山镇",
            value: "茶山镇",
          },
          {
            areaCode: 441900104000,
            label: "石排镇",
            value: "石排镇",
          },
          {
            areaCode: 441900105000,
            label: "企石镇",
            value: "企石镇",
          },
          {
            areaCode: 441900106000,
            label: "横沥镇",
            value: "横沥镇",
          },
          {
            areaCode: 441900107000,
            label: "桥头镇",
            value: "桥头镇",
          },
          {
            areaCode: 441900108000,
            label: "谢岗镇",
            value: "谢岗镇",
          },
          {
            areaCode: 441900109000,
            label: "东坑镇",
            value: "东坑镇",
          },
          {
            areaCode: 441900110000,
            label: "常平镇",
            value: "常平镇",
          },
          {
            areaCode: 441900111000,
            label: "寮步镇",
            value: "寮步镇",
          },
          {
            areaCode: 441900112000,
            label: "樟木头镇",
            value: "樟木头镇",
          },
          {
            areaCode: 441900113000,
            label: "大朗镇",
            value: "大朗镇",
          },
          {
            areaCode: 441900114000,
            label: "黄江镇",
            value: "黄江镇",
          },
          {
            areaCode: 441900115000,
            label: "清溪镇",
            value: "清溪镇",
          },
          {
            areaCode: 441900116000,
            label: "塘厦镇",
            value: "塘厦镇",
          },
          {
            areaCode: 441900117000,
            label: "凤岗镇",
            value: "凤岗镇",
          },
          {
            areaCode: 441900118000,
            label: "大岭山镇",
            value: "大岭山镇",
          },
          {
            areaCode: 441900119000,
            label: "长安镇",
            value: "长安镇",
          },
          {
            areaCode: 441900121000,
            label: "虎门镇",
            value: "虎门镇",
          },
          {
            areaCode: 441900122000,
            label: "厚街镇",
            value: "厚街镇",
          },
          {
            areaCode: 441900123000,
            label: "沙田镇",
            value: "沙田镇",
          },
          {
            areaCode: 441900124000,
            label: "道滘镇",
            value: "道滘镇",
          },
          {
            areaCode: 441900125000,
            label: "洪梅镇",
            value: "洪梅镇",
          },
          {
            areaCode: 441900126000,
            label: "麻涌镇",
            value: "麻涌镇",
          },
          {
            areaCode: 441900127000,
            label: "望牛墩镇",
            value: "望牛墩镇",
          },
          {
            areaCode: 441900128000,
            label: "中堂镇",
            value: "中堂镇",
          },
          {
            areaCode: 441900129000,
            label: "高埗镇",
            value: "高埗镇",
          },
          {
            areaCode: 441900401000,
            label: "松山湖管委会",
            value: "松山湖管委会",
          },
          {
            areaCode: 441900402000,
            label: "虎门港管委会",
            value: "虎门港管委会",
          },
          {
            areaCode: 441900403000,
            label: "东莞生态园",
            value: "东莞生态园",
          },
        ],
        label: "东莞市",
        value: "东莞市",
      },
      {
        areaCode: 442000000000,
        children: [
          {
            areaCode: 442000001000,
            label: "石岐区街道办事处",
            value: "石岐区街道办事处",
          },
          {
            areaCode: 442000002000,
            label: "东区街道办事处",
            value: "东区街道办事处",
          },
          {
            areaCode: 442000003000,
            label: "火炬开发区街道办事处",
            value: "火炬开发区街道办事处",
          },
          {
            areaCode: 442000004000,
            label: "西区街道办事处",
            value: "西区街道办事处",
          },
          {
            areaCode: 442000005000,
            label: "南区街道办事处",
            value: "南区街道办事处",
          },
          {
            areaCode: 442000006000,
            label: "五桂山街道办事处",
            value: "五桂山街道办事处",
          },
          {
            areaCode: 442000100000,
            label: "小榄镇",
            value: "小榄镇",
          },
          {
            areaCode: 442000101000,
            label: "黄圃镇",
            value: "黄圃镇",
          },
          {
            areaCode: 442000102000,
            label: "民众镇",
            value: "民众镇",
          },
          {
            areaCode: 442000103000,
            label: "东凤镇",
            value: "东凤镇",
          },
          {
            areaCode: 442000104000,
            label: "东升镇",
            value: "东升镇",
          },
          {
            areaCode: 442000105000,
            label: "古镇镇",
            value: "古镇镇",
          },
          {
            areaCode: 442000106000,
            label: "沙溪镇",
            value: "沙溪镇",
          },
          {
            areaCode: 442000107000,
            label: "坦洲镇",
            value: "坦洲镇",
          },
          {
            areaCode: 442000108000,
            label: "港口镇",
            value: "港口镇",
          },
          {
            areaCode: 442000109000,
            label: "三角镇",
            value: "三角镇",
          },
          {
            areaCode: 442000110000,
            label: "横栏镇",
            value: "横栏镇",
          },
          {
            areaCode: 442000111000,
            label: "南头镇",
            value: "南头镇",
          },
          {
            areaCode: 442000112000,
            label: "阜沙镇",
            value: "阜沙镇",
          },
          {
            areaCode: 442000113000,
            label: "南朗镇",
            value: "南朗镇",
          },
          {
            areaCode: 442000114000,
            label: "三乡镇",
            value: "三乡镇",
          },
          {
            areaCode: 442000115000,
            label: "板芙镇",
            value: "板芙镇",
          },
          {
            areaCode: 442000116000,
            label: "大涌镇",
            value: "大涌镇",
          },
          {
            areaCode: 442000117000,
            label: "神湾镇",
            value: "神湾镇",
          },
        ],
        label: "中山市",
        value: "中山市",
      },
      {
        areaCode: 445100000000,
        children: [
          {
            areaCode: 445102000000,
            label: "湘桥区",
            value: "湘桥区",
          },
          {
            areaCode: 445103000000,
            label: "潮安区",
            value: "潮安区",
          },
          {
            areaCode: 445122000000,
            label: "饶平县",
            value: "饶平县",
          },
        ],
        label: "潮州市",
        value: "潮州市",
      },
      {
        areaCode: 445200000000,
        children: [
          {
            areaCode: 445202000000,
            label: "榕城区",
            value: "榕城区",
          },
          {
            areaCode: 445203000000,
            label: "揭东区",
            value: "揭东区",
          },
          {
            areaCode: 445222000000,
            label: "揭西县",
            value: "揭西县",
          },
          {
            areaCode: 445224000000,
            label: "惠来县",
            value: "惠来县",
          },
          {
            areaCode: 445281000000,
            label: "普宁市",
            value: "普宁市",
          },
        ],
        label: "揭阳市",
        value: "揭阳市",
      },
      {
        areaCode: 445300000000,
        children: [
          {
            areaCode: 445302000000,
            label: "云城区",
            value: "云城区",
          },
          {
            areaCode: 445303000000,
            label: "云安区",
            value: "云安区",
          },
          {
            areaCode: 445321000000,
            label: "新兴县",
            value: "新兴县",
          },
          {
            areaCode: 445322000000,
            label: "郁南县",
            value: "郁南县",
          },
          {
            areaCode: 445381000000,
            label: "罗定市",
            value: "罗定市",
          },
        ],
        label: "云浮市",
        value: "云浮市",
      },
    ],
    label: "广东省",
    value: "广东省",
  },
  {
    areaCode: 450000000000,
    children: [
      {
        areaCode: 450100000000,
        children: [
          {
            areaCode: 450102000000,
            label: "兴宁区",
            value: "兴宁区",
          },
          {
            areaCode: 450103000000,
            label: "青秀区",
            value: "青秀区",
          },
          {
            areaCode: 450105000000,
            label: "江南区",
            value: "江南区",
          },
          {
            areaCode: 450107000000,
            label: "西乡塘区",
            value: "西乡塘区",
          },
          {
            areaCode: 450108000000,
            label: "良庆区",
            value: "良庆区",
          },
          {
            areaCode: 450109000000,
            label: "邕宁区",
            value: "邕宁区",
          },
          {
            areaCode: 450110000000,
            label: "武鸣区",
            value: "武鸣区",
          },
          {
            areaCode: 450123000000,
            label: "隆安县",
            value: "隆安县",
          },
          {
            areaCode: 450124000000,
            label: "马山县",
            value: "马山县",
          },
          {
            areaCode: 450125000000,
            label: "上林县",
            value: "上林县",
          },
          {
            areaCode: 450126000000,
            label: "宾阳县",
            value: "宾阳县",
          },
          {
            areaCode: 450127000000,
            label: "横县",
            value: "横县",
          },
        ],
        label: "南宁市",
        value: "南宁市",
      },
      {
        areaCode: 450200000000,
        children: [
          {
            areaCode: 450202000000,
            label: "城中区",
            value: "城中区",
          },
          {
            areaCode: 450203000000,
            label: "鱼峰区",
            value: "鱼峰区",
          },
          {
            areaCode: 450204000000,
            label: "柳南区",
            value: "柳南区",
          },
          {
            areaCode: 450205000000,
            label: "柳北区",
            value: "柳北区",
          },
          {
            areaCode: 450206000000,
            label: "柳江区",
            value: "柳江区",
          },
          {
            areaCode: 450222000000,
            label: "柳城县",
            value: "柳城县",
          },
          {
            areaCode: 450223000000,
            label: "鹿寨县",
            value: "鹿寨县",
          },
          {
            areaCode: 450224000000,
            label: "融安县",
            value: "融安县",
          },
          {
            areaCode: 450225000000,
            label: "融水苗族自治县",
            value: "融水苗族自治县",
          },
          {
            areaCode: 450226000000,
            label: "三江侗族自治县",
            value: "三江侗族自治县",
          },
        ],
        label: "柳州市",
        value: "柳州市",
      },
      {
        areaCode: 450300000000,
        children: [
          {
            areaCode: 450302000000,
            label: "秀峰区",
            value: "秀峰区",
          },
          {
            areaCode: 450303000000,
            label: "叠彩区",
            value: "叠彩区",
          },
          {
            areaCode: 450304000000,
            label: "象山区",
            value: "象山区",
          },
          {
            areaCode: 450305000000,
            label: "七星区",
            value: "七星区",
          },
          {
            areaCode: 450311000000,
            label: "雁山区",
            value: "雁山区",
          },
          {
            areaCode: 450312000000,
            label: "临桂区",
            value: "临桂区",
          },
          {
            areaCode: 450321000000,
            label: "阳朔县",
            value: "阳朔县",
          },
          {
            areaCode: 450323000000,
            label: "灵川县",
            value: "灵川县",
          },
          {
            areaCode: 450324000000,
            label: "全州县",
            value: "全州县",
          },
          {
            areaCode: 450325000000,
            label: "兴安县",
            value: "兴安县",
          },
          {
            areaCode: 450326000000,
            label: "永福县",
            value: "永福县",
          },
          {
            areaCode: 450327000000,
            label: "灌阳县",
            value: "灌阳县",
          },
          {
            areaCode: 450328000000,
            label: "龙胜各族自治县",
            value: "龙胜各族自治县",
          },
          {
            areaCode: 450329000000,
            label: "资源县",
            value: "资源县",
          },
          {
            areaCode: 450330000000,
            label: "平乐县",
            value: "平乐县",
          },
          {
            areaCode: 450331000000,
            label: "荔浦县",
            value: "荔浦县",
          },
          {
            areaCode: 450332000000,
            label: "恭城瑶族自治县",
            value: "恭城瑶族自治县",
          },
        ],
        label: "桂林市",
        value: "桂林市",
      },
      {
        areaCode: 450400000000,
        children: [
          {
            areaCode: 450403000000,
            label: "万秀区",
            value: "万秀区",
          },
          {
            areaCode: 450405000000,
            label: "长洲区",
            value: "长洲区",
          },
          {
            areaCode: 450406000000,
            label: "龙圩区",
            value: "龙圩区",
          },
          {
            areaCode: 450421000000,
            label: "苍梧县",
            value: "苍梧县",
          },
          {
            areaCode: 450422000000,
            label: "藤县",
            value: "藤县",
          },
          {
            areaCode: 450423000000,
            label: "蒙山县",
            value: "蒙山县",
          },
          {
            areaCode: 450481000000,
            label: "岑溪市",
            value: "岑溪市",
          },
        ],
        label: "梧州市",
        value: "梧州市",
      },
      {
        areaCode: 450500000000,
        children: [
          {
            areaCode: 450502000000,
            label: "海城区",
            value: "海城区",
          },
          {
            areaCode: 450503000000,
            label: "银海区",
            value: "银海区",
          },
          {
            areaCode: 450512000000,
            label: "铁山港区",
            value: "铁山港区",
          },
          {
            areaCode: 450521000000,
            label: "合浦县",
            value: "合浦县",
          },
        ],
        label: "北海市",
        value: "北海市",
      },
      {
        areaCode: 450600000000,
        children: [
          {
            areaCode: 450602000000,
            label: "港口区",
            value: "港口区",
          },
          {
            areaCode: 450603000000,
            label: "防城区",
            value: "防城区",
          },
          {
            areaCode: 450621000000,
            label: "上思县",
            value: "上思县",
          },
          {
            areaCode: 450681000000,
            label: "东兴市",
            value: "东兴市",
          },
        ],
        label: "防城港市",
        value: "防城港市",
      },
      {
        areaCode: 450700000000,
        children: [
          {
            areaCode: 450702000000,
            label: "钦南区",
            value: "钦南区",
          },
          {
            areaCode: 450703000000,
            label: "钦北区",
            value: "钦北区",
          },
          {
            areaCode: 450721000000,
            label: "灵山县",
            value: "灵山县",
          },
          {
            areaCode: 450722000000,
            label: "浦北县",
            value: "浦北县",
          },
        ],
        label: "钦州市",
        value: "钦州市",
      },
      {
        areaCode: 450800000000,
        children: [
          {
            areaCode: 450802000000,
            label: "港北区",
            value: "港北区",
          },
          {
            areaCode: 450803000000,
            label: "港南区",
            value: "港南区",
          },
          {
            areaCode: 450804000000,
            label: "覃塘区",
            value: "覃塘区",
          },
          {
            areaCode: 450821000000,
            label: "平南县",
            value: "平南县",
          },
          {
            areaCode: 450881000000,
            label: "桂平市",
            value: "桂平市",
          },
        ],
        label: "贵港市",
        value: "贵港市",
      },
      {
        areaCode: 450900000000,
        children: [
          {
            areaCode: 450902000000,
            label: "玉州区",
            value: "玉州区",
          },
          {
            areaCode: 450903000000,
            label: "福绵区",
            value: "福绵区",
          },
          {
            areaCode: 450921000000,
            label: "容县",
            value: "容县",
          },
          {
            areaCode: 450922000000,
            label: "陆川县",
            value: "陆川县",
          },
          {
            areaCode: 450923000000,
            label: "博白县",
            value: "博白县",
          },
          {
            areaCode: 450924000000,
            label: "兴业县",
            value: "兴业县",
          },
          {
            areaCode: 450981000000,
            label: "北流市",
            value: "北流市",
          },
        ],
        label: "玉林市",
        value: "玉林市",
      },
      {
        areaCode: 451000000000,
        children: [
          {
            areaCode: 451002000000,
            label: "右江区",
            value: "右江区",
          },
          {
            areaCode: 451021000000,
            label: "田阳县",
            value: "田阳县",
          },
          {
            areaCode: 451022000000,
            label: "田东县",
            value: "田东县",
          },
          {
            areaCode: 451023000000,
            label: "平果县",
            value: "平果县",
          },
          {
            areaCode: 451024000000,
            label: "德保县",
            value: "德保县",
          },
          {
            areaCode: 451026000000,
            label: "那坡县",
            value: "那坡县",
          },
          {
            areaCode: 451027000000,
            label: "凌云县",
            value: "凌云县",
          },
          {
            areaCode: 451028000000,
            label: "乐业县",
            value: "乐业县",
          },
          {
            areaCode: 451029000000,
            label: "田林县",
            value: "田林县",
          },
          {
            areaCode: 451030000000,
            label: "西林县",
            value: "西林县",
          },
          {
            areaCode: 451031000000,
            label: "隆林各族自治县",
            value: "隆林各族自治县",
          },
          {
            areaCode: 451081000000,
            label: "靖西市",
            value: "靖西市",
          },
        ],
        label: "百色市",
        value: "百色市",
      },
      {
        areaCode: 451100000000,
        children: [
          {
            areaCode: 451102000000,
            label: "八步区",
            value: "八步区",
          },
          {
            areaCode: 451103000000,
            label: "平桂区",
            value: "平桂区",
          },
          {
            areaCode: 451121000000,
            label: "昭平县",
            value: "昭平县",
          },
          {
            areaCode: 451122000000,
            label: "钟山县",
            value: "钟山县",
          },
          {
            areaCode: 451123000000,
            label: "富川瑶族自治县",
            value: "富川瑶族自治县",
          },
        ],
        label: "贺州市",
        value: "贺州市",
      },
      {
        areaCode: 451200000000,
        children: [
          {
            areaCode: 451202000000,
            label: "金城江区",
            value: "金城江区",
          },
          {
            areaCode: 451221000000,
            label: "南丹县",
            value: "南丹县",
          },
          {
            areaCode: 451222000000,
            label: "天峨县",
            value: "天峨县",
          },
          {
            areaCode: 451223000000,
            label: "凤山县",
            value: "凤山县",
          },
          {
            areaCode: 451224000000,
            label: "东兰县",
            value: "东兰县",
          },
          {
            areaCode: 451225000000,
            label: "罗城仫佬族自治县",
            value: "罗城仫佬族自治县",
          },
          {
            areaCode: 451226000000,
            label: "环江毛南族自治县",
            value: "环江毛南族自治县",
          },
          {
            areaCode: 451227000000,
            label: "巴马瑶族自治县",
            value: "巴马瑶族自治县",
          },
          {
            areaCode: 451228000000,
            label: "都安瑶族自治县",
            value: "都安瑶族自治县",
          },
          {
            areaCode: 451229000000,
            label: "大化瑶族自治县",
            value: "大化瑶族自治县",
          },
          {
            areaCode: 451281000000,
            label: "宜州市",
            value: "宜州市",
          },
        ],
        label: "河池市",
        value: "河池市",
      },
      {
        areaCode: 451300000000,
        children: [
          {
            areaCode: 451302000000,
            label: "兴宾区",
            value: "兴宾区",
          },
          {
            areaCode: 451321000000,
            label: "忻城县",
            value: "忻城县",
          },
          {
            areaCode: 451322000000,
            label: "象州县",
            value: "象州县",
          },
          {
            areaCode: 451323000000,
            label: "武宣县",
            value: "武宣县",
          },
          {
            areaCode: 451324000000,
            label: "金秀瑶族自治县",
            value: "金秀瑶族自治县",
          },
          {
            areaCode: 451381000000,
            label: "合山市",
            value: "合山市",
          },
        ],
        label: "来宾市",
        value: "来宾市",
      },
      {
        areaCode: 451400000000,
        children: [
          {
            areaCode: 451402000000,
            label: "江州区",
            value: "江州区",
          },
          {
            areaCode: 451421000000,
            label: "扶绥县",
            value: "扶绥县",
          },
          {
            areaCode: 451422000000,
            label: "宁明县",
            value: "宁明县",
          },
          {
            areaCode: 451423000000,
            label: "龙州县",
            value: "龙州县",
          },
          {
            areaCode: 451424000000,
            label: "大新县",
            value: "大新县",
          },
          {
            areaCode: 451425000000,
            label: "天等县",
            value: "天等县",
          },
          {
            areaCode: 451481000000,
            label: "凭祥市",
            value: "凭祥市",
          },
        ],
        label: "崇左市",
        value: "崇左市",
      },
    ],
    label: "广西壮族自治区",
    value: "广西壮族自治区",
  },
  {
    areaCode: 460000000000,
    children: [
      {
        areaCode: 460100000000,
        children: [
          {
            areaCode: 460105000000,
            label: "秀英区",
            value: "秀英区",
          },
          {
            areaCode: 460106000000,
            label: "龙华区",
            value: "龙华区",
          },
          {
            areaCode: 460107000000,
            label: "琼山区",
            value: "琼山区",
          },
          {
            areaCode: 460108000000,
            label: "美兰区",
            value: "美兰区",
          },
        ],
        label: "海口市",
        value: "海口市",
      },
      {
        areaCode: 460200000000,
        children: [
          {
            areaCode: 460202000000,
            label: "海棠区",
            value: "海棠区",
          },
          {
            areaCode: 460203000000,
            label: "吉阳区",
            value: "吉阳区",
          },
          {
            areaCode: 460204000000,
            label: "天涯区",
            value: "天涯区",
          },
          {
            areaCode: 460205000000,
            label: "崖州区",
            value: "崖州区",
          },
        ],
        label: "三亚市",
        value: "三亚市",
      },
      {
        areaCode: 460300000000,
        children: [
          {
            areaCode: 460321000000,
            label: "西沙群岛",
            value: "西沙群岛",
          },
          {
            areaCode: 460322000000,
            label: "南沙群岛",
            value: "南沙群岛",
          },
          {
            areaCode: 460323000000,
            label: "中沙群岛的岛礁及其海域",
            value: "中沙群岛的岛礁及其海域",
          },
        ],
        label: "三沙市",
        value: "三沙市",
      },
      {
        areaCode: 460400000000,
        children: [
          {
            areaCode: 460400100000,
            label: "那大镇",
            value: "那大镇",
          },
          {
            areaCode: 460400101000,
            label: "和庆镇",
            value: "和庆镇",
          },
          {
            areaCode: 460400102000,
            label: "南丰镇",
            value: "南丰镇",
          },
          {
            areaCode: 460400103000,
            label: "大成镇",
            value: "大成镇",
          },
          {
            areaCode: 460400104000,
            label: "雅星镇",
            value: "雅星镇",
          },
          {
            areaCode: 460400105000,
            label: "兰洋镇",
            value: "兰洋镇",
          },
          {
            areaCode: 460400106000,
            label: "光村镇",
            value: "光村镇",
          },
          {
            areaCode: 460400107000,
            label: "木棠镇",
            value: "木棠镇",
          },
          {
            areaCode: 460400108000,
            label: "海头镇",
            value: "海头镇",
          },
          {
            areaCode: 460400109000,
            label: "峨蔓镇",
            value: "峨蔓镇",
          },
          {
            areaCode: 460400110000,
            label: "三都镇",
            value: "三都镇",
          },
          {
            areaCode: 460400111000,
            label: "王五镇",
            value: "王五镇",
          },
          {
            areaCode: 460400112000,
            label: "白马井镇",
            value: "白马井镇",
          },
          {
            areaCode: 460400113000,
            label: "中和镇",
            value: "中和镇",
          },
          {
            areaCode: 460400114000,
            label: "排浦镇",
            value: "排浦镇",
          },
          {
            areaCode: 460400115000,
            label: "东成镇",
            value: "东成镇",
          },
          {
            areaCode: 460400116000,
            label: "新州镇",
            value: "新州镇",
          },
          {
            areaCode: 460400400000,
            label: "国营西培农场",
            value: "国营西培农场",
          },
          {
            areaCode: 460400404000,
            label: "国营西联农场",
            value: "国营西联农场",
          },
          {
            areaCode: 460400405000,
            label: "国营蓝洋农场",
            value: "国营蓝洋农场",
          },
          {
            areaCode: 460400407000,
            label: "国营八一农场",
            value: "国营八一农场",
          },
          {
            areaCode: 460400499000,
            label: "洋浦经济开发区",
            value: "洋浦经济开发区",
          },
          {
            areaCode: 460400500000,
            label: "华南热作学院",
            value: "华南热作学院",
          },
        ],
        label: "儋州市",
        value: "儋州市",
      },
      {
        areaCode: 469000000000,
        children: [
          {
            areaCode: 469001000000,
            label: "五指山市",
            value: "五指山市",
          },
          {
            areaCode: 469002000000,
            label: "琼海市",
            value: "琼海市",
          },
          {
            areaCode: 469005000000,
            label: "文昌市",
            value: "文昌市",
          },
          {
            areaCode: 469006000000,
            label: "万宁市",
            value: "万宁市",
          },
          {
            areaCode: 469007000000,
            label: "东方市",
            value: "东方市",
          },
          {
            areaCode: 469021000000,
            label: "定安县",
            value: "定安县",
          },
          {
            areaCode: 469022000000,
            label: "屯昌县",
            value: "屯昌县",
          },
          {
            areaCode: 469023000000,
            label: "澄迈县",
            value: "澄迈县",
          },
          {
            areaCode: 469024000000,
            label: "临高县",
            value: "临高县",
          },
          {
            areaCode: 469025000000,
            label: "白沙黎族自治县",
            value: "白沙黎族自治县",
          },
          {
            areaCode: 469026000000,
            label: "昌江黎族自治县",
            value: "昌江黎族自治县",
          },
          {
            areaCode: 469027000000,
            label: "乐东黎族自治县",
            value: "乐东黎族自治县",
          },
          {
            areaCode: 469028000000,
            label: "陵水黎族自治县",
            value: "陵水黎族自治县",
          },
          {
            areaCode: 469029000000,
            label: "保亭黎族苗族自治县",
            value: "保亭黎族苗族自治县",
          },
          {
            areaCode: 469030000000,
            label: "琼中黎族苗族自治县",
            value: "琼中黎族苗族自治县",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "海南省",
    value: "海南省",
  },
  {
    areaCode: 500000000000,
    children: [
      {
        areaCode: 500100000000,
        children: [
          {
            areaCode: 500101000000,
            label: "万州区",
            value: "万州区",
          },
          {
            areaCode: 500102000000,
            label: "涪陵区",
            value: "涪陵区",
          },
          {
            areaCode: 500103000000,
            label: "渝中区",
            value: "渝中区",
          },
          {
            areaCode: 500104000000,
            label: "大渡口区",
            value: "大渡口区",
          },
          {
            areaCode: 500105000000,
            label: "江北区",
            value: "江北区",
          },
          {
            areaCode: 500106000000,
            label: "沙坪坝区",
            value: "沙坪坝区",
          },
          {
            areaCode: 500107000000,
            label: "九龙坡区",
            value: "九龙坡区",
          },
          {
            areaCode: 500108000000,
            label: "南岸区",
            value: "南岸区",
          },
          {
            areaCode: 500109000000,
            label: "北碚区",
            value: "北碚区",
          },
          {
            areaCode: 500110000000,
            label: "綦江区",
            value: "綦江区",
          },
          {
            areaCode: 500111000000,
            label: "大足区",
            value: "大足区",
          },
          {
            areaCode: 500112000000,
            label: "渝北区",
            value: "渝北区",
          },
          {
            areaCode: 500113000000,
            label: "巴南区",
            value: "巴南区",
          },
          {
            areaCode: 500114000000,
            label: "黔江区",
            value: "黔江区",
          },
          {
            areaCode: 500115000000,
            label: "长寿区",
            value: "长寿区",
          },
          {
            areaCode: 500116000000,
            label: "江津区",
            value: "江津区",
          },
          {
            areaCode: 500117000000,
            label: "合川区",
            value: "合川区",
          },
          {
            areaCode: 500118000000,
            label: "永川区",
            value: "永川区",
          },
          {
            areaCode: 500119000000,
            label: "南川区",
            value: "南川区",
          },
          {
            areaCode: 500120000000,
            label: "璧山区",
            value: "璧山区",
          },
          {
            areaCode: 500151000000,
            label: "铜梁区",
            value: "铜梁区",
          },
          {
            areaCode: 500152000000,
            label: "潼南区",
            value: "潼南区",
          },
          {
            areaCode: 500153000000,
            label: "荣昌区",
            value: "荣昌区",
          },
          {
            areaCode: 500154000000,
            label: "开州区",
            value: "开州区",
          },
        ],
        label: "直辖区",
        value: "直辖区",
      },
      {
        areaCode: 500200000000,
        children: [
          {
            areaCode: 500228000000,
            label: "梁平县",
            value: "梁平县",
          },
          {
            areaCode: 500229000000,
            label: "城口县",
            value: "城口县",
          },
          {
            areaCode: 500230000000,
            label: "丰都县",
            value: "丰都县",
          },
          {
            areaCode: 500231000000,
            label: "垫江县",
            value: "垫江县",
          },
          {
            areaCode: 500232000000,
            label: "武隆县",
            value: "武隆县",
          },
          {
            areaCode: 500233000000,
            label: "忠县",
            value: "忠县",
          },
          {
            areaCode: 500235000000,
            label: "云阳县",
            value: "云阳县",
          },
          {
            areaCode: 500236000000,
            label: "奉节县",
            value: "奉节县",
          },
          {
            areaCode: 500237000000,
            label: "巫山县",
            value: "巫山县",
          },
          {
            areaCode: 500238000000,
            label: "巫溪县",
            value: "巫溪县",
          },
          {
            areaCode: 500240000000,
            label: "石柱土家族自治县",
            value: "石柱土家族自治县",
          },
          {
            areaCode: 500241000000,
            label: "秀山土家族苗族自治县",
            value: "秀山土家族苗族自治县",
          },
          {
            areaCode: 500242000000,
            label: "酉阳土家族苗族自治县",
            value: "酉阳土家族苗族自治县",
          },
          {
            areaCode: 500243000000,
            label: "彭水苗族土家族自治县",
            value: "彭水苗族土家族自治县",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "重庆市",
    value: "重庆市",
  },
  {
    areaCode: 510000000000,
    children: [
      {
        areaCode: 510100000000,
        children: [
          {
            areaCode: 510104000000,
            label: "锦江区",
            value: "锦江区",
          },
          {
            areaCode: 510105000000,
            label: "青羊区",
            value: "青羊区",
          },
          {
            areaCode: 510106000000,
            label: "金牛区",
            value: "金牛区",
          },
          {
            areaCode: 510107000000,
            label: "武侯区",
            value: "武侯区",
          },
          {
            areaCode: 510108000000,
            label: "成华区",
            value: "成华区",
          },
          {
            areaCode: 510112000000,
            label: "龙泉驿区",
            value: "龙泉驿区",
          },
          {
            areaCode: 510113000000,
            label: "青白江区",
            value: "青白江区",
          },
          {
            areaCode: 510114000000,
            label: "新都区",
            value: "新都区",
          },
          {
            areaCode: 510115000000,
            label: "温江区",
            value: "温江区",
          },
          {
            areaCode: 510116000000,
            label: "双流区",
            value: "双流区",
          },
          {
            areaCode: 510121000000,
            label: "金堂县",
            value: "金堂县",
          },
          {
            areaCode: 510124000000,
            label: "郫县",
            value: "郫县",
          },
          {
            areaCode: 510129000000,
            label: "大邑县",
            value: "大邑县",
          },
          {
            areaCode: 510131000000,
            label: "蒲江县",
            value: "蒲江县",
          },
          {
            areaCode: 510132000000,
            label: "新津县",
            value: "新津县",
          },
          {
            areaCode: 510181000000,
            label: "都江堰市",
            value: "都江堰市",
          },
          {
            areaCode: 510182000000,
            label: "彭州市",
            value: "彭州市",
          },
          {
            areaCode: 510183000000,
            label: "邛崃市",
            value: "邛崃市",
          },
          {
            areaCode: 510184000000,
            label: "崇州市",
            value: "崇州市",
          },
          {
            areaCode: 510185000000,
            label: "简阳市",
            value: "简阳市",
          },
        ],
        label: "成都市",
        value: "成都市",
      },
      {
        areaCode: 510300000000,
        children: [
          {
            areaCode: 510302000000,
            label: "自流井区",
            value: "自流井区",
          },
          {
            areaCode: 510303000000,
            label: "贡井区",
            value: "贡井区",
          },
          {
            areaCode: 510304000000,
            label: "大安区",
            value: "大安区",
          },
          {
            areaCode: 510311000000,
            label: "沿滩区",
            value: "沿滩区",
          },
          {
            areaCode: 510321000000,
            label: "荣县",
            value: "荣县",
          },
          {
            areaCode: 510322000000,
            label: "富顺县",
            value: "富顺县",
          },
        ],
        label: "自贡市",
        value: "自贡市",
      },
      {
        areaCode: 510400000000,
        children: [
          {
            areaCode: 510402000000,
            label: "东区",
            value: "东区",
          },
          {
            areaCode: 510403000000,
            label: "西区",
            value: "西区",
          },
          {
            areaCode: 510411000000,
            label: "仁和区",
            value: "仁和区",
          },
          {
            areaCode: 510421000000,
            label: "米易县",
            value: "米易县",
          },
          {
            areaCode: 510422000000,
            label: "盐边县",
            value: "盐边县",
          },
        ],
        label: "攀枝花市",
        value: "攀枝花市",
      },
      {
        areaCode: 510500000000,
        children: [
          {
            areaCode: 510502000000,
            label: "江阳区",
            value: "江阳区",
          },
          {
            areaCode: 510503000000,
            label: "纳溪区",
            value: "纳溪区",
          },
          {
            areaCode: 510504000000,
            label: "龙马潭区",
            value: "龙马潭区",
          },
          {
            areaCode: 510521000000,
            label: "泸县",
            value: "泸县",
          },
          {
            areaCode: 510522000000,
            label: "合江县",
            value: "合江县",
          },
          {
            areaCode: 510524000000,
            label: "叙永县",
            value: "叙永县",
          },
          {
            areaCode: 510525000000,
            label: "古蔺县",
            value: "古蔺县",
          },
        ],
        label: "泸州市",
        value: "泸州市",
      },
      {
        areaCode: 510600000000,
        children: [
          {
            areaCode: 510603000000,
            label: "旌阳区",
            value: "旌阳区",
          },
          {
            areaCode: 510623000000,
            label: "中江县",
            value: "中江县",
          },
          {
            areaCode: 510626000000,
            label: "罗江县",
            value: "罗江县",
          },
          {
            areaCode: 510681000000,
            label: "广汉市",
            value: "广汉市",
          },
          {
            areaCode: 510682000000,
            label: "什邡市",
            value: "什邡市",
          },
          {
            areaCode: 510683000000,
            label: "绵竹市",
            value: "绵竹市",
          },
        ],
        label: "德阳市",
        value: "德阳市",
      },
      {
        areaCode: 510700000000,
        children: [
          {
            areaCode: 510703000000,
            label: "涪城区",
            value: "涪城区",
          },
          {
            areaCode: 510704000000,
            label: "游仙区",
            value: "游仙区",
          },
          {
            areaCode: 510705000000,
            label: "安州区",
            value: "安州区",
          },
          {
            areaCode: 510722000000,
            label: "三台县",
            value: "三台县",
          },
          {
            areaCode: 510723000000,
            label: "盐亭县",
            value: "盐亭县",
          },
          {
            areaCode: 510725000000,
            label: "梓潼县",
            value: "梓潼县",
          },
          {
            areaCode: 510726000000,
            label: "北川羌族自治县",
            value: "北川羌族自治县",
          },
          {
            areaCode: 510727000000,
            label: "平武县",
            value: "平武县",
          },
          {
            areaCode: 510781000000,
            label: "江油市",
            value: "江油市",
          },
        ],
        label: "绵阳市",
        value: "绵阳市",
      },
      {
        areaCode: 510800000000,
        children: [
          {
            areaCode: 510802000000,
            label: "利州区",
            value: "利州区",
          },
          {
            areaCode: 510811000000,
            label: "昭化区",
            value: "昭化区",
          },
          {
            areaCode: 510812000000,
            label: "朝天区",
            value: "朝天区",
          },
          {
            areaCode: 510821000000,
            label: "旺苍县",
            value: "旺苍县",
          },
          {
            areaCode: 510822000000,
            label: "青川县",
            value: "青川县",
          },
          {
            areaCode: 510823000000,
            label: "剑阁县",
            value: "剑阁县",
          },
          {
            areaCode: 510824000000,
            label: "苍溪县",
            value: "苍溪县",
          },
        ],
        label: "广元市",
        value: "广元市",
      },
      {
        areaCode: 510900000000,
        children: [
          {
            areaCode: 510903000000,
            label: "船山区",
            value: "船山区",
          },
          {
            areaCode: 510904000000,
            label: "安居区",
            value: "安居区",
          },
          {
            areaCode: 510921000000,
            label: "蓬溪县",
            value: "蓬溪县",
          },
          {
            areaCode: 510922000000,
            label: "射洪县",
            value: "射洪县",
          },
          {
            areaCode: 510923000000,
            label: "大英县",
            value: "大英县",
          },
        ],
        label: "遂宁市",
        value: "遂宁市",
      },
      {
        areaCode: 511000000000,
        children: [
          {
            areaCode: 511002000000,
            label: "市中区",
            value: "市中区",
          },
          {
            areaCode: 511011000000,
            label: "东兴区",
            value: "东兴区",
          },
          {
            areaCode: 511024000000,
            label: "威远县",
            value: "威远县",
          },
          {
            areaCode: 511025000000,
            label: "资中县",
            value: "资中县",
          },
          {
            areaCode: 511028000000,
            label: "隆昌县",
            value: "隆昌县",
          },
        ],
        label: "内江市",
        value: "内江市",
      },
      {
        areaCode: 511100000000,
        children: [
          {
            areaCode: 511102000000,
            label: "市中区",
            value: "市中区",
          },
          {
            areaCode: 511111000000,
            label: "沙湾区",
            value: "沙湾区",
          },
          {
            areaCode: 511112000000,
            label: "五通桥区",
            value: "五通桥区",
          },
          {
            areaCode: 511113000000,
            label: "金口河区",
            value: "金口河区",
          },
          {
            areaCode: 511123000000,
            label: "犍为县",
            value: "犍为县",
          },
          {
            areaCode: 511124000000,
            label: "井研县",
            value: "井研县",
          },
          {
            areaCode: 511126000000,
            label: "夹江县",
            value: "夹江县",
          },
          {
            areaCode: 511129000000,
            label: "沐川县",
            value: "沐川县",
          },
          {
            areaCode: 511132000000,
            label: "峨边彝族自治县",
            value: "峨边彝族自治县",
          },
          {
            areaCode: 511133000000,
            label: "马边彝族自治县",
            value: "马边彝族自治县",
          },
          {
            areaCode: 511181000000,
            label: "峨眉山市",
            value: "峨眉山市",
          },
        ],
        label: "乐山市",
        value: "乐山市",
      },
      {
        areaCode: 511300000000,
        children: [
          {
            areaCode: 511302000000,
            label: "顺庆区",
            value: "顺庆区",
          },
          {
            areaCode: 511303000000,
            label: "高坪区",
            value: "高坪区",
          },
          {
            areaCode: 511304000000,
            label: "嘉陵区",
            value: "嘉陵区",
          },
          {
            areaCode: 511321000000,
            label: "南部县",
            value: "南部县",
          },
          {
            areaCode: 511322000000,
            label: "营山县",
            value: "营山县",
          },
          {
            areaCode: 511323000000,
            label: "蓬安县",
            value: "蓬安县",
          },
          {
            areaCode: 511324000000,
            label: "仪陇县",
            value: "仪陇县",
          },
          {
            areaCode: 511325000000,
            label: "西充县",
            value: "西充县",
          },
          {
            areaCode: 511381000000,
            label: "阆中市",
            value: "阆中市",
          },
        ],
        label: "南充市",
        value: "南充市",
      },
      {
        areaCode: 511400000000,
        children: [
          {
            areaCode: 511402000000,
            label: "东坡区",
            value: "东坡区",
          },
          {
            areaCode: 511403000000,
            label: "彭山区",
            value: "彭山区",
          },
          {
            areaCode: 511421000000,
            label: "仁寿县",
            value: "仁寿县",
          },
          {
            areaCode: 511423000000,
            label: "洪雅县",
            value: "洪雅县",
          },
          {
            areaCode: 511424000000,
            label: "丹棱县",
            value: "丹棱县",
          },
          {
            areaCode: 511425000000,
            label: "青神县",
            value: "青神县",
          },
        ],
        label: "眉山市",
        value: "眉山市",
      },
      {
        areaCode: 511500000000,
        children: [
          {
            areaCode: 511502000000,
            label: "翠屏区",
            value: "翠屏区",
          },
          {
            areaCode: 511503000000,
            label: "南溪区",
            value: "南溪区",
          },
          {
            areaCode: 511521000000,
            label: "宜宾县",
            value: "宜宾县",
          },
          {
            areaCode: 511523000000,
            label: "江安县",
            value: "江安县",
          },
          {
            areaCode: 511524000000,
            label: "长宁县",
            value: "长宁县",
          },
          {
            areaCode: 511525000000,
            label: "高县",
            value: "高县",
          },
          {
            areaCode: 511526000000,
            label: "珙县",
            value: "珙县",
          },
          {
            areaCode: 511527000000,
            label: "筠连县",
            value: "筠连县",
          },
          {
            areaCode: 511528000000,
            label: "兴文县",
            value: "兴文县",
          },
          {
            areaCode: 511529000000,
            label: "屏山县",
            value: "屏山县",
          },
        ],
        label: "宜宾市",
        value: "宜宾市",
      },
      {
        areaCode: 511600000000,
        children: [
          {
            areaCode: 511602000000,
            label: "广安区",
            value: "广安区",
          },
          {
            areaCode: 511603000000,
            label: "前锋区",
            value: "前锋区",
          },
          {
            areaCode: 511621000000,
            label: "岳池县",
            value: "岳池县",
          },
          {
            areaCode: 511622000000,
            label: "武胜县",
            value: "武胜县",
          },
          {
            areaCode: 511623000000,
            label: "邻水县",
            value: "邻水县",
          },
          {
            areaCode: 511681000000,
            label: "华蓥市",
            value: "华蓥市",
          },
        ],
        label: "广安市",
        value: "广安市",
      },
      {
        areaCode: 511700000000,
        children: [
          {
            areaCode: 511702000000,
            label: "通川区",
            value: "通川区",
          },
          {
            areaCode: 511703000000,
            label: "达川区",
            value: "达川区",
          },
          {
            areaCode: 511722000000,
            label: "宣汉县",
            value: "宣汉县",
          },
          {
            areaCode: 511723000000,
            label: "开江县",
            value: "开江县",
          },
          {
            areaCode: 511724000000,
            label: "大竹县",
            value: "大竹县",
          },
          {
            areaCode: 511725000000,
            label: "渠县",
            value: "渠县",
          },
          {
            areaCode: 511781000000,
            label: "万源市",
            value: "万源市",
          },
        ],
        label: "达州市",
        value: "达州市",
      },
      {
        areaCode: 511800000000,
        children: [
          {
            areaCode: 511802000000,
            label: "雨城区",
            value: "雨城区",
          },
          {
            areaCode: 511803000000,
            label: "名山区",
            value: "名山区",
          },
          {
            areaCode: 511822000000,
            label: "荥经县",
            value: "荥经县",
          },
          {
            areaCode: 511823000000,
            label: "汉源县",
            value: "汉源县",
          },
          {
            areaCode: 511824000000,
            label: "石棉县",
            value: "石棉县",
          },
          {
            areaCode: 511825000000,
            label: "天全县",
            value: "天全县",
          },
          {
            areaCode: 511826000000,
            label: "芦山县",
            value: "芦山县",
          },
          {
            areaCode: 511827000000,
            label: "宝兴县",
            value: "宝兴县",
          },
        ],
        label: "雅安市",
        value: "雅安市",
      },
      {
        areaCode: 511900000000,
        children: [
          {
            areaCode: 511902000000,
            label: "巴州区",
            value: "巴州区",
          },
          {
            areaCode: 511903000000,
            label: "恩阳区",
            value: "恩阳区",
          },
          {
            areaCode: 511921000000,
            label: "通江县",
            value: "通江县",
          },
          {
            areaCode: 511922000000,
            label: "南江县",
            value: "南江县",
          },
          {
            areaCode: 511923000000,
            label: "平昌县",
            value: "平昌县",
          },
        ],
        label: "巴中市",
        value: "巴中市",
      },
      {
        areaCode: 512000000000,
        children: [
          {
            areaCode: 512002000000,
            label: "雁江区",
            value: "雁江区",
          },
          {
            areaCode: 512021000000,
            label: "安岳县",
            value: "安岳县",
          },
          {
            areaCode: 512022000000,
            label: "乐至县",
            value: "乐至县",
          },
        ],
        label: "资阳市",
        value: "资阳市",
      },
      {
        areaCode: 513200000000,
        children: [
          {
            areaCode: 513201000000,
            label: "马尔康市",
            value: "马尔康市",
          },
          {
            areaCode: 513221000000,
            label: "汶川县",
            value: "汶川县",
          },
          {
            areaCode: 513222000000,
            label: "理县",
            value: "理县",
          },
          {
            areaCode: 513223000000,
            label: "茂县",
            value: "茂县",
          },
          {
            areaCode: 513224000000,
            label: "松潘县",
            value: "松潘县",
          },
          {
            areaCode: 513225000000,
            label: "九寨沟县",
            value: "九寨沟县",
          },
          {
            areaCode: 513226000000,
            label: "金川县",
            value: "金川县",
          },
          {
            areaCode: 513227000000,
            label: "小金县",
            value: "小金县",
          },
          {
            areaCode: 513228000000,
            label: "黑水县",
            value: "黑水县",
          },
          {
            areaCode: 513230000000,
            label: "壤塘县",
            value: "壤塘县",
          },
          {
            areaCode: 513231000000,
            label: "阿坝县",
            value: "阿坝县",
          },
          {
            areaCode: 513232000000,
            label: "若尔盖县",
            value: "若尔盖县",
          },
          {
            areaCode: 513233000000,
            label: "红原县",
            value: "红原县",
          },
        ],
        label: "阿坝藏族羌族自治州",
        value: "阿坝藏族羌族自治州",
      },
      {
        areaCode: 513300000000,
        children: [
          {
            areaCode: 513301000000,
            label: "康定市",
            value: "康定市",
          },
          {
            areaCode: 513322000000,
            label: "泸定县",
            value: "泸定县",
          },
          {
            areaCode: 513323000000,
            label: "丹巴县",
            value: "丹巴县",
          },
          {
            areaCode: 513324000000,
            label: "九龙县",
            value: "九龙县",
          },
          {
            areaCode: 513325000000,
            label: "雅江县",
            value: "雅江县",
          },
          {
            areaCode: 513326000000,
            label: "道孚县",
            value: "道孚县",
          },
          {
            areaCode: 513327000000,
            label: "炉霍县",
            value: "炉霍县",
          },
          {
            areaCode: 513328000000,
            label: "甘孜县",
            value: "甘孜县",
          },
          {
            areaCode: 513329000000,
            label: "新龙县",
            value: "新龙县",
          },
          {
            areaCode: 513330000000,
            label: "德格县",
            value: "德格县",
          },
          {
            areaCode: 513331000000,
            label: "白玉县",
            value: "白玉县",
          },
          {
            areaCode: 513332000000,
            label: "石渠县",
            value: "石渠县",
          },
          {
            areaCode: 513333000000,
            label: "色达县",
            value: "色达县",
          },
          {
            areaCode: 513334000000,
            label: "理塘县",
            value: "理塘县",
          },
          {
            areaCode: 513335000000,
            label: "巴塘县",
            value: "巴塘县",
          },
          {
            areaCode: 513336000000,
            label: "乡城县",
            value: "乡城县",
          },
          {
            areaCode: 513337000000,
            label: "稻城县",
            value: "稻城县",
          },
          {
            areaCode: 513338000000,
            label: "得荣县",
            value: "得荣县",
          },
        ],
        label: "甘孜藏族自治州",
        value: "甘孜藏族自治州",
      },
      {
        areaCode: 513400000000,
        children: [
          {
            areaCode: 513401000000,
            label: "西昌市",
            value: "西昌市",
          },
          {
            areaCode: 513422000000,
            label: "木里藏族自治县",
            value: "木里藏族自治县",
          },
          {
            areaCode: 513423000000,
            label: "盐源县",
            value: "盐源县",
          },
          {
            areaCode: 513424000000,
            label: "德昌县",
            value: "德昌县",
          },
          {
            areaCode: 513425000000,
            label: "会理县",
            value: "会理县",
          },
          {
            areaCode: 513426000000,
            label: "会东县",
            value: "会东县",
          },
          {
            areaCode: 513427000000,
            label: "宁南县",
            value: "宁南县",
          },
          {
            areaCode: 513428000000,
            label: "普格县",
            value: "普格县",
          },
          {
            areaCode: 513429000000,
            label: "布拖县",
            value: "布拖县",
          },
          {
            areaCode: 513430000000,
            label: "金阳县",
            value: "金阳县",
          },
          {
            areaCode: 513431000000,
            label: "昭觉县",
            value: "昭觉县",
          },
          {
            areaCode: 513432000000,
            label: "喜德县",
            value: "喜德县",
          },
          {
            areaCode: 513433000000,
            label: "冕宁县",
            value: "冕宁县",
          },
          {
            areaCode: 513434000000,
            label: "越西县",
            value: "越西县",
          },
          {
            areaCode: 513435000000,
            label: "甘洛县",
            value: "甘洛县",
          },
          {
            areaCode: 513436000000,
            label: "美姑县",
            value: "美姑县",
          },
          {
            areaCode: 513437000000,
            label: "雷波县",
            value: "雷波县",
          },
        ],
        label: "凉山彝族自治州",
        value: "凉山彝族自治州",
      },
    ],
    label: "四川省",
    value: "四川省",
  },
  {
    areaCode: 520000000000,
    children: [
      {
        areaCode: 520100000000,
        children: [
          {
            areaCode: 520102000000,
            label: "南明区",
            value: "南明区",
          },
          {
            areaCode: 520103000000,
            label: "云岩区",
            value: "云岩区",
          },
          {
            areaCode: 520111000000,
            label: "花溪区",
            value: "花溪区",
          },
          {
            areaCode: 520112000000,
            label: "乌当区",
            value: "乌当区",
          },
          {
            areaCode: 520113000000,
            label: "白云区",
            value: "白云区",
          },
          {
            areaCode: 520115000000,
            label: "观山湖区",
            value: "观山湖区",
          },
          {
            areaCode: 520121000000,
            label: "开阳县",
            value: "开阳县",
          },
          {
            areaCode: 520122000000,
            label: "息烽县",
            value: "息烽县",
          },
          {
            areaCode: 520123000000,
            label: "修文县",
            value: "修文县",
          },
          {
            areaCode: 520181000000,
            label: "清镇市",
            value: "清镇市",
          },
        ],
        label: "贵阳市",
        value: "贵阳市",
      },
      {
        areaCode: 520200000000,
        children: [
          {
            areaCode: 520201000000,
            label: "钟山区",
            value: "钟山区",
          },
          {
            areaCode: 520203000000,
            label: "六枝特区",
            value: "六枝特区",
          },
          {
            areaCode: 520221000000,
            label: "水城县",
            value: "水城县",
          },
          {
            areaCode: 520222000000,
            label: "盘县",
            value: "盘县",
          },
        ],
        label: "六盘水市",
        value: "六盘水市",
      },
      {
        areaCode: 520300000000,
        children: [
          {
            areaCode: 520302000000,
            label: "红花岗区",
            value: "红花岗区",
          },
          {
            areaCode: 520303000000,
            label: "汇川区",
            value: "汇川区",
          },
          {
            areaCode: 520304000000,
            label: "播州区",
            value: "播州区",
          },
          {
            areaCode: 520322000000,
            label: "桐梓县",
            value: "桐梓县",
          },
          {
            areaCode: 520323000000,
            label: "绥阳县",
            value: "绥阳县",
          },
          {
            areaCode: 520324000000,
            label: "正安县",
            value: "正安县",
          },
          {
            areaCode: 520325000000,
            label: "道真仡佬族苗族自治县",
            value: "道真仡佬族苗族自治县",
          },
          {
            areaCode: 520326000000,
            label: "务川仡佬族苗族自治县",
            value: "务川仡佬族苗族自治县",
          },
          {
            areaCode: 520327000000,
            label: "凤冈县",
            value: "凤冈县",
          },
          {
            areaCode: 520328000000,
            label: "湄潭县",
            value: "湄潭县",
          },
          {
            areaCode: 520329000000,
            label: "余庆县",
            value: "余庆县",
          },
          {
            areaCode: 520330000000,
            label: "习水县",
            value: "习水县",
          },
          {
            areaCode: 520381000000,
            label: "赤水市",
            value: "赤水市",
          },
          {
            areaCode: 520382000000,
            label: "仁怀市",
            value: "仁怀市",
          },
        ],
        label: "遵义市",
        value: "遵义市",
      },
      {
        areaCode: 520400000000,
        children: [
          {
            areaCode: 520402000000,
            label: "西秀区",
            value: "西秀区",
          },
          {
            areaCode: 520403000000,
            label: "平坝区",
            value: "平坝区",
          },
          {
            areaCode: 520422000000,
            label: "普定县",
            value: "普定县",
          },
          {
            areaCode: 520423000000,
            label: "镇宁布依族苗族自治县",
            value: "镇宁布依族苗族自治县",
          },
          {
            areaCode: 520424000000,
            label: "关岭布依族苗族自治县",
            value: "关岭布依族苗族自治县",
          },
          {
            areaCode: 520425000000,
            label: "紫云苗族布依族自治县",
            value: "紫云苗族布依族自治县",
          },
        ],
        label: "安顺市",
        value: "安顺市",
      },
      {
        areaCode: 520500000000,
        children: [
          {
            areaCode: 520502000000,
            label: "七星关区",
            value: "七星关区",
          },
          {
            areaCode: 520521000000,
            label: "大方县",
            value: "大方县",
          },
          {
            areaCode: 520522000000,
            label: "黔西县",
            value: "黔西县",
          },
          {
            areaCode: 520523000000,
            label: "金沙县",
            value: "金沙县",
          },
          {
            areaCode: 520524000000,
            label: "织金县",
            value: "织金县",
          },
          {
            areaCode: 520525000000,
            label: "纳雍县",
            value: "纳雍县",
          },
          {
            areaCode: 520526000000,
            label: "威宁彝族回族苗族自治县",
            value: "威宁彝族回族苗族自治县",
          },
          {
            areaCode: 520527000000,
            label: "赫章县",
            value: "赫章县",
          },
        ],
        label: "毕节市",
        value: "毕节市",
      },
      {
        areaCode: 520600000000,
        children: [
          {
            areaCode: 520602000000,
            label: "碧江区",
            value: "碧江区",
          },
          {
            areaCode: 520603000000,
            label: "万山区",
            value: "万山区",
          },
          {
            areaCode: 520621000000,
            label: "江口县",
            value: "江口县",
          },
          {
            areaCode: 520622000000,
            label: "玉屏侗族自治县",
            value: "玉屏侗族自治县",
          },
          {
            areaCode: 520623000000,
            label: "石阡县",
            value: "石阡县",
          },
          {
            areaCode: 520624000000,
            label: "思南县",
            value: "思南县",
          },
          {
            areaCode: 520625000000,
            label: "印江土家族苗族自治县",
            value: "印江土家族苗族自治县",
          },
          {
            areaCode: 520626000000,
            label: "德江县",
            value: "德江县",
          },
          {
            areaCode: 520627000000,
            label: "沿河土家族自治县",
            value: "沿河土家族自治县",
          },
          {
            areaCode: 520628000000,
            label: "松桃苗族自治县",
            value: "松桃苗族自治县",
          },
        ],
        label: "铜仁市",
        value: "铜仁市",
      },
      {
        areaCode: 522300000000,
        children: [
          {
            areaCode: 522301000000,
            label: "兴义市",
            value: "兴义市",
          },
          {
            areaCode: 522322000000,
            label: "兴仁县",
            value: "兴仁县",
          },
          {
            areaCode: 522323000000,
            label: "普安县",
            value: "普安县",
          },
          {
            areaCode: 522324000000,
            label: "晴隆县",
            value: "晴隆县",
          },
          {
            areaCode: 522325000000,
            label: "贞丰县",
            value: "贞丰县",
          },
          {
            areaCode: 522326000000,
            label: "望谟县",
            value: "望谟县",
          },
          {
            areaCode: 522327000000,
            label: "册亨县",
            value: "册亨县",
          },
          {
            areaCode: 522328000000,
            label: "安龙县",
            value: "安龙县",
          },
        ],
        label: "黔西南布依族苗族自治州",
        value: "黔西南布依族苗族自治州",
      },
      {
        areaCode: 522600000000,
        children: [
          {
            areaCode: 522601000000,
            label: "凯里市",
            value: "凯里市",
          },
          {
            areaCode: 522622000000,
            label: "黄平县",
            value: "黄平县",
          },
          {
            areaCode: 522623000000,
            label: "施秉县",
            value: "施秉县",
          },
          {
            areaCode: 522624000000,
            label: "三穗县",
            value: "三穗县",
          },
          {
            areaCode: 522625000000,
            label: "镇远县",
            value: "镇远县",
          },
          {
            areaCode: 522626000000,
            label: "岑巩县",
            value: "岑巩县",
          },
          {
            areaCode: 522627000000,
            label: "天柱县",
            value: "天柱县",
          },
          {
            areaCode: 522628000000,
            label: "锦屏县",
            value: "锦屏县",
          },
          {
            areaCode: 522629000000,
            label: "剑河县",
            value: "剑河县",
          },
          {
            areaCode: 522630000000,
            label: "台江县",
            value: "台江县",
          },
          {
            areaCode: 522631000000,
            label: "黎平县",
            value: "黎平县",
          },
          {
            areaCode: 522632000000,
            label: "榕江县",
            value: "榕江县",
          },
          {
            areaCode: 522633000000,
            label: "从江县",
            value: "从江县",
          },
          {
            areaCode: 522634000000,
            label: "雷山县",
            value: "雷山县",
          },
          {
            areaCode: 522635000000,
            label: "麻江县",
            value: "麻江县",
          },
          {
            areaCode: 522636000000,
            label: "丹寨县",
            value: "丹寨县",
          },
        ],
        label: "黔东南苗族侗族自治州",
        value: "黔东南苗族侗族自治州",
      },
      {
        areaCode: 522700000000,
        children: [
          {
            areaCode: 522701000000,
            label: "都匀市",
            value: "都匀市",
          },
          {
            areaCode: 522702000000,
            label: "福泉市",
            value: "福泉市",
          },
          {
            areaCode: 522722000000,
            label: "荔波县",
            value: "荔波县",
          },
          {
            areaCode: 522723000000,
            label: "贵定县",
            value: "贵定县",
          },
          {
            areaCode: 522725000000,
            label: "瓮安县",
            value: "瓮安县",
          },
          {
            areaCode: 522726000000,
            label: "独山县",
            value: "独山县",
          },
          {
            areaCode: 522727000000,
            label: "平塘县",
            value: "平塘县",
          },
          {
            areaCode: 522728000000,
            label: "罗甸县",
            value: "罗甸县",
          },
          {
            areaCode: 522729000000,
            label: "长顺县",
            value: "长顺县",
          },
          {
            areaCode: 522730000000,
            label: "龙里县",
            value: "龙里县",
          },
          {
            areaCode: 522731000000,
            label: "惠水县",
            value: "惠水县",
          },
          {
            areaCode: 522732000000,
            label: "三都水族自治县",
            value: "三都水族自治县",
          },
        ],
        label: "黔南布依族苗族自治州",
        value: "黔南布依族苗族自治州",
      },
    ],
    label: "贵州省",
    value: "贵州省",
  },
  {
    areaCode: 530000000000,
    children: [
      {
        areaCode: 530100000000,
        children: [
          {
            areaCode: 530102000000,
            label: "五华区",
            value: "五华区",
          },
          {
            areaCode: 530103000000,
            label: "盘龙区",
            value: "盘龙区",
          },
          {
            areaCode: 530111000000,
            label: "官渡区",
            value: "官渡区",
          },
          {
            areaCode: 530112000000,
            label: "西山区",
            value: "西山区",
          },
          {
            areaCode: 530113000000,
            label: "东川区",
            value: "东川区",
          },
          {
            areaCode: 530114000000,
            label: "呈贡区",
            value: "呈贡区",
          },
          {
            areaCode: 530122000000,
            label: "晋宁县",
            value: "晋宁县",
          },
          {
            areaCode: 530124000000,
            label: "富民县",
            value: "富民县",
          },
          {
            areaCode: 530125000000,
            label: "宜良县",
            value: "宜良县",
          },
          {
            areaCode: 530126000000,
            label: "石林彝族自治县",
            value: "石林彝族自治县",
          },
          {
            areaCode: 530127000000,
            label: "嵩明县",
            value: "嵩明县",
          },
          {
            areaCode: 530128000000,
            label: "禄劝彝族苗族自治县",
            value: "禄劝彝族苗族自治县",
          },
          {
            areaCode: 530129000000,
            label: "寻甸回族彝族自治县",
            value: "寻甸回族彝族自治县",
          },
          {
            areaCode: 530181000000,
            label: "安宁市",
            value: "安宁市",
          },
        ],
        label: "昆明市",
        value: "昆明市",
      },
      {
        areaCode: 530300000000,
        children: [
          {
            areaCode: 530302000000,
            label: "麒麟区",
            value: "麒麟区",
          },
          {
            areaCode: 530303000000,
            label: "沾益区",
            value: "沾益区",
          },
          {
            areaCode: 530321000000,
            label: "马龙县",
            value: "马龙县",
          },
          {
            areaCode: 530322000000,
            label: "陆良县",
            value: "陆良县",
          },
          {
            areaCode: 530323000000,
            label: "师宗县",
            value: "师宗县",
          },
          {
            areaCode: 530324000000,
            label: "罗平县",
            value: "罗平县",
          },
          {
            areaCode: 530325000000,
            label: "富源县",
            value: "富源县",
          },
          {
            areaCode: 530326000000,
            label: "会泽县",
            value: "会泽县",
          },
          {
            areaCode: 530381000000,
            label: "宣威市",
            value: "宣威市",
          },
        ],
        label: "曲靖市",
        value: "曲靖市",
      },
      {
        areaCode: 530400000000,
        children: [
          {
            areaCode: 530402000000,
            label: "红塔区",
            value: "红塔区",
          },
          {
            areaCode: 530403000000,
            label: "江川区",
            value: "江川区",
          },
          {
            areaCode: 530422000000,
            label: "澄江县",
            value: "澄江县",
          },
          {
            areaCode: 530423000000,
            label: "通海县",
            value: "通海县",
          },
          {
            areaCode: 530424000000,
            label: "华宁县",
            value: "华宁县",
          },
          {
            areaCode: 530425000000,
            label: "易门县",
            value: "易门县",
          },
          {
            areaCode: 530426000000,
            label: "峨山彝族自治县",
            value: "峨山彝族自治县",
          },
          {
            areaCode: 530427000000,
            label: "新平彝族傣族自治县",
            value: "新平彝族傣族自治县",
          },
          {
            areaCode: 530428000000,
            label: "元江哈尼族彝族傣族自治县",
            value: "元江哈尼族彝族傣族自治县",
          },
        ],
        label: "玉溪市",
        value: "玉溪市",
      },
      {
        areaCode: 530500000000,
        children: [
          {
            areaCode: 530502000000,
            label: "隆阳区",
            value: "隆阳区",
          },
          {
            areaCode: 530521000000,
            label: "施甸县",
            value: "施甸县",
          },
          {
            areaCode: 530523000000,
            label: "龙陵县",
            value: "龙陵县",
          },
          {
            areaCode: 530524000000,
            label: "昌宁县",
            value: "昌宁县",
          },
          {
            areaCode: 530581000000,
            label: "腾冲市",
            value: "腾冲市",
          },
        ],
        label: "保山市",
        value: "保山市",
      },
      {
        areaCode: 530600000000,
        children: [
          {
            areaCode: 530602000000,
            label: "昭阳区",
            value: "昭阳区",
          },
          {
            areaCode: 530621000000,
            label: "鲁甸县",
            value: "鲁甸县",
          },
          {
            areaCode: 530622000000,
            label: "巧家县",
            value: "巧家县",
          },
          {
            areaCode: 530623000000,
            label: "盐津县",
            value: "盐津县",
          },
          {
            areaCode: 530624000000,
            label: "大关县",
            value: "大关县",
          },
          {
            areaCode: 530625000000,
            label: "永善县",
            value: "永善县",
          },
          {
            areaCode: 530626000000,
            label: "绥江县",
            value: "绥江县",
          },
          {
            areaCode: 530627000000,
            label: "镇雄县",
            value: "镇雄县",
          },
          {
            areaCode: 530628000000,
            label: "彝良县",
            value: "彝良县",
          },
          {
            areaCode: 530629000000,
            label: "威信县",
            value: "威信县",
          },
          {
            areaCode: 530630000000,
            label: "水富县",
            value: "水富县",
          },
        ],
        label: "昭通市",
        value: "昭通市",
      },
      {
        areaCode: 530700000000,
        children: [
          {
            areaCode: 530702000000,
            label: "古城区",
            value: "古城区",
          },
          {
            areaCode: 530721000000,
            label: "玉龙纳西族自治县",
            value: "玉龙纳西族自治县",
          },
          {
            areaCode: 530722000000,
            label: "永胜县",
            value: "永胜县",
          },
          {
            areaCode: 530723000000,
            label: "华坪县",
            value: "华坪县",
          },
          {
            areaCode: 530724000000,
            label: "宁蒗彝族自治县",
            value: "宁蒗彝族自治县",
          },
        ],
        label: "丽江市",
        value: "丽江市",
      },
      {
        areaCode: 530800000000,
        children: [
          {
            areaCode: 530802000000,
            label: "思茅区",
            value: "思茅区",
          },
          {
            areaCode: 530821000000,
            label: "宁洱哈尼族彝族自治县",
            value: "宁洱哈尼族彝族自治县",
          },
          {
            areaCode: 530822000000,
            label: "墨江哈尼族自治县",
            value: "墨江哈尼族自治县",
          },
          {
            areaCode: 530823000000,
            label: "景东彝族自治县",
            value: "景东彝族自治县",
          },
          {
            areaCode: 530824000000,
            label: "景谷傣族彝族自治县",
            value: "景谷傣族彝族自治县",
          },
          {
            areaCode: 530825000000,
            label: "镇沅彝族哈尼族拉祜族自治县",
            value: "镇沅彝族哈尼族拉祜族自治县",
          },
          {
            areaCode: 530826000000,
            label: "江城哈尼族彝族自治县",
            value: "江城哈尼族彝族自治县",
          },
          {
            areaCode: 530827000000,
            label: "孟连傣族拉祜族佤族自治县",
            value: "孟连傣族拉祜族佤族自治县",
          },
          {
            areaCode: 530828000000,
            label: "澜沧拉祜族自治县",
            value: "澜沧拉祜族自治县",
          },
          {
            areaCode: 530829000000,
            label: "西盟佤族自治县",
            value: "西盟佤族自治县",
          },
        ],
        label: "普洱市",
        value: "普洱市",
      },
      {
        areaCode: 530900000000,
        children: [
          {
            areaCode: 530902000000,
            label: "临翔区",
            value: "临翔区",
          },
          {
            areaCode: 530921000000,
            label: "凤庆县",
            value: "凤庆县",
          },
          {
            areaCode: 530922000000,
            label: "云县",
            value: "云县",
          },
          {
            areaCode: 530923000000,
            label: "永德县",
            value: "永德县",
          },
          {
            areaCode: 530924000000,
            label: "镇康县",
            value: "镇康县",
          },
          {
            areaCode: 530925000000,
            label: "双江拉祜族佤族布朗族傣族自治县",
            value: "双江拉祜族佤族布朗族傣族自治县",
          },
          {
            areaCode: 530926000000,
            label: "耿马傣族佤族自治县",
            value: "耿马傣族佤族自治县",
          },
          {
            areaCode: 530927000000,
            label: "沧源佤族自治县",
            value: "沧源佤族自治县",
          },
        ],
        label: "临沧市",
        value: "临沧市",
      },
      {
        areaCode: 532300000000,
        children: [
          {
            areaCode: 532301000000,
            label: "楚雄市",
            value: "楚雄市",
          },
          {
            areaCode: 532322000000,
            label: "双柏县",
            value: "双柏县",
          },
          {
            areaCode: 532323000000,
            label: "牟定县",
            value: "牟定县",
          },
          {
            areaCode: 532324000000,
            label: "南华县",
            value: "南华县",
          },
          {
            areaCode: 532325000000,
            label: "姚安县",
            value: "姚安县",
          },
          {
            areaCode: 532326000000,
            label: "大姚县",
            value: "大姚县",
          },
          {
            areaCode: 532327000000,
            label: "永仁县",
            value: "永仁县",
          },
          {
            areaCode: 532328000000,
            label: "元谋县",
            value: "元谋县",
          },
          {
            areaCode: 532329000000,
            label: "武定县",
            value: "武定县",
          },
          {
            areaCode: 532331000000,
            label: "禄丰县",
            value: "禄丰县",
          },
        ],
        label: "楚雄彝族自治州",
        value: "楚雄彝族自治州",
      },
      {
        areaCode: 532500000000,
        children: [
          {
            areaCode: 532501000000,
            label: "个旧市",
            value: "个旧市",
          },
          {
            areaCode: 532502000000,
            label: "开远市",
            value: "开远市",
          },
          {
            areaCode: 532503000000,
            label: "蒙自市",
            value: "蒙自市",
          },
          {
            areaCode: 532504000000,
            label: "弥勒市",
            value: "弥勒市",
          },
          {
            areaCode: 532523000000,
            label: "屏边苗族自治县",
            value: "屏边苗族自治县",
          },
          {
            areaCode: 532524000000,
            label: "建水县",
            value: "建水县",
          },
          {
            areaCode: 532525000000,
            label: "石屏县",
            value: "石屏县",
          },
          {
            areaCode: 532527000000,
            label: "泸西县",
            value: "泸西县",
          },
          {
            areaCode: 532528000000,
            label: "元阳县",
            value: "元阳县",
          },
          {
            areaCode: 532529000000,
            label: "红河县",
            value: "红河县",
          },
          {
            areaCode: 532530000000,
            label: "金平苗族瑶族傣族自治县",
            value: "金平苗族瑶族傣族自治县",
          },
          {
            areaCode: 532531000000,
            label: "绿春县",
            value: "绿春县",
          },
          {
            areaCode: 532532000000,
            label: "河口瑶族自治县",
            value: "河口瑶族自治县",
          },
        ],
        label: "红河哈尼族彝族自治州",
        value: "红河哈尼族彝族自治州",
      },
      {
        areaCode: 532600000000,
        children: [
          {
            areaCode: 532601000000,
            label: "文山市",
            value: "文山市",
          },
          {
            areaCode: 532622000000,
            label: "砚山县",
            value: "砚山县",
          },
          {
            areaCode: 532623000000,
            label: "西畴县",
            value: "西畴县",
          },
          {
            areaCode: 532624000000,
            label: "麻栗坡县",
            value: "麻栗坡县",
          },
          {
            areaCode: 532625000000,
            label: "马关县",
            value: "马关县",
          },
          {
            areaCode: 532626000000,
            label: "丘北县",
            value: "丘北县",
          },
          {
            areaCode: 532627000000,
            label: "广南县",
            value: "广南县",
          },
          {
            areaCode: 532628000000,
            label: "富宁县",
            value: "富宁县",
          },
        ],
        label: "文山壮族苗族自治州",
        value: "文山壮族苗族自治州",
      },
      {
        areaCode: 532800000000,
        children: [
          {
            areaCode: 532801000000,
            label: "景洪市",
            value: "景洪市",
          },
          {
            areaCode: 532822000000,
            label: "勐海县",
            value: "勐海县",
          },
          {
            areaCode: 532823000000,
            label: "勐腊县",
            value: "勐腊县",
          },
        ],
        label: "西双版纳傣族自治州",
        value: "西双版纳傣族自治州",
      },
      {
        areaCode: 532900000000,
        children: [
          {
            areaCode: 532901000000,
            label: "大理市",
            value: "大理市",
          },
          {
            areaCode: 532922000000,
            label: "漾濞彝族自治县",
            value: "漾濞彝族自治县",
          },
          {
            areaCode: 532923000000,
            label: "祥云县",
            value: "祥云县",
          },
          {
            areaCode: 532924000000,
            label: "宾川县",
            value: "宾川县",
          },
          {
            areaCode: 532925000000,
            label: "弥渡县",
            value: "弥渡县",
          },
          {
            areaCode: 532926000000,
            label: "南涧彝族自治县",
            value: "南涧彝族自治县",
          },
          {
            areaCode: 532927000000,
            label: "巍山彝族回族自治县",
            value: "巍山彝族回族自治县",
          },
          {
            areaCode: 532928000000,
            label: "永平县",
            value: "永平县",
          },
          {
            areaCode: 532929000000,
            label: "云龙县",
            value: "云龙县",
          },
          {
            areaCode: 532930000000,
            label: "洱源县",
            value: "洱源县",
          },
          {
            areaCode: 532931000000,
            label: "剑川县",
            value: "剑川县",
          },
          {
            areaCode: 532932000000,
            label: "鹤庆县",
            value: "鹤庆县",
          },
        ],
        label: "大理白族自治州",
        value: "大理白族自治州",
      },
      {
        areaCode: 533100000000,
        children: [
          {
            areaCode: 533102000000,
            label: "瑞丽市",
            value: "瑞丽市",
          },
          {
            areaCode: 533103000000,
            label: "芒市",
            value: "芒市",
          },
          {
            areaCode: 533122000000,
            label: "梁河县",
            value: "梁河县",
          },
          {
            areaCode: 533123000000,
            label: "盈江县",
            value: "盈江县",
          },
          {
            areaCode: 533124000000,
            label: "陇川县",
            value: "陇川县",
          },
        ],
        label: "德宏傣族景颇族自治州",
        value: "德宏傣族景颇族自治州",
      },
      {
        areaCode: 533300000000,
        children: [
          {
            areaCode: 533301000000,
            label: "泸水市",
            value: "泸水市",
          },
          {
            areaCode: 533323000000,
            label: "福贡县",
            value: "福贡县",
          },
          {
            areaCode: 533324000000,
            label: "贡山独龙族怒族自治县",
            value: "贡山独龙族怒族自治县",
          },
          {
            areaCode: 533325000000,
            label: "兰坪白族普米族自治县",
            value: "兰坪白族普米族自治县",
          },
        ],
        label: "怒江傈僳族自治州",
        value: "怒江傈僳族自治州",
      },
      {
        areaCode: 533400000000,
        children: [
          {
            areaCode: 533401000000,
            label: "香格里拉市",
            value: "香格里拉市",
          },
          {
            areaCode: 533422000000,
            label: "德钦县",
            value: "德钦县",
          },
          {
            areaCode: 533423000000,
            label: "维西傈僳族自治县",
            value: "维西傈僳族自治县",
          },
        ],
        label: "迪庆藏族自治州",
        value: "迪庆藏族自治州",
      },
    ],
    label: "云南省",
    value: "云南省",
  },
  {
    areaCode: 540000000000,
    children: [
      {
        areaCode: 540100000000,
        children: [
          {
            areaCode: 540102000000,
            label: "城关区",
            value: "城关区",
          },
          {
            areaCode: 540103000000,
            label: "堆龙德庆区",
            value: "堆龙德庆区",
          },
          {
            areaCode: 540121000000,
            label: "林周县",
            value: "林周县",
          },
          {
            areaCode: 540122000000,
            label: "当雄县",
            value: "当雄县",
          },
          {
            areaCode: 540123000000,
            label: "尼木县",
            value: "尼木县",
          },
          {
            areaCode: 540124000000,
            label: "曲水县",
            value: "曲水县",
          },
          {
            areaCode: 540126000000,
            label: "达孜县",
            value: "达孜县",
          },
          {
            areaCode: 540127000000,
            label: "墨竹工卡县",
            value: "墨竹工卡县",
          },
        ],
        label: "拉萨市",
        value: "拉萨市",
      },
      {
        areaCode: 540200000000,
        children: [
          {
            areaCode: 540202000000,
            label: "桑珠孜区",
            value: "桑珠孜区",
          },
          {
            areaCode: 540221000000,
            label: "南木林县",
            value: "南木林县",
          },
          {
            areaCode: 540222000000,
            label: "江孜县",
            value: "江孜县",
          },
          {
            areaCode: 540223000000,
            label: "定日县",
            value: "定日县",
          },
          {
            areaCode: 540224000000,
            label: "萨迦县",
            value: "萨迦县",
          },
          {
            areaCode: 540225000000,
            label: "拉孜县",
            value: "拉孜县",
          },
          {
            areaCode: 540226000000,
            label: "昂仁县",
            value: "昂仁县",
          },
          {
            areaCode: 540227000000,
            label: "谢通门县",
            value: "谢通门县",
          },
          {
            areaCode: 540228000000,
            label: "白朗县",
            value: "白朗县",
          },
          {
            areaCode: 540229000000,
            label: "仁布县",
            value: "仁布县",
          },
          {
            areaCode: 540230000000,
            label: "康马县",
            value: "康马县",
          },
          {
            areaCode: 540231000000,
            label: "定结县",
            value: "定结县",
          },
          {
            areaCode: 540232000000,
            label: "仲巴县",
            value: "仲巴县",
          },
          {
            areaCode: 540233000000,
            label: "亚东县",
            value: "亚东县",
          },
          {
            areaCode: 540234000000,
            label: "吉隆县",
            value: "吉隆县",
          },
          {
            areaCode: 540235000000,
            label: "聂拉木县",
            value: "聂拉木县",
          },
          {
            areaCode: 540236000000,
            label: "萨嘎县",
            value: "萨嘎县",
          },
          {
            areaCode: 540237000000,
            label: "岗巴县",
            value: "岗巴县",
          },
        ],
        label: "日喀则市",
        value: "日喀则市",
      },
      {
        areaCode: 540300000000,
        children: [
          {
            areaCode: 540302000000,
            label: "卡若区",
            value: "卡若区",
          },
          {
            areaCode: 540321000000,
            label: "江达县",
            value: "江达县",
          },
          {
            areaCode: 540322000000,
            label: "贡觉县",
            value: "贡觉县",
          },
          {
            areaCode: 540323000000,
            label: "类乌齐县",
            value: "类乌齐县",
          },
          {
            areaCode: 540324000000,
            label: "丁青县",
            value: "丁青县",
          },
          {
            areaCode: 540325000000,
            label: "察雅县",
            value: "察雅县",
          },
          {
            areaCode: 540326000000,
            label: "八宿县",
            value: "八宿县",
          },
          {
            areaCode: 540327000000,
            label: "左贡县",
            value: "左贡县",
          },
          {
            areaCode: 540328000000,
            label: "芒康县",
            value: "芒康县",
          },
          {
            areaCode: 540329000000,
            label: "洛隆县",
            value: "洛隆县",
          },
          {
            areaCode: 540330000000,
            label: "边坝县",
            value: "边坝县",
          },
        ],
        label: "昌都市",
        value: "昌都市",
      },
      {
        areaCode: 540400000000,
        children: [
          {
            areaCode: 540402000000,
            label: "巴宜区",
            value: "巴宜区",
          },
          {
            areaCode: 540421000000,
            label: "工布江达县",
            value: "工布江达县",
          },
          {
            areaCode: 540422000000,
            label: "米林县",
            value: "米林县",
          },
          {
            areaCode: 540423000000,
            label: "墨脱县",
            value: "墨脱县",
          },
          {
            areaCode: 540424000000,
            label: "波密县",
            value: "波密县",
          },
          {
            areaCode: 540425000000,
            label: "察隅县",
            value: "察隅县",
          },
          {
            areaCode: 540426000000,
            label: "朗县",
            value: "朗县",
          },
        ],
        label: "林芝市",
        value: "林芝市",
      },
      {
        areaCode: 540500000000,
        children: [
          {
            areaCode: 540502000000,
            label: "乃东区",
            value: "乃东区",
          },
          {
            areaCode: 540521000000,
            label: "扎囊县",
            value: "扎囊县",
          },
          {
            areaCode: 540522000000,
            label: "贡嘎县",
            value: "贡嘎县",
          },
          {
            areaCode: 540523000000,
            label: "桑日县",
            value: "桑日县",
          },
          {
            areaCode: 540524000000,
            label: "琼结县",
            value: "琼结县",
          },
          {
            areaCode: 540525000000,
            label: "曲松县",
            value: "曲松县",
          },
          {
            areaCode: 540526000000,
            label: "措美县",
            value: "措美县",
          },
          {
            areaCode: 540527000000,
            label: "洛扎县",
            value: "洛扎县",
          },
          {
            areaCode: 540528000000,
            label: "加查县",
            value: "加查县",
          },
          {
            areaCode: 540529000000,
            label: "隆子县",
            value: "隆子县",
          },
          {
            areaCode: 540530000000,
            label: "错那县",
            value: "错那县",
          },
          {
            areaCode: 540531000000,
            label: "浪卡子县",
            value: "浪卡子县",
          },
        ],
        label: "山南市",
        value: "山南市",
      },
      {
        areaCode: 542400000000,
        children: [
          {
            areaCode: 542421000000,
            label: "那曲县",
            value: "那曲县",
          },
          {
            areaCode: 542422000000,
            label: "嘉黎县",
            value: "嘉黎县",
          },
          {
            areaCode: 542423000000,
            label: "比如县",
            value: "比如县",
          },
          {
            areaCode: 542424000000,
            label: "聂荣县",
            value: "聂荣县",
          },
          {
            areaCode: 542425000000,
            label: "安多县",
            value: "安多县",
          },
          {
            areaCode: 542426000000,
            label: "申扎县",
            value: "申扎县",
          },
          {
            areaCode: 542427000000,
            label: "索县",
            value: "索县",
          },
          {
            areaCode: 542428000000,
            label: "班戈县",
            value: "班戈县",
          },
          {
            areaCode: 542429000000,
            label: "巴青县",
            value: "巴青县",
          },
          {
            areaCode: 542430000000,
            label: "尼玛县",
            value: "尼玛县",
          },
          {
            areaCode: 542431000000,
            label: "双湖县",
            value: "双湖县",
          },
        ],
        label: "那曲地区",
        value: "那曲地区",
      },
      {
        areaCode: 542500000000,
        children: [
          {
            areaCode: 542521000000,
            label: "普兰县",
            value: "普兰县",
          },
          {
            areaCode: 542522000000,
            label: "札达县",
            value: "札达县",
          },
          {
            areaCode: 542523000000,
            label: "噶尔县",
            value: "噶尔县",
          },
          {
            areaCode: 542524000000,
            label: "日土县",
            value: "日土县",
          },
          {
            areaCode: 542525000000,
            label: "革吉县",
            value: "革吉县",
          },
          {
            areaCode: 542526000000,
            label: "改则县",
            value: "改则县",
          },
          {
            areaCode: 542527000000,
            label: "措勤县",
            value: "措勤县",
          },
        ],
        label: "阿里地区",
        value: "阿里地区",
      },
    ],
    label: "西藏自治区",
    value: "西藏自治区",
  },
  {
    areaCode: 610000000000,
    children: [
      {
        areaCode: 610100000000,
        children: [
          {
            areaCode: 610102000000,
            label: "新城区",
            value: "新城区",
          },
          {
            areaCode: 610103000000,
            label: "碑林区",
            value: "碑林区",
          },
          {
            areaCode: 610104000000,
            label: "莲湖区",
            value: "莲湖区",
          },
          {
            areaCode: 610111000000,
            label: "灞桥区",
            value: "灞桥区",
          },
          {
            areaCode: 610112000000,
            label: "未央区",
            value: "未央区",
          },
          {
            areaCode: 610113000000,
            label: "雁塔区",
            value: "雁塔区",
          },
          {
            areaCode: 610114000000,
            label: "阎良区",
            value: "阎良区",
          },
          {
            areaCode: 610115000000,
            label: "临潼区",
            value: "临潼区",
          },
          {
            areaCode: 610116000000,
            label: "长安区",
            value: "长安区",
          },
          {
            areaCode: 610117000000,
            label: "高陵区",
            value: "高陵区",
          },
          {
            areaCode: 610122000000,
            label: "蓝田县",
            value: "蓝田县",
          },
          {
            areaCode: 610124000000,
            label: "周至县",
            value: "周至县",
          },
          {
            areaCode: 610125000000,
            label: "户县",
            value: "户县",
          },
        ],
        label: "西安市",
        value: "西安市",
      },
      {
        areaCode: 610200000000,
        children: [
          {
            areaCode: 610202000000,
            label: "王益区",
            value: "王益区",
          },
          {
            areaCode: 610203000000,
            label: "印台区",
            value: "印台区",
          },
          {
            areaCode: 610204000000,
            label: "耀州区",
            value: "耀州区",
          },
          {
            areaCode: 610222000000,
            label: "宜君县",
            value: "宜君县",
          },
        ],
        label: "铜川市",
        value: "铜川市",
      },
      {
        areaCode: 610300000000,
        children: [
          {
            areaCode: 610302000000,
            label: "渭滨区",
            value: "渭滨区",
          },
          {
            areaCode: 610303000000,
            label: "金台区",
            value: "金台区",
          },
          {
            areaCode: 610304000000,
            label: "陈仓区",
            value: "陈仓区",
          },
          {
            areaCode: 610322000000,
            label: "凤翔县",
            value: "凤翔县",
          },
          {
            areaCode: 610323000000,
            label: "岐山县",
            value: "岐山县",
          },
          {
            areaCode: 610324000000,
            label: "扶风县",
            value: "扶风县",
          },
          {
            areaCode: 610326000000,
            label: "眉县",
            value: "眉县",
          },
          {
            areaCode: 610327000000,
            label: "陇县",
            value: "陇县",
          },
          {
            areaCode: 610328000000,
            label: "千阳县",
            value: "千阳县",
          },
          {
            areaCode: 610329000000,
            label: "麟游县",
            value: "麟游县",
          },
          {
            areaCode: 610330000000,
            label: "凤县",
            value: "凤县",
          },
          {
            areaCode: 610331000000,
            label: "太白县",
            value: "太白县",
          },
        ],
        label: "宝鸡市",
        value: "宝鸡市",
      },
      {
        areaCode: 610400000000,
        children: [
          {
            areaCode: 610402000000,
            label: "秦都区",
            value: "秦都区",
          },
          {
            areaCode: 610403000000,
            label: "杨陵区",
            value: "杨陵区",
          },
          {
            areaCode: 610404000000,
            label: "渭城区",
            value: "渭城区",
          },
          {
            areaCode: 610422000000,
            label: "三原县",
            value: "三原县",
          },
          {
            areaCode: 610423000000,
            label: "泾阳县",
            value: "泾阳县",
          },
          {
            areaCode: 610424000000,
            label: "乾县",
            value: "乾县",
          },
          {
            areaCode: 610425000000,
            label: "礼泉县",
            value: "礼泉县",
          },
          {
            areaCode: 610426000000,
            label: "永寿县",
            value: "永寿县",
          },
          {
            areaCode: 610427000000,
            label: "彬县",
            value: "彬县",
          },
          {
            areaCode: 610428000000,
            label: "长武县",
            value: "长武县",
          },
          {
            areaCode: 610429000000,
            label: "旬邑县",
            value: "旬邑县",
          },
          {
            areaCode: 610430000000,
            label: "淳化县",
            value: "淳化县",
          },
          {
            areaCode: 610431000000,
            label: "武功县",
            value: "武功县",
          },
          {
            areaCode: 610481000000,
            label: "兴平市",
            value: "兴平市",
          },
        ],
        label: "咸阳市",
        value: "咸阳市",
      },
      {
        areaCode: 610500000000,
        children: [
          {
            areaCode: 610502000000,
            label: "临渭区",
            value: "临渭区",
          },
          {
            areaCode: 610503000000,
            label: "华州区",
            value: "华州区",
          },
          {
            areaCode: 610522000000,
            label: "潼关县",
            value: "潼关县",
          },
          {
            areaCode: 610523000000,
            label: "大荔县",
            value: "大荔县",
          },
          {
            areaCode: 610524000000,
            label: "合阳县",
            value: "合阳县",
          },
          {
            areaCode: 610525000000,
            label: "澄城县",
            value: "澄城县",
          },
          {
            areaCode: 610526000000,
            label: "蒲城县",
            value: "蒲城县",
          },
          {
            areaCode: 610527000000,
            label: "白水县",
            value: "白水县",
          },
          {
            areaCode: 610528000000,
            label: "富平县",
            value: "富平县",
          },
          {
            areaCode: 610581000000,
            label: "韩城市",
            value: "韩城市",
          },
          {
            areaCode: 610582000000,
            label: "华阴市",
            value: "华阴市",
          },
        ],
        label: "渭南市",
        value: "渭南市",
      },
      {
        areaCode: 610600000000,
        children: [
          {
            areaCode: 610602000000,
            label: "宝塔区",
            value: "宝塔区",
          },
          {
            areaCode: 610603000000,
            label: "安塞区",
            value: "安塞区",
          },
          {
            areaCode: 610621000000,
            label: "延长县",
            value: "延长县",
          },
          {
            areaCode: 610622000000,
            label: "延川县",
            value: "延川县",
          },
          {
            areaCode: 610623000000,
            label: "子长县",
            value: "子长县",
          },
          {
            areaCode: 610625000000,
            label: "志丹县",
            value: "志丹县",
          },
          {
            areaCode: 610626000000,
            label: "吴起县",
            value: "吴起县",
          },
          {
            areaCode: 610627000000,
            label: "甘泉县",
            value: "甘泉县",
          },
          {
            areaCode: 610628000000,
            label: "富县",
            value: "富县",
          },
          {
            areaCode: 610629000000,
            label: "洛川县",
            value: "洛川县",
          },
          {
            areaCode: 610630000000,
            label: "宜川县",
            value: "宜川县",
          },
          {
            areaCode: 610631000000,
            label: "黄龙县",
            value: "黄龙县",
          },
          {
            areaCode: 610632000000,
            label: "黄陵县",
            value: "黄陵县",
          },
        ],
        label: "延安市",
        value: "延安市",
      },
      {
        areaCode: 610700000000,
        children: [
          {
            areaCode: 610702000000,
            label: "汉台区",
            value: "汉台区",
          },
          {
            areaCode: 610721000000,
            label: "南郑县",
            value: "南郑县",
          },
          {
            areaCode: 610722000000,
            label: "城固县",
            value: "城固县",
          },
          {
            areaCode: 610723000000,
            label: "洋县",
            value: "洋县",
          },
          {
            areaCode: 610724000000,
            label: "西乡县",
            value: "西乡县",
          },
          {
            areaCode: 610725000000,
            label: "勉县",
            value: "勉县",
          },
          {
            areaCode: 610726000000,
            label: "宁强县",
            value: "宁强县",
          },
          {
            areaCode: 610727000000,
            label: "略阳县",
            value: "略阳县",
          },
          {
            areaCode: 610728000000,
            label: "镇巴县",
            value: "镇巴县",
          },
          {
            areaCode: 610729000000,
            label: "留坝县",
            value: "留坝县",
          },
          {
            areaCode: 610730000000,
            label: "佛坪县",
            value: "佛坪县",
          },
        ],
        label: "汉中市",
        value: "汉中市",
      },
      {
        areaCode: 610800000000,
        children: [
          {
            areaCode: 610802000000,
            label: "榆阳区",
            value: "榆阳区",
          },
          {
            areaCode: 610803000000,
            label: "横山区",
            value: "横山区",
          },
          {
            areaCode: 610821000000,
            label: "神木县",
            value: "神木县",
          },
          {
            areaCode: 610822000000,
            label: "府谷县",
            value: "府谷县",
          },
          {
            areaCode: 610824000000,
            label: "靖边县",
            value: "靖边县",
          },
          {
            areaCode: 610825000000,
            label: "定边县",
            value: "定边县",
          },
          {
            areaCode: 610826000000,
            label: "绥德县",
            value: "绥德县",
          },
          {
            areaCode: 610827000000,
            label: "米脂县",
            value: "米脂县",
          },
          {
            areaCode: 610828000000,
            label: "佳县",
            value: "佳县",
          },
          {
            areaCode: 610829000000,
            label: "吴堡县",
            value: "吴堡县",
          },
          {
            areaCode: 610830000000,
            label: "清涧县",
            value: "清涧县",
          },
          {
            areaCode: 610831000000,
            label: "子洲县",
            value: "子洲县",
          },
        ],
        label: "榆林市",
        value: "榆林市",
      },
      {
        areaCode: 610900000000,
        children: [
          {
            areaCode: 610902000000,
            label: "汉滨区",
            value: "汉滨区",
          },
          {
            areaCode: 610921000000,
            label: "汉阴县",
            value: "汉阴县",
          },
          {
            areaCode: 610922000000,
            label: "石泉县",
            value: "石泉县",
          },
          {
            areaCode: 610923000000,
            label: "宁陕县",
            value: "宁陕县",
          },
          {
            areaCode: 610924000000,
            label: "紫阳县",
            value: "紫阳县",
          },
          {
            areaCode: 610925000000,
            label: "岚皋县",
            value: "岚皋县",
          },
          {
            areaCode: 610926000000,
            label: "平利县",
            value: "平利县",
          },
          {
            areaCode: 610927000000,
            label: "镇坪县",
            value: "镇坪县",
          },
          {
            areaCode: 610928000000,
            label: "旬阳县",
            value: "旬阳县",
          },
          {
            areaCode: 610929000000,
            label: "白河县",
            value: "白河县",
          },
        ],
        label: "安康市",
        value: "安康市",
      },
      {
        areaCode: 611000000000,
        children: [
          {
            areaCode: 611002000000,
            label: "商州区",
            value: "商州区",
          },
          {
            areaCode: 611021000000,
            label: "洛南县",
            value: "洛南县",
          },
          {
            areaCode: 611022000000,
            label: "丹凤县",
            value: "丹凤县",
          },
          {
            areaCode: 611023000000,
            label: "商南县",
            value: "商南县",
          },
          {
            areaCode: 611024000000,
            label: "山阳县",
            value: "山阳县",
          },
          {
            areaCode: 611025000000,
            label: "镇安县",
            value: "镇安县",
          },
          {
            areaCode: 611026000000,
            label: "柞水县",
            value: "柞水县",
          },
        ],
        label: "商洛市",
        value: "商洛市",
      },
    ],
    label: "陕西省",
    value: "陕西省",
  },
  {
    areaCode: 620000000000,
    children: [
      {
        areaCode: 620100000000,
        children: [
          {
            areaCode: 620102000000,
            label: "城关区",
            value: "城关区",
          },
          {
            areaCode: 620103000000,
            label: "七里河区",
            value: "七里河区",
          },
          {
            areaCode: 620104000000,
            label: "西固区",
            value: "西固区",
          },
          {
            areaCode: 620105000000,
            label: "安宁区",
            value: "安宁区",
          },
          {
            areaCode: 620111000000,
            label: "红古区",
            value: "红古区",
          },
          {
            areaCode: 620121000000,
            label: "永登县",
            value: "永登县",
          },
          {
            areaCode: 620122000000,
            label: "皋兰县",
            value: "皋兰县",
          },
          {
            areaCode: 620123000000,
            label: "榆中县",
            value: "榆中县",
          },
        ],
        label: "兰州市",
        value: "兰州市",
      },
      {
        areaCode: 620200000000,
        children: [],
        label: "嘉峪关市",
        value: "嘉峪关市",
      },
      {
        areaCode: 620300000000,
        children: [
          {
            areaCode: 620302000000,
            label: "金川区",
            value: "金川区",
          },
          {
            areaCode: 620321000000,
            label: "永昌县",
            value: "永昌县",
          },
        ],
        label: "金昌市",
        value: "金昌市",
      },
      {
        areaCode: 620400000000,
        children: [
          {
            areaCode: 620402000000,
            label: "白银区",
            value: "白银区",
          },
          {
            areaCode: 620403000000,
            label: "平川区",
            value: "平川区",
          },
          {
            areaCode: 620421000000,
            label: "靖远县",
            value: "靖远县",
          },
          {
            areaCode: 620422000000,
            label: "会宁县",
            value: "会宁县",
          },
          {
            areaCode: 620423000000,
            label: "景泰县",
            value: "景泰县",
          },
        ],
        label: "白银市",
        value: "白银市",
      },
      {
        areaCode: 620500000000,
        children: [
          {
            areaCode: 620502000000,
            label: "秦州区",
            value: "秦州区",
          },
          {
            areaCode: 620503000000,
            label: "麦积区",
            value: "麦积区",
          },
          {
            areaCode: 620521000000,
            label: "清水县",
            value: "清水县",
          },
          {
            areaCode: 620522000000,
            label: "秦安县",
            value: "秦安县",
          },
          {
            areaCode: 620523000000,
            label: "甘谷县",
            value: "甘谷县",
          },
          {
            areaCode: 620524000000,
            label: "武山县",
            value: "武山县",
          },
          {
            areaCode: 620525000000,
            label: "张家川回族自治县",
            value: "张家川回族自治县",
          },
        ],
        label: "天水市",
        value: "天水市",
      },
      {
        areaCode: 620600000000,
        children: [
          {
            areaCode: 620602000000,
            label: "凉州区",
            value: "凉州区",
          },
          {
            areaCode: 620621000000,
            label: "民勤县",
            value: "民勤县",
          },
          {
            areaCode: 620622000000,
            label: "古浪县",
            value: "古浪县",
          },
          {
            areaCode: 620623000000,
            label: "天祝藏族自治县",
            value: "天祝藏族自治县",
          },
        ],
        label: "武威市",
        value: "武威市",
      },
      {
        areaCode: 620700000000,
        children: [
          {
            areaCode: 620702000000,
            label: "甘州区",
            value: "甘州区",
          },
          {
            areaCode: 620721000000,
            label: "肃南裕固族自治县",
            value: "肃南裕固族自治县",
          },
          {
            areaCode: 620722000000,
            label: "民乐县",
            value: "民乐县",
          },
          {
            areaCode: 620723000000,
            label: "临泽县",
            value: "临泽县",
          },
          {
            areaCode: 620724000000,
            label: "高台县",
            value: "高台县",
          },
          {
            areaCode: 620725000000,
            label: "山丹县",
            value: "山丹县",
          },
        ],
        label: "张掖市",
        value: "张掖市",
      },
      {
        areaCode: 620800000000,
        children: [
          {
            areaCode: 620802000000,
            label: "崆峒区",
            value: "崆峒区",
          },
          {
            areaCode: 620821000000,
            label: "泾川县",
            value: "泾川县",
          },
          {
            areaCode: 620822000000,
            label: "灵台县",
            value: "灵台县",
          },
          {
            areaCode: 620823000000,
            label: "崇信县",
            value: "崇信县",
          },
          {
            areaCode: 620824000000,
            label: "华亭县",
            value: "华亭县",
          },
          {
            areaCode: 620825000000,
            label: "庄浪县",
            value: "庄浪县",
          },
          {
            areaCode: 620826000000,
            label: "静宁县",
            value: "静宁县",
          },
        ],
        label: "平凉市",
        value: "平凉市",
      },
      {
        areaCode: 620900000000,
        children: [
          {
            areaCode: 620902000000,
            label: "肃州区",
            value: "肃州区",
          },
          {
            areaCode: 620921000000,
            label: "金塔县",
            value: "金塔县",
          },
          {
            areaCode: 620922000000,
            label: "瓜州县",
            value: "瓜州县",
          },
          {
            areaCode: 620923000000,
            label: "肃北蒙古族自治县",
            value: "肃北蒙古族自治县",
          },
          {
            areaCode: 620924000000,
            label: "阿克塞哈萨克族自治县",
            value: "阿克塞哈萨克族自治县",
          },
          {
            areaCode: 620981000000,
            label: "玉门市",
            value: "玉门市",
          },
          {
            areaCode: 620982000000,
            label: "敦煌市",
            value: "敦煌市",
          },
        ],
        label: "酒泉市",
        value: "酒泉市",
      },
      {
        areaCode: 621000000000,
        children: [
          {
            areaCode: 621002000000,
            label: "西峰区",
            value: "西峰区",
          },
          {
            areaCode: 621021000000,
            label: "庆城县",
            value: "庆城县",
          },
          {
            areaCode: 621022000000,
            label: "环县",
            value: "环县",
          },
          {
            areaCode: 621023000000,
            label: "华池县",
            value: "华池县",
          },
          {
            areaCode: 621024000000,
            label: "合水县",
            value: "合水县",
          },
          {
            areaCode: 621025000000,
            label: "正宁县",
            value: "正宁县",
          },
          {
            areaCode: 621026000000,
            label: "宁县",
            value: "宁县",
          },
          {
            areaCode: 621027000000,
            label: "镇原县",
            value: "镇原县",
          },
        ],
        label: "庆阳市",
        value: "庆阳市",
      },
      {
        areaCode: 621100000000,
        children: [
          {
            areaCode: 621102000000,
            label: "安定区",
            value: "安定区",
          },
          {
            areaCode: 621121000000,
            label: "通渭县",
            value: "通渭县",
          },
          {
            areaCode: 621122000000,
            label: "陇西县",
            value: "陇西县",
          },
          {
            areaCode: 621123000000,
            label: "渭源县",
            value: "渭源县",
          },
          {
            areaCode: 621124000000,
            label: "临洮县",
            value: "临洮县",
          },
          {
            areaCode: 621125000000,
            label: "漳县",
            value: "漳县",
          },
          {
            areaCode: 621126000000,
            label: "岷县",
            value: "岷县",
          },
        ],
        label: "定西市",
        value: "定西市",
      },
      {
        areaCode: 621200000000,
        children: [
          {
            areaCode: 621202000000,
            label: "武都区",
            value: "武都区",
          },
          {
            areaCode: 621221000000,
            label: "成县",
            value: "成县",
          },
          {
            areaCode: 621222000000,
            label: "文县",
            value: "文县",
          },
          {
            areaCode: 621223000000,
            label: "宕昌县",
            value: "宕昌县",
          },
          {
            areaCode: 621224000000,
            label: "康县",
            value: "康县",
          },
          {
            areaCode: 621225000000,
            label: "西和县",
            value: "西和县",
          },
          {
            areaCode: 621226000000,
            label: "礼县",
            value: "礼县",
          },
          {
            areaCode: 621227000000,
            label: "徽县",
            value: "徽县",
          },
          {
            areaCode: 621228000000,
            label: "两当县",
            value: "两当县",
          },
        ],
        label: "陇南市",
        value: "陇南市",
      },
      {
        areaCode: 622900000000,
        children: [
          {
            areaCode: 622901000000,
            label: "临夏市",
            value: "临夏市",
          },
          {
            areaCode: 622921000000,
            label: "临夏县",
            value: "临夏县",
          },
          {
            areaCode: 622922000000,
            label: "康乐县",
            value: "康乐县",
          },
          {
            areaCode: 622923000000,
            label: "永靖县",
            value: "永靖县",
          },
          {
            areaCode: 622924000000,
            label: "广河县",
            value: "广河县",
          },
          {
            areaCode: 622925000000,
            label: "和政县",
            value: "和政县",
          },
          {
            areaCode: 622926000000,
            label: "东乡族自治县",
            value: "东乡族自治县",
          },
          {
            areaCode: 622927000000,
            label: "积石山保安族东乡族撒拉族自治县",
            value: "积石山保安族东乡族撒拉族自治县",
          },
        ],
        label: "临夏回族自治州",
        value: "临夏回族自治州",
      },
      {
        areaCode: 623000000000,
        children: [
          {
            areaCode: 623001000000,
            label: "合作市",
            value: "合作市",
          },
          {
            areaCode: 623021000000,
            label: "临潭县",
            value: "临潭县",
          },
          {
            areaCode: 623022000000,
            label: "卓尼县",
            value: "卓尼县",
          },
          {
            areaCode: 623023000000,
            label: "舟曲县",
            value: "舟曲县",
          },
          {
            areaCode: 623024000000,
            label: "迭部县",
            value: "迭部县",
          },
          {
            areaCode: 623025000000,
            label: "玛曲县",
            value: "玛曲县",
          },
          {
            areaCode: 623026000000,
            label: "碌曲县",
            value: "碌曲县",
          },
          {
            areaCode: 623027000000,
            label: "夏河县",
            value: "夏河县",
          },
        ],
        label: "甘南藏族自治州",
        value: "甘南藏族自治州",
      },
    ],
    label: "甘肃省",
    value: "甘肃省",
  },
  {
    areaCode: 630000000000,
    children: [
      {
        areaCode: 630100000000,
        children: [
          {
            areaCode: 630102000000,
            label: "城东区",
            value: "城东区",
          },
          {
            areaCode: 630103000000,
            label: "城中区",
            value: "城中区",
          },
          {
            areaCode: 630104000000,
            label: "城西区",
            value: "城西区",
          },
          {
            areaCode: 630105000000,
            label: "城北区",
            value: "城北区",
          },
          {
            areaCode: 630121000000,
            label: "大通回族土族自治县",
            value: "大通回族土族自治县",
          },
          {
            areaCode: 630122000000,
            label: "湟中县",
            value: "湟中县",
          },
          {
            areaCode: 630123000000,
            label: "湟源县",
            value: "湟源县",
          },
        ],
        label: "西宁市",
        value: "西宁市",
      },
      {
        areaCode: 630200000000,
        children: [
          {
            areaCode: 630202000000,
            label: "乐都区",
            value: "乐都区",
          },
          {
            areaCode: 630203000000,
            label: "平安区",
            value: "平安区",
          },
          {
            areaCode: 630222000000,
            label: "民和回族土族自治县",
            value: "民和回族土族自治县",
          },
          {
            areaCode: 630223000000,
            label: "互助土族自治县",
            value: "互助土族自治县",
          },
          {
            areaCode: 630224000000,
            label: "化隆回族自治县",
            value: "化隆回族自治县",
          },
          {
            areaCode: 630225000000,
            label: "循化撒拉族自治县",
            value: "循化撒拉族自治县",
          },
        ],
        label: "海东市",
        value: "海东市",
      },
      {
        areaCode: 632200000000,
        children: [
          {
            areaCode: 632221000000,
            label: "门源回族自治县",
            value: "门源回族自治县",
          },
          {
            areaCode: 632222000000,
            label: "祁连县",
            value: "祁连县",
          },
          {
            areaCode: 632223000000,
            label: "海晏县",
            value: "海晏县",
          },
          {
            areaCode: 632224000000,
            label: "刚察县",
            value: "刚察县",
          },
        ],
        label: "海北藏族自治州",
        value: "海北藏族自治州",
      },
      {
        areaCode: 632300000000,
        children: [
          {
            areaCode: 632321000000,
            label: "同仁县",
            value: "同仁县",
          },
          {
            areaCode: 632322000000,
            label: "尖扎县",
            value: "尖扎县",
          },
          {
            areaCode: 632323000000,
            label: "泽库县",
            value: "泽库县",
          },
          {
            areaCode: 632324000000,
            label: "河南蒙古族自治县",
            value: "河南蒙古族自治县",
          },
        ],
        label: "黄南藏族自治州",
        value: "黄南藏族自治州",
      },
      {
        areaCode: 632500000000,
        children: [
          {
            areaCode: 632521000000,
            label: "共和县",
            value: "共和县",
          },
          {
            areaCode: 632522000000,
            label: "同德县",
            value: "同德县",
          },
          {
            areaCode: 632523000000,
            label: "贵德县",
            value: "贵德县",
          },
          {
            areaCode: 632524000000,
            label: "兴海县",
            value: "兴海县",
          },
          {
            areaCode: 632525000000,
            label: "贵南县",
            value: "贵南县",
          },
        ],
        label: "海南藏族自治州",
        value: "海南藏族自治州",
      },
      {
        areaCode: 632600000000,
        children: [
          {
            areaCode: 632621000000,
            label: "玛沁县",
            value: "玛沁县",
          },
          {
            areaCode: 632622000000,
            label: "班玛县",
            value: "班玛县",
          },
          {
            areaCode: 632623000000,
            label: "甘德县",
            value: "甘德县",
          },
          {
            areaCode: 632624000000,
            label: "达日县",
            value: "达日县",
          },
          {
            areaCode: 632625000000,
            label: "久治县",
            value: "久治县",
          },
          {
            areaCode: 632626000000,
            label: "玛多县",
            value: "玛多县",
          },
        ],
        label: "果洛藏族自治州",
        value: "果洛藏族自治州",
      },
      {
        areaCode: 632700000000,
        children: [
          {
            areaCode: 632701000000,
            label: "玉树市",
            value: "玉树市",
          },
          {
            areaCode: 632722000000,
            label: "杂多县",
            value: "杂多县",
          },
          {
            areaCode: 632723000000,
            label: "称多县",
            value: "称多县",
          },
          {
            areaCode: 632724000000,
            label: "治多县",
            value: "治多县",
          },
          {
            areaCode: 632725000000,
            label: "囊谦县",
            value: "囊谦县",
          },
          {
            areaCode: 632726000000,
            label: "曲麻莱县",
            value: "曲麻莱县",
          },
        ],
        label: "玉树藏族自治州",
        value: "玉树藏族自治州",
      },
      {
        areaCode: 632800000000,
        children: [
          {
            areaCode: 632801000000,
            label: "格尔木市",
            value: "格尔木市",
          },
          {
            areaCode: 632802000000,
            label: "德令哈市",
            value: "德令哈市",
          },
          {
            areaCode: 632821000000,
            label: "乌兰县",
            value: "乌兰县",
          },
          {
            areaCode: 632822000000,
            label: "都兰县",
            value: "都兰县",
          },
          {
            areaCode: 632823000000,
            label: "天峻县",
            value: "天峻县",
          },
        ],
        label: "海西蒙古族藏族自治州",
        value: "海西蒙古族藏族自治州",
      },
    ],
    label: "青海省",
    value: "青海省",
  },
  {
    areaCode: 640000000000,
    children: [
      {
        areaCode: 640100000000,
        children: [
          {
            areaCode: 640104000000,
            label: "兴庆区",
            value: "兴庆区",
          },
          {
            areaCode: 640105000000,
            label: "西夏区",
            value: "西夏区",
          },
          {
            areaCode: 640106000000,
            label: "金凤区",
            value: "金凤区",
          },
          {
            areaCode: 640121000000,
            label: "永宁县",
            value: "永宁县",
          },
          {
            areaCode: 640122000000,
            label: "贺兰县",
            value: "贺兰县",
          },
          {
            areaCode: 640181000000,
            label: "灵武市",
            value: "灵武市",
          },
        ],
        label: "银川市",
        value: "银川市",
      },
      {
        areaCode: 640200000000,
        children: [
          {
            areaCode: 640202000000,
            label: "大武口区",
            value: "大武口区",
          },
          {
            areaCode: 640205000000,
            label: "惠农区",
            value: "惠农区",
          },
          {
            areaCode: 640221000000,
            label: "平罗县",
            value: "平罗县",
          },
        ],
        label: "石嘴山市",
        value: "石嘴山市",
      },
      {
        areaCode: 640300000000,
        children: [
          {
            areaCode: 640302000000,
            label: "利通区",
            value: "利通区",
          },
          {
            areaCode: 640303000000,
            label: "红寺堡区",
            value: "红寺堡区",
          },
          {
            areaCode: 640323000000,
            label: "盐池县",
            value: "盐池县",
          },
          {
            areaCode: 640324000000,
            label: "同心县",
            value: "同心县",
          },
          {
            areaCode: 640381000000,
            label: "青铜峡市",
            value: "青铜峡市",
          },
        ],
        label: "吴忠市",
        value: "吴忠市",
      },
      {
        areaCode: 640400000000,
        children: [
          {
            areaCode: 640402000000,
            label: "原州区",
            value: "原州区",
          },
          {
            areaCode: 640422000000,
            label: "西吉县",
            value: "西吉县",
          },
          {
            areaCode: 640423000000,
            label: "隆德县",
            value: "隆德县",
          },
          {
            areaCode: 640424000000,
            label: "泾源县",
            value: "泾源县",
          },
          {
            areaCode: 640425000000,
            label: "彭阳县",
            value: "彭阳县",
          },
        ],
        label: "固原市",
        value: "固原市",
      },
      {
        areaCode: 640500000000,
        children: [
          {
            areaCode: 640502000000,
            label: "沙坡头区",
            value: "沙坡头区",
          },
          {
            areaCode: 640521000000,
            label: "中宁县",
            value: "中宁县",
          },
          {
            areaCode: 640522000000,
            label: "海原县",
            value: "海原县",
          },
        ],
        label: "中卫市",
        value: "中卫市",
      },
    ],
    label: "宁夏回族自治区",
    value: "宁夏回族自治区",
  },
  {
    areaCode: 650000000000,
    children: [
      {
        areaCode: 650100000000,
        children: [
          {
            areaCode: 650102000000,
            label: "天山区",
            value: "天山区",
          },
          {
            areaCode: 650103000000,
            label: "沙依巴克区",
            value: "沙依巴克区",
          },
          {
            areaCode: 650104000000,
            label: "新市区",
            value: "新市区",
          },
          {
            areaCode: 650105000000,
            label: "水磨沟区",
            value: "水磨沟区",
          },
          {
            areaCode: 650106000000,
            label: "头屯河区",
            value: "头屯河区",
          },
          {
            areaCode: 650107000000,
            label: "达坂城区",
            value: "达坂城区",
          },
          {
            areaCode: 650109000000,
            label: "米东区",
            value: "米东区",
          },
          {
            areaCode: 650121000000,
            label: "乌鲁木齐县",
            value: "乌鲁木齐县",
          },
        ],
        label: "乌鲁木齐市",
        value: "乌鲁木齐市",
      },
      {
        areaCode: 650200000000,
        children: [
          {
            areaCode: 650202000000,
            label: "独山子区",
            value: "独山子区",
          },
          {
            areaCode: 650203000000,
            label: "克拉玛依区",
            value: "克拉玛依区",
          },
          {
            areaCode: 650204000000,
            label: "白碱滩区",
            value: "白碱滩区",
          },
          {
            areaCode: 650205000000,
            label: "乌尔禾区",
            value: "乌尔禾区",
          },
        ],
        label: "克拉玛依市",
        value: "克拉玛依市",
      },
      {
        areaCode: 650400000000,
        children: [
          {
            areaCode: 650402000000,
            label: "高昌区",
            value: "高昌区",
          },
          {
            areaCode: 650421000000,
            label: "鄯善县",
            value: "鄯善县",
          },
          {
            areaCode: 650422000000,
            label: "托克逊县",
            value: "托克逊县",
          },
        ],
        label: "吐鲁番市",
        value: "吐鲁番市",
      },
      {
        areaCode: 650500000000,
        children: [
          {
            areaCode: 650502000000,
            label: "伊州区",
            value: "伊州区",
          },
          {
            areaCode: 650521000000,
            label: "巴里坤哈萨克自治县",
            value: "巴里坤哈萨克自治县",
          },
          {
            areaCode: 650522000000,
            label: "伊吾县",
            value: "伊吾县",
          },
        ],
        label: "哈密市",
        value: "哈密市",
      },
      {
        areaCode: 652300000000,
        children: [
          {
            areaCode: 652301000000,
            label: "昌吉市",
            value: "昌吉市",
          },
          {
            areaCode: 652302000000,
            label: "阜康市",
            value: "阜康市",
          },
          {
            areaCode: 652323000000,
            label: "呼图壁县",
            value: "呼图壁县",
          },
          {
            areaCode: 652324000000,
            label: "玛纳斯县",
            value: "玛纳斯县",
          },
          {
            areaCode: 652325000000,
            label: "奇台县",
            value: "奇台县",
          },
          {
            areaCode: 652327000000,
            label: "吉木萨尔县",
            value: "吉木萨尔县",
          },
          {
            areaCode: 652328000000,
            label: "木垒哈萨克自治县",
            value: "木垒哈萨克自治县",
          },
        ],
        label: "昌吉回族自治州",
        value: "昌吉回族自治州",
      },
      {
        areaCode: 652700000000,
        children: [
          {
            areaCode: 652701000000,
            label: "博乐市",
            value: "博乐市",
          },
          {
            areaCode: 652702000000,
            label: "阿拉山口市",
            value: "阿拉山口市",
          },
          {
            areaCode: 652722000000,
            label: "精河县",
            value: "精河县",
          },
          {
            areaCode: 652723000000,
            label: "温泉县",
            value: "温泉县",
          },
        ],
        label: "博尔塔拉蒙古自治州",
        value: "博尔塔拉蒙古自治州",
      },
      {
        areaCode: 652800000000,
        children: [
          {
            areaCode: 652801000000,
            label: "库尔勒市",
            value: "库尔勒市",
          },
          {
            areaCode: 652822000000,
            label: "轮台县",
            value: "轮台县",
          },
          {
            areaCode: 652823000000,
            label: "尉犁县",
            value: "尉犁县",
          },
          {
            areaCode: 652824000000,
            label: "若羌县",
            value: "若羌县",
          },
          {
            areaCode: 652825000000,
            label: "且末县",
            value: "且末县",
          },
          {
            areaCode: 652826000000,
            label: "焉耆回族自治县",
            value: "焉耆回族自治县",
          },
          {
            areaCode: 652827000000,
            label: "和静县",
            value: "和静县",
          },
          {
            areaCode: 652828000000,
            label: "和硕县",
            value: "和硕县",
          },
          {
            areaCode: 652829000000,
            label: "博湖县",
            value: "博湖县",
          },
        ],
        label: "巴音郭楞蒙古自治州",
        value: "巴音郭楞蒙古自治州",
      },
      {
        areaCode: 652900000000,
        children: [
          {
            areaCode: 652901000000,
            label: "阿克苏市",
            value: "阿克苏市",
          },
          {
            areaCode: 652922000000,
            label: "温宿县",
            value: "温宿县",
          },
          {
            areaCode: 652923000000,
            label: "库车县",
            value: "库车县",
          },
          {
            areaCode: 652924000000,
            label: "沙雅县",
            value: "沙雅县",
          },
          {
            areaCode: 652925000000,
            label: "新和县",
            value: "新和县",
          },
          {
            areaCode: 652926000000,
            label: "拜城县",
            value: "拜城县",
          },
          {
            areaCode: 652927000000,
            label: "乌什县",
            value: "乌什县",
          },
          {
            areaCode: 652928000000,
            label: "阿瓦提县",
            value: "阿瓦提县",
          },
          {
            areaCode: 652929000000,
            label: "柯坪县",
            value: "柯坪县",
          },
        ],
        label: "阿克苏地区",
        value: "阿克苏地区",
      },
      {
        areaCode: 653000000000,
        children: [
          {
            areaCode: 653001000000,
            label: "阿图什市",
            value: "阿图什市",
          },
          {
            areaCode: 653022000000,
            label: "阿克陶县",
            value: "阿克陶县",
          },
          {
            areaCode: 653023000000,
            label: "阿合奇县",
            value: "阿合奇县",
          },
          {
            areaCode: 653024000000,
            label: "乌恰县",
            value: "乌恰县",
          },
        ],
        label: "克孜勒苏柯尔克孜自治州",
        value: "克孜勒苏柯尔克孜自治州",
      },
      {
        areaCode: 653100000000,
        children: [
          {
            areaCode: 653101000000,
            label: "喀什市",
            value: "喀什市",
          },
          {
            areaCode: 653121000000,
            label: "疏附县",
            value: "疏附县",
          },
          {
            areaCode: 653122000000,
            label: "疏勒县",
            value: "疏勒县",
          },
          {
            areaCode: 653123000000,
            label: "英吉沙县",
            value: "英吉沙县",
          },
          {
            areaCode: 653124000000,
            label: "泽普县",
            value: "泽普县",
          },
          {
            areaCode: 653125000000,
            label: "莎车县",
            value: "莎车县",
          },
          {
            areaCode: 653126000000,
            label: "叶城县",
            value: "叶城县",
          },
          {
            areaCode: 653127000000,
            label: "麦盖提县",
            value: "麦盖提县",
          },
          {
            areaCode: 653128000000,
            label: "岳普湖县",
            value: "岳普湖县",
          },
          {
            areaCode: 653129000000,
            label: "伽师县",
            value: "伽师县",
          },
          {
            areaCode: 653130000000,
            label: "巴楚县",
            value: "巴楚县",
          },
          {
            areaCode: 653131000000,
            label: "塔什库尔干塔吉克自治县",
            value: "塔什库尔干塔吉克自治县",
          },
        ],
        label: "喀什地区",
        value: "喀什地区",
      },
      {
        areaCode: 653200000000,
        children: [
          {
            areaCode: 653201000000,
            label: "和田市",
            value: "和田市",
          },
          {
            areaCode: 653221000000,
            label: "和田县",
            value: "和田县",
          },
          {
            areaCode: 653222000000,
            label: "墨玉县",
            value: "墨玉县",
          },
          {
            areaCode: 653223000000,
            label: "皮山县",
            value: "皮山县",
          },
          {
            areaCode: 653224000000,
            label: "洛浦县",
            value: "洛浦县",
          },
          {
            areaCode: 653225000000,
            label: "策勒县",
            value: "策勒县",
          },
          {
            areaCode: 653226000000,
            label: "于田县",
            value: "于田县",
          },
          {
            areaCode: 653227000000,
            label: "民丰县",
            value: "民丰县",
          },
        ],
        label: "和田地区",
        value: "和田地区",
      },
      {
        areaCode: 654000000000,
        children: [
          {
            areaCode: 654002000000,
            label: "伊宁市",
            value: "伊宁市",
          },
          {
            areaCode: 654003000000,
            label: "奎屯市",
            value: "奎屯市",
          },
          {
            areaCode: 654004000000,
            label: "霍尔果斯市",
            value: "霍尔果斯市",
          },
          {
            areaCode: 654021000000,
            label: "伊宁县",
            value: "伊宁县",
          },
          {
            areaCode: 654022000000,
            label: "察布查尔锡伯自治县",
            value: "察布查尔锡伯自治县",
          },
          {
            areaCode: 654023000000,
            label: "霍城县",
            value: "霍城县",
          },
          {
            areaCode: 654024000000,
            label: "巩留县",
            value: "巩留县",
          },
          {
            areaCode: 654025000000,
            label: "新源县",
            value: "新源县",
          },
          {
            areaCode: 654026000000,
            label: "昭苏县",
            value: "昭苏县",
          },
          {
            areaCode: 654027000000,
            label: "特克斯县",
            value: "特克斯县",
          },
          {
            areaCode: 654028000000,
            label: "尼勒克县",
            value: "尼勒克县",
          },
        ],
        label: "伊犁哈萨克自治州",
        value: "伊犁哈萨克自治州",
      },
      {
        areaCode: 654200000000,
        children: [
          {
            areaCode: 654201000000,
            label: "塔城市",
            value: "塔城市",
          },
          {
            areaCode: 654202000000,
            label: "乌苏市",
            value: "乌苏市",
          },
          {
            areaCode: 654221000000,
            label: "额敏县",
            value: "额敏县",
          },
          {
            areaCode: 654223000000,
            label: "沙湾县",
            value: "沙湾县",
          },
          {
            areaCode: 654224000000,
            label: "托里县",
            value: "托里县",
          },
          {
            areaCode: 654225000000,
            label: "裕民县",
            value: "裕民县",
          },
          {
            areaCode: 654226000000,
            label: "和布克赛尔蒙古自治县",
            value: "和布克赛尔蒙古自治县",
          },
        ],
        label: "塔城地区",
        value: "塔城地区",
      },
      {
        areaCode: 654300000000,
        children: [
          {
            areaCode: 654301000000,
            label: "阿勒泰市",
            value: "阿勒泰市",
          },
          {
            areaCode: 654321000000,
            label: "布尔津县",
            value: "布尔津县",
          },
          {
            areaCode: 654322000000,
            label: "富蕴县",
            value: "富蕴县",
          },
          {
            areaCode: 654323000000,
            label: "福海县",
            value: "福海县",
          },
          {
            areaCode: 654324000000,
            label: "哈巴河县",
            value: "哈巴河县",
          },
          {
            areaCode: 654325000000,
            label: "青河县",
            value: "青河县",
          },
          {
            areaCode: 654326000000,
            label: "吉木乃县",
            value: "吉木乃县",
          },
        ],
        label: "阿勒泰地区",
        value: "阿勒泰地区",
      },
      {
        areaCode: 659000000000,
        children: [
          {
            areaCode: 659001000000,
            label: "石河子市",
            value: "石河子市",
          },
          {
            areaCode: 659002000000,
            label: "阿拉尔市",
            value: "阿拉尔市",
          },
          {
            areaCode: 659003000000,
            label: "图木舒克市",
            value: "图木舒克市",
          },
          {
            areaCode: 659004000000,
            label: "五家渠市",
            value: "五家渠市",
          },
          {
            areaCode: 659006000000,
            label: "铁门关市",
            value: "铁门关市",
          },
        ],
        label: "直辖县",
        value: "直辖县",
      },
    ],
    label: "新疆维吾尔自治区",
    value: "新疆维吾尔自治区",
  },
  {
    areaCode: 710000000000,
    children: [
      {
        areaCode: 710100000000,
        children: [
          {
            areaCode: 710101000000,
            label: "芳苑乡",
            value: "芳苑乡",
          },
          {
            areaCode: 710102000000,
            label: "芬园乡",
            value: "芬园乡",
          },
          {
            areaCode: 710103000000,
            label: "福兴乡",
            value: "福兴乡",
          },
          {
            areaCode: 710104000000,
            label: "和美镇",
            value: "和美镇",
          },
          {
            areaCode: 710106000000,
            label: "花坛乡",
            value: "花坛乡",
          },
          {
            areaCode: 710107000000,
            label: "鹿港镇",
            value: "鹿港镇",
          },
          {
            areaCode: 710108000000,
            label: "埤头乡",
            value: "埤头乡",
          },
          {
            areaCode: 710109000000,
            label: "埔心乡",
            value: "埔心乡",
          },
          {
            areaCode: 710110000000,
            label: "埔盐乡",
            value: "埔盐乡",
          },
          {
            areaCode: 710111000000,
            label: "伸港乡",
            value: "伸港乡",
          },
          {
            areaCode: 710112000000,
            label: "社头乡",
            value: "社头乡",
          },
          {
            areaCode: 710113000000,
            label: "田尾乡",
            value: "田尾乡",
          },
          {
            areaCode: 710114000000,
            label: "田中镇",
            value: "田中镇",
          },
          {
            areaCode: 710115000000,
            label: "线西乡",
            value: "线西乡",
          },
          {
            areaCode: 710116000000,
            label: "溪湖镇",
            value: "溪湖镇",
          },
          {
            areaCode: 710117000000,
            label: "秀水乡",
            value: "秀水乡",
          },
          {
            areaCode: 710118000000,
            label: "溪州乡",
            value: "溪州乡",
          },
          {
            areaCode: 710119000000,
            label: "永靖乡",
            value: "永靖乡",
          },
          {
            areaCode: 710120000000,
            label: "员林市",
            value: "员林市",
          },
          {
            areaCode: 710121000000,
            label: "竹塘乡",
            value: "竹塘乡",
          },
        ],
        label: "彰化县",
        value: "彰化县",
      },
      {
        areaCode: 710200000000,
        children: [
          {
            areaCode: 710201000000,
            label: "八里区",
            value: "八里区",
          },
          {
            areaCode: 710202000000,
            label: "板桥区",
            value: "板桥区",
          },
          {
            areaCode: 710203000000,
            label: "贡寮区",
            value: "贡寮区",
          },
          {
            areaCode: 710204000000,
            label: "金山区",
            value: "金山区",
          },
          {
            areaCode: 710205000000,
            label: "林口区",
            value: "林口区",
          },
          {
            areaCode: 710206000000,
            label: "芦洲区",
            value: "芦洲区",
          },
          {
            areaCode: 710207000000,
            label: "坪林区",
            value: "坪林区",
          },
          {
            areaCode: 710208000000,
            label: "平溪区",
            value: "平溪区",
          },
          {
            areaCode: 710209000000,
            label: "瑞芳区",
            value: "瑞芳区",
          },
          {
            areaCode: 710210000000,
            label: "三重区",
            value: "三重区",
          },
          {
            areaCode: 710211000000,
            label: "三峡区",
            value: "三峡区",
          },
          {
            areaCode: 710212000000,
            label: "三芝区",
            value: "三芝区",
          },
          {
            areaCode: 710213000000,
            label: "深坑区",
            value: "深坑区",
          },
          {
            areaCode: 710214000000,
            label: "石碇区",
            value: "石碇区",
          },
          {
            areaCode: 710215000000,
            label: "石门区",
            value: "石门区",
          },
          {
            areaCode: 710216000000,
            label: "双溪区",
            value: "双溪区",
          },
          {
            areaCode: 710217000000,
            label: "树林区",
            value: "树林区",
          },
          {
            areaCode: 710218000000,
            label: "泰山区",
            value: "泰山区",
          },
          {
            areaCode: 710219000000,
            label: "淡水区",
            value: "淡水区",
          },
          {
            areaCode: 710301000000,
            label: "土城区",
            value: "土城区",
          },
        ],
        label: "新北市",
        value: "新北市",
      },
      {
        areaCode: 710300000000,
        children: [
          {
            areaCode: 710302000000,
            label: "白沙乡",
            value: "白沙乡",
          },
          {
            areaCode: 710303000000,
            label: "湖西乡",
            value: "湖西乡",
          },
          {
            areaCode: 710304000000,
            label: "马公市",
            value: "马公市",
          },
          {
            areaCode: 710305000000,
            label: "七美乡",
            value: "七美乡",
          },
          {
            areaCode: 710306000000,
            label: "望安乡",
            value: "望安乡",
          },
          {
            areaCode: 710307000000,
            label: "西屿乡",
            value: "西屿乡",
          },
        ],
        label: "澎湖县",
        value: "澎湖县",
      },
      {
        areaCode: 710400000000,
        children: [
          {
            areaCode: 710401000000,
            label: "三地门乡",
            value: "三地门乡",
          },
          {
            areaCode: 710402000000,
            label: "狮子乡",
            value: "狮子乡",
          },
          {
            areaCode: 710403000000,
            label: "泰武乡",
            value: "泰武乡",
          },
          {
            areaCode: 710404000000,
            label: "万丹乡",
            value: "万丹乡",
          },
          {
            areaCode: 710405000000,
            label: "万峦乡",
            value: "万峦乡",
          },
          {
            areaCode: 710406000000,
            label: "雾臺乡",
            value: "雾臺乡",
          },
          {
            areaCode: 710407000000,
            label: "新埤乡",
            value: "新埤乡",
          },
          {
            areaCode: 710408000000,
            label: "新园乡",
            value: "新园乡",
          },
          {
            areaCode: 710409000000,
            label: "盐埔乡",
            value: "盐埔乡",
          },
          {
            areaCode: 710410000000,
            label: "竹田乡",
            value: "竹田乡",
          },
          {
            areaCode: 710411000000,
            label: "长治乡",
            value: "长治乡",
          },
          {
            areaCode: 710412000000,
            label: "潮州镇",
            value: "潮州镇",
          },
          {
            areaCode: 710413000000,
            label: "车城乡",
            value: "车城乡",
          },
          {
            areaCode: 710414000000,
            label: "春日乡",
            value: "春日乡",
          },
          {
            areaCode: 710415000000,
            label: "东港镇",
            value: "东港镇",
          },
          {
            areaCode: 710416000000,
            label: "枋寮乡",
            value: "枋寮乡",
          },
          {
            areaCode: 710417000000,
            label: "枋山乡",
            value: "枋山乡",
          },
          {
            areaCode: 710418000000,
            label: "高树乡",
            value: "高树乡",
          },
          {
            areaCode: 710419000000,
            label: "恆春镇",
            value: "恆春镇",
          },
          {
            areaCode: 710420000000,
            label: "佳冬乡",
            value: "佳冬乡",
          },
        ],
        label: "屏东县",
        value: "屏东县",
      },
      {
        areaCode: 710500000000,
        children: [
          {
            areaCode: 710501000000,
            label: "梧栖区",
            value: "梧栖区",
          },
          {
            areaCode: 710502000000,
            label: "乌日区",
            value: "乌日区",
          },
          {
            areaCode: 710503000000,
            label: "新社区",
            value: "新社区",
          },
          {
            areaCode: 710504000000,
            label: "西屯区",
            value: "西屯区",
          },
          {
            areaCode: 710505000000,
            label: "北屯区",
            value: "北屯区",
          },
          {
            areaCode: 710506000000,
            label: "中区",
            value: "中区",
          },
          {
            areaCode: 710507000000,
            label: "大肚区",
            value: "大肚区",
          },
          {
            areaCode: 710508000000,
            label: "大甲区",
            value: "大甲区",
          },
          {
            areaCode: 710509000000,
            label: "大里区",
            value: "大里区",
          },
          {
            areaCode: 710510000000,
            label: "大雅区",
            value: "大雅区",
          },
          {
            areaCode: 710511000000,
            label: "大安区",
            value: "大安区",
          },
          {
            areaCode: 710512000000,
            label: "东势区",
            value: "东势区",
          },
          {
            areaCode: 710513000000,
            label: "东区",
            value: "东区",
          },
          {
            areaCode: 710514000000,
            label: "丰原区",
            value: "丰原区",
          },
          {
            areaCode: 710515000000,
            label: "和平区",
            value: "和平区",
          },
          {
            areaCode: 710516000000,
            label: "后里区",
            value: "后里区",
          },
          {
            areaCode: 710517000000,
            label: "龙井区",
            value: "龙井区",
          },
          {
            areaCode: 710518000000,
            label: "南屯区",
            value: "南屯区",
          },
          {
            areaCode: 710519000000,
            label: "北区",
            value: "北区",
          },
          {
            areaCode: 710520000000,
            label: "清水区",
            value: "清水区",
          },
        ],
        label: "臺中市",
        value: "臺中市",
      },
      {
        areaCode: 710600000000,
        children: [
          {
            areaCode: 710601000000,
            label: "佳里区",
            value: "佳里区",
          },
          {
            areaCode: 710602000000,
            label: "将军区",
            value: "将军区",
          },
          {
            areaCode: 710603000000,
            label: "六甲区",
            value: "六甲区",
          },
          {
            areaCode: 710604000000,
            label: "柳营区",
            value: "柳营区",
          },
          {
            areaCode: 710605000000,
            label: "龙崎区",
            value: "龙崎区",
          },
          {
            areaCode: 710606000000,
            label: "麻豆区",
            value: "麻豆区",
          },
          {
            areaCode: 710607000000,
            label: "南化区",
            value: "南化区",
          },
          {
            areaCode: 710608000000,
            label: "楠西区",
            value: "楠西区",
          },
          {
            areaCode: 710609000000,
            label: "北区",
            value: "北区",
          },
          {
            areaCode: 710610000000,
            label: "七股区",
            value: "七股区",
          },
          {
            areaCode: 710611000000,
            label: "仁德区",
            value: "仁德区",
          },
          {
            areaCode: 710612000000,
            label: "善化区",
            value: "善化区",
          },
          {
            areaCode: 710613000000,
            label: "山上区",
            value: "山上区",
          },
          {
            areaCode: 710614000000,
            label: "南区",
            value: "南区",
          },
          {
            areaCode: 710615000000,
            label: "中西区",
            value: "中西区",
          },
          {
            areaCode: 710616000000,
            label: "下营区",
            value: "下营区",
          },
          {
            areaCode: 710617000000,
            label: "西港区",
            value: "西港区",
          },
          {
            areaCode: 710618000000,
            label: "新化区",
            value: "新化区",
          },
          {
            areaCode: 710619000000,
            label: "新市区",
            value: "新市区",
          },
          {
            areaCode: 710620000000,
            label: "新营区",
            value: "新营区",
          },
        ],
        label: "臺南市",
        value: "臺南市",
      },
      {
        areaCode: 710700000000,
        children: [
          {
            areaCode: 710701000000,
            label: "北投区",
            value: "北投区",
          },
          {
            areaCode: 710702000000,
            label: "大同区",
            value: "大同区",
          },
          {
            areaCode: 710703000000,
            label: "大安区",
            value: "大安区",
          },
          {
            areaCode: 710704000000,
            label: "南港区",
            value: "南港区",
          },
          {
            areaCode: 710705000000,
            label: "内湖区",
            value: "内湖区",
          },
          {
            areaCode: 710706000000,
            label: "士林区",
            value: "士林区",
          },
          {
            areaCode: 710707000000,
            label: "松山区",
            value: "松山区",
          },
          {
            areaCode: 710708000000,
            label: "万华区",
            value: "万华区",
          },
          {
            areaCode: 710709000000,
            label: "文山区",
            value: "文山区",
          },
          {
            areaCode: 710710000000,
            label: "信义区",
            value: "信义区",
          },
          {
            areaCode: 710711000000,
            label: "中山区",
            value: "中山区",
          },
          {
            areaCode: 710712000000,
            label: "中正区",
            value: "中正区",
          },
        ],
        label: "臺北市",
        value: "臺北市",
      },
      {
        areaCode: 710800000000,
        children: [
          {
            areaCode: 710801000000,
            label: "卑南乡",
            value: "卑南乡",
          },
          {
            areaCode: 710802000000,
            label: "长滨乡",
            value: "长滨乡",
          },
          {
            areaCode: 710803000000,
            label: "成功镇",
            value: "成功镇",
          },
          {
            areaCode: 710804000000,
            label: "池上乡",
            value: "池上乡",
          },
          {
            areaCode: 710805000000,
            label: "达仁乡",
            value: "达仁乡",
          },
          {
            areaCode: 710806000000,
            label: "大武乡",
            value: "大武乡",
          },
          {
            areaCode: 710807000000,
            label: "东河乡",
            value: "东河乡",
          },
          {
            areaCode: 710808000000,
            label: "关山镇",
            value: "关山镇",
          },
          {
            areaCode: 710809000000,
            label: "海端乡",
            value: "海端乡",
          },
          {
            areaCode: 710810000000,
            label: "金峰乡",
            value: "金峰乡",
          },
          {
            areaCode: 710811000000,
            label: "兰屿乡",
            value: "兰屿乡",
          },
          {
            areaCode: 710812000000,
            label: "绿岛乡",
            value: "绿岛乡",
          },
          {
            areaCode: 710813000000,
            label: "鹿野乡",
            value: "鹿野乡",
          },
          {
            areaCode: 710814000000,
            label: "太麻里乡",
            value: "太麻里乡",
          },
          {
            areaCode: 710815000000,
            label: "臺东市",
            value: "臺东市",
          },
          {
            areaCode: 710816000000,
            label: "延平乡",
            value: "延平乡",
          },
        ],
        label: "臺东县",
        value: "臺东县",
      },
      {
        areaCode: 710900000000,
        children: [
          {
            areaCode: 710901000000,
            label: "八德区",
            value: "八德区",
          },
          {
            areaCode: 710902000000,
            label: "大溪区",
            value: "大溪区",
          },
          {
            areaCode: 710903000000,
            label: "大园区",
            value: "大园区",
          },
          {
            areaCode: 710904000000,
            label: "復兴区",
            value: "復兴区",
          },
          {
            areaCode: 710905000000,
            label: "观音区",
            value: "观音区",
          },
          {
            areaCode: 710906000000,
            label: "龟山区",
            value: "龟山区",
          },
          {
            areaCode: 710907000000,
            label: "龙潭区",
            value: "龙潭区",
          },
          {
            areaCode: 710908000000,
            label: "芦竹区",
            value: "芦竹区",
          },
          {
            areaCode: 710909000000,
            label: "平镇区",
            value: "平镇区",
          },
          {
            areaCode: 710910000000,
            label: "桃园区",
            value: "桃园区",
          },
          {
            areaCode: 710911000000,
            label: "新屋区",
            value: "新屋区",
          },
          {
            areaCode: 710912000000,
            label: "杨梅区",
            value: "杨梅区",
          },
          {
            areaCode: 710913000000,
            label: "中坜区",
            value: "中坜区",
          },
        ],
        label: "桃园市",
        value: "桃园市",
      },
      {
        areaCode: 711000000000,
        children: [
          {
            areaCode: 711001000000,
            label: "大同乡",
            value: "大同乡",
          },
          {
            areaCode: 711002000000,
            label: "钓鱼臺",
            value: "钓鱼臺",
          },
          {
            areaCode: 711003000000,
            label: "冬山乡",
            value: "冬山乡",
          },
          {
            areaCode: 711004000000,
            label: "礁溪乡",
            value: "礁溪乡",
          },
          {
            areaCode: 711005000000,
            label: "罗东镇",
            value: "罗东镇",
          },
          {
            areaCode: 711006000000,
            label: "南澳乡",
            value: "南澳乡",
          },
          {
            areaCode: 711007000000,
            label: "三星乡",
            value: "三星乡",
          },
          {
            areaCode: 711008000000,
            label: "苏澳镇",
            value: "苏澳镇",
          },
          {
            areaCode: 711009000000,
            label: "头城镇",
            value: "头城镇",
          },
          {
            areaCode: 711010000000,
            label: "五结乡",
            value: "五结乡",
          },
          {
            areaCode: 711011000000,
            label: "宜兰市",
            value: "宜兰市",
          },
          {
            areaCode: 711012000000,
            label: "员山乡",
            value: "员山乡",
          },
          {
            areaCode: 711013000000,
            label: "壮围乡",
            value: "壮围乡",
          },
        ],
        label: "宜兰县",
        value: "宜兰县",
      },
      {
        areaCode: 711100000000,
        children: [
          {
            areaCode: 711101000000,
            label: "草屯镇",
            value: "草屯镇",
          },
          {
            areaCode: 711102000000,
            label: "国姓乡",
            value: "国姓乡",
          },
          {
            areaCode: 711103000000,
            label: "集集镇",
            value: "集集镇",
          },
          {
            areaCode: 711104000000,
            label: "鹿谷乡",
            value: "鹿谷乡",
          },
          {
            areaCode: 711105000000,
            label: "名间乡",
            value: "名间乡",
          },
          {
            areaCode: 711106000000,
            label: "南投市",
            value: "南投市",
          },
          {
            areaCode: 711107000000,
            label: "埔里镇",
            value: "埔里镇",
          },
          {
            areaCode: 711108000000,
            label: "仁爱乡",
            value: "仁爱乡",
          },
          {
            areaCode: 711109000000,
            label: "水里乡",
            value: "水里乡",
          },
          {
            areaCode: 711110000000,
            label: "信义乡",
            value: "信义乡",
          },
          {
            areaCode: 711111000000,
            label: "鱼池乡",
            value: "鱼池乡",
          },
          {
            areaCode: 711112000000,
            label: "中寮乡",
            value: "中寮乡",
          },
          {
            areaCode: 711113000000,
            label: "竹山镇",
            value: "竹山镇",
          },
        ],
        label: "南投县",
        value: "南投县",
      },
      {
        areaCode: 711200000000,
        children: [
          {
            areaCode: 711201000000,
            label: "东沙群岛",
            value: "东沙群岛",
          },
          {
            areaCode: 711202000000,
            label: "南沙群岛",
            value: "南沙群岛",
          },
        ],
        label: "南海岛",
        value: "南海岛",
      },
      {
        areaCode: 711300000000,
        children: [
          {
            areaCode: 711301000000,
            label: "头屋乡",
            value: "头屋乡",
          },
          {
            areaCode: 711302000000,
            label: "西湖乡",
            value: "西湖乡",
          },
          {
            areaCode: 711303000000,
            label: "苑里镇",
            value: "苑里镇",
          },
          {
            areaCode: 711304000000,
            label: "造桥乡",
            value: "造桥乡",
          },
          {
            areaCode: 711305000000,
            label: "竹南镇",
            value: "竹南镇",
          },
          {
            areaCode: 711306000000,
            label: "卓兰镇",
            value: "卓兰镇",
          },
          {
            areaCode: 711307000000,
            label: "大湖乡",
            value: "大湖乡",
          },
          {
            areaCode: 711308000000,
            label: "公馆乡",
            value: "公馆乡",
          },
          {
            areaCode: 711309000000,
            label: "后龙镇",
            value: "后龙镇",
          },
          {
            areaCode: 711310000000,
            label: "苗栗市",
            value: "苗栗市",
          },
          {
            areaCode: 711311000000,
            label: "南庄乡",
            value: "南庄乡",
          },
          {
            areaCode: 711312000000,
            label: "三湾乡",
            value: "三湾乡",
          },
          {
            areaCode: 711313000000,
            label: "三义乡",
            value: "三义乡",
          },
          {
            areaCode: 711314000000,
            label: "狮潭乡",
            value: "狮潭乡",
          },
          {
            areaCode: 711315000000,
            label: "泰安乡",
            value: "泰安乡",
          },
          {
            areaCode: 711316000000,
            label: "铜锣乡",
            value: "铜锣乡",
          },
          {
            areaCode: 711317000000,
            label: "通霄镇",
            value: "通霄镇",
          },
          {
            areaCode: 711318000000,
            label: "头份市",
            value: "头份市",
          },
        ],
        label: "苗栗县",
        value: "苗栗县",
      },
      {
        areaCode: 711400000000,
        children: [
          {
            areaCode: 711401000000,
            label: "东区",
            value: "东区",
          },
          {
            areaCode: 711402000000,
            label: "西区",
            value: "西区",
          },
        ],
        label: "嘉义市",
        value: "嘉义市",
      },
      {
        areaCode: 711500000000,
        children: [
          {
            areaCode: 711501000000,
            label: "阿里山乡",
            value: "阿里山乡",
          },
          {
            areaCode: 711502000000,
            label: "布袋镇",
            value: "布袋镇",
          },
          {
            areaCode: 711503000000,
            label: "大林镇",
            value: "大林镇",
          },
          {
            areaCode: 711504000000,
            label: "大埔乡",
            value: "大埔乡",
          },
          {
            areaCode: 711505000000,
            label: "东石乡",
            value: "东石乡",
          },
          {
            areaCode: 711506000000,
            label: "番路乡",
            value: "番路乡",
          },
          {
            areaCode: 711507000000,
            label: "六脚乡",
            value: "六脚乡",
          },
          {
            areaCode: 711508000000,
            label: "鹿草乡",
            value: "鹿草乡",
          },
          {
            areaCode: 711509000000,
            label: "梅山乡",
            value: "梅山乡",
          },
          {
            areaCode: 711510000000,
            label: "民雄乡",
            value: "民雄乡",
          },
          {
            areaCode: 711511000000,
            label: "朴子市",
            value: "朴子市",
          },
          {
            areaCode: 711512000000,
            label: "水上乡",
            value: "水上乡",
          },
          {
            areaCode: 711513000000,
            label: "太保市",
            value: "太保市",
          },
          {
            areaCode: 711514000000,
            label: "溪口乡",
            value: "溪口乡",
          },
          {
            areaCode: 711515000000,
            label: "新港乡",
            value: "新港乡",
          },
          {
            areaCode: 711516000000,
            label: "义竹乡",
            value: "义竹乡",
          },
          {
            areaCode: 711517000000,
            label: "中埔乡",
            value: "中埔乡",
          },
          {
            areaCode: 711518000000,
            label: "竹崎乡",
            value: "竹崎乡",
          },
        ],
        label: "嘉义县",
        value: "嘉义县",
      },
      {
        areaCode: 711600000000,
        children: [
          {
            areaCode: 711601000000,
            label: "东区",
            value: "东区",
          },
          {
            areaCode: 711602000000,
            label: "北区",
            value: "北区",
          },
        ],
        label: "新竹市",
        value: "新竹市",
      },
      {
        areaCode: 711700000000,
        children: [
          {
            areaCode: 711701000000,
            label: "峨眉乡",
            value: "峨眉乡",
          },
          {
            areaCode: 711702000000,
            label: "关西镇",
            value: "关西镇",
          },
          {
            areaCode: 711703000000,
            label: "横山乡",
            value: "横山乡",
          },
          {
            areaCode: 711704000000,
            label: "湖口乡",
            value: "湖口乡",
          },
          {
            areaCode: 711705000000,
            label: "尖石乡",
            value: "尖石乡",
          },
          {
            areaCode: 711706000000,
            label: "芎林乡",
            value: "芎林乡",
          },
          {
            areaCode: 711707000000,
            label: "五峰乡",
            value: "五峰乡",
          },
          {
            areaCode: 711708000000,
            label: "新丰乡",
            value: "新丰乡",
          },
          {
            areaCode: 711709000000,
            label: "新埔镇",
            value: "新埔镇",
          },
          {
            areaCode: 711710000000,
            label: "竹北市",
            value: "竹北市",
          },
          {
            areaCode: 711711000000,
            label: "竹东镇",
            value: "竹东镇",
          },
          {
            areaCode: 711712000000,
            label: "宝山乡",
            value: "宝山乡",
          },
          {
            areaCode: 711713000000,
            label: "北埔乡",
            value: "北埔乡",
          },
        ],
        label: "新竹县",
        value: "新竹县",
      },
      {
        areaCode: 711800000000,
        children: [
          {
            areaCode: 711801000000,
            label: "卓溪乡",
            value: "卓溪乡",
          },
          {
            areaCode: 711802000000,
            label: "丰滨乡",
            value: "丰滨乡",
          },
          {
            areaCode: 711803000000,
            label: "凤林镇",
            value: "凤林镇",
          },
          {
            areaCode: 711804000000,
            label: "富里乡",
            value: "富里乡",
          },
          {
            areaCode: 711805000000,
            label: "光復乡",
            value: "光復乡",
          },
          {
            areaCode: 711806000000,
            label: "花莲市",
            value: "花莲市",
          },
          {
            areaCode: 711807000000,
            label: "吉安乡",
            value: "吉安乡",
          },
          {
            areaCode: 711808000000,
            label: "瑞穗乡",
            value: "瑞穗乡",
          },
          {
            areaCode: 711809000000,
            label: "寿丰乡",
            value: "寿丰乡",
          },
          {
            areaCode: 711810000000,
            label: "万荣乡",
            value: "万荣乡",
          },
          {
            areaCode: 711811000000,
            label: "新城乡",
            value: "新城乡",
          },
          {
            areaCode: 711812000000,
            label: "秀林乡",
            value: "秀林乡",
          },
          {
            areaCode: 711813000000,
            label: "玉里镇",
            value: "玉里镇",
          },
        ],
        label: "花莲县",
        value: "花莲县",
      },
      {
        areaCode: 711900000000,
        children: [
          {
            areaCode: 711901000000,
            label: "阿莲区",
            value: "阿莲区",
          },
          {
            areaCode: 711902000000,
            label: "大寮区",
            value: "大寮区",
          },
          {
            areaCode: 711903000000,
            label: "大社区",
            value: "大社区",
          },
          {
            areaCode: 711904000000,
            label: "大树区",
            value: "大树区",
          },
          {
            areaCode: 711905000000,
            label: "凤山区",
            value: "凤山区",
          },
          {
            areaCode: 711906000000,
            label: "冈山区",
            value: "冈山区",
          },
          {
            areaCode: 711907000000,
            label: "鼓山区",
            value: "鼓山区",
          },
          {
            areaCode: 711908000000,
            label: "湖内区",
            value: "湖内区",
          },
          {
            areaCode: 711909000000,
            label: "甲仙区",
            value: "甲仙区",
          },
          {
            areaCode: 711910000000,
            label: "苓雅区",
            value: "苓雅区",
          },
          {
            areaCode: 711911000000,
            label: "林园区",
            value: "林园区",
          },
          {
            areaCode: 711912000000,
            label: "六龟区",
            value: "六龟区",
          },
          {
            areaCode: 711913000000,
            label: "路竹区",
            value: "路竹区",
          },
          {
            areaCode: 711914000000,
            label: "茂林区",
            value: "茂林区",
          },
          {
            areaCode: 711915000000,
            label: "美浓区",
            value: "美浓区",
          },
          {
            areaCode: 711916000000,
            label: "弥陀区",
            value: "弥陀区",
          },
          {
            areaCode: 711917000000,
            label: "那玛夏区",
            value: "那玛夏区",
          },
          {
            areaCode: 711918000000,
            label: "楠梓区",
            value: "楠梓区",
          },
          {
            areaCode: 711919000000,
            label: "内门区",
            value: "内门区",
          },
          {
            areaCode: 711920000000,
            label: "鸟松区",
            value: "鸟松区",
          },
        ],
        label: "高雄市",
        value: "高雄市",
      },
      {
        areaCode: 712000000000,
        children: [
          {
            areaCode: 712001000000,
            label: "安乐区",
            value: "安乐区",
          },
          {
            areaCode: 712002000000,
            label: "暖暖区",
            value: "暖暖区",
          },
          {
            areaCode: 712003000000,
            label: "七堵区",
            value: "七堵区",
          },
          {
            areaCode: 712004000000,
            label: "仁爱区",
            value: "仁爱区",
          },
          {
            areaCode: 712005000000,
            label: "信义区",
            value: "信义区",
          },
          {
            areaCode: 712006000000,
            label: "中山区",
            value: "中山区",
          },
          {
            areaCode: 712007000000,
            label: "中正区",
            value: "中正区",
          },
        ],
        label: "基隆市",
        value: "基隆市",
      },
      {
        areaCode: 712100000000,
        children: [
          {
            areaCode: 712101000000,
            label: "金城镇",
            value: "金城镇",
          },
          {
            areaCode: 712102000000,
            label: "金湖镇",
            value: "金湖镇",
          },
          {
            areaCode: 712103000000,
            label: "金宁乡",
            value: "金宁乡",
          },
          {
            areaCode: 712104000000,
            label: "金沙镇",
            value: "金沙镇",
          },
          {
            areaCode: 712105000000,
            label: "烈屿乡",
            value: "烈屿乡",
          },
          {
            areaCode: 712106000000,
            label: "乌坵乡",
            value: "乌坵乡",
          },
        ],
        label: "金门县",
        value: "金门县",
      },
      {
        areaCode: 712200000000,
        children: [
          {
            areaCode: 712201000000,
            label: "北竿乡",
            value: "北竿乡",
          },
          {
            areaCode: 712202000000,
            label: "东引乡",
            value: "东引乡",
          },
          {
            areaCode: 712203000000,
            label: "莒光乡",
            value: "莒光乡",
          },
          {
            areaCode: 712301000000,
            label: "南竿乡",
            value: "南竿乡",
          },
        ],
        label: "连江县",
        value: "连江县",
      },
      {
        areaCode: 712300000000,
        children: [
          {
            areaCode: 712302000000,
            label: "褒忠乡",
            value: "褒忠乡",
          },
          {
            areaCode: 712303000000,
            label: "北港镇",
            value: "北港镇",
          },
          {
            areaCode: 712304000000,
            label: "莿桐乡",
            value: "莿桐乡",
          },
          {
            areaCode: 712305000000,
            label: "大埤乡",
            value: "大埤乡",
          },
          {
            areaCode: 712306000000,
            label: "东势乡",
            value: "东势乡",
          },
          {
            areaCode: 712307000000,
            label: "斗六市",
            value: "斗六市",
          },
          {
            areaCode: 712308000000,
            label: "斗南镇",
            value: "斗南镇",
          },
          {
            areaCode: 712309000000,
            label: "二崙乡",
            value: "二崙乡",
          },
          {
            areaCode: 712310000000,
            label: "古坑乡",
            value: "古坑乡",
          },
          {
            areaCode: 712311000000,
            label: "虎尾镇",
            value: "虎尾镇",
          },
          {
            areaCode: 712312000000,
            label: "口湖乡",
            value: "口湖乡",
          },
          {
            areaCode: 712313000000,
            label: "林内乡",
            value: "林内乡",
          },
          {
            areaCode: 712314000000,
            label: "崙背乡",
            value: "崙背乡",
          },
          {
            areaCode: 712315000000,
            label: "麦寮乡",
            value: "麦寮乡",
          },
          {
            areaCode: 712316000000,
            label: "水林乡",
            value: "水林乡",
          },
          {
            areaCode: 712317000000,
            label: "四湖乡",
            value: "四湖乡",
          },
          {
            areaCode: 712318000000,
            label: "臺西乡",
            value: "臺西乡",
          },
          {
            areaCode: 712319000000,
            label: "土库镇",
            value: "土库镇",
          },
          {
            areaCode: 712320000000,
            label: "西螺镇",
            value: "西螺镇",
          },
          {
            areaCode: 712321000000,
            label: "元长乡",
            value: "元长乡",
          },
        ],
        label: "云林县",
        value: "云林县",
      },
    ],
    label: "台湾",
    value: "台湾",
  },
  {
    areaCode: 810000000000,
    children: [
      {
        areaCode: 810100000000,
        children: [
          {
            areaCode: 810101000000,
            label: "中西区",
            value: "中西区",
          },
          {
            areaCode: 810102000000,
            label: "湾仔区",
            value: "湾仔区",
          },
          {
            areaCode: 810103000000,
            label: "东区",
            value: "东区",
          },
          {
            areaCode: 810104000000,
            label: "南区",
            value: "南区",
          },
        ],
        label: "香港岛",
        value: "香港岛",
      },
      {
        areaCode: 810200000000,
        children: [
          {
            areaCode: 810201000000,
            label: "油尖旺区",
            value: "油尖旺区",
          },
          {
            areaCode: 810202000000,
            label: "深水埗区",
            value: "深水埗区",
          },
          {
            areaCode: 810203000000,
            label: "九龙城区",
            value: "九龙城区",
          },
          {
            areaCode: 810204000000,
            label: "黄大仙区",
            value: "黄大仙区",
          },
          {
            areaCode: 810205000000,
            label: "观塘区",
            value: "观塘区",
          },
        ],
        label: "九龙",
        value: "九龙",
      },
      {
        areaCode: 810300000000,
        children: [
          {
            areaCode: 810301000000,
            label: "荃湾区",
            value: "荃湾区",
          },
          {
            areaCode: 810302000000,
            label: "屯门区",
            value: "屯门区",
          },
          {
            areaCode: 810303000000,
            label: "元朗区",
            value: "元朗区",
          },
          {
            areaCode: 810304000000,
            label: "北区",
            value: "北区",
          },
          {
            areaCode: 810305000000,
            label: "大埔区",
            value: "大埔区",
          },
          {
            areaCode: 810306000000,
            label: "西贡区",
            value: "西贡区",
          },
          {
            areaCode: 810307000000,
            label: "沙田区",
            value: "沙田区",
          },
          {
            areaCode: 810308000000,
            label: "葵青区",
            value: "葵青区",
          },
          {
            areaCode: 810309000000,
            label: "离岛区",
            value: "离岛区",
          },
        ],
        label: "新界",
        value: "新界",
      },
    ],
    label: "香港特别行政区",
    value: "香港特别行政区",
  },
  {
    areaCode: 820000000000,
    children: [
      {
        areaCode: 820100000000,
        children: [
          {
            areaCode: 820101000000,
            label: "花地玛堂区",
            value: "花地玛堂区",
          },
          {
            areaCode: 820102000000,
            label: "圣安多尼堂区",
            value: "圣安多尼堂区",
          },
          {
            areaCode: 820103000000,
            label: "大堂区",
            value: "大堂区",
          },
          {
            areaCode: 820104000000,
            label: "望德堂区",
            value: "望德堂区",
          },
          {
            areaCode: 820105000000,
            label: "风顺堂区",
            value: "风顺堂区",
          },
        ],
        label: "澳门半岛",
        value: "澳门半岛",
      },
      {
        areaCode: 820200000000,
        children: [
          {
            areaCode: 820201000000,
            label: "嘉模堂区",
            value: "嘉模堂区",
          },
        ],
        label: "氹仔岛",
        value: "氹仔岛",
      },
      {
        areaCode: 820300000000,
        children: [
          {
            areaCode: 820301000000,
            label: "圣方济各堂区",
            value: "圣方济各堂区",
          },
        ],
        label: "路环岛",
        value: "路环岛",
      },
    ],
    label: "澳门特别行政区",
    value: "澳门特别行政区",
  },
];

export default districtList;
