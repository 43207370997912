import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, Input, Upload, Tooltip, Progress, Modal, Select } from "antd";
import { LoadingOutlined, UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import request from "@/util/request";

import { TestPreviewUrl, ProdPreviewUrl } from "@/constants";
import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";
import { beforeUpload } from "@/util/fileUpload";

import { ResultCodeEnum, commonFilesApi, housesApi, urls, mycenterApi, userApi } from "@/api/index";

const FormItem = Form.Item;
const { confirm } = Modal;
const Option = Select.Option;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading

    attachmentList: [], // 附件列表
    fileUploadLoading: false,

    businessCardList: [],

    fileTypeList: [],
    isDisable: false,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
    this.getFileTypeList();
    this.getUserInfo();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      if (detail.roleCodes.includes("bank_manager") && !detail.roleCodes.includes("platform")) {
        this.setState({ isDisable: true });
        this.formRef.current.setFieldsValue({
          bankId: res.data.houseId,
        });
      } else {
        this.setState({ isDisable: false });
      }
    }
  };

  // 获取详情
  getDetail = async (id) => {
    const res = await commonFilesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      const attachmentList = [];
      detail.attachments.map((item, index) => {
        attachmentList.push({
          name: item.name,
          url: item.url,
          bankId: item.bankId,
          status: "done",
          uid: `${item.id}_${index}`,
        });
      });
      this.setState({
        detail: detail,
        attachmentList,
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        name: detail.name,
        bankId: detail.bankId,
      });
    }
  };

  /** 获取所属银行 */
  getFileTypeList = async () => {
    const res = await housesApi.list({
      pageNum: 0,
      pageSize: 500,
      // dictItemId: fileTypeItemId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        fileTypeList: res.data.rows,
      });
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { history } = this.props;
    const { id, isEdit } = this.state;
    const params = {
      name: values.name,
      bankId: values.bankId,
      attachments: this.state.attachmentList,
    };
    this.setState({ submitting: true });
    if (isEdit) {
      params.id = id;
      const res = await commonFilesApi.update(params);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("编辑成功", () => {
          history.push(`/files/management/index`);
        });
      }
    } else {
      const res = await commonFilesApi.create(params);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("提交成功", () => {
          history.push(`/files/management/index`);
        });
      }
    }
  };

  /* 删除单个附件 */
  onDeleteAttachment = async (index) => {
    const _this = this;
    confirm({
      title: "确定此次操作",
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        let { attachmentList } = _this.state;
        attachmentList = attachmentList.filter((el, ind) => {
          return index !== ind;
        });
        _this.setState({
          attachmentList,
        });
        _this.formRef.current.setFieldsValue({
          attachmentList,
        });
      },
    });
  };

  /** 预览 */
  onPreview = (url) => {
    let prefix = "";
    // prefix 需要区分test环境与prod环境
    if (__ENV__ === "prod") {
      prefix = ProdPreviewUrl;
    } else {
      prefix = TestPreviewUrl;
    }
    // 把字符串编码为 URI 组件
    const encode = encodeURI(url);
    const base64 = btoa(encode);
    url = encodeURIComponent(base64);
    window.open(`${prefix}${url}`, "_blank");
  };

  onDownload = async (url) => {
    window.open(url);
  };

  render() {
    const _this = this;
    const { isEdit, submitting, fileUploadLoading, attachmentList, isDisable } = this.state;

    const document_props = {
      accept: ".",
      name: "file",
      action: urls.uploadImage,
      beforeUpload: beforeUpload,
      onChange(info) {
        console.log(info);
        // 避免原来的文件丢失，这样子写就可以了
        _this.setState({ attachmentList: info.file.status ? [...info.fileList] : _this.state.attachmentList });
      },
      customRequest(info) {
        const file = info.file;
        const formData = new FormData();
        formData.append("file", file);
        request
          .upload(formData, {
            onUploadProgress(progress) {
              let { attachmentList } = _this.state;
              attachmentList = attachmentList.map((item) => {
                if (item.uid === file.uid) {
                  item.percent = Math.round((progress.loaded / progress.total) * 100);
                }
                return item;
              });
              _this.setState({
                attachmentList,
              });
              _this.formRef.current.setFieldsValue({
                attachmentList,
              });
            },
          })
          .then((res) => {
            let { attachmentList } = _this.state;
            attachmentList = attachmentList.map((item) => {
              if (item.uid === file.uid) {
                item.status = res.data.code === ResultCodeEnum.success.code ? "done" : "error";
                item.url = res.data.code === ResultCodeEnum.success.code ? res.data.data : "";
              }
              return item;
            });
            _this.setState({
              attachmentList,
            });
            _this.formRef.current.setFieldsValue({
              attachmentList,
            });
            if (res.data.code !== ResultCodeEnum.success.code) {
              dialog.error(res.data.errorMsg);
            }
          });
      },
      fileList: _this.state.attachmentList,
      defaultFileList: _this.state.attachmentList,
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true, showDownloadIcon: false },
    };

    // 附件信息
    const AttachmentInfo = (el) => {
      return (
        <div
          className="attachment_item flex flex-item flex-middle"
          style={el.status === "done" ? { color: "#3B71DE" } : el.status === "error" ? { color: "#D11C1F" } : {}}
        >
          <div className="flex-item attachment_name">{el.name}</div>
        </div>
      );
    };

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/files/management/index`}>
                  <span>资料管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{isEdit ? "编辑" : "新增"}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="文件名称"
                name="name"
                rules={[{ required: true, message: "请输入文件名称" }]}
              >
                <Input placeholder="请输入文件名称" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="所属银行"
                name="bankId"
                rules={[{ required: true, message: "请选择所属银行" }]}
              >
                <Select disabled={isDisable} placeholder="请选择所属银行">
                  {this.state.fileTypeList.map((el) => {
                    return (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                className="attachmentList"
                label="文件"
                name="attachmentList"
                rules={[{ required: true, message: "请上传文件" }]}
              >
                <Upload {...document_props} className="file_upload">
                  {fileUploadLoading ? (
                    <Button>
                      <LoadingOutlined /> 上传中
                    </Button>
                  ) : (
                    <Button>
                      <UploadOutlined /> 上传附件
                    </Button>
                  )}
                </Upload>
                <div className="attachment_list">
                  {attachmentList.map((el, index) => {
                    return (
                      <div className="attachment" key={index}>
                        <div className="attachment_info flex">
                          {el.status !== "error" ? (
                            AttachmentInfo(el)
                          ) : (
                            <Tooltip placement="top" title={`上传失败`}>
                              {AttachmentInfo(el)}
                            </Tooltip>
                          )}
                          <Button onClick={this.onPreview.bind(this, el.url)} type="link">
                            查看
                          </Button>
                          <Button onClick={this.onDownload.bind(this, el.url)} type="link">
                            下载
                          </Button>
                          <Button onClick={this.onDeleteAttachment.bind(this, index)} type="link">
                            删除
                          </Button>
                        </div>
                        {el.status !== "done" && el.status !== "error" && (
                          <div className="attachment_progress">
                            <Progress percent={el.percent} status={el.status === "error" ? "exception" : "-"} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const FileManagementForm = List;
export default FileManagementForm;
