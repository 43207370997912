import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Divider, Card, Input, Row, Col, Popconfirm, Modal, Select } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize, AuditStatus, TransactionStatus } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ResultCodeEnum, enterprisesApi, mycenterApi } from "@/api/index";

const { confirm } = Modal;
const { Option } = Select;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    importUploadLoading: false,

    userInfo: {},
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getUserInfo();
    this.getList();
  }

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        userInfo: res.data,
      });
    }
  };

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await enterprisesApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await enterprisesApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  /** 导入上传 */
  excelImport = async (formData) => {
    this.setState({
      importUploadLoading: true,
    });
    enterprisesApi.excelImport(formData).then((res) => {
      this.setState({
        importUploadLoading: false,
      });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("导入成功");
        this.getList();
      } else if (res.code === ResultCodeEnum.enterprise_exists.code) {
        this.coverImport(formData, res.errorMsg);
      } else {
        dialog.error(res.errorMsg);
      }
    });
  };

  /** 覆盖上传 */
  coverImport = async (formData, errorMsg) => {
    const _this = this;
    confirm({
      title: "重要提醒",
      icon: <ExclamationCircleOutlined />,
      content: errorMsg,
      okText: "是",
      cancelText: "否",
      onOk() {
        formData.append("isCover", true);
        _this.excelImport(formData);
      },
      onCancel() {
        formData.append("isCover", false);
        _this.excelImport(formData);
      },
    });
  };

  onTop = async (id, value) => {
    const api = value ? enterprisesApi.top : enterprisesApi.cancelTop;
    const res = await api(id);
    if (res.code === ResultCodeEnum.success.code) {
      this.getList();
    }
  };

  render() {
    // const _this = this;
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      // {
      //   title: "注册号",
      //   dataIndex: "regNumber",
      //   key: "regNumber",
      // },
      {
        title: "企业名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "法定代表人",
        dataIndex: "legalPersonName",
        key: "legalPersonName",
      },
      {
        title: "注册资本",
        dataIndex: "regCapital",
        key: "regCapital",
        render: (text) => {
          return <span>{text || "-"}</span>;
        },
      },
      {
        title: "登记状态",
        dataIndex: "regStatus",
        key: "regStatus",
      },
      {
        title: "审核状态",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return <span>{AuditStatus[text].desc}</span>;
        },
      },
      {
        title: "客户状态",
        dataIndex: "transactionStatus",
        key: "transactionStatus",
        render: (text) => {
          return <span>{text ? TransactionStatus[text].desc : "-"}</span>;
        },
      },
      {
        title: "来源",
        dataIndex: "isInternalAddition",
        key: "isInternalAddition",
        render: (isInternalAddition) => {
          return <span>{isInternalAddition ? "内部" : "外部"}</span>;
        },
      },
      {
        title: "创建人",
        dataIndex: "createUserName",
        key: "createUserName",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record) => {
          return (
            <div className="operation">
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onTop.bind(this, id, !record.sort)}
                placement="left"
                title={`确定${record.sort ? "取消置顶" : "置顶"}该企业吗？`}
              >
                <a href="javescript:;">{record.sort ? "取消置顶" : "置顶"}</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`${match.url}/finance/${id}`}>财务情况</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/progress/${id}`}>跟进记录</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/detail/${id}`}>查看</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/edit/${id}`}>编辑</Link>
              {record.status === "audit_pass" && this.props.permission.indexOf("loans") > -1 && (
                <Divider type="vertical" />
              )}
              {record.status === "audit_pass" && this.props.permission.indexOf("loans") > -1 && (
                <Link to={`${match.url}/loans/${id}`}>融资申请</Link>
              )}
              {this.state.userInfo.isSuperAdmin && <Divider type="vertical" />}
              {this.state.userInfo.isSuperAdmin && (
                <Popconfirm
                  cancelText="取消"
                  okText="确定"
                  onConfirm={this.onDelete.bind(this, id)}
                  placement="left"
                  title="确定删除该企业吗？"
                >
                  <a href="javescript:;">删除</a>
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ];

    // const ImportUploadProps = {
    //   accept: ".xls,.xlsx",
    //   customRequest(info) {
    //     const file = info.file;
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     confirm({
    //       title: "重要提醒",
    //       icon: <ExclamationCircleOutlined />,
    //       content: "确定导入吗",
    //       okText: "是",
    //       cancelText: "否",
    //       onOk() {
    //         _this.excelImport(formData);
    //       },
    //       onCancel() {},
    //     });
    //   },
    //   fileList: [],
    // };

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>企业管理</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                {/**  
                  <Col key={1} span={6}>
                    <Form.Item label={`企业代码`} name={`code`}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                */}
                <Col key={2} span={8}>
                  <Form.Item label={`企业名称`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={3} span={8}>
                  <Form.Item label={`注册地址`} name={`regLocation`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={4} span={8}>
                  <Form.Item label={`创建人`} name={`createUserName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={5} span={8}>
                  <Form.Item label={`创建人所属城市`} name={`cityName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={6} span={8}>
                  <Form.Item label={`审核状态`} name={`status`}>
                    <Select placeholder="请选择">
                      <Option value="">全部</Option>
                      <Option value="to_audit">待审核</Option>
                      <Option value="audit_pass">通过</Option>
                      <Option value="audit_refuse">不通过</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col key={6} span={8}>
                  <Form.Item label={`客户状态`} name={`transactionStatus`}>
                    <Select placeholder="请选择">
                      <Option value="">全部</Option>
                      <Option value="follow_up">跟进中</Option>
                      <Option value="done_deal">已交易</Option>
                      <Option value="give_up">储备客户</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button htmlType="submit" style={{ float: "right" }} type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator flex">
              <Link to={`${match.url}/add`}>
                <Button type="primary">新增</Button>
              </Link>
              {/** 
                <Upload {...ImportUploadProps}>
                  <Button loading={this.state.importUploadLoading}>
                    {this.state.importUploadLoading ? "导入中" : "导入"}
                  </Button>
                </Upload>
              */}
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseManagementList = List;
export default EnterpriseManagementList;
