import React from "react";
import { Route, Redirect } from "react-router-dom";

import ChannelManagementList from "@/component/channel/management/list.component";
import ChannelManagementForm from "@/component/channel/management/form.component";

import ChannelRecordList from "@/component/channel/record/list.component";

import EnterpriseManagementDetail from "@/component/enterprise/management/detail.component";

class ChannelComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/channel/management/index`}
          render={(props) => <ChannelManagementList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/channel/management/index/add`}
          render={(props) => (
            <ChannelManagementForm {...props} from={"channel_manager"} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/channel/management/index/edit/:id`}
          render={(props) => (
            <ChannelManagementForm {...props} from={"channel_manager"} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/channel/management/index/record/:recommendationCode`}
          render={(props) => <ChannelRecordList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/channel/management/index/record/:recommendationCode/:id`}
          render={(props) => (
            <EnterpriseManagementDetail {...props} from={"channel"} permission={this.props.permission} />
          )}
        />
      </div>
    );
  }
}

export default ChannelComponent;
