import React from "react";
import { Route, Redirect } from "react-router-dom";

import LoansProgressList from "@/component/loans_progress/progress/list.component";
import EnterpriseManagementDetail from "@/component/enterprise/management/detail.component";

class LoansComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/enterprise/loansProgress/index`}
          render={(props) => <LoansProgressList {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default LoansComponent;
