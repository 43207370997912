import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import NotFound from "@/component/error/not_found.component";
import NoAuthority from "@/component/error/no_authority.component";
import Mycenter from "@/component/myCenter/index.component";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      menu: this.props.menu,
      permissionCodes: this.props.permissionCodes,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      menu: nextProps.menu,
      permissionCodes: nextProps.permissionCodes,
    });
  }

  // 判断组件路径是否正确
  authority = (permission, component, props, func) => {
    return component({ ...props, ...{ permission: this.props.permissionCodes } });
  };

  getLinkUrl = (data) => {
    return `/${data.split("_").join("/")}`;
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            let url = "";
            if (this.state.permissionCodes.length) {
              this.state.menu.some((record) => {
                if (record.children.length) {
                  return record.children.some((item) => {
                    if (this.state.permissionCodes.indexOf(item.key) > -1) {
                      url = this.getLinkUrl(item.key);
                      return true;
                    }
                  });
                } else {
                  if (this.state.permissionCodes.indexOf(record.key) > -1) {
                    url = this.getLinkUrl(record.key);
                    return true;
                  }
                }
              });
              return <Redirect to={url} />;
            } else {
              return <NoAuthority />;
            }
          }}
        />
        {this.state.menu.map((record, index) => {
          if (record.children.length === 0 && this.state.permissionCodes.indexOf(record.key) > -1) {
            return (
              <Route
                key={index}
                path={record.path}
                render={(props) => {
                  return this.authority(record.key, record.component, props, record.func);
                }}
              />
            );
          } else {
            const hasPermission = (item) => {
              return this.state.permissionCodes.indexOf(item.key) > -1;
            };

            if (record.children.some(hasPermission)) {
              return record.children.map((item) => {
                if (this.state.permissionCodes.indexOf(item.key) > -1) {
                  return (
                    <Route
                      key={item.path}
                      path={item.path}
                      render={(props) => {
                        return this.authority(item.key, item.component, props, item.func);
                      }}
                    />
                  );
                }
              });
            }
          }
        })}
        <Route
          path="/myCenter"
          render={(props) => {
            return <Mycenter {...props} />;
          }}
        />
        {/** 

          <Route
            path="/resetPwd"
            render={(props) => {
              return <Mycenter {...props} />;
            }}
          />
        */}
        <Route render={(props) => <NotFound />} />
      </Switch>
    );
  }
}
export default Routes;
