import React from "react";
import { Button, Table, Breadcrumb, Divider, Card, Popconfirm, Form, Modal, Row, Col, Input } from "antd";

import { TableSize, fileTypeItemId } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import { ResultCodeEnum, dictApi } from "@/api/index";

const FormItem = Form.Item;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    /** 弹窗 */
    visible: false,
    visibleKey: 1,
    visibleType: "add",
    modalLoading: false,
    modalSelectedId: "",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      ...{
        dictItemId: fileTypeItemId,
      },
    };
    this.setState({ loading: true });
    const res = await dictApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await dictApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  /** 打开 */
  handleModalOpen = (type, index) => {
    const detail = this.state.list[index];
    this.setState(
      {
        visible: true,
        visibleKey: this.state.visible + 1,
        visibleType: type,
        modalSelectedId: index,
      },
      () => {
        if (type === "edit") {
          this.formRef.current.setFieldsValue({
            name: detail.name,
          });
        }
      }
    );
  };

  /** 取消添加 */
  handleModalCancel = () => {
    this.setState({
      visible: false,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 确定添加 */
  handleModalOk = async (values) => {
    let res = "";
    values.dictItemId = fileTypeItemId;
    this.setState({
      modalLoading: true,
    });
    if (this.state.visibleType === "add") {
      res = await dictApi.create(values);
    } else {
      res = await dictApi.update(this.state.list[this.state.modalSelectedId].id, values);
    }
    this.setState({
      modalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.state.visibleType === "add" ? dialog.success("新增成功") : dialog.success("编辑成功");
      this.setState({
        visible: false,
        visibleKey: this.state.visible + 1,
      });
      this.getList();
    }
  };

  render() {
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "类型名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              <Button onClick={this.handleModalOpen.bind(this, "edit", index)} type="link">
                编辑
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该贷款状态吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>配置管理</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>文件类型</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator">
              <Button onClick={this.handleModalOpen.bind(this, "add")} type="primary">
                新增
              </Button>
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.visibleKey}
          loading={this.state.modalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          onOk={this.handleModalOk}
          title={this.state.visibleType === "add" ? `添加` : `编辑`}
          visible={this.state.visible}
          width={600}
        >
          <Form onFinish={this.handleModalOk.bind(this)} ref={this.formRef}>
            <FormItem
              label="类型名称"
              labelCol={modalLabelCol}
              name="name"
              rules={[{ required: true, message: "请输入类型名称" }]}
              wrapperCol={modalWrapperCol}
            >
              <Input placeholder="请输入类型名称"></Input>
            </FormItem>

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.modalLoading} type="primary">
                    {this.state.modalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancel.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

const FileTypeDetail = List;
export default FileTypeDetail;
