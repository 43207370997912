import React from "react";
import { Link } from "react-router-dom";
import { Button, Table, Breadcrumb, Divider, Card, Popconfirm } from "antd";

import { TableSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, roleApi } from "@/api/index";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await roleApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await roleApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  render() {
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "角色名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record) => {
          return (
            <div className="operation">
              <Link to={`${match.url}/edit/${id}`}>编辑</Link>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该角色吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>角色管理</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator">
              <Link to={`${match.url}/add`}>
                <Button type="primary">新增</Button>
              </Link>
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const RoleList = List;
export default RoleList;
