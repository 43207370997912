import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Table,
  Breadcrumb,
  Divider,
  Card,
  Input,
  Popconfirm,
  Badge,
  Row,
  Col,
  Select,
  Alert,
} from "antd";

import { TableSize, FormSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, userApi, mycenterApi, banksApi } from "@/api/index";

const { Option } = Select;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},
    bankId: "",
    subBankId: "",
    subBankDetail: {},

    userInfo: {
      roleCodes: [],
    },
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getLoginUser();
    this.setState(
      {
        bankId: this.props.match.params.bankId || "",
        subBankId: this.props.match.params.subBankId || "",
      },
      () => {
        this.getList();
        if (this.state.subBankId) {
          this.getSubBranchDetail();
        }
      }
    );
  }

  /** 获取登录用户 */
  getLoginUser = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({ userInfo: res.data });
    }
  };

  /** 获取支行详情 */
  getSubBranchDetail = async () => {
    const res = await banksApi.detail(this.state.subBankId);
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        subBankDetail: res.data,
      });
    }
  };

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      bankId: this.state.subBankId,
    };
    this.setState({ loading: true });
    const res = await userApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击改变状态
   * @param id 选中表格id
   * @param isEnabled 选中表格当前状态 enabled/正常 disabled/禁用
   */
  onChangeStatus = async (id, status) => {
    let res = "";
    if (status === "enabled") {
      res = await userApi.disable(id);
    } else {
      res = await userApi.enable(id);
    }
    if (res.code === ResultCodeEnum.success.code) {
      const list = this.state.list;
      list.map((el) => {
        if (el.id === id) {
          el.status = status === "enabled" ? "disabled" : "enabled";
        }
        return el;
      });
      dialog.success(status === "enabled" ? "禁用成功" : "启用成功");
      this.setState({
        list: list,
      });
    }
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await userApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  render() {
    const { match } = this.props;
    const { list, loading, pagination, userInfo } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "登录账号",
        dataIndex: "loginName",
        key: "loginName",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "手机号",
        dataIndex: "phone",
        key: "phone",
        render: (text) => {
          return <span>{text ? text : "-"}</span>;
        },
      },
      {
        title: "分部名称",
        dataIndex: "orgName",
        key: "orgName",
        render: (text) => {
          return <span>{text ? text : "-"}</span>;
        },
      },
      {
        title: "所属城市",
        dataIndex: "addressDistrictName",
        key: "addressDistrictName",
        render: (text) => {
          return <span>{text ? text : "-"}</span>;
        },
      },
      // {
      //   title: "性别",
      //   dataIndex: "gender",
      //   key: "gender",
      //   render: (text) => {
      //     return <span>{text === "man" ? "男" : text === "woman" ? "女" : text === "unknown" ? "未知" : ""}</span>;
      //   },
      // },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          let status = "",
            statusText = "";
          switch (text) {
            case "enabled":
              status = "green";
              statusText = "正常";
              break;
            case "disabled":
              status = "red";
              statusText = "禁用";
              break;
          }
          return <Badge status={status} text={statusText} />;
        },
      },
      {
        title: "创建人",
        dataIndex: "createdName",
        key: "createdName",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record) => {
          return (
            <div className="operation">
              {(!userInfo.roleCodes.includes("channel_manager") || userInfo.roleCodes.includes("platform")) && (
                <Link to={`${match.url}/edit/${id}`}>编辑</Link>
              )}
              {userInfo.roleCodes.includes("channel_manager") && !userInfo.roleCodes.includes("platform") && (
                <Link to={`${match.url}/editSalesman/${id}`}>编辑</Link>
              )}
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onChangeStatus.bind(this, id, record.status)}
                placement="left"
                title={`确定${record.status === "enabled" ? "禁用" : "启用"}该用户吗？`}
              >
                <a href="javescript:;">{record.status === "enabled" ? "禁用" : "启用"}</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该用户吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            {!this.state.bankId && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <span>用户管理</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}

            {this.state.bankId && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/bank/management/index`}>
                    <span>银行管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/bank/management/index/branch/${this.state.bankId}`}>
                    <span>支行管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>客户经理管理</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={1} span={8}>
                  <Form.Item label={`登录账号`} name={`loginName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={2} span={8}>
                  <Form.Item label={`手机号码`} name={`phone`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={3} span={8}>
                  <Form.Item label={`真实姓名`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={4} span={8}>
                  <Form.Item label={`用户状态`} name={`status`}>
                    <Select placeholder="请选择">
                      <Option value="">全部</Option>
                      <Option value="enabled">正常</Option>
                      <Option value="disabled">禁用</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col key={5} span={8}>
                  <Form.Item label={`城市名称`} name={`cityName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator">
              {(!userInfo.roleCodes.includes("channel_manager") || userInfo.roleCodes.includes("platform")) && (
                <Link to={`${match.url}/add`}>
                  <Button type="primary">新增</Button>
                </Link>
              )}

              {userInfo.roleCodes.includes("channel_manager") && !userInfo.roleCodes.includes("platform") && (
                <Link to={`${match.url}/addSalesman`}>
                  <Button type="primary">新增</Button>
                </Link>
              )}
            </div>

            {this.state.subBankId && (
              <div style={{ marginBottom: 16 }}>
                <Alert message={`支行名称：${this.state.subBankDetail.name || "-"}`} type="info" />
              </div>
            )}

            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const CarouselManagementList = List;
export default CarouselManagementList;
