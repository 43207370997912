import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Divider, Card, Popconfirm, Row, Col, DatePicker, Alert } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, enterpriseFinancesApi, enterprisesApi } from "@/api/index";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    enterpriseInfo: {},
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
    this.getEnterpriseInfo();
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      ...{
        enterpriseId: this.props.match.params.id,
      },
    };
    this.setState({ loading: true });
    const res = await enterpriseFinancesApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取企业信息 */
  getEnterpriseInfo = async (id) => {
    const res = await enterprisesApi.detail(this.props.match.params.id);
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        enterpriseInfo: res.data,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    if (values.year) {
      values.year = values.year.format("YYYY");
    }
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await enterpriseFinancesApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  render() {
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "年份",
        dataIndex: "year",
        key: "year",
      },
      {
        title: "季度",
        dataIndex: "quarter",
        key: "quarter",
        render: (quarter) => {
          let text = "";
          switch (quarter) {
            case 0:
              text = "全年";
              break;
            case 1:
              text = "第一季度";
              break;
            case 2:
              text = "第二季度";
              break;
            case 3:
              text = "第三季度";
              break;
            case 4:
              text = "第四季度";
              break;
          }
          return <span>{text}</span>;
        },
      },
      {
        title: "营收(万元)",
        dataIndex: "revenue",
        key: "revenue",
      },
      {
        title: "净利润(万元)",
        dataIndex: "netProfit",
        key: "netProfit",
      },
      {
        title: "总资产(万元)",
        dataIndex: "totalAssets",
        key: "totalAssets",
      },
      {
        title: "总负债(万元)",
        dataIndex: "totalLiabilities",
        key: "totalLiabilities",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record) => {
          return (
            <div className="operation">
              <Link to={`${match.url}/edit/${id}`}>编辑</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/detail/${id}`}>查看详情</Link>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该财务情况吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`/enterprise/management/index/finance/log/${id}/${this.props.match.params.id}`}>操作记录</Link>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index`}>
                  <span>企业管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>财务情况</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={1} span={6}>
                  <Form.Item label={`年份`} name={`year`}>
                    <DatePicker picker="year" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator">
              <Link to={`${match.url}/add`}>
                <Button type="primary">新增</Button>
              </Link>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Alert message={`企业名称：${this.state.enterpriseInfo.name || "-"}`} type="info" />
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseFinanceList = List;
export default EnterpriseFinanceList;
