import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Card, Input, Row, Col } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, AuditStatus } from "@/constants";
import Enum from "@/enum";
import Utils from "@/util/util";

import { ResultCodeEnum, channelApi } from "@/api/index";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    recommendationCode: "", // 推荐码
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    const { recommendationCode } = this.props.match.params;
    this.setState({ recommendationCode }, () => {
      this.getList();
    });
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      recommendationCode: this.state.recommendationCode,
    };

    this.setState({ loading: true });
    const res = await channelApi.recordList({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {};

  render() {
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "企业名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "联系人",
        dataIndex: "contactPerson",
        key: "contactPerson",
        render: (_, record) => {
          return <span>{record.contactList.length !== 0 ? record.contactList[0].name : "-"}</span>;
        },
      },
      {
        title: "联系电话",
        dataIndex: "contactPhone",
        key: "contactPhone",
        render: (_, record) => {
          return <span>{record.contactList.length !== 0 ? record.contactList[0].phone : "-"}</span>;
        },
      },
      {
        title: "意向授信金额",
        dataIndex: "intentionLoans",
        key: "intentionLoans",
        render: (text) => {
          return <span>{`${text}元`}</span>;
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return <span>{Enum.getObjectEnumDoec(AuditStatus, text)}</span>;
        },
      },
      {
        title: "审核人",
        dataIndex: "auditUserName",
        key: "auditUserName",
      },
      {
        title: "注册时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id) => {
          return (
            <div className="operation">
              <Link to={`${match.url}/${id}`}>查看详情</Link>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/channel/management/index`}>
                  <span>渠道管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>注册记录</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          {/* <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={2} span={8}>
                  <Form.Item label={`渠道名称`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={4} span={8}>
                  <Form.Item label={`联系人`} name={`contactPerson`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card> */}
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const ChannelRecordList = List;
export default ChannelRecordList;
