import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Descriptions,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Select,
  Input,
  Empty,
  Table,
  Image,
  Tag,
} from "antd";

import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { AuditStatus, TransactionStatus, TestPreviewUrl, ProdPreviewUrl } from "@/constants";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import { ResultCodeEnum, enterprisesApi } from "@/api/index";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
import { UpOutlined, DownOutlined, UndoOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import * as echarts from "echarts";
const { confirm } = Modal;

const EnterpriseDetail = ({ detail }) => {
  return (
    <Descriptions bordered title="企业信息">
      <Descriptions.Item label="企业名称" span={2}>
        {detail.name}
      </Descriptions.Item>
      <Descriptions.Item label="代码简称">{detail.bondNum || "-"}</Descriptions.Item>
      <Descriptions.Item label="企业标签" span={3}>
        {detail.tags && detail.tags.length !== 0
          ? detail.tags.map((tag) => {
              {
                const tagColor = [
                  "magenta",
                  "red",
                  "volcano",
                  "orange",
                  "gold",
                  "lime",
                  "green",
                  "cyan",
                  "blue",
                  "geekblue",
                  "purple",
                ];
                const target = tagColor[parseInt(Math.random() * tagColor.length, 10)];
                return <Tag color={target}>{tag}</Tag>;
              }
            })
          : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="登记状态">{detail.regStatus || "-"}</Descriptions.Item>
      <Descriptions.Item label="法定代表人">{detail.legalPersonName || "-"}</Descriptions.Item>
      <Descriptions.Item label="注册资本">{detail.regCapital || "-"}</Descriptions.Item>
      <Descriptions.Item label="成立日期">
        {detail.estiblishTime ? Utils.formatDate(detail.estiblishTime, "YYYY-MM-DD") : ""}
      </Descriptions.Item>
      <Descriptions.Item label="核准日期" span={2}>
        {detail.approvedTime ? Utils.formatDate(detail.approvedTime, "YYYY-MM-DD") : ""}
      </Descriptions.Item>
      <Descriptions.Item label="所属地区" span={3}>
        {detail.city}
        {detail.district}
      </Descriptions.Item>
      <Descriptions.Item label="电话" span={3}>
        {detail.phoneNumber || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="邮箱" span={3}>
        {detail.email || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="统一社会信用代码">{detail.creditCode || "-"}</Descriptions.Item>
      <Descriptions.Item label="纳税人识别号">{detail.taxNumber || "-"}</Descriptions.Item>
      <Descriptions.Item label="注册号">{detail.regNumber || "-"}</Descriptions.Item>
      <Descriptions.Item label="组织分部代码">{detail.orgNumber || "-"}</Descriptions.Item>
      <Descriptions.Item label="参保人数" span={2}>
        {detail.socialStaffNum || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="企业类型">{detail.companyOrgType || "-"}</Descriptions.Item>
      <Descriptions.Item label="所属行业" span={2}>
        {detail.industry || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="曾用名">{detail.historyNames || "-"}</Descriptions.Item>
      <Descriptions.Item label="英文名" span={2}>
        {detail.property3 || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="网址">{detail.websiteList || "-"}</Descriptions.Item>
      <Descriptions.Item label="注册地址" span={2}>
        {detail.regLocation || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="经营范围" span={3}>
        {detail.businessScope || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="备注" span={3}>
        {detail.remark || "-"}
      </Descriptions.Item>
    </Descriptions>
  );
};

const ShareholderDetail = ({ detail }) => {
  const tableColumns = [
    {
      title: "序号",
      align: "center",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "股东（发起人）",
      align: "center",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "持股比例",
      align: "center",
      dataIndex: "percent",
      key: "percent",
      render: (_, record) => {
        return (
          <span>
            {record.capital && record.capital.length !== 0 && record.capital[0].percent
              ? record.capital[0].percent
              : "-"}
          </span>
        );
      },
    },
    {
      title: "认缴出资额",
      align: "center",
      dataIndex: "amomon",
      key: "amomon",
      render: (_, record) => {
        return (
          <span>
            {record.capital && record.capital.length !== 0 && record.capital[0].amomon ? record.capital[0].amomon : "-"}
          </span>
        );
      },
    },
    {
      title: "认缴出资日期",
      align: "center",
      dataIndex: "time",
      key: "time",
      render: (_, record) => {
        return (
          <span>
            {record.capital && record.capital.length !== 0 && record.capital[0].time ? record.capital[0].time : "-"}
          </span>
        );
      },
    },
  ];
  return <Table bordered columns={tableColumns} dataSource={detail} pagination={false} />;
};

class Detail extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    detail: {}, // 详情

    /** 弹窗 */
    visible: false,
    visibleKey: 1,
    modalLoading: false,

    auditStatus: "",

    attachmentList: {
      basic: [],
      shareholders: [],
      finance: [],
      others: [],
    },

    infoSelect: "businessIncome",
    showList: {
      shareholderInfoIsShow: true,
      financialAnalysisIsShow: false,
      stockIsShow: false,
      seniorExecutiveIsShow: false,
      shareholderIsShow: false,
      mainIndexIsShow: false,
      quarMainIndexIsShow: false,
      balanceSheetIsShow: false,
      profitIsShow: false,
      icIsShow: false,
      judicialIsShow: false,
    },
    shareholderInfoResult: "", // 股东信息
    financialAnalysisResult: "", // 财务简析
    stockResult: "", // 上市公司信息
    seniorExecutiveResult: [], // 高管信息
    shareholderResult: [], // 十大股东
    mainIndexResult: "", // 主要指标-年度
    quarMainIndexResult: "", // 主要指标-季度
    balanceSheetResult: "", // 资产负债表
    profitResult: "", // 利润表
    icResult: "", // 工商信息
    judicialResult: "", // 司法风险

    mainIndexStr: [
      {
        name: "每股指标",
        str: "showYear",
      },
      {
        name: "基本每股收益(元)",
        str: "basic_eps",
      },
      {
        name: "扣非每股收益(元)",
        str: "basic_e_ps_net_of_nrgal",
      },
      {
        name: "稀释每股收益(元)",
        str: "dlt_earnings_per_share",
      },
      {
        name: "每股净资产(元)",
        str: "net_profit_per_share",
      },
      {
        name: "每股公积金(元)",
        str: "capital_reserve",
      },
      {
        name: "销售现金流/营业收入",
        str: "crfgsasr_to_revenue",
      },
      {
        name: "扣非净利润同比增长(%)",
        str: "np_atsopc_nrgal_yoy",
      },
      {
        name: "资产负债率(%)",
        str: "asset_liab_ratio",
      },
      {
        name: "营业总收入同比增长(%)",
        str: "revenue_yoy",
      },
      {
        name: "归属净利润同比增长(%)",
        str: "net_profit_atsopc_yoy",
      },
      {
        name: "摊薄净资产收益率(%)",
        str: "fully_dlt_roe",
      },
      {
        name: "实际税率(%)",
        str: "tax_rate",
      },
      {
        name: "应收账款周转天数(天)",
        str: "receivable_turnover_days",
      },
      {
        name: "预收款/营业收入",
        str: "pre_receivable",
      },
      {
        name: "流动比率",
        str: "current_ratio",
      },
      {
        name: "每股经营现金流(元)",
        str: "operate_cash_flow_ps",
      },
      {
        name: "毛利率(%)",
        str: "gross_selling_rate",
      },
      {
        name: "流动负债/总负债(%)",
        str: "current_liab_to_total_liab",
      },
      {
        name: "速动比率",
        str: "quick_ratio",
      },
      {
        name: "摊薄总资产收益率(%)",
        str: "net_interest_of_total_assets",
      },
      {
        name: "营业总收入滚动环比增长(%)",
        str: "operating_total_revenue_lrr_sq",
      },
      {
        name: "扣非净利润滚动环比增长(%)",
        str: "profit_deduct_nrgal_lrr_sq",
      },
      {
        name: "加权净资产收益率(%)",
        str: "wgt_avg_roe",
      },
      {
        name: "净利率(%)",
        str: "net_selling_rate",
      },
      {
        name: "总资产周转率(次)",
        str: "total_capital_turnover",
      },
      {
        name: "归属净利润滚动环比增长(%)",
        str: "net_profit_atsopc_lrr_sq",
      },
      {
        name: "扣非净利润(元)",
        str: "profit_nrgal_sq",
      },
      {
        name: "存货周转天数(天)",
        str: "inventory_turnover_days",
      },
      {
        name: "营业总收入(元)",
        str: "total_revenue",
      },
      {
        name: "每股未分配利润(元)",
        str: "undistri_profit_ps",
      },
      {
        name: "归属净利润(元)",
        str: "net_profit_atsopc",
      },
      {
        name: "毛利润（元）",
        str: "op_to_revenue",
      },
      {
        name: "经营现金流/营业收入",
        str: "ncf_from_oa_to_revenue",
      },
    ],
    quarMainIndexStr: [
      {
        name: "",
        str: "showYear",
      },
      {
        name: "每股指标",
        str: "",
      },
      {
        name: "基本每股收益(元)",
        str: "basic_eps",
      },
      {
        name: "扣非每股收益(元)",
        str: "basic_e_ps_net_of_nrgal",
      },
      {
        name: "稀释每股收益(元)",
        str: "dlt_earnings_per_share",
      },
      {
        name: "每股净资产(元)",
        str: "net_profit_per_share",
      },
      {
        name: "每股公积金(元)",
        str: "capital_reserve",
      },
      {
        name: "每股未分配利润(元)",
        str: "undistri_profit_ps",
      },
      {
        name: "每股经营现金流(元)",
        str: "operate_cash_flow_ps",
      },
      {
        name: "成长能力指标",
        str: "",
      },
      {
        name: "营业总收入(元)",
        str: "total_revenue",
      },
      {
        name: "毛利润（元）",
        str: "op_to_revenue",
      },
      {
        name: "归属净利润(元)",
        str: "net_profit_atsopc",
      },
      {
        name: "扣非净利润(元)",
        str: "profit_nrgal_sq",
      },
      {
        name: "营业总收入同比增长(%)",
        str: "revenue_yoy",
      },
    ],
    balanceSheetStr: [
      {
        name: "",
        str: "showYear",
      },
      {
        name: "流动资产",
        str: "",
      },
      {
        name: "货币资金",
        str: "currency_funds",
      },
      {
        name: "以公允价值计量且其变动计入当期损益的金融资产",
        str: "specified_fnncl_assets_at_fv",
      },
      {
        name: "其中:交易性金融资产",
        str: "tradable_fnncl_assets",
      },
      {
        name: "应收票据",
        str: "bills_receivable",
      },
      {
        name: "应收账款",
        str: "account_receivable",
      },
      {
        name: "预付款项",
        str: "pre_payment",
      },
      {
        name: "其他应收款",
        str: "othr_receivables",
      },
      {
        name: "存货",
        str: "inventory",
      },
      {
        name: "其他流动资产",
        str: "othr_current_assets",
      },
      {
        name: "流动资产合计",
        str: "",
      },
      {
        name: "非流动资产",
        str: "",
      },
      {
        name: "长期股权投资",
        str: "lt_equity_invest",
      },
      {
        name: "投资性房地产",
        str: "invest_property",
      },
      {
        name: "固定资产",
        str: "fixed_asset",
      },
      {
        name: "在建工程",
        str: "construction_in_process",
      },
    ],
    profitStr: [
      {
        name: "",
        str: "showYear",
      },
      {
        name: "营业总收入",
        str: "total_revenue",
        isBold: true,
      },
      {
        name: "营业收入",
        str: "revenue",
      },
      {
        name: "营业总成本",
        str: "operating_costs",
        isBold: true,
      },
      {
        name: "营业成本",
        str: "operating_cost",
      },
      {
        name: "研发费用",
        str: "rad_cost",
      },
      {
        name: "营业税金及附加",
        str: "operating_taxes_and_surcharge",
      },
      {
        name: "销售费用",
        str: "sales_fee",
      },
      {
        name: "管理费用",
        str: "manage_fee",
      },
      {
        name: "财务费用",
        str: "financing_expenses",
      },
      {
        name: "资产减值损失",
        str: "asset_impairment_loss",
      },
      {
        name: "其他经营收益",
        str: "othr_income",
        isBold: true,
      },
      {
        name: "加:公允价值变动收益",
        str: "income_from_chg_in_fv",
      },
      {
        name: "加:投资收益",
        str: "invest_income",
      },
      {
        name: "其中:对联营企业和合营企业的投资收益",
        str: "invest_incomes_from_rr",
      },
      {
        name: "营业利润",
        str: "op",
        isBold: true,
      },
      {
        name: "加:营业外收入",
        str: "non_operating_income",
      },
    ],
    icStr: [
      {
        sectionName: "主要人员列表",
        sectionStr: "staffList",
        column: [
          {
            title: "主要人员名称",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "主要人员类型",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "主要人员职位",
            dataIndex: "staffTypeName",
            key: "staffTypeName",
          },
        ],
      },
      {
        sectionName: "经营异常列表",
        sectionStr: "abnormalList",
        column: [
          {
            title: "列入原因",
            dataIndex: "putReason",
            key: "putReason",
          },
          {
            title: "列入时间",
            dataIndex: "putDate",
            key: "putDate",
          },
          {
            title: "决定列入机关",
            dataIndex: "putDepartment",
            key: "putDepartment",
          },
          {
            title: "移出原因",
            dataIndex: "removeReason",
            key: "removeReason",
          },
          {
            title: "移出时间",
            dataIndex: "removeDate",
            key: "removeDate",
          },
          {
            title: "移出机关",
            dataIndex: "removeDepartment",
            key: "removeDepartment",
          },
        ],
      },
      {
        sectionName: "严重违法",
        sectionStr: "illegalList",
        column: [
          {
            title: "列入原因",
            dataIndex: "putReason",
            key: "putReason",
          },
          {
            title: "列入时间",
            dataIndex: "putDate",
            key: "putDate",
          },
          {
            title: "决定列入机关",
            dataIndex: "putDepartment",
            key: "putDepartment",
          },
          {
            title: "移出原因",
            dataIndex: "removeReason",
            key: "removeReason",
          },
          {
            title: "移出时间",
            dataIndex: "removeDate",
            key: "removeDate",
          },
          {
            title: "决定移出机关",
            dataIndex: "removeDepartment",
            key: "removeDepartment",
          },
          {
            title: "类别",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "违法事实",
            dataIndex: "fact",
            key: "fact",
          },
        ],
      },
      {
        sectionName: "行政处罚",
        sectionStr: "punishList",
        column: [
          {
            title: "省份简称",
            dataIndex: "base",
            key: "base",
          },
          {
            title: "行政处罚决定书文号",
            dataIndex: "punishNumber",
            key: "punishNumber",
          },
          {
            title: "公司名称",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "注册号",
            dataIndex: "regNumber",
            key: "regNumber",
          },
          {
            title: "法定代表人（负责人）姓名",
            dataIndex: "legalPersonName",
            key: "legalPersonName",
          },
          {
            title: "违法行为类型",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "行政处罚内容",
            dataIndex: "content",
            key: "content",
          },
          {
            title: "作出行政处罚决定机关名称",
            dataIndex: "departmentName",
            key: "departmentName",
          },
          {
            title: "作出行政处罚决定日期",
            dataIndex: "decisionDate",
            key: "decisionDate",
          },
          {
            title: "公示日期",
            dataIndex: "publishDate",
            key: "publishDate",
          },
          {
            title: "描述",
            dataIndex: "description",
            key: "description",
          },
          {
            title: "",
            dataIndex: "",
            key: "",
          },
        ],
      },
      {
        sectionName: "抽查检查",
        sectionStr: "checkList",
        column: [
          {
            title: "检查实施机关",
            dataIndex: "checkOrg",
            key: "checkOrg",
          },
          {
            title: "类型",
            dataIndex: "checkType",
            key: "checkType",
          },
          {
            title: "日期",
            dataIndex: "checkDate",
            key: "checkDate",
          },
          {
            title: "结果",
            dataIndex: "checkResult",
            key: "checkResult",
          },
          {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
          },
        ],
      },
      {
        sectionName: "行政许可",
        sectionStr: "licenseList",
        column: [
          {
            title: "许可证名称",
            dataIndex: "licencename",
            key: "licencename",
          },
          {
            title: "许可书文编号",
            dataIndex: "licencenumber",
            key: "licencenumber",
          },
          {
            title: "范围",
            dataIndex: "scope",
            key: "scope",
          },
          {
            title: "起始日期",
            dataIndex: "fromdate",
            key: "fromdate",
          },
          {
            title: "截止日期",
            dataIndex: "todate",
            key: "todate",
          },
          {
            title: "发证机关",
            dataIndex: "department",
            key: "department",
          },
        ],
      },
      {
        sectionName: "清算信息",
        sectionStr: "liquidatingInfo",
        column: [
          {
            title: "清算组负责人",
            dataIndex: "manager",
            key: "manager",
          },
          {
            title: "清算成员名称",
            dataIndex: "member",
            key: "member",
          },
        ],
      },
      {
        sectionName: "股权出质",
        sectionStr: "equityList",
        column: [
          {
            title: "登记编号",
            dataIndex: "regNumber",
            key: "regNumber",
          },
          {
            title: "出质股权标的企业",
            dataIndex: "targetCompany",
            key: "targetCompany",
          },
          {
            title: "出质人",
            dataIndex: "pledgor",
            key: "pledgor",
          },
          {
            title: "出质人证照/证件号码",
            dataIndex: "certifNumber",
            key: "certifNumber",
          },
          {
            title: "出质股权数额",
            dataIndex: "equityAmount",
            key: "equityAmount",
          },
          {
            title: "质权人",
            dataIndex: "pledgee",
            key: "pledgee",
          },
          {
            title: "质权人证照/证件号码",
            dataIndex: "certifNumberR",
            key: "certifNumberR",
          },
          {
            title: "股权出质设立登记日期",
            dataIndex: "regDate",
            key: "regDate",
          },
          {
            title: "状态",
            dataIndex: "state",
            key: "state",
          },
          {
            title: "股权出质设立发布日期",
            dataIndex: "putDate",
            key: "putDate",
          },
          {
            title: "注销日期",
            dataIndex: "cancelDate",
            key: "cancelDate",
          },
          {
            title: "注销原因",
            dataIndex: "cancelReason",
            key: "cancelReason",
          },
        ],
      },
      {
        sectionName: "分支分部",
        sectionStr: "branchList",
        column: [
          {
            title: "简称",
            dataIndex: "alias",
            key: "alias",
          },
          {
            title: "成立日期",
            dataIndex: "estiblishTime",
            key: "estiblishTime",
          },
          {
            title: "经营状态",
            dataIndex: "regStatus",
            key: "regStatus",
          },
          {
            title: "法人",
            dataIndex: "legalPersonName",
            key: "legalPersonName",
          },
          {
            title: "行业",
            dataIndex: "category",
            key: "category",
          },
          {
            title: "注册资本",
            dataIndex: "regCapital",
            key: "regCapital",
          },
          {
            title: "名称",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "法人类型",
            dataIndex: "personType",
            key: "personType",
          },
        ],
      },
      {
        sectionName: "司法协助",
        sectionStr: "judicialList",
        column: [
          {
            title: "被执行人",
            dataIndex: "executedPerson",
            key: "executedPerson",
          },
          {
            title: "股权数额",
            dataIndex: "equityAmount",
            key: "equityAmount",
          },
          {
            title: "执行通知书文号",
            dataIndex: "executeNoticeNum",
            key: "executeNoticeNum",
          },
          {
            title: "执行法院",
            dataIndex: "executiveCourt",
            key: "executiveCourt",
          },
          {
            title: "类型|状态",
            dataIndex: "typeState",
            key: "typeState",
          },
        ],
      },

      {
        sectionName: "简易注销",
        sectionStr: "briefCancel",
        column: [
          {
            title: "公司名",
            dataIndex: "company_name",
            key: "company_name",
          },
          {
            title: "注册号",
            dataIndex: "reg_num",
            key: "reg_num",
          },
          {
            title: "统一社会信用代码",
            dataIndex: "credit_code",
            key: "credit_code",
          },
          {
            title: "公告期",
            dataIndex: "announcement_term",
            key: "announcement_term",
          },
          {
            title: "公告结束日期",
            dataIndex: "announcement_end_date",
            key: "announcement_end_date",
          },
          {
            title: "登记机关",
            dataIndex: "reg_authority",
            key: "reg_authority",
          },
          {
            title: "简易注销结果",
            dataIndex: "brief_cancel_result",
            key: "brief_cancel_result",
          },
          {
            title: "公告申请日期",
            dataIndex: "announcement_apply_date",
            key: "announcement_apply_date",
          },
        ],
      },
      {
        sectionName: "知识产权出质",
        sectionStr: "iprPledgeList",
        column: [
          {
            title: "知识产权登记证号",
            dataIndex: "iprCertificateNum",
            key: "iprCertificateNum",
          },
          {
            title: "名称",
            dataIndex: "iprName",
            key: "iprName",
          },
          {
            title: "种类",
            dataIndex: "iprType",
            key: "iprType",
          },
          {
            title: "出质人",
            dataIndex: "pledgorName",
            key: "pledgorName",
          },
          {
            title: "质权人名称",
            dataIndex: "pledgeeName",
            key: "pledgeeName",
          },
          {
            title: "质权登记期限",
            dataIndex: "pledgeRegPeriod",
            key: "pledgeRegPeriod",
          },
          {
            title: "状态",
            dataIndex: "state",
            key: "state",
          },
          {
            title: "公示日期",
            dataIndex: "publicityDate",
            key: "publicityDate",
          },
          {
            title: "注销日期",
            dataIndex: "cancleDate",
            key: "cancleDate",
          },
          {
            title: "注销原因",
            dataIndex: "cancleReason",
            key: "cancleReason",
          },
        ],
      },
      {
        sectionName: "动产抵押",
        sectionStr: "mortList",
        column: [
          {
            title: "登记编号",
            dataIndex: "regNum",
            key: "regNum",
          },
          {
            title: "登记日期",
            dataIndex: "regDate",
            key: "regDate",
          },
          {
            title: "公示日期",
            dataIndex: "publishDate",
            key: "publishDate",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "登记机关",
            dataIndex: "regDepartment",
            key: "regDepartment",
          },
          {
            title: "被担保债权种类",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "被担保债权数额",
            dataIndex: "amount",
            key: "amount",
          },
          {
            title: "债务人履行债务的期限",
            dataIndex: "term",
            key: "term",
          },
          {
            title: "担保范围",
            dataIndex: "scope",
            key: "scope",
          },
          {
            title: "注销日期",
            dataIndex: "cancelDate",
            key: "cancelDate",
          },
          {
            title: "注销原因",
            dataIndex: "cancleReason",
            key: "cancleReason",
          },
        ],
      },

      {
        sectionName: "年报",
        sectionStr: "reportList",
        column: [
          {
            title: "发布时间",
            dataIndex: "releaseTime",
            key: "releaseTime",
          },
          {
            title: "年报年度",
            dataIndex: "reportYear",
            key: "reportYear",
          },
          {
            title: "企业名称",
            dataIndex: "companyName",
            key: "companyName",
          },
          {
            title: "统一社会信用代码",
            dataIndex: "creditCode",
            key: "creditCode",
          },
          {
            title: "注册号",
            dataIndex: "regNumber",
            key: "regNumber",
          },
          {
            title: "电话号",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
          },
          {
            title: "邮政编码",
            dataIndex: "postcode",
            key: "postcode",
          },
          {
            title: "企业通信地址",
            dataIndex: "postalAddress",
            key: "postalAddress",
          },
          {
            title: "企业经营状态",
            dataIndex: "manageState",
            key: "manageState",
          },
          {
            title: "纳税总额",
            dataIndex: "totalTax",
            key: "totalTax",
          },
          {
            title: "负债总额",
            dataIndex: "totalLiability",
            key: "totalLiability",
          },
        ],
      },
      {
        sectionName: "企业变更",
        sectionStr: "changeList",
        column: [
          {
            title: "变更事项",
            dataIndex: "changeItem",
            key: "changeItem",
          },
          {
            title: "变更时间",
            dataIndex: "changeTime",
            key: "reportYear",
          },
          {
            title: "变更前",
            dataIndex: "contentBefore",
            key: "contentBefore",
          },
          {
            title: "变更后",
            dataIndex: "contentAfter",
            key: "contentAfter",
          },
        ],
      },
      {
        sectionName: "对外投资",
        sectionStr: "investList",
        column: [
          {
            title: "公司类型",
            dataIndex: "orgType",
            key: "orgType",
          },
          {
            title: "经营范围",
            dataIndex: "business_scope",
            key: "business_scope",
          },
          {
            title: "投资占比",
            dataIndex: "percent",
            key: "percent",
          },
          {
            title: "企业状态",
            dataIndex: "regStatus",
            key: "regStatus",
          },
          {
            title: "开业时间",
            dataIndex: "estiblishTime",
            key: "estiblishTime",
          },
          {
            title: "注册资金",
            dataIndex: "regCapital",
            key: "regCapital",
          },
          {
            title: "统一社会信用代码",
            dataIndex: "creditCode",
            key: "creditCode",
          },
          {
            title: "法人类型",
            dataIndex: "personType",
            key: "personType",
          },
        ],
      },
      {
        sectionName: "股东",
        sectionStr: "shareHolderList",
        column: [
          {
            title: "股东名",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "简称",
            dataIndex: "alias",
            key: "alias",
          },
          {
            title: "类型",
            dataIndex: "type",
            key: "type",
          },
        ],
      },
    ],
    judicialStr: [
      {
        sectionName: "开庭公告",
        sectionStr: "ktAnnouncementList",
        column: [
          {
            title: "开庭日期(时间)",
            dataIndex: "startDate",
            key: "startDate",
          },
          {
            title: "法庭",
            dataIndex: "courtroom",
            key: "courtroom",
          },
          {
            title: "案由",
            dataIndex: "caseReason",
            key: "caseReason",
          },
          {
            title: "法院",
            dataIndex: "court",
            key: "court",
          },
          {
            title: "当事人",
            dataIndex: "litigant",
            key: "litigant",
          },
          {
            title: "审判长/主审人",
            dataIndex: "judge",
            key: "judge",
          },
          {
            title: "承办部门",
            dataIndex: "contractors",
            key: "contractors",
          },
          {
            title: "案号",
            dataIndex: "caseNo",
            key: "caseNo",
          },
        ],
      },
      {
        sectionName: "被执行人",
        sectionStr: "zhixingList",
        column: [
          {
            title: "被执行人名称",
            dataIndex: "pname",
            key: "pname",
          },
          {
            title: "执行法院",
            dataIndex: "execCourtName",
            key: "execCourtName",
          },
          {
            title: "案号",
            dataIndex: "caseCode",
            key: "caseCode",
          },
          {
            title: "身份证号／组织分部代码",
            dataIndex: "partyCardNum",
            key: "partyCardNum",
          },
          {
            title: "创建时间",
            dataIndex: "caseCreateTime",
            key: "caseCreateTime",
          },
          {
            title: "执行标的",
            dataIndex: "execMoney",
            key: "execMoney",
          },
        ],
      },
      {
        sectionName: "法院公告",
        sectionStr: "courtAnnouncementList",
        column: [
          {
            title: "公告号",
            dataIndex: "bltnno",
            key: "bltnno",
          },
          {
            title: "公告状态号",
            dataIndex: "bltnstate",
            key: "bltnstate",
          },
          {
            title: "公告类型",
            dataIndex: "bltntype",
            key: "bltntype",
          },
          {
            title: "案件号",
            dataIndex: "caseno",
            key: "caseno",
          },
          {
            title: "法院名",
            dataIndex: "courtcode",
            key: "courtcode",
          },
          {
            title: "处理等级名称",
            dataIndex: "dealgrade",
            key: "dealgrade",
          },
          {
            title: "法官",
            dataIndex: "judge",
            key: "judge",
          },
          {
            title: "原告",
            dataIndex: "party1",
            key: "party1",
          },
          {
            title: "当事人",
            dataIndex: "party2",
            key: "party2",
          },
          {
            title: "刊登日期",
            dataIndex: "publishdate",
            key: "publishdate",
          },
        ],
      },
      {
        sectionName: "立案信息",
        sectionStr: "courtRegisterList",
        column: [
          {
            title: "案号",
            dataIndex: "caseNo",
            key: "caseNo",
          },
          {
            title: "案由",
            dataIndex: "caseReason",
            key: "caseReason",
          },
          {
            title: "法院",
            dataIndex: "court",
            key: "court",
          },
          {
            title: "当事人",
            dataIndex: "litigant",
            key: "litigant",
          },
          {
            title: "第三人",
            dataIndex: "third",
            key: "third",
          },
          {
            title: "立案时间",
            dataIndex: "filingDate",
            key: "filingDate",
          },
          {
            title: "结案时间",
            dataIndex: "closeDate",
            key: "closeDate",
          },
          {
            title: "案件状态",
            dataIndex: "caseStatus",
            key: "caseStatus",
          },
          {
            title: "案件类型",
            dataIndex: "caseType",
            key: "caseType",
          },
          {
            title: "案件详情",
            dataIndex: "content",
            key: "content",
          },
        ],
      },
      {
        sectionName: "失信人",
        sectionStr: "dishonestList",
        column: [
          {
            title: "失信人名称",
            dataIndex: "iname",
            key: "iname",
          },
          {
            title: "法人、负责人姓名",
            dataIndex: "businessentity",
            key: "businessentity",
          },
          {
            title: "执行依据文号",
            dataIndex: "gistid",
            key: "gistid",
          },
          {
            title: "身份证号码/组织分部代码",
            dataIndex: "cardnum",
            key: "cardnum",
          },
          {
            title: "失信人类型",
            dataIndex: "type",
            key: "type",
          },
          {
            title: "法定负责人/主要负责人信息",
            dataIndex: "staff",
            key: "staff",
          },
          {
            title: "履行情况",
            dataIndex: "performance",
            key: "performance",
          },
          {
            title: "失信被执行人行为具体情形",
            dataIndex: "disrupttypename",
            key: "disrupttypename",
          },
          {
            title: "案号",
            dataIndex: "casecode",
            key: "casecode",
          },
          {
            title: "立案时间",
            dataIndex: "regdate",
            key: "regdate",
          },
        ],
      },
      {
        sectionName: "诉讼",
        sectionStr: "lawSuitList",
        column: [
          {
            title: "原告",
            dataIndex: "plaintiffs",
            key: "plaintiffs",
          },
          {
            title: "裁判日期",
            dataIndex: "judgetime",
            key: "judgetime",
          },
          {
            title: "法院",
            dataIndex: "court",
            key: "court",
          },
          {
            title: "案由",
            dataIndex: "casereason",
            key: "casereason",
          },
          {
            title: "标题",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "发布时间",
            dataIndex: "submittime",
            key: "submittime",
          },
          {
            title: "案件类型",
            dataIndex: "casetype",
            key: "casetype",
          },
          {
            title: "被告",
            dataIndex: "defendants",
            key: "defendants",
          },
          {
            title: "当事人",
            dataIndex: "parties",
            key: "parties",
          },
        ],
      },
      {
        sectionName: "送达公告",
        sectionStr: "sendAnnouncementList",
        column: [
          {
            title: "标题",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "法院",
            dataIndex: "court",
            key: "court",
          },
          {
            title: "内容",
            dataIndex: "content",
            key: "content",
          },
          {
            title: "发布日期",
            dataIndex: "startDate",
            key: "startDate",
          },
        ],
      },
    ],
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const id = match.params.id;
    this.setState(
      {
        id,
      },
      () => {
        this.getData("shareholderInfo");
        this.getData("financialAnalysis");
        this.getData("stock");
        this.getData("seniorExecutive");
        this.getData("shareholder");
        this.getData("mainIndex");
        this.getData("quarMainIndex");
        this.getData("balanceSheet");
        this.getData("profit");
        this.getData("ic");
        this.getData("judicial");
      }
    );
    this.getDetail(id);
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await enterprisesApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const { attachmentList } = this.state;
      const detail = res.data;
      detail.attachmentList.forEach((el) => {
        console.log(el);
        if (el.type === "basic") {
          attachmentList.basic.push(el);
        }
        if (el.type === "shareholders") {
          attachmentList.shareholders.push(el);
        }
        if (el.type === "finance") {
          attachmentList.finance.push(el);
        }
        if (el.type === "others") {
          attachmentList.others.push(el);
        }
      });

      if (detail.incomeInThreeYears) {
        detail.incomeInThreeYears = JSON.parse(detail.incomeInThreeYears);
      }

      if (detail.incomeInRecentMonth) {
        detail.incomeInRecentMonth = JSON.parse(detail.incomeInRecentMonth);
      }

      if (detail.creditGrantingBanks) {
        detail.creditGrantingBanks = JSON.parse(detail.creditGrantingBanks);
        const other = detail.creditGrantingBanks[detail.creditGrantingBanks.length - 1];
        if (other instanceof Object) {
          detail.creditGrantingBanks.pop();
          detail.creditGrantingBanks.push(other.creditGrantingBanksOther);
        }
      }

      if (detail.tags) {
        detail.tags = detail.tags.split(";");
      }

      console.log(attachmentList);
      this.setState({
        detail,
        attachmentList,
      });
    }
  };

  ColAttachment = (label, value) => {
    const ColSpan = { xs: 24, sm: 12, md: 12, lg: 12 };
    return (
      <Col className="details_col" lg={ColSpan.md} md={ColSpan.md} sm={ColSpan.md} xs={ColSpan.xs}>
        <Row className="details_col_row" gutter={20}>
          <Col span={24}>
            {value.length > 0 ? (
              value.map((el, index) => {
                return (
                  <div key={index}>
                    <div className="details_col_value attachment">
                      <span className="attachment_name">{el.name}</span>
                      <Button
                        className="download"
                        loading={el.isPull}
                        onClick={this.onPreview.bind(this, el.url)}
                        type="link"
                      >
                        查看
                      </Button>
                      <Button
                        className="download"
                        loading={el.isPull}
                        onClick={this.onDownload.bind(this, el.url)}
                        type="link"
                      >
                        下载
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>无</div>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  onDownload = (url) => {
    window.open(url);
  };

  /** 预览 */
  onPreview = (url) => {
    let prefix = "";
    // prefix 需要区分test环境与prod环境
    if (__ENV__ === "prod") {
      prefix = ProdPreviewUrl;
    } else {
      prefix = TestPreviewUrl;
    }
    // 把字符串编码为 URI 组件
    const encode = encodeURI(url);
    const base64 = btoa(encode);
    url = encodeURIComponent(base64);
    window.open(`${prefix}${url}`, "_blank");
  };

  /** 打开 */
  handleModalOpen = () => {
    this.setState({
      visible: true,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 取消 */
  handleModalCancel = () => {
    this.setState({
      visible: false,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 确定 */
  handleModalOk = async (values) => {
    this.setState({
      modalLoading: true,
    });
    const id = this.state.id;
    const res = await enterprisesApi.audit(id, values);
    this.setState({
      modalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.handleModalCancel();
      this.getDetail(id);
      dialog.success("操作成功");
      this.setState({
        auditStatus: "",
      });
    }
  };
  // 改变每个信息展示与否
  onChangeShow = (el) => {
    const that = this;
    that.state.showList[el] = !that.state.showList[el];
    that.setState({
      showList: that.state.showList,
    });
  };
  clickInfo = (el) => {
    const { showList } = this.state;
    this.setState(
      {
        infoSelect: el,
        showList: {
          ...showList,
          financialAnalysisIsShow: true,
        },
      },
      () => {
        const data = this.state.financialAnalysisResult[el];
        if (!data) {
          return;
        }
        // 渲染图表
        const legenData = data.time;
        const seriesData = [];
        data.list.map((item, index) => {
          seriesData.push({
            value: item.amount,
            itemStyle: {
              color: "#67b5ff",
            },
          });
        });
        // 基于准备好的dom，初始化echarts实例
        const info_echart = echarts.init(document.getElementById("info_echart"));
        info_echart.setOption({
          title: {
            text: data.info,
          },
          tooltip: {
            formatter: function(params, trigger) {
              const str = `${params.name}：${data[params.name].convertAmount || data[params.name].amount}${
                !data[params.name].convertAmount ? "%" : ""
              }`;
              return '<div style="text-align:center"><div>' + str + "</div></div>";
            },
          },
          xAxis: {
            type: "category",
            data: legenData,
            axisTick: {
              // 隐藏刻度
              show: true,
            },
          },
          yAxis: {
            // 虚线
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
              },
            },
            axisTick: {
              // 隐藏刻度
              show: false,
            },
            // 隐藏轴线
            axisLine: {
              show: true,
            },
            axisLabel: {
              formatter: function(params, e) {
                return el === "grossInterestRate" || el === "netInterestRate" ? `${params}` : `${params / 100000000}亿`;
              },
            },
          },
          series: [
            {
              barWidth: 20,
              name: "",
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series",
              },
              data: seriesData,
              formatter: function(param) {
                const str = param.data + "";
                let strs = new Array();
                strs = str.split(",");
                return (parseFloat(strs[1]).toFixed(2) + "").replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
              },
            },
          ],
        });
      }
    );
  };

  showConfirm = async (el) => {
    const that = this;
    confirm({
      title: "确认更新该项信息?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        console.log("OK");
        that.getData(el, { isUpdated: true });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // 获取天眼查信息
  getData = async (type, params) => {
    // 股东信息 shareholderInfo
    // 上市公司财务简析 financialAnalysis
    // 上市公司信息 stock
    // 高管信息 seniorExecutive
    // 十大股东 shareholder
    const that = this;
    const res = await enterprisesApi[type]({ id: this.state.id, isUpdated: false, ...params });
    if (res.code === "OK" && res.data) {
      if (type === "mainIndex") {
        res.data = res.data.slice(-8);
      }
      this.setState(
        {
          [`${type}Result`]:
            type === "shareholder"
              ? res.data.holderList
              : type === "quarMainIndex"
              ? res.data.corpTotalMainIndex.slice(-8)
              : type === "balanceSheet"
              ? res.data.corpBalanceSheet.slice(-8)
              : type === "profit"
              ? res.data.corpProfit.slice(-8)
              : res.data,
        },
        () => {
          if (type === "financialAnalysis" && res.data) {
            that.clickInfo("businessIncome");
          }
        }
      );
    }
    if (params && params.isUpdated) {
      dialog.success("已更新");
    }
  };

  render() {
    const { detail } = this.state;
    const columns = [
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "职务",
        dataIndex: "position",
        key: "position",
      },
      {
        title: "持股数",
        dataIndex: "numberOfShares",
        key: "numberOfShares",
        render: (text) => {
          return <span>{text ? text : "-"}</span>;
        },
      },
      {
        title: "年龄",
        dataIndex: "age",
        key: "age",
      },
      {
        title: "学历",
        dataIndex: "education",
        key: "education",
      },
    ];
    const shareholderColumns = [
      {
        title: "分部或基金",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "持有数量",
        dataIndex: "holdingNum",
        key: "holdingNum",
      },
      {
        title: "持股变化(股)",
        dataIndex: "holdingChange",
        key: "holdingChange",
      },
      {
        title: "占股本比例(%)",
        dataIndex: "proportion",
        key: "proportion",
      },
      {
        title: "实际增减持(%)",
        dataIndex: "actual",
        key: "actual",
      },
      {
        title: "股份类型",
        dataIndex: "shareType",
        key: "shareType",
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              {this.props.from === "loansAudit" && (
                <Breadcrumb.Item>
                  <Link to={`/enterprise/loansAudit/index`}>
                    <span>贷款审核</span>
                  </Link>
                </Breadcrumb.Item>
              )}

              {this.props.from === "audit" && (
                <Breadcrumb.Item>
                  <Link to={`/enterprise/audit/index`}>
                    <span>企业审核</span>
                  </Link>
                </Breadcrumb.Item>
              )}

              {this.props.from === "management" && (
                <Breadcrumb.Item>
                  <Link to={`/enterprise/management/index`}>
                    <span>企业管理</span>
                  </Link>
                </Breadcrumb.Item>
              )}

              {this.props.from === "channel" && (
                <>
                  <Breadcrumb.Item>
                    <Link to={`/channel/management/index`}>
                      <span>渠道管理</span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/channel/management/index/record/${this.props.match.params.recommendationCode}`}>
                      <span>注册记录</span>
                    </Link>
                  </Breadcrumb.Item>
                </>
              )}

              <Breadcrumb.Item>
                <span>企业详情</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false} style={{ marginBottom: 16 }}>
            <div className="flex flex-between flex-middle">
              <h3 style={{ color: "#333", fonWeight: "bold" }}>
                审核状态：{detail.status ? AuditStatus[detail.status].desc : ""}
              </h3>
              <div>
                {detail.status === "to_audit" && this.props.permission.indexOf("audit_enterprise") > -1 && (
                  <Button onClick={this.handleModalOpen.bind(this)} type="primary">
                    审核
                  </Button>
                )}
              </div>
            </div>
            {detail.status === "audit_refuse" && (
              <div className="refuseReason">
                <div>审核原因：{detail.refuseReason}</div>
              </div>
            )}
            {detail.status !== "to_audit" && (
              <div className="refuseReason">
                <div style={{ marginTop: 10 }}>
                  <span style={{ marginRight: 15 }}>审核人：{detail.auditUserName || "-"}</span>
                  <span>
                    审核时间：
                    {Utils.formatDate(detail.auditTime, "yyyy-mm-dd hh:ii")}
                  </span>
                </div>
              </div>
            )}
          </Card>
          <Card bordered={false}>
            <EnterpriseDetail detail={detail} />
          </Card>
          {!detail.isInternalAddition && (
            <Card bordered={false} style={{ marginTop: 16, marginBottom: 16 }}>
              <Descriptions bordered title="附加信息">
                {/* <Descriptions.Item label="近三年营业收入" span={3}>
                  {detail.incomeInThreeYears
                    ? detail.incomeInThreeYears.map((item, index) => {
                        return (
                          <span className="descriptions_span" key={index}>{`${item.year}年：${item.amount ||
                            "-"}万元`}</span>
                        );
                      })
                    : "-"}
                </Descriptions.Item> */}
                <Descriptions.Item label="近一个月营业收入" span={3}>
                  {detail.incomeInRecentMonth && detail.incomeInRecentMonth.month ? (
                    <span className="descriptions_span">{`${detail.incomeInRecentMonth.month}：${detail.incomeInRecentMonth.income}万元`}</span>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="负债率" span={3}>
                  {detail.debtRatio ? <span className="descriptions_span">{`${detail.debtRatio}%`}</span> : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="已取得授信的银行名单" span={3}>
                  {detail.creditGrantingBanks && detail.creditGrantingBanks.length !== 0
                    ? detail.creditGrantingBanks.map((el) => {
                        return (
                          <span className="descriptions_span" key={el}>
                            {el}
                          </span>
                        );
                      })
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="是否有抵押物" span={3}>
                  {detail.hasPawn ? "是" : "否"}
                </Descriptions.Item>
                {detail.hasPawn && (
                  <Descriptions.Item label="抵押物内容" span={3}>
                    {detail.pawnContent || "-"}
                  </Descriptions.Item>
                )}
                {/* <Descriptions.Item label="法人身份证正反面" span={3}>
                {
                  <>
                    {detail.legalPersonIdCardFont && (
                      <Image
                        height={100}
                        src={detail.legalPersonIdCardFont}
                        style={{ marginRight: 50, cursor: "pointer" }}
                        width={100}
                      />
                    )}
                    {detail.legalPersonIdCardBack && (
                      <Image
                        height={100}
                        src={detail.legalPersonIdCardBack}
                        style={{ cursor: "pointer" }}
                        width={100}
                      />
                    )}
                    {!detail.legalPersonIdCardFont && !detail.legalPersonIdCardBack && "-"}
                  </>
                }
              </Descriptions.Item> */}
              </Descriptions>
            </Card>
          )}
          <Card bordered={false} style={{ marginTop: 16, marginBottom: 16 }}>
            <Descriptions bordered title="其他信息">
              <Descriptions.Item label="意向授信金额(万元)" span={3}>
                {detail.intentionLoans ? detail.intentionLoans.toLocaleString() : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="客户状态" span={3}>
                {detail.transactionStatus ? TransactionStatus[detail.transactionStatus].desc : "-"}
              </Descriptions.Item>
              {detail.contactList && detail.contactList.length !== 0 && (
                <Descriptions.Item label={`联系人`} span={3}>
                  {detail.contactList.map((item, index) => {
                    return (
                      <div className="flex flex-middle" key={index}>
                        <span className="descriptions_span">姓名：{item.name || "-"}</span>
                        <span className="descriptions_span">联系电话：{item.phone || "-"}</span>
                        <span className="descriptions_span">职务：{item.title || "-"}</span>
                        <span className="descriptions_span flex flex-middle">
                          名片：
                          {item.businessCard ? (
                            <Button
                              onClick={() => {
                                window.open(item.businessCard);
                              }}
                              type="link"
                            >
                              查看
                            </Button>
                          ) : (
                            "无"
                          )}
                        </span>
                      </div>
                    );
                  })}
                </Descriptions.Item>
              )}
              {this.state.attachmentList.basic.length === 0 && (
                <Descriptions.Item label="基础资料" span={3}>
                  暂无
                </Descriptions.Item>
              )}
              {this.state.attachmentList.basic.length !== 0 && (
                <Descriptions.Item label="基础资料" span={3}>
                  {this.ColAttachment("项目附件", this.state.attachmentList.basic || [])}
                </Descriptions.Item>
              )}
              {this.state.attachmentList.shareholders.length === 0 && (
                <Descriptions.Item label="股东资料" span={3}>
                  暂无
                </Descriptions.Item>
              )}
              {this.state.attachmentList.shareholders.length !== 0 && (
                <Descriptions.Item label="股东资料" span={3}>
                  {this.ColAttachment("项目附件", this.state.attachmentList.shareholders || [])}
                </Descriptions.Item>
              )}
              {this.state.attachmentList.finance.length === 0 && (
                <Descriptions.Item label="财务资料" span={3}>
                  暂无
                </Descriptions.Item>
              )}
              {this.state.attachmentList.finance.length !== 0 && (
                <Descriptions.Item label="财务资料" span={3}>
                  {this.ColAttachment("项目附件", this.state.attachmentList.finance || [])}
                </Descriptions.Item>
              )}
              {this.state.attachmentList.others.length === 0 && (
                <Descriptions.Item label="其他资料" span={3}>
                  暂无
                </Descriptions.Item>
              )}
              {this.state.attachmentList.others.length !== 0 && (
                <Descriptions.Item label="其他资料" span={3}>
                  {this.ColAttachment("项目附件", this.state.attachmentList.others || [])}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("shareholderInfoIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">股东信息</div>
                {this.state.showList.shareholderInfoIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.shareholderInfoIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("shareholderInfo");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.shareholderInfoIsShow && (
              <div className="enterpriseContent flex">
                {this.state.shareholderInfoResult && <ShareholderDetail detail={this.state.shareholderInfoResult} />}
                {!this.state.shareholderInfoResult && (
                  <div className="flex flex-item flex-center">
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("financialAnalysisIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">财务简析</div>
                {this.state.showList.financialAnalysisIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.financialAnalysisIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("financialAnalysis");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.financialAnalysisIsShow && (
              <div className="enterpriseContent flex">
                {this.state.financialAnalysisResult && (
                  <div className="flex flex-item">
                    <div className="infoList flex flex-column">
                      <div
                        className={`infoItem ${this.state.infoSelect === "businessIncome" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("businessIncome");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        营业收入
                      </div>
                      <div
                        className={`infoItem ${this.state.infoSelect === "netProfit" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("netProfit");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        净利润
                      </div>
                      <div
                        className={`infoItem ${this.state.infoSelect === "totalAssets" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("totalAssets");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        总资产
                      </div>
                      <div
                        className={`infoItem ${this.state.infoSelect === "netAssets" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("netAssets");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        净资产
                      </div>
                      <div
                        className={`infoItem ${this.state.infoSelect === "netInterestRate" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("netInterestRate");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        净利率
                      </div>
                      <div
                        className={`infoItem ${this.state.infoSelect === "grossInterestRate" ? "infoActive" : ""}`}
                        onClick={() => {
                          this.clickInfo("grossInterestRate");
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        毛利率
                      </div>
                    </div>
                    <div className="contentRight flex flex-item">
                      <div className="flex flex-item" id="info_echart" style={{ width: "100%", height: "300px" }}></div>
                    </div>
                  </div>
                )}
                {!this.state.financialAnalysisResult && (
                  <div className="flex flex-item flex-center">
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("stockIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">上市公司信息</div>
                {this.state.showList.stockIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.stockIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("stock");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.stockIsShow && (
              <div className="enterpriseContent flex">
                {this.state.stockResult && (
                  <Descriptions bordered>
                    <Descriptions.Item label="公司名称">
                      {this.state.stockResult.companyName ? this.state.stockResult.companyName.toLocaleString() : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="股票代码">
                      {this.state.stockResult.bondNum ? this.state.stockResult.bondNum.toLocaleString() : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="股票名称">
                      {this.state.stockResult.bondName ? this.state.stockResult.bondName.toLocaleString() : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="股票类型">
                      {this.state.stockResult.stockType ? this.state.stockResult.stockType.toLocaleString() : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="上市状态">
                      {this.state.stockResult.listingStatus
                        ? this.state.stockResult.listingStatus.toLocaleString()
                        : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="上市日期">
                      {this.state.stockResult.listedDate
                        ? Utils.formatDate(this.state.stockResult.listedDate, "YYYY-MM-DD")
                        : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="交易所">
                      {this.state.stockResult.market ? this.state.stockResult.market.toLocaleString() : "-"}
                    </Descriptions.Item>
                  </Descriptions>
                )}
                {!this.state.stockResult && (
                  <div className="flex flex-item flex-center">
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("seniorExecutiveIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">高管信息</div>
                {this.state.showList.seniorExecutiveIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.seniorExecutiveIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("seniorExecutive");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.seniorExecutiveIsShow && (
              <div className="enterpriseContent flex">
                {this.state.seniorExecutiveResult.length !== 0 && (
                  <Table columns={columns} dataSource={this.state.seniorExecutiveResult} />
                )}
                {this.state.seniorExecutiveResult.length === 0 && (
                  <div className="flex flex-item flex-center">
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </Card>

          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("shareholderIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">十大股东</div>
                {this.state.showList.shareholderIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.shareholderIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("shareholder");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.shareholderIsShow && (
              <div className="enterpriseContent flex">
                {this.state.shareholderResult.length !== 0 && (
                  <Table columns={shareholderColumns} dataSource={this.state.shareholderResult} pagination={false} />
                )}
                {this.state.shareholderResult.length === 0 && (
                  <div className="flex flex-item flex-center">
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </Card>

          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("mainIndexIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">主要指标-年度</div>
                {this.state.showList.mainIndexIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.mainIndexIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("mainIndex");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.mainIndexIsShow && (
              <div className={"customTableContent"}>
                <div className="enterpriseContent flex">
                  {this.state.mainIndexResult && (
                    <div className="customTable flex flex-item">
                      <div className="tr td flex flex-item-1 flex-column">
                        {this.state.mainIndexStr.map((item, index) => {
                          return (
                            <div className="tHeader tItem" key={`name_${index}`}>
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.mainIndexResult.map((item, index) => {
                        return (
                          <div className="td flex flex-item flex-column" key="index">
                            {this.state.mainIndexStr.map((sItem, sIndex) => {
                              return (
                                <div className="tItem" key={`str_${index}`}>
                                  {item[sItem.str] ? item[sItem.str] : "-"}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!this.state.mainIndexResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("quarMainIndexIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">主要指标-季度</div>
                {this.state.showList.quarMainIndexIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.quarMainIndexIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("quarMainIndex");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.quarMainIndexIsShow && (
              <div className="customTableContent">
                <div className="enterpriseContent flex">
                  {this.state.quarMainIndexResult && (
                    <div className="customTable flex flex-item">
                      <div className="tr td flex flex-item-1 flex-column">
                        {this.state.quarMainIndexStr.map((item, index) => {
                          return (
                            <div
                              className={item.str ? "tHeader tItem" : "tHeader tItem boldItem"}
                              key={`quarname_${index}`}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.quarMainIndexResult.map((item, index) => {
                        return (
                          <div className="td flex flex-item flex-column" key="index">
                            {this.state.quarMainIndexStr.map((sItem, sIndex) => {
                              return (
                                <div className={"tItem"} key={`quarstr_${index}`}>
                                  {sItem.str ? (item[sItem.str] ? item[sItem.str] : "-") : ""}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!this.state.quarMainIndexResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>

          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("balanceSheetIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">资产负债表</div>
                {this.state.showList.balanceSheetIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.balanceSheetIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("balanceSheet");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.balanceSheetIsShow && (
              <div className="customTableContent">
                <div className="enterpriseContent flex">
                  {this.state.balanceSheetResult && (
                    <div className="customTable flex flex-item">
                      <div className="tr td flex flex-item-1 flex-column">
                        {this.state.balanceSheetStr.map((item, index) => {
                          return (
                            <div
                              className={item.str ? "tHeader tItem" : "tHeader tItem boldItem"}
                              key={`quarname_${index}`}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.balanceSheetResult.map((item, index) => {
                        return (
                          <div className="td flex flex-item flex-column" key="index">
                            {this.state.balanceSheetStr.map((sItem, sIndex) => {
                              return (
                                <div className={"tItem"} key={`quarstr_${index}`}>
                                  {sItem.str ? (item[sItem.str] ? item[sItem.str] : "-") : ""}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!this.state.balanceSheetResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>

          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("profitIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">利润表</div>
                {this.state.showList.profitIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.profitIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("profit");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.profitIsShow && (
              <div className="customTableContent">
                <div className="enterpriseContent flex">
                  {this.state.profitResult && (
                    <div className="customTable flex flex-item">
                      <div className="tr td flex flex-item-1 flex-column">
                        {this.state.profitStr.map((item, index) => {
                          return (
                            <div
                              className={!item.str || item.isBold ? "tHeader tItem boldItem" : "tHeader tItem"}
                              key={`quarname_${index}`}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.profitResult.map((item, index) => {
                        return (
                          <div className="td flex flex-item flex-column" key="index">
                            {this.state.profitStr.map((sItem, sIndex) => {
                              return (
                                <div className={"tItem"} key={`quarstr_${index}`}>
                                  {sItem.str ? (item[sItem.str] ? item[sItem.str] : "-") : ""}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!this.state.profitResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("icIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">工商信息</div>
                {this.state.showList.icIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.icIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("ic");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.icIsShow && (
              <div className="customTableContent">
                <div className="enterpriseContent flex flex-column">
                  {this.state.icResult &&
                    this.state.icStr.map((iItem, iIndex) => {
                      return (
                        <div className="flex flex-item flex-column" key={`iIndex_${iIndex}`}>
                          <div className="sectionName">{iItem.sectionName}</div>
                          <Table
                            columns={iItem.column}
                            dataSource={this.state.icResult[iItem.sectionStr]}
                            pagination={false}
                          />
                        </div>
                      );
                    })}
                  {!this.state.icResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>
          <Card bordered={false} className="enterpriseSection" style={{ marginTop: 16, marginBottom: 16 }}>
            <div className="enterpriseTitle flex flex-between">
              <div
                className="enterpriseName flex flex-middle"
                onClick={() => {
                  this.onChangeShow("judicialIsShow");
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="name">司法风险</div>
                {this.state.showList.judicialIsShow && <UpOutlined className="showIcon" />}
                {!this.state.showList.judicialIsShow && <DownOutlined className="showIcon" />}
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.showConfirm("judicial");
                  }}
                >
                  <UndoOutlined />
                  更新
                </Button>
              </div>
            </div>
            {this.state.showList.judicialIsShow && (
              <div className="customTableContent">
                <div className="enterpriseContent flex flex-column">
                  {this.state.judicialResult &&
                    this.state.judicialStr.map((iItem, iIndex) => {
                      return (
                        <div className="flex flex-item flex-column" key={`iIndex_${iIndex}`}>
                          <div className="sectionName">{iItem.sectionName}</div>
                          <Table
                            columns={iItem.column}
                            dataSource={this.state.judicialResult[iItem.sectionStr]}
                            pagination={false}
                          />
                        </div>
                      );
                    })}
                  {!this.state.judicialResult && (
                    <div className="flex flex-item flex-center">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.visibleKey}
          loading={this.state.modalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          onOk={this.handleModalOk}
          title={`审核`}
          visible={this.state.visible}
          width={600}
        >
          <Form onFinish={this.handleModalOk.bind(this)} ref={this.formRef}>
            <FormItem
              label="审核状态"
              labelCol={modalLabelCol}
              name="status"
              rules={[{ required: true, message: "请选择审核状态" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select
                onChange={(value) =>
                  this.setState({
                    auditStatus: value,
                  })
                }
                placeholder="请选择审核状态"
              >
                <Option value={"audit_pass"}>审核通过</Option>
                <Option value={"audit_refuse"}>审核不通过</Option>
              </Select>
            </FormItem>

            {this.state.auditStatus === "audit_refuse" && (
              <FormItem
                label="审核原因"
                labelCol={modalLabelCol}
                name="refuseReason"
                rules={[{ required: true, message: "请输入审核理由" }]}
                wrapperCol={modalWrapperCol}
              >
                <TextArea placeholder="请输入审核理由" rows={4} />
              </FormItem>
            )}

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.modalLoading} type="primary">
                    {this.state.modalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancel.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

const EnterpriseManagementDetail = Detail;
export default EnterpriseManagementDetail;
