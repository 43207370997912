import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";

/* 多语言支持*/
import { IntlProvider } from "umi-plugin-react/locale";
// antd 组件多语言
import zh_CN from "antd/es/locale/zh_CN";
import en_US from "antd/es/locale/en_US";
import zh_TW from "antd/es/locale/zh_TW";
import "moment/locale/zh-cn";
// 多语言配置文件
import zhCN from "@/locales/zh-CN.ts";
import enUS from "@/locales/en-US.ts";
import zhTW from "@/locales/zh-TW.ts";

// common组件引入
import HeaderComponent from "@/component/common/Header";
import SiderNav from "@/component/common/SiderNav";
import ScrollToTop from "@/component/common/ScrollToTop";
import Routes from "@/component/common/Routes";
import * as Navs from "@/component/common/navs";

// 样式
import "react-quill/dist/quill.snow.css";
import "../common.less";

// Api
import { ResultCodeEnum, loginApi } from "@/api/index";

const langMap = {
  zh_CN: {
    localeProvider: zh_CN,
    localeConfig: zhCN,
  },
  en_US: {
    localeProvider: en_US,
    localeConfig: enUS,
  },
  zh_TW: {
    localeProvider: zh_TW,
    localeConfig: zhTW,
  },
};

const { Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "zh_CN", // 语言,默认中文
      collapsed: false, // 左侧栏收缩开关

      loginName: "", // 登录用户名
      headImg: "", // 登录用户头像
      isEnterprise: false, // 是否为企业用户
      permissionCodes: [], // 登录用户权限列表code
      menu: [], // 菜单栏
    };
  }

  UNSAFE_componentWillMount() {
    this.getLoginUser();
  }

  /**
   * 获取当前登录用户的信息
   */
  getLoginUser = () => {
    loginApi.verifyLogin().then((res) => {
      if (res.code === ResultCodeEnum.success.code) {
        const menu = Navs.data;
        sessionStorage.setItem("loginUser", JSON.stringify(res.data));
        this.setState({
          loginName: res.data.name,
          headImg: res.data.headImg,
          isEnterprise: res.data.roleCodes.includes("enterprise_manager") && !res.data.roleCodes.includes("platform"),
          permissionCodes: res.data.permissionCodes,
          menu,
        });
      }
    });
  };

  /**
   * 点击收缩左侧栏按钮
   */
  onCollapsed = (collapsed) => {
    this.setState({
      collapsed,
    });
  };

  /**
   * 切换语言,目前支持中文/英文/繁体
   * 语言配置文件在src/locales下
   */
  onChangeLanguage = (language) => {
    this.setState({
      language,
    });
  };

  gotoUpdatePwd = () => {
    this.props.history.push(`/myCenter/updatePwd`);
  };

  render() {
    return (
      <IntlProvider locale="en" messages={langMap[this.state.language].localeConfig}>
        <HashRouter>
          <ConfigProvider locale={langMap[this.state.language].localeProvider}>
            {/* 左侧菜单栏 */}
            <SiderNav
              collapsed={this.state.collapsed}
              menu={this.state.menu}
              permissionCodes={this.state.permissionCodes}
            />
            <Layout>
              {/* 头部 */}
              <HeaderComponent
                collapsed={this.state.collapsed}
                gotoUpdatePwd={this.gotoUpdatePwd.bind(this)}
                headImg={this.state.headImg}
                isEnterprise={this.state.isEnterprise}
                loginName={this.state.loginName}
                onChangeLanguage={this.onChangeLanguage.bind(this)}
                onCollapsed={this.onCollapsed.bind(this)}
              />
              <Layout>
                {/* 组件显示内容 */}
                <Content id="antContent" style={{ position: "relative" }}>
                  <ScrollToTop>
                    <Routes menu={this.state.menu} permissionCodes={this.state.permissionCodes} />
                  </ScrollToTop>
                </Content>
              </Layout>
            </Layout>
          </ConfigProvider>
        </HashRouter>
      </IntlProvider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("container"));
