import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Table,
  Breadcrumb,
  Card,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Modal,
  Checkbox,
  Space,
  Cascader,
  Radio,
} from "antd";
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, MinusOutlined } from "@ant-design/icons";

import "@/component/carousel/styles.less";

import { TableSize, loanStatusdictItemId, FormSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { checkMoney } from "@/util/formValidation";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import { ResultCodeEnum, enterpriseLoansApi, dictApi, mycenterApi, userApi } from "@/api/index";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

import "./styles.less";

import districtMap from "./districtMap";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    /** 银行信息 */
    bankList: [],
    userList: [],
    userInfo: {
      id: "",
      roleCodes: [],
    },

    /** 弹窗 */
    visible: false,
    visibleKey: 1,
    modalLoading: false,

    /** 弹窗 */
    resetSubmiting: false,
    submitSetuping: false,
    setupVisible: false,
    loanVisible: false,
    loanVisibleKey: 100,
    loanModalLoading: false,

    loanStatusList: [],

    selectedIndex: "",

    // 地区选择
    defaultAreaType: "all",
    districtConditions: [],
    defaultDistrictValue: [],
    selectedDistrictNumber: 0,
    showDistrict: "",
    cityList: [],
    districtList: [],
    isOpenCascader: false,

    // 错误提示
    errTipMap: {
      loanAmount: null,
      revenueAmount: null,
    },
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getUserInfo();
    this.getLoanStatusList();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  loadsFormRef = React.createRef();
  setupFormRef = React.createRef();

  /** 获取用户信息 */
  getUserInfo = async () => {
    const res = await mycenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState(
        {
          userInfo: res.data,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    if (this.state.userInfo.loginName !== "admin" && this.state.userInfo.roleCodes.indexOf("bank_manager") > -1) {
      params.userId = this.state.userInfo.id;
    }
    this.setState({ loading: true });
    const res = await enterpriseLoansApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取贷款状态列表 */
  getLoanStatusList = async () => {
    const res = await dictApi.list({
      pageNum: 0,
      pageSize: 100,
      dictItemId: loanStatusdictItemId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        loanStatusList: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    if (values.year) {
      values.year = values.year.format("YYYY");
    }
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /** 打开 */
  handleModalOpenLoan = (detail, index) => {
    this.setState(
      {
        loanVisible: true,
        loanVisibleKey: this.state.visible + 1,
        selectedIndex: index,
      },
      () => {
        this.loadsFormRef.current.setFieldsValue({
          loansStatus: detail.loansStatus,
          actualLoans: detail.actualLoans,
          remark: detail.remark,
        });
      }
    );
  };
  handleModalOpenSetup = () => {
    this.setState({ setupVisible: true }, async () => {
      const res = await userApi.detail(this.state.userInfo.id);
      if (res.code === ResultCodeEnum.success.code) {
        const stateVal = {};
        const fieldVal = {};
        const detail = res.data.screeningCondition;

        fieldVal.tags = detail.tags ? detail.tags.split(",") : [];
        fieldVal.loanAmount = detail.loanAmount ? detail.loanAmount.split(",") : [];
        fieldVal.foundingTime = detail.foundingTime ? detail.foundingTime.split(",") : [];
        fieldVal.registeredCapital = detail.registeredCapital ? detail.registeredCapital.split(",") : [];
        fieldVal.financeInformation = detail.financeInformation ? detail.financeInformation.split(",") : [];
        fieldVal.enterpriseType = detail.enterpriseType ? detail.enterpriseType.split(",") : [];
        fieldVal.revenueAmount = detail.revenueAmount ? detail.revenueAmount.split(",") : [];

        // 自定义金额处理
        fieldVal.customLoanAmountMin = detail.customLoanAmountMin ? detail.customLoanAmountMin : null;
        fieldVal.customLoanAmountMax = detail.customLoanAmountMax ? detail.customLoanAmountMax : null;
        fieldVal.customRevenueAmountMin = detail.customRevenueAmountMin ? detail.customRevenueAmountMin : null;
        fieldVal.customRevenueAmountMax = detail.customRevenueAmountMax ? detail.customRevenueAmountMax : null;

        // 抵押物处理
        switch (detail.pawnStatus) {
          case 0:
            fieldVal.pawnStatus = [];
            break;
          case 1:
          case 2:
            fieldVal.pawnStatus = [detail.pawnStatus];
            break;
          case 3:
            fieldVal.pawnStatus = [1, 2];
            break;
        }

        // 地区特殊处理
        stateVal.showDistrict = detail.showDistrict ? detail.showDistrict : "";
        stateVal.cityList = detail.city ? detail.city.split(",") : [];
        stateVal.districtList = detail.district ? detail.district.split(",") : [];
        stateVal.defaultDistrictValue = stateVal.showDistrict
          .split(";")
          .map((el) => el.split(","))
          .filter((el) => !el.includes(""));
        stateVal.selectedDistrictNumber = stateVal.defaultDistrictValue.filter((item, idx, arr) => {
          return arr.findIndex((el) => el.includes(item[0])) === idx;
        }).length;
        stateVal.districtConditions = stateVal.defaultDistrictValue
          .filter((item, idx, arr) => {
            return arr.findIndex((el) => el.includes(item[0])) === idx;
          })
          .map((item) => item[0]);
        stateVal.defaultAreaType = detail.city === "全国" && detail.district === "全国" ? "all" : "part";

        this.setupFormRef.current.setFieldsValue(fieldVal);
        this.setState(stateVal);
      }
    });
  };

  /** 取消 */
  handleModalCancelLoan = () => {
    this.setState({
      loanVisible: false,
      loanVisibleKey: this.state.visible + 1,
    });
  };
  handleModalCancelSetup = () => {
    const { resetSubmiting, submitSetuping } = this.state;
    if (!submitSetuping && !resetSubmiting) {
      this.setState({
        setupVisible: false,
      });
    }
  };
  handleResetModalData = async () => {
    this.setState({ resetSubmiting: true });
    const params = {
      id: this.state.userInfo.id,
      screeningCondition: {
        managerId: this.state.userInfo.id,
        showDistrict: "",
        city: null,
        district: null,
        tags: null,
        foundingTime: null,
        loanAmount: null,
        registeredCapital: null,
        pawnStatus: null,
        financeInformation: null,
        enterpriseType: null,
        revenueAmount: null,
        customRevenueAmountMax: null,
        customLoanAmountMax: null,
        customRevenueAmountMin: null,
        customLoanAmountMin: null,
      },
    };
    const res = await userApi.addScreeningCondition(params);
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("设置成功", () => {
        this.setupFormRef.current.setFieldsValue({
          tags: [],
          foundingTime: [],
          loanAmount: [],
          registeredCapital: [],
          pawnStatus: null,
          financeInformation: [],
          enterpriseType: [],
          revenueAmount: [],
          customRevenueAmountMax: null,
          customLoanAmountMax: null,
          customRevenueAmountMin: null,
          customLoanAmountMin: null,
        });
        this.setState({
          districtConditions: [],
          defaultDistrictValue: null,
          selectedDistrictNumber: 0,
          showDistrict: "",
          cityList: [],
          districtList: [],
          isOpenCascader: false,
          resetSubmiting: false,
          errTipMap: {
            loanAmount: null,
            revenueAmount: null,
          },
        });
      });
    } else {
      this.setState({ resetSubmiting: false });
    }
  };

  /** 确定 */
  handleModalOkLoan = async (values) => {
    this.setState({
      loanModalLoading: true,
    });
    const { selectedIndex, list } = this.state;
    const id = list[selectedIndex].id;
    const res = await enterpriseLoansApi.update(id, values);
    this.setState({
      loanModalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.handleModalCancelLoan();
      dialog.success("更新成功");
      list[selectedIndex].loansStatus = values.loansStatus;
      list[selectedIndex].actualLoans = Number(values.actualLoans);
      list[selectedIndex].remark = values.remark;
      this.setState({
        list,
      });
    }
  };
  handleModalOkSetup = async (values) => {
    console.log("[values]: ", values);
    const errTipMap = this.state.errTipMap;
    if (errTipMap.loanAmount || errTipMap.revenueAmount) {
      return false;
    }

    const { cityList, districtList, showDistrict, defaultAreaType } = this.state;
    // 是否有抵押物
    let pawnStatus = 0;
    const pawnStatusSize = values.pawnStatus ? values.pawnStatus.length : 0;
    switch (pawnStatusSize) {
      case 0:
        pawnStatus = 0;
        break;

      case 1:
        pawnStatus = values.pawnStatus[0];
        break;

      case 2:
        pawnStatus = 3;
        break;
    }

    // 地区选择处理
    let city = "";
    let district = "";
    let showDistrictStr = "";
    switch (defaultAreaType) {
      case "all":
        city = "全国";
        district = "全国";
        showDistrictStr = "";
        break;

      case "part":
        city = cityList.length ? cityList.join(",") : null;
        district = districtList.length ? districtList.join(",") : null;
        showDistrictStr = showDistrict;
        break;
    }

    const params = {
      id: this.state.userInfo.id,
      screeningCondition: {
        managerId: this.state.userInfo.id,
        showDistrict: showDistrictStr,
        city: city,
        district: district,
        tags: values.tags.length ? values.tags.join(",") : null,
        foundingTime: values.foundingTime.length ? values.foundingTime.join(",") : null,
        loanAmount: values.loanAmount.length ? values.loanAmount.join(",") : null,
        registeredCapital: values.registeredCapital.length ? values.registeredCapital.join(",") : null,
        pawnStatus: pawnStatus,
        financeInformation: values.financeInformation.length ? values.financeInformation.join(",") : null,
        enterpriseType: values.enterpriseType.length ? values.enterpriseType.join(",") : null,
        revenueAmount: values.revenueAmount.length ? values.revenueAmount.join(",") : null,
        customLoanAmountMin: values.customLoanAmountMin || null,
        customLoanAmountMax: values.customLoanAmountMax || null,
        customRevenueAmountMin: values.customRevenueAmountMin || null,
        customRevenueAmountMax: values.customRevenueAmountMax || null,
      },
    };

    this.setState({ submitSetuping: true });
    const res = await userApi.addScreeningCondition(params);
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("设置成功", () => {
        this.setState({
          setupVisible: false,
          submitSetuping: false,
        });
      });
    } else {
      this.setState({ submitSetuping: false });
    }
  };

  /** 地区清除 */
  handleCascaderClose = () => {
    this.setState({
      districtConditions: [],
      defaultDistrictValue: null,
      selectedDistrictNumber: 0,
      showDistrict: "",
      cityList: [],
      districtList: [],
      isOpenCascader: false,
    });
  };

  /**
   * 选择地区修改
   *
   * @param {*} value
   * @param {*} selectedOptions
   */
  selectedRegion = async (_, selectedOptions) => {
    const districtConditions = new Set();
    const cityList = new Set();
    const districtList = [];
    const districtStrList = [];
    let selectedDistrictNumber = 0;

    for (const option of selectedOptions) {
      // 添加已选地区
      districtConditions.add(option[0].label);

      // 选中省级
      if (option.length === 1) {
        const citys = option[0].children;
        for (const cityIdx in citys) {
          const city = citys[cityIdx];
          let districts = city.children;
          districts = districts.map((district) => {
            return district.value;
          });
          cityList.add(city.value);
          districtList.push(...districts);
        }
        districtStrList.unshift(`${option[0].label}`);
        selectedDistrictNumber += 1;
        continue;
      }

      // 选中市级
      if (option.length === 2) {
        const city = option[1];
        let districts = option[1].children;
        districts = districts.map((district) => {
          return district.value;
        });
        cityList.add(option[1].value);
        districtList.push(...districts);
        districtStrList.unshift(`${option[0].label},${city.label}`);
        selectedDistrictNumber += 1;
        continue;
      }

      // 选中区级
      if (option.length === 3) {
        cityList.add(option[1].value);
        districtList.push(option[2].value);
        districtStrList.unshift(`${option[0].label},${option[1].label},${option[2].label}`);
        selectedDistrictNumber += 1;
        continue;
      }
    }

    this.setState({
      districtConditions: [...districtConditions].reverse(),
      cityList: [...cityList],
      districtList,
      showDistrict: districtStrList.join(";"),
      selectedDistrictNumber,
    });
  };

  /**
   * 显示/隐藏地区选择框的回调
   *
   * @param {*} value
   */
  handleChangeCascaderVisible = (value) => {
    this.setState({ isOpenCascader: value });
  };

  // 修改地区选择方式
  handelChangeAreaType = (e) => {
    const selValue = e.target.value;
    this.setState({
      defaultAreaType: selValue,
    });
  };

  // 检查自定义金额
  checkCustomMoney = (modal) => {
    if (!this.setupFormRef || !this.setupFormRef.current) {
      return false;
    }

    const getFieldValue = this.setupFormRef.current.getFieldValue;
    const customLoanAmountMin =
      modal === "loanAmount" ? getFieldValue("customLoanAmountMin") : getFieldValue("customRevenueAmountMin");
    const customLoanAmountMax =
      modal === "loanAmount" ? getFieldValue("customLoanAmountMax") : getFieldValue("customRevenueAmountMax");

    const errTipMap = this.state.errTipMap;
    if (!customLoanAmountMin && !customLoanAmountMax) {
      errTipMap[modal] = null;
    } else if (!customLoanAmountMin) {
      errTipMap[modal] = "请输入最低金额";
    } else if (!customLoanAmountMax) {
      errTipMap[modal] = "请输入最高金额";
    } else if (isNaN(customLoanAmountMin) || isNaN(customLoanAmountMax)) {
      errTipMap[modal] = "金额必须为数值型";
    } else if (Number(customLoanAmountMin) <= 0 || Number(customLoanAmountMax) <= 0) {
      errTipMap[modal] = "金额必须大于零";
    } else if (Number(customLoanAmountMax) <= Number(customLoanAmountMin)) {
      errTipMap[modal] = "最高金额必须小于最低金额";
    } else {
      errTipMap[modal] = null;
    }

    this.setState({ errTipMap });
    return true;
  };

  render() {
    const {
      list,
      loading,
      pagination,
      districtConditions,
      defaultAreaType,
      isOpenCascader,
      defaultDistrictValue,
      selectedDistrictNumber,
    } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "核心企业",
        dataIndex: "enterpriseName",
        key: "enterpriseName",
      },
      {
        title: "贷款金额(万元)",
        dataIndex: "intentionLoans",
        key: "intentionLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "实际贷款金额(万元)",
        dataIndex: "actualLoans",
        key: "actualLoans",
        render: (text) => {
          return <span>{text ? text.toLocaleString() : "-"}</span>;
        },
      },
      {
        title: "银行名称",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "客户经理",
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: "状态",
        dataIndex: "loansStatus",
        key: "loansStatus",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              {this.props.permission.indexOf("audit_loan") > -1 ? (
                <Button onClick={this.handleModalOpenLoan.bind(this, record, index)} type="link">
                  审核状态
                </Button>
              ) : (
                <span></span>
              )}
              {this.props.permission.indexOf("audit_loan") > -1 && <Divider type="vertical" />}
              <Link to={`/enterprise/loansAudit/index/detail/${record.enterpriseId}`}>
                <Button type="link">企业详情</Button>
              </Link>
            </div>
          );
        },
      },
    ];

    /**
     * 高级设置选项
     */
    const advanceSettings = [
      {
        key: "pawnStatus",
        title: "是否有抵押物",
        options: [
          {
            label: "是",
            value: 1,
          },
          {
            label: "否",
            value: 2,
          },
        ],
      },
      {
        key: "loanAmount",
        title: "融资金额",
        options: [
          {
            label: "1000万以内",
            value: "0~1000",
          },
          {
            label: "1000万-5000万",
            value: "1000~5000",
          },
          {
            label: "5000万以上",
            value: "5000~-1",
          },
        ],
        custom: ["customLoanAmountMin", "customLoanAmountMax"],
      },
      {
        key: "revenueAmount",
        title: "营收金额",
        options: [
          {
            label: "3000万以内",
            value: "0~3000",
          },
          {
            label: "3000万-10000万",
            value: "3000~10000",
          },
          {
            label: "10000万以上",
            value: "10000~-1",
          },
        ],
        custom: ["customRevenueAmountMin", "customRevenueAmountMax"],
      },
      {
        key: "tags",
        title: "科技型企业",
        options: [
          {
            label: "科技型中小企业",
            value: "科技型中小企业",
          },
          {
            label: "技术创新示范企业",
            value: "技术创新示范企业",
          },
          {
            label: "独角兽企业",
            value: "独角兽企业",
          },
          {
            label: "瞪羚企业",
            value: "瞪羚企业",
          },
          {
            label: "企业技术中心",
            value: "企业技术中心",
          },
          {
            label: "科技小巨人企业",
            value: "科技小巨人企业",
          },
          {
            label: "民营科技企业",
            value: "民营科技企业",
          },
          {
            label: "雏鹰企业",
            value: "雏鹰企业",
          },
          {
            label: "技术先进型服务企业",
            value: "技术先进型服务企业",
          },
          {
            label: "隐形冠军企业",
            value: "隐形冠军企业",
          },
          {
            label: "国家级专精特新小巨人",
            value: "国家级专精特新小巨人",
          },
          {
            label: "众创空间",
            value: "众创空间",
          },
          {
            label: "科技企业孵化器",
            value: "科技企业孵化器",
          },
        ],
      },
      {
        key: "financeInformation",
        title: "融资信息",
        options: [
          {
            label: "天使轮",
            value: "天使轮",
          },
          {
            label: "Pre-A轮",
            value: "Pre-A轮",
          },
          {
            label: "A轮",
            value: "A轮",
          },
          {
            label: "A+轮",
            value: "A+轮",
          },
          {
            label: "Pre-B轮",
            value: "Pre-B轮",
          },
          {
            label: "B轮",
            value: "B轮",
          },
          {
            label: "B+轮",
            value: "B+轮",
          },
          {
            label: "C轮",
            value: "C轮",
          },
          {
            label: "D轮",
            value: "D轮",
          },
          {
            label: "E轮",
            value: "E轮",
          },
          {
            label: "IPO上市",
            value: "IPO上市",
          },
          {
            label: "并购",
            value: "并购",
          },
          {
            label: "战略融资",
            value: "战略融资",
          },
          {
            label: "股权融资",
            value: "股权融资",
          },
          {
            label: "定向增发",
            value: "定向增发",
          },
        ],
      },
      {
        key: "enterpriseType",
        title: "企业类型",
        options: [
          {
            label: "股份有限公司",
            value: "股份有限公司",
          },
          {
            label: "有限责任公司",
            value: "有限责任公司",
          },
          {
            label: "个体工商户",
            value: "个体工商户",
          },
          {
            label: "有限合伙企业",
            value: "有限合伙企业",
          },
          {
            label: "普通合伙企业",
            value: "普通合伙企业",
          },
          {
            label: "集体所有制",
            value: "集体所有制",
          },
        ],
      },
      {
        key: "registeredCapital",
        title: "注册资本",
        options: [
          {
            label: "1000万以内",
            value: "0~1000",
          },
          {
            label: "1000万-5000万",
            value: "1000~5000",
          },
          {
            label: "5000万以上",
            value: "5000~-1",
          },
        ],
      },
      {
        key: "foundingTime",
        title: "成立时间",
        options: [
          {
            label: "1年内",
            value: "0~1",
          },
          {
            label: "1-5年",
            value: "1~5",
          },
          {
            label: "5-10年",
            value: "5~10",
          },
          {
            label: "10-15年",
            value: "10~15",
          },
          {
            label: "15年以上",
            value: "15~-1",
          },
        ],
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>贷款审核</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label={`状态`} name={`loansStatus`}>
                    <Select placeholder="请选择">
                      <Option value={""}>全部</Option>
                      {this.state.loanStatusList.map((item, index) => {
                        return (
                          <Option key={index + 1000} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={`核心企业`} name={`enterpriseName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            {!this.state.userInfo.roleCodes.includes("platform") &&
              this.state.userInfo.roleCodes.includes("bank_manager") && (
                <div className="tableListOperator flex">
                  <Button onClick={this.handleModalOpenSetup.bind(this)} type="primary">
                    高级设置
                  </Button>
                </div>
              )}
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.loanVisibleKey}
          loading={this.state.loanModalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancelLoan}
          onOk={this.handleModalOkLoan}
          title={`审核状态`}
          visible={this.state.loanVisible}
          width={600}
        >
          <Form onFinish={this.handleModalOkLoan.bind(this)} ref={this.loadsFormRef}>
            <FormItem
              label="贷款状态"
              labelCol={modalLabelCol}
              name="loansStatus"
              rules={[{ required: true, message: "请选择贷款状态" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select>
                {this.state.loanStatusList.map((item, index) => {
                  return (
                    <Option key={index + 100} value={item.name}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="实际贷款金额"
              labelCol={modalLabelCol}
              name="actualLoans"
              rules={[{ required: false, message: "请输入实际贷款金额" }, { validator: checkMoney }]}
              wrapperCol={modalWrapperCol}
            >
              <Input addonAfter="万元" placeholder="请输入实际贷款金额"></Input>
            </FormItem>

            <FormItem
              label="进度说明"
              labelCol={modalLabelCol}
              name="remark"
              rules={[{ required: false, message: "请输入进度说明" }]}
              wrapperCol={modalWrapperCol}
            >
              <TextArea placeholder="选填" rows={4}></TextArea>
            </FormItem>

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.loanModalLoading} type="primary">
                    {this.state.loanModalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancelLoan.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
        <Modal
          className="setupModalWrapper"
          confirmLoading={this.state.submitSetuping}
          footer={
            <>
              <Button loading={this.state.resetSubmiting} onClick={() => this.handleResetModalData()} type="ghost">
                重置选项
              </Button>
              <Button
                loading={this.state.submitSetuping}
                onClick={() => this.setupFormRef.current.submit()}
                type="primary"
              >
                保存选项
              </Button>
            </>
          }
          key={this.state.setupVisibleKey}
          onCancel={this.handleModalCancelSetup}
          title={`高级设置`}
          visible={this.state.setupVisible}
          width={900}
        >
          <Form onFinish={this.handleModalOkSetup.bind(this)} ref={this.setupFormRef}>
            <Space direction={"vertical"} size={"large"} style={{ width: "100%" }}>
              {defaultAreaType === "part" && districtConditions.length !== 0 && (
                <Space align={"baseline"} className="setupSpaceHead">
                  <div style={{ width: 75 }}>地区条件</div>
                  <div className="selectedDistrictArea flex flex-middle">
                    <span className="districTxt line1">{districtConditions.join(", ")}</span>
                    <CloseOutlined onClick={this.handleCascaderClose.bind(this)} />
                  </div>
                </Space>
              )}
              <Space align={"baseline"} className="setupSpaceItem flex flex-top">
                <div style={{ width: 100 }}>地区</div>
                <Radio.Group
                  onChange={this.handelChangeAreaType.bind(this)}
                  style={{ width: "100%" }}
                  value={defaultAreaType}
                >
                  <Radio value={`all`}>全国</Radio>
                  <Radio value={`part`}>
                    <Cascader
                      defaultValue={defaultDistrictValue}
                      expandTrigger="hover"
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      key={defaultDistrictValue}
                      maxTagCount="responsive"
                      multiple
                      onChange={this.selectedRegion.bind(this)}
                      onDropdownVisibleChange={this.handleChangeCascaderVisible.bind(this)}
                      options={districtMap}
                    >
                      <span
                        className={
                          isOpenCascader || selectedDistrictNumber !== 0 ? "districtArea active" : "districtArea"
                        }
                      >
                        {selectedDistrictNumber !== 0 ? `已选择${selectedDistrictNumber}项` : `只选部分地区`}
                        {!isOpenCascader && <CaretDownOutlined />}
                        {isOpenCascader && <CaretUpOutlined />}
                      </span>
                    </Cascader>
                  </Radio>
                </Radio.Group>
              </Space>
              {advanceSettings.map((item) => {
                return (
                  <Space className="setupSpaceItem flex flex-top" key={item.key}>
                    <div style={{ width: 100 }}>{item.title}</div>
                    <FormItem noStyle>
                      <FormItem name={item.key} noStyle>
                        <Checkbox.Group style={{ width: "100%" }}>
                          {item.options.map((el) => {
                            return (
                              <Checkbox key={el.value} value={el.value}>
                                {el.label}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </FormItem>
                      {item.custom && item.custom.length !== 0 && (
                        <div className="customInputWrapper flex flex-column">
                          <div className="flex flex-row flex-middle">
                            <div className="customInputHeader">自定义</div>
                            <div
                              className={`customInputBody flex flex-row flex-middle ${
                                this.state.errTipMap[item.key] ? "error" : ""
                              }`}
                            >
                              <FormItem name={item.custom[0]} noStyle>
                                <Input
                                  autoComplete="off"
                                  bordered={false}
                                  onChange={() => this.checkCustomMoney(item.key)}
                                  placeholder="最低"
                                />
                              </FormItem>
                              <div className="outline"></div>
                              <FormItem name={item.custom[1]} noStyle>
                                <Input
                                  autoComplete="off"
                                  bordered={false}
                                  onChange={() => this.checkCustomMoney(item.key)}
                                  placeholder="最高"
                                />
                              </FormItem>
                            </div>
                          </div>
                          {this.state.errTipMap[item.key] && (
                            <div className="error">{this.state.errTipMap[item.key]}</div>
                          )}
                        </div>
                      )}
                    </FormItem>
                  </Space>
                );
              })}
            </Space>
          </Form>
        </Modal>
      </div>
    );
  }
}

const LoansAuditList = List;
export default LoansAuditList;
