import React from "react";
import { Route, Redirect } from "react-router-dom";

import LoanStatusDetail from "@/component/config/loanStatus/detail.component";
import EnterpriseTemplateUrlDetail from "@/component/config/enterpriseTemplateUrl/detail.component";
import FileTypeDetail from "@/component/config/fileType/detail.component";

class ConfigComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/config/loanStatus/index`}
          render={(props) => <LoanStatusDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/config/template/index`}
          render={(props) => <EnterpriseTemplateUrlDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/config/fileType/index`}
          render={(props) => <FileTypeDetail {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default ConfigComponent;
