import React from "react";
import { Route, Redirect } from "react-router-dom";

import EnterpriseManagementList from "@/component/enterprise/management/list.component";
import EnterpriseManagementDetail from "@/component/enterprise/management/detail.component";
import EnterpriseManagementForm from "@/component/enterprise/management/form.component";

import EnterpriseFinanceList from "@/component/enterprise/finance/list.component";
import EnterpriseFinanceForm from "@/component/enterprise/finance/form.component";
import EnterpriseFinanceDetail from "@/component/enterprise/finance/detail.component";
import EnterpriseFinanceLog from "@/component/enterprise/finance/log.component";

import EnterpriseLoansList from "@/component/enterprise/loans/list.component";

import EnterpriseAuditList from "@/component/enterprise/audit/list.component";

import EnterpriseProgressList from "@/component/enterprise/progress/list.component";

class EnterpriseComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/enterprise/management/index`}
          render={(props) => <EnterpriseManagementList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/detail/:id`}
          render={(props) => (
            <EnterpriseManagementDetail {...props} from={`management`} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/enterprise/management/index/add`}
          render={(props) => (
            <EnterpriseManagementForm from={`management`} {...props} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/enterprise/management/index/edit/:id`}
          render={(props) => (
            <EnterpriseManagementForm from={`management`} {...props} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/enterprise/management/index/finance/:id`}
          render={(props) => <EnterpriseFinanceList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/finance/:enterpriseId/add`}
          render={(props) => <EnterpriseFinanceForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/finance/:enterpriseId/edit/:id`}
          render={(props) => <EnterpriseFinanceForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/finance/:enterpriseId/detail/:id`}
          render={(props) => <EnterpriseFinanceDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/finance/log/:id/:enterpriseId`}
          render={(props) => <EnterpriseFinanceLog {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/management/index/loans/:enterpriseId`}
          render={(props) => <EnterpriseLoansList {...props} permission={this.props.permission} />}
        />

        {/* 企业审核 */}
        <Route
          exact
          path={`/enterprise/audit/index`}
          render={(props) => <EnterpriseAuditList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/audit/index/detail/:id`}
          render={(props) => (
            <EnterpriseManagementDetail {...props} from={"audit"} permission={this.props.permission} />
          )}
        />

        {/* 跟进记录 */}
        <Route
          exact
          path={`/enterprise/management/index/progress/:id`}
          render={(props) => <EnterpriseProgressList {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default EnterpriseComponent;
