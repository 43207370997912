import React from "react";
import { Route, Redirect } from "react-router-dom";

import UserList from "./user/list/list.component";
import UserDetail from "./user/edit/form.component";

import RoleList from "./role/list/list.component";
import RoleDetail from "./role/detail/detail.component";

import ChannelManagementForm from "@/component/channel/management/form.component";

class UserPermissionComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/user/management/index`}
          render={(props) => <UserList {...props} permission={this.props.permission} />}
        />
        {/* 系统用户 */}
        <Route
          exact
          path={`/user/management/index/add`}
          render={(props) => <UserDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/user/management/index/edit/:id`}
          render={(props) => <UserDetail {...props} permission={this.props.permission} />}
        />
        {/* 渠道业务员 */}
        <Route
          exact
          path={`/user/management/index/addSalesman`}
          render={(props) => (
            <ChannelManagementForm {...props} from={"channel_salesman"} permission={this.props.permission} />
          )}
        />
        <Route
          exact
          path={`/user/management/index/editSalesman/:id`}
          render={(props) => (
            <ChannelManagementForm {...props} from={"channel_salesman"} permission={this.props.permission} />
          )}
        />
        {/* 用户角色 */}
        <Route
          exact
          path={`/user/role/index`}
          render={(props) => <RoleList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/user/role/index/add`}
          render={(props) => <RoleDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/user/role/index/edit/:id`}
          render={(props) => <RoleDetail {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default UserPermissionComponent;
