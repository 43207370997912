import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, Input, Select } from "antd";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";

import { ResultCodeEnum, channelApi, userApi } from "@/api/index";

const TextArea = Input.TextArea;
const FormItem = Form.Item;
const Option = Select.Option;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading

    userInfo: {
      channelId: "",
    },
    isChannelManagement: true,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const match = this.props.match;
    const state = {
      id: match.params.id,
      isEdit: match.url.includes("edit"),
      isChannelManagement: match.url.includes("channel/management"),
    };

    this.setState(state, () => {
      if (state.isEdit) {
        this.getDetail(state.id);
      }
    });
  }

  // 获取详情
  getDetail = async (id) => {
    const { isChannelManagement } = this.state;
    if (isChannelManagement) {
      this.selectChannelManagement(id);
    } else {
      this.selectChannelSalesman(id);
    }
  };

  // 渠道管理员详情
  selectChannelManagement = async (id) => {
    const res = await channelApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({ detail });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        loginName: detail.loginName,
        name: detail.name,
        contactPerson: detail.contactPerson || null,
        contactPhone: detail.contactPhone || null,
        remark: detail.remark || null,
      });
    }
  };

  // 渠道业务员详情
  selectChannelSalesman = async (id) => {
    const res = await userApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({ detail });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        loginName: detail.loginName,
        contactPerson: detail.name || null,
        contactPhone: detail.phone || null,
        remark: detail.remark || null,
      });
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { isChannelManagement } = this.state;
    if (isChannelManagement) {
      this.insertChannelManagement(values);
    } else {
      this.insertChannelSalesman(values);
    }
  };

  // 新增渠道管理员
  insertChannelManagement = async (params) => {
    const { history } = this.props;
    const { id, isEdit } = this.state;

    this.setState({ submitting: true });
    const requestApi = isEdit ? channelApi.update : channelApi.create;
    params = {
      ...params,
      contactPhone: params.contactPhone || undefined,
    };
    const res = await requestApi({ ...params, id });
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success(isEdit ? "编辑成功" : "提交成功", () => {
        history.push(`/channel/management/index`);
      });
    } else {
      this.setState({ submitting: false });
    }
  };

  // 新增渠道业务员
  insertChannelSalesman = async (params) => {
    const { history } = this.props;
    const { isEdit } = this.state;

    this.setState({ submitting: true });
    params = {
      ...params,
      name: params.contactPerson,
      phone: params.contactPhone || undefined,
    };
    const requestApi = isEdit ? userApi.update : userApi.create;
    const res = await requestApi(params);
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success(isEdit ? "编辑成功" : "提交成功", () => {
        history.push(`/user/management/index`);
      });
    } else {
      this.setState({ submitting: false });
    }
  };

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  render() {
    const { isEdit, submitting } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            {this.props.from === "channel_manager" && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/channel/management/index`}>
                    <span>渠道管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{isEdit ? "编辑渠道" : "新增渠道"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}

            {this.props.from === "channel_salesman" && (
              <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>
                  <Link to={`/user/management/index`}>
                    <span>用户管理</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{isEdit ? "编辑渠道业务员" : "新增渠道业务员"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              {(!isEdit || this.state.detail.managerId) && (
                <FormItem
                  {...formItemLayout}
                  label="登录账号"
                  name="loginName"
                  rules={[{ required: true, message: "请输入登录账号" }]}
                >
                  <Input placeholder="请输入登录账号" />
                </FormItem>
              )}

              {!this.state.isEdit && (
                <FormItem
                  {...formItemLayout}
                  extra="请输入8-20位，包含大小写字母和数字且不含其它特殊字符的密码"
                  label="登录密码"
                  name="loginPassword"
                  rules={[{ required: true, message: "请输入登录密码" }]}
                >
                  <Input.Password placeholder="请输入登录密码" />
                </FormItem>
              )}

              {this.props.from === "channel_manager" && (
                <FormItem
                  {...formItemLayout}
                  label="渠道名称"
                  name="name"
                  rules={[{ required: true, message: "请输入企业名称" }]}
                >
                  <Input placeholder="请输入企业名称" />
                </FormItem>
              )}

              <FormItem
                {...formItemLayout}
                label="联系人"
                name="contactPerson"
                rules={[{ required: true, message: "请输入联系人" }]}
              >
                <Input placeholder="请输入联系人" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="联系电话"
                name="contactPhone"
                rules={[{ required: false, message: "请输入联系电话" }]}
              >
                <Input placeholder="请输入联系电话" />
              </FormItem>

              {this.props.from === "channel_manager" && (
                <FormItem {...formItemLayout} label="角色" rules={[{ required: true, message: "请选择角色" }]}>
                  <Select defaultValue={`channel_manager`} disabled placeholder="请选择角色">
                    <Option value="channel_manager">渠道管理员</Option>
                  </Select>
                </FormItem>
              )}

              {this.props.from === "channel_salesman" && (
                <FormItem {...formItemLayout} label="角色" rules={[{ required: true, message: "请选择角色" }]}>
                  <Select defaultValue={`channel_salesman`} disabled placeholder="请选择角色">
                    <Option value="channel_salesman">渠道业务员</Option>
                  </Select>
                </FormItem>
              )}

              <FormItem
                {...formItemLayout}
                label="备注"
                name="remark"
                rules={[{ required: false, message: "请输入备注" }]}
              >
                <TextArea placeholder="请输入备注" rows={3} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const ChannelManagementForm = List;
export default ChannelManagementForm;
