import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Layout } from "antd";
import { SettingOutlined, LogoutOutlined, UnorderedListOutlined, BankOutlined } from "@ant-design/icons";

import { ResultCodeEnum, loginApi } from "@/api/index";

import Default_Headimg from "@/assets/images/headerImg.png";
// import main_logo from "@/assets/images/main_logo.gif";

const { Header } = Layout;

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: "",
      loginName: "",
      headImg: "",
      isEnterprise: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      collapsed: this.state.collapsed,
      loginName: this.props.loginName,
      headImg: this.props.headImg,
      isEnterprise: this.props.isEnterprise,
    });
  }

  componentDidMount() {
    // 初始化判断屏幕是否小于1500,左侧菜单栏默认收起
    if (document.body.clientWidth < 1500) {
      this.toggle();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      collapsed: nextProps.collapsed,
      loginName: nextProps.loginName,
      headImg: nextProps.headImg,
      isEnterprise: nextProps.isEnterprise,
    });
  }

  /**
   * 左侧栏收缩图标点击的事件回调
   */
  toggle = () => {
    const collapsed = !this.state.collapsed;
    this.setState({
      collapsed,
    });
    this.props.onCollapsed(collapsed);
  };

  /**
   * 用户头像/名称鼠标聚焦后下拉菜单栏点击事件回调
   * @param event下的key对应Menu.Item配置的key
   */
  onMenuClick = (event) => {
    const { key } = event;
    if (key === "changePassword") {
      // this.props.gotoUpdatePwd();
    } else if (key === "logout") {
      loginApi.onLogout().then((result) => {
        if (result.code === ResultCodeEnum.success.code) {
          location.href = "./login.html";
        }
      });
    }
  };

  /**
   * 顶部右上角国际化语言事件点击回调
   * @param event下的key对应Menu.Item配置的key,切换成功后向父组件index.js传值
   */
  onClickLanguage = (event) => {
    const { key } = event;
    this.props.onChangeLanguage(key);
  };

  render() {
    const menu = (
      <Menu onClick={this.onMenuClick}>
        {this.state.isEnterprise && (
          <Menu.Item key="changeEnterprise">
            <Link to={`/myCenter/index/updateEnterprise`}>
              <BankOutlined style={{ marginRight: 5 }} />
              企业信息
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="changePassword">
          <Link to={`/myCenter/index/updatePwd`}>
            <SettingOutlined style={{ marginRight: 5 }} />
            修改密码
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <LogoutOutlined style={{ marginRight: 5 }} />
          退出登录
        </Menu.Item>
      </Menu>
    );

    return (
      <Header
        className="ant-layout-header"
        id="header"
        style={{ background: "#fff", paddingLeft: 0, paddingRight: 16 }}
      >
        <div className="header-content flex flex-between">
          {/* 一级菜单 */}
          <div className="header-left flex flex-middle" style={{ paddingLeft: 16 }}>
            {/** 
              <div className="logo flex flex-middle">
                <img alt="" src={main_logo} />
              </div>
              <div className="name">企业管理系统</div>
            */}
            <div className="flex flex-middle collapse-sidebar">
              <UnorderedListOutlined className="classify" onClick={this.toggle} />
            </div>
          </div>
          {/* 底部右边操作与登录账号信息 */}
          <div className="header-right flex flex-middle">
            <Dropdown overlay={menu} placement="bottomRight">
              <span className="ant-dropdown-link flex flex-middle">
                <img alt="头像" src={this.state.headImg ? this.state.headImg : Default_Headimg} />
                <span>{this.state.loginName}</span>
              </span>
            </Dropdown>
          </div>
        </div>
      </Header>
    );
  }
}
export default HeaderComponent;
