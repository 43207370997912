import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Breadcrumb, Card, Input, Select } from "antd";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";

import { ResultCodeEnum, upmsOrgsApi, districtApi } from "@/api/index";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading

    provinces: [],
    city: [],
    provinceCode: "",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    // 判断是否为编辑模式或详情页模式
    const { match } = this.props;
    const url = match.url;
    const id = match.params.id;
    if (url.indexOf("edit") > -1) {
      this.setState({
        id,
        isEdit: true,
      });
      this.getDetail(id);
    }
    // 获取地区数据
    this.getListProvinces();
  }

  /** 获取省份 */
  getListProvinces = async () => {
    const res = await districtApi.getListProvinces();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        provinces: res.data,
      });
    }
  };

  /** 根据areaCode获取下级区域 */
  getListChildren = async (code, type) => {
    const res = await districtApi.getListChildren({
      areaCode: code,
    });
    if (res.code === ResultCodeEnum.success.code) {
      if (type === "provinces") {
        this.setState({
          city: res.data,
        });
      }
    }
  };

  /** 地区选择回调 */
  onChangeDistrict = (value, type) => {
    if (type === "provinces") {
      this.formRef.current.setFieldsValue({
        cityCode: undefined,
      });
      this.setState(
        {
          provinceCode: value,
          city: [],
        },
        () => {
          this.getListChildren(value, type);
        }
      );
    }
  };

  getParentPaths = async (cityCode) => {
    const res = await districtApi.getParentPaths({ areaCode: cityCode });
    if (res.code === ResultCodeEnum.success.code) {
      this.getListChildren(res.data[1].areaCode, "provinces");
      this.setState({
        provinceCode: res.data[1].areaCode,
      });
      this.formRef.current.setFieldsValue({
        provinceCode: res.data[1].areaCode,
        cityCode: res.data[0].areaCode,
      });
    }
  };

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await upmsOrgsApi.detail(id);
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({
        detail: detail,
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        name: detail.name,
        remark: detail.remark,
      });
      this.getParentPaths(detail.cityCode);
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    const { history } = this.props;
    const { id, isEdit } = this.state;
    values.parentId = 1;
    this.setState({ submitting: true });
    if (isEdit) {
      const res = await upmsOrgsApi.update(id, values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("编辑成功", () => {
          history.push(`/org/management/index`);
        });
      }
    } else {
      const res = await upmsOrgsApi.create(values);
      this.setState({ submitting: false });
      if (res.code === ResultCodeEnum.success.code) {
        dialog.success("提交成功", () => {
          history.push(`/org/management/index`);
        });
      }
    }
  };

  render() {
    const { isEdit, submitting } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/org/management/index`}>
                  <span>分部管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{isEdit ? "编辑" : "新增"}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="分部名称"
                name="name"
                rules={[{ required: true, message: "请输入分部名称" }]}
              >
                <Input placeholder="请输入分部名称" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="所属省份"
                name="provinceCode"
                rules={[{ required: true, message: "请选择省份" }]}
              >
                <Select onChange={(value) => this.onChangeDistrict(value, "provinces")} placeholder="请选择所属省份">
                  {this.state.provinces.map((item) => {
                    return (
                      <Option key={item.id} value={item.areaCode}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="所属城市"
                name="cityCode"
                rules={[{ required: true, message: "请选择所属城市" }]}
              >
                <Select onChange={(value) => this.onChangeDistrict(value, "city")} placeholder="请选择所属城市">
                  {this.state.city.map((item) => {
                    return (
                      <Option key={item.id} value={item.areaCode}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="备注"
                name="remark"
                rules={[{ required: false, message: "请填写备注(选填)" }]}
              >
                <TextArea placeholder={"请填写备注(选填)"} rows={4} />
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const OrgForm = List;
export default OrgForm;
