import React from "react";
import { Route, Redirect } from "react-router-dom";

import OrgList from "@/component/org/management/list.component";
import OrgForm from "@/component/org/management/form.component";

class OrgComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/org/management/index`}
          render={(props) => <OrgList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/org/management/index/add`}
          render={(props) => <OrgForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/org/management/index/edit/:id`}
          render={(props) => <OrgForm {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default OrgComponent;
