import React from "react";
import { Route, Redirect } from "react-router-dom";

import BankList from "@/component/bank/management/list.component";
import BankForm from "@/component/bank/management/form.component";

import BankBranch from "@/component/bank/branch/list.component";
import UserList from "@/component/user_permission/user/list/list.component";
import UserDetail from "@/component/user_permission/user/edit/form.component";

import ContactsList from "@/component/bank/contacts/list.component";
class BankComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/bank/management/index`}
          render={(props) => <BankList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/add`}
          render={(props) => <BankForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/edit/:id`}
          render={(props) => <BankForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/branch/:bankId`}
          render={(props) => <BankBranch {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/branch/:bankId/user/:subBankId`}
          render={(props) => <UserList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/branch/:bankId/user/:subBankId/add`}
          render={(props) => <UserDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/branch/:bankId/user/:subBankId/edit/:id`}
          render={(props) => <UserDetail {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/bank/management/index/branch/:bankId/contacts/:subBankId`}
          render={(props) => <ContactsList {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default BankComponent;
