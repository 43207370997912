import React from "react";
import { Form, Input, Button, Card, Breadcrumb } from "antd";
import dialog from "@/util/dialog";
import userApi from "@/api/userApi";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";

const FormItem = Form.Item;

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      confirmDirty: false,
      submitting: false,
    };
  }

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    const id = match.params.id;
    this.setState({ id });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const _this = this;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        _this.setState({
          submitting: true,
        });
        return userApi
          .resetUserPasswords({
            newPassword: values.newPassword,
            userId: _this.state.id,
          })
          .then((res) => {
            _this.setState({
              submitting: false,
            });
            if (res.code === 0) {
              dialog.success("修改成功", () => {
                _this.props.form.resetFields();
              });
            }
          });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("两次密码输入不一致!");
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>重置密码</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <div>
                <FormItem {...formItemLayout} hasFeedback label="新密码">
                  {getFieldDecorator("newPassword", {
                    rules: [
                      {
                        required: true,
                        message: "请填写新密码!",
                      },
                      {
                        validator: this.checkConfirm.bind(this),
                      },
                    ],
                  })(<Input type="password" />)}
                </FormItem>
                <FormItem {...formItemLayout} hasFeedback label="确认密码">
                  {getFieldDecorator("confirmPwd", {
                    rules: [
                      {
                        required: true,
                        message: "请确认密码",
                      },
                      {
                        validator: this.checkPassword.bind(this),
                      },
                    ],
                  })(<Input onBlur={this.handleConfirmBlur.bind(this)} type="password" />)}
                </FormItem>
              </div>
              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={this.state.submitting} type="primary">
                  {this.state.submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const ResetPwd = Detail;
export default ResetPwd;
