import React from "react";
import { Route, Redirect } from "react-router-dom";

import FilesManagementList from "@/component/files/management/list.component";
import FileManagementForm from "@/component/files/management/detail.component";

class FilesComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/files/management/index`}
          render={(props) => <FilesManagementList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/files/management/index/add`}
          render={(props) => <FileManagementForm {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/files/management/index/edit/:id`}
          render={(props) => <FileManagementForm {...props} permission={this.props.permission} />}
        />
      </div>
    );
  }
}

export default FilesComponent;
