import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Table,
  Breadcrumb,
  Divider,
  Card,
  Popconfirm,
  Row,
  Col,
  Input,
  Alert,
  Modal,
  Select,
} from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize } from "@/constants";
import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, banksApi, housesApi, districtApi } from "@/api/index";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    bankDetail: {},

    /** 弹窗 */
    visible: false,
    visibleKey: 1,
    modalLoading: false,
    modalType: "add",

    selectedItemIndex: "",

    provinces: [],
    city: [],
    provinceCode: "",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
    this.getBankDetail();
    // 获取地区数据
    this.getListProvinces();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
      ...{
        houseId: this.props.match.params.bankId,
      },
    };
    this.setState({ loading: true });
    const res = await banksApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取银行详情 */
  getBankDetail = async () => {
    const res = await housesApi.detail(this.props.match.params.bankId);
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        bankDetail: res.data,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    if (values.year) {
      values.year = values.year.format("YYYY");
    }
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await banksApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  onTop = async (id, value) => {
    const api = value ? banksApi.top : banksApi.cancelTop;
    const res = await api(id);
    if (res.code === ResultCodeEnum.success.code) {
      this.getList();
    }
  };

  /** 打开 */
  handleModalOpen = (type, data, index) => {
    this.setState(
      {
        visible: true,
        visibleKey: this.state.visible + 1,
        modalType: type,
        selectedItemIndex: index,
      },
      () => {
        if (type === "edit") {
          this.formRef.current.setFieldsValue({
            name: data.name,
            address: data.address,
            remark: data.remark,
          });
          if (data.cityCode) {
            this.getParentPaths(data.cityCode);
          }
        } else {
          this.setState({
            provinceCode: "",
            city: [],
          });
        }
      }
    );
  };

  /** 取消 */
  handleModalCancel = () => {
    this.setState({
      visible: false,
      visibleKey: this.state.visible + 1,
    });
  };

  /** 确定 */
  handleModalOk = async (values) => {
    const { modalType, list, selectedItemIndex } = this.state;
    this.setState({
      modalLoading: true,
    });
    let res = "";
    if (modalType === "add") {
      res = await banksApi.create({
        ...{
          houseId: this.props.match.params.bankId,
        },
        ...values,
      });
    } else {
      const id = list[selectedItemIndex].id;
      res = await banksApi.update(id, values);
    }
    this.setState({
      modalLoading: false,
    });
    if (res.code === ResultCodeEnum.success.code) {
      if (modalType === "add") {
        dialog.success("新增成功");
        this.getList();
      } else {
        dialog.success("编辑成功");
        list[selectedItemIndex].name = values.name;
        list[selectedItemIndex].address = values.address;
        list[selectedItemIndex].remark = values.remark;
        this.setState({
          list,
        });
        this.getList();
      }
      this.handleModalCancel();
    }
  };

  /** 获取省份 */
  getListProvinces = async () => {
    const res = await districtApi.getListProvinces();
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        provinces: res.data,
      });
    }
  };

  /** 根据areaCode获取下级区域 */
  getListChildren = async (code, type) => {
    const res = await districtApi.getListChildren({
      areaCode: code,
    });
    if (res.code === ResultCodeEnum.success.code) {
      if (type === "provinces") {
        this.setState({
          city: res.data,
        });
      }
    }
  };

  /** 地区选择回调 */
  onChangeDistrict = (value, type) => {
    if (type === "provinces") {
      this.formRef.current.setFieldsValue({
        cityCode: undefined,
      });
      this.setState(
        {
          provinceCode: value,
          city: [],
        },
        () => {
          this.getListChildren(value, type);
        }
      );
    }
  };

  getParentPaths = async (cityCode) => {
    const res = await districtApi.getParentPaths({ areaCode: cityCode });
    if (res.code === ResultCodeEnum.success.code) {
      this.getListChildren(res.data[1].areaCode, "provinces");
      this.setState({
        provinceCode: res.data[1].areaCode,
      });
      this.formRef.current.setFieldsValue({
        provinceCode: res.data[1].areaCode,
        cityCode: res.data[0].areaCode,
      });
    }
  };

  render() {
    const { list, loading, pagination } = this.state;
    const { match } = this.props;
    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "支行名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "所属城市",
        dataIndex: "addressDistrictName",
        key: "addressDistrictName",
      },
      {
        title: "地址",
        dataIndex: "address",
        key: "address",
        render: (text) => {
          return <span>{text || "-"}</span>;
        },
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record, index) => {
          return (
            <div className="operation">
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onTop.bind(this, id, !record.sort)}
                placement="left"
                title={`确定${record.sort ? "取消置顶" : "置顶"}该银行吗？`}
              >
                <a href="javescript:;">{record.sort ? "取消置顶" : "置顶"}</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`${match.url}/contacts/${id}`}>通讯录</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/user/${id}`}>客户经理管理</Link>
              <Divider type="vertical" />
              <Button onClick={this.handleModalOpen.bind(this, "edit", record, index)} type="link">
                编辑
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该支行吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/bank/management/index`}>
                  <span>银行管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>支行管理</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={1} span={6}>
                  <Form.Item label={`支行名称`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col key={5} span={6}>
                  <Form.Item label={`城市名称`} name={`cityName`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator">
              <Button onClick={this.handleModalOpen.bind(this, "add")} type="primary">
                新增
              </Button>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Alert message={`银行名称：${this.state.bankDetail.name || "-"}`} type="info" />
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
        <Modal
          footer={null}
          key={this.state.visibleKey}
          loading={this.state.modalLoading}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          onOk={this.handleModalOk}
          title={`${this.state.modalType === "add" ? "新增" : "编辑"}`}
          visible={this.state.visible}
          width={600}
        >
          <Form onFinish={this.handleModalOk.bind(this)} ref={this.formRef}>
            <FormItem
              label="支行名称"
              labelCol={modalLabelCol}
              name="name"
              rules={[{ required: true, message: "请输入支行名称" }]}
              wrapperCol={modalWrapperCol}
            >
              <Input placeholder="请输入支行名称"></Input>
            </FormItem>

            <FormItem
              label="所属省份"
              labelCol={modalLabelCol}
              name="provinceCode"
              rules={[{ required: false, message: "请选择省份" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select onChange={(value) => this.onChangeDistrict(value, "provinces")} placeholder="请选择所属省份">
                {this.state.provinces.map((item) => {
                  return (
                    <Option key={item.id} value={item.areaCode}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="所属城市"
              labelCol={modalLabelCol}
              name="cityCode"
              rules={[{ required: this.state.provinceCode ? true : false, message: "请选择所属城市" }]}
              wrapperCol={modalWrapperCol}
            >
              <Select onChange={(value) => this.onChangeDistrict(value, "city")} placeholder="请选择所属城市">
                {this.state.city.map((item) => {
                  return (
                    <Option key={item.id} value={item.areaCode}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            <FormItem
              label="地址"
              labelCol={modalLabelCol}
              name="address"
              rules={[{ required: false, message: "请输入地址" }]}
              wrapperCol={modalWrapperCol}
            >
              <Input placeholder="请输入地址"></Input>
            </FormItem>

            <FormItem
              label="备注"
              labelCol={modalLabelCol}
              name="remark"
              rules={[{ required: false, message: "请输入地址" }]}
              wrapperCol={modalWrapperCol}
            >
              <TextArea placeholder={"选填"} rows={3}></TextArea>
            </FormItem>

            <FormItem wrapperCol={modalSubmitFormLayout}>
              <Row gutter={15}>
                <Col>
                  <Button htmlType="submit" loading={this.state.modalLoading} type="primary">
                    {this.state.modalLoading ? "提交中" : "确定"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={this.handleModalCancel.bind(this)}>取消</Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

const BranchList = List;
export default BranchList;
