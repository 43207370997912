import React from "react";
import UserPermissionComponent from "@/component/user_permission/index.component";
import ConfigComponent from "@/component/config/index.component";

import EnterpriseComponent from "@/component/enterprise/index.component";
import BankComponent from "@/component/bank/index.component";
import LoansComponent from "@/component/loans/index.component";
import LoansProgressComponent from "@/component/loans_progress/index.component";
import FilesComponent from "@/component/files/index.component";
import OrgComponent from "@/component/org/index.component";
import ChannelComponent from "@/component/channel/index.component";

/**
 * 导航栏数据
 * @param key 每一个菜单栏对应一个key,key的命名需要与path一致,用下划线 _ 分割
 * @param name 菜单栏名称
 * @param icon 菜单栏图标,图标需要在iconfont上新建一个项目,把自己需要图标添加到项目后,查看在线链接,将链接复制到src/component/common/SiderNav下的scriptUrl
 * @param children 是否有子菜单,如果没有默认[]
 * @param path 访问路径
 * @param component 对应组件
 */

export const data = [
  {
    key: "enterprise_management",
    name: "企业管理",
    icon: "icon-qiye",
    children: [],
    path: "/enterprise/management",
    component: (props) => {
      return <EnterpriseComponent {...props} />;
    },
  },
  {
    key: "enterprise_audit",
    name: "企业审核",
    icon: "icon-approval",
    children: [],
    path: "/enterprise/audit",
    component: (props) => {
      return <EnterpriseComponent {...props} />;
    },
  },
  {
    key: "channel_management",
    name: "渠道管理",
    icon: "icon-channel",
    children: [],
    path: "/channel/management",
    component: (props) => {
      return <ChannelComponent {...props} />;
    },
  },
  {
    key: "bank_management",
    name: "银行管理",
    icon: "icon-bank",
    children: [],
    path: "/bank/management",
    component: (props) => {
      return <BankComponent {...props} />;
    },
  },
  {
    key: "org_management",
    name: "分部管理",
    icon: "icon-zuzhijigou",
    children: [],
    path: "/org/management",
    component: (props) => {
      return <OrgComponent {...props} />;
    },
  },
  {
    key: "enterprise_loansAudit",
    name: "贷款审核",
    icon: "icon-shenhe",
    children: [],
    path: "/enterprise/loansAudit",
    component: (props) => {
      return <LoansComponent {...props} />;
    },
  },
  {
    key: "enterprise_loansProgress",
    name: "贷款进度",
    icon: "icon-shenhe",
    children: [],
    path: "/enterprise/loansProgress",
    component: (props) => {
      return <LoansProgressComponent {...props} />;
    },
  },
  {
    key: "files_management",
    name: "资料管理",
    icon: "icon-wenjian",
    children: [],
    path: "/files/management",
    component: (props) => {
      return <FilesComponent {...props} />;
    },
  },
  {
    key: "user_management",
    name: "用户管理",
    icon: "icon-tubiaozhizuomoban",
    children: [],
    path: "/user/management",
    component: (props) => {
      return <UserPermissionComponent {...props} />;
    },
  },
  {
    key: "user_role",
    name: "角色管理",
    icon: "icon-quanxian",
    children: [],
    path: "/user/role",
    component: (props) => {
      return <UserPermissionComponent {...props} />;
    },
  },
  {
    key: "config",
    name: "配置管理",
    icon: "icon-icon-test",
    children: [
      {
        key: "config_loanStatus",
        name: "贷款状态",
        path: "/config/loanStatus",
        component: (props) => {
          return <ConfigComponent {...props} />;
        },
      },
      // {
      //   key: "config_fileType",
      //   name: "文件类型",
      //   path: "/config/fileType",
      //   component: (props) => {
      //     return <ConfigComponent {...props} />;
      //   },
      // },
      // {
      //   key: "config_template",
      //   name: "企业导入模板",
      //   path: "/config/template",
      //   component: (props) => {
      //     return <ConfigComponent {...props} />;
      //   },
      // },
    ],
  },
];
