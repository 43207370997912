import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Card, Input, Row, Col, Divider, Popconfirm } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize, no_content } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { ResultCodeEnum, channelApi, userApi } from "@/api/index";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    importUploadLoading: false,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await channelApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await channelApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => el.id !== id);
      dialog.success("删除成功");
      this.setState({ list });
    }
  };

  /**
   * 修改有效状态
   * @param id 选中表格id
   * @param isEnabled 修改后的状态
   */
  changeEnabled = async (id, isEnabled) => {
    const res = await channelApi.isEnable({ id, isEnable: isEnabled });
    if (res.code === ResultCodeEnum.success.code) {
      const list = this.state.list;
      const idx = list.findIndex((el) => el.id === id);
      list[idx].isEnabled = isEnabled;
      dialog.success("修改成功");
      this.setState({ list });
    }
  };

  // 重置渠道管理员账号密码
  resetPassword = async (id) => {
    const res = await userApi.resetPwd(id);
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("重置密码成功");
    }
  };

  render() {
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "渠道名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "联系人",
        dataIndex: "contactPerson",
        key: "contactPerson",
        render: (text) => {
          return <span>{text || no_content}</span>;
        },
      },
      {
        title: "联系电话",
        dataIndex: "contactPhone",
        key: "contactPhone",
        render: (text) => {
          return <span>{text || no_content}</span>;
        },
      },
      {
        title: "管理员账号",
        dataIndex: "loginName",
        key: "loginName",
        render: (text) => {
          return <span>{text || no_content}</span>;
        },
      },
      {
        title: "推荐码",
        dataIndex: "recommendationCode",
        key: "recommendationCode",
        render: (text) => {
          return <span>{text || no_content}</span>;
        },
      },
      {
        title: "状态",
        dataIndex: "isEnabled",
        key: "isEnabled",
        render: (isEnabled) => {
          return <span>{isEnabled ? "启用" : "禁用"}</span>;
        },
      },
      {
        title: "创建人",
        dataIndex: "creatorName",
        key: "creatorName",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (id, record) => {
          return (
            <div className="operation">
              {record.managerId && record.managerId !== "" && (
                <>
                  <Popconfirm
                    cancelText="取消"
                    okText="确定"
                    onConfirm={this.resetPassword.bind(this, record.managerId)}
                    placement="left"
                    title={
                      <>
                        <p>确定是否重置改渠道管理员账号密码吗？</p>
                        <p style={{ margin: 0 }}>重置密码：Abc123456</p>
                      </>
                    }
                  >
                    <a href="javescript:;">重置密码</a>
                  </Popconfirm>
                  <Divider type="vertical" />
                </>
              )}
              <Link to={`${match.url}/record/${record.recommendationCode}`}>注册记录</Link>
              <Divider type="vertical" />
              <Link to={`${match.url}/edit/${id}`}>编辑</Link>
              <Divider type="vertical" />
              {/* <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.changeEnabled.bind(this, id, !record.isEnabled)}
                placement="left"
                title={record.isEnabled ? `确定禁用该渠道吗？` : `确定启用该渠道吗？`}
              >
                <a href="javescript:;">{record.isEnabled ? `禁用` : `启用`}</a>
              </Popconfirm>
              <Divider type="vertical" /> */}
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该渠道吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>渠道管理</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={2} span={8}>
                  <Form.Item label={`渠道名称`} name={`name`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                {/* <Col key={4} span={8}>
                  <Form.Item label={`联系人`} name={`contactPerson`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col> */}
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator flex">
              <Link to={`${match.url}/add`}>
                <Button type="primary">新增</Button>
              </Link>
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseManagementList = List;
export default EnterpriseManagementList;
