/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Divider, Card, Input, Row, Col, Modal, Select, Popconfirm } from "antd";

import "@/component/carousel/styles.less";

import { TableSize, FormSize, TestPreviewUrl, ProdPreviewUrl } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ResultCodeEnum, commonFilesApi, housesApi } from "@/api/index";
import ModalDetail from "@/component/common/modal-detail/ModalDetail";

const { confirm } = Modal;
const { Option } = Select;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    fileTypeList: [],
    selectedRecord: {},
    isModalDetailVisible: false,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getList();
    this.getFileTypeList();
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await commonFilesApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /** 获取所属银行 */
  getFileTypeList = async (params) => {
    const res = await housesApi.list({
      pageNum: 0,
      pageSize: 500,
      // dictItemId: fileTypeItemId,
    });
    if (res.code === ResultCodeEnum.success.code) {
      this.setState({
        fileTypeList: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  /**
   * 点击确定删除
   * @param id 选中表格id
   */
  onDelete = async (id) => {
    const res = await commonFilesApi.delete(id);
    if (res.code === ResultCodeEnum.success.code) {
      let list = this.state.list;
      list = list.filter((el) => {
        return el.id !== id;
      });
      dialog.success("删除成功");
      this.setState({
        list: list,
      });
    }
  };

  /** 覆盖上传 */
  coverImport = async (formData, errorMsg) => {
    const _this = this;
    confirm({
      title: "重要提醒",
      icon: <ExclamationCircleOutlined />,
      content: errorMsg,
      okText: "是",
      cancelText: "否",
      onOk() {
        formData.append("isCover", true);
        _this.excelImport(formData);
      },
      onCancel() {
        formData.append("isCover", false);
        _this.excelImport(formData);
      },
    });
  };

  onDownload = async (url) => {
    window.open(url);
  };

  /** 预览 */
  onPreview = (url) => {
    let prefix = "";
    // prefix 需要区分test环境与prod环境
    if (__ENV__ === "prod") {
      prefix = ProdPreviewUrl;
    } else {
      prefix = TestPreviewUrl;
    }
    // 把字符串编码为 URI 组件
    const encode = encodeURI(url);
    const base64 = btoa(encode);
    url = encodeURIComponent(base64);
    window.open(`${prefix}${url}`, "_blank");
  };

  render() {
    // const _this = this;
    const { match } = this.props;
    const { list, loading, pagination } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "所属银行",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "文件名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "创建人",
        dataIndex: "createdUserName",
        key: "createdUserName",
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        render: (text) => {
          return <span>{Utils.formatDate(text, "yyyy-mm-dd hh:ii")}</span>;
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        hidden: true,
        render: (id, record) => {
          return (
            <div className="operation">
              <Button onClick={() => this.setState({ isModalDetailVisible: true, selectedRecord: record })} type="link">
                详情
              </Button>
              {/* <Button onClick={this.onPreview.bind(this, record.url)} type="link">
                查看
              </Button>
              <Divider type="vertical" />
              <Button onClick={this.onDownload.bind(this, record.url)} type="link">
                下载
                </Button> */}

              <Divider type="vertical" />
              <Popconfirm
                cancelText="取消"
                okText="确定"
                onConfirm={this.onDelete.bind(this, id)}
                placement="left"
                title="确定删除该资料文件吗？"
              >
                <a href="javescript:;">删除</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    const tablecColumnsDetail = tablecColumns.concat([
      {
        title: "附件列表",
        dataIndex: "attachments",
        key: "attachments",
        render: (value) => {
          return (
            <div>
              {value &&
                value.map((item) => {
                  return (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    // <a href={item.url} key={item.id} style={{ display: "block" }} target="_blank">
                    //   {item.name}
                    // </a>
                    <div key={item.id}>
                      <span>{item.name}</span>
                      <span
                        onClick={this.onPreview.bind(this, item.url)}
                        style={{ color: "blue", paddingLeft: "10px" }}
                        type="link"
                      >
                        查看
                      </span>
                      <span
                        onClick={this.onDownload.bind(this, item.url)}
                        style={{ color: "blue", paddingLeft: "10px" }}
                        type="link"
                      >
                        下载
                      </span>
                    </div>
                  );
                })}
            </div>
          );
        },
      },
    ]);

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>资料管理</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 
            顶部搜索栏
            如果搜索栏个数超过6个,展开方式可参考ant.design/Form表单下高级搜索例子
           */}
          <Card bordered={false} style={{ marginBottom: 20 }}>
            <Form
              className="ant-advanced-search-form"
              name="advanced_search"
              onFinish={this.onFinishSearch.bind(this)}
              size={FormSize}
            >
              <Row gutter={24}>
                <Col key={1} span={6}>
                  <Form.Item label={`所属银行`} name={`bankId`}>
                    <Select placeholder="请选择">
                      <Option value="">全部</Option>
                      {this.state.fileTypeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col key={2} span={6}>
                  <Form.Item label={`文件名称`} name={`keyword`}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button htmlType="submit" type="primary">
                    查询
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator flex">
              <Link to={`${match.url}/add`}>
                <Button type="primary">新增</Button>
              </Link>
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
        </div>

        {/* 详情modal */}
        <ModalDetail
          data={this.state.selectedRecord}
          onCancel={() => this.setState({ isModalDetailVisible: false })}
          onConfirm={() => this.setState({ isModalDetailVisible: false })}
          showList={tablecColumnsDetail}
          visible={this.state.isModalDetailVisible}
        />
      </div>
    );
  }
}

const FilesManagementList = List;
export default FilesManagementList;
