import React from "react";
import { Route, Redirect } from "react-router-dom";

import LoansAuditList from "@/component/loans/audit/list.component";
import EnterpriseManagementDetail from "@/component/enterprise/management/detail.component";

class LoansComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <Redirect to={`${match.url}/index`} />;
          }}
        />
        <Route
          exact
          path={`/enterprise/loansAudit/index`}
          render={(props) => <LoansAuditList {...props} permission={this.props.permission} />}
        />
        <Route
          exact
          path={`/enterprise/loansAudit/index/detail/:id`}
          render={(props) => (
            <EnterpriseManagementDetail {...props} from={"loansAudit"} permission={this.props.permission} />
          )}
        />
      </div>
    );
  }
}

export default LoansComponent;
