import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Table, Breadcrumb, Card, Input, Modal, DatePicker, Descriptions } from "antd";

import "@/component/carousel/styles.less";

import { TableSize } from "@/constants";
import Utils from "@/util/util";
import dialog from "@/util/dialog";

import { modalLabelCol, modalWrapperCol, modalSubmitFormLayout } from "@/util/modalFormLayout";

import { ResultCodeEnum, followRecordsApi } from "@/api/index";

const TextArea = Input.TextArea;

import moment from "moment";

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    /**
     * @param list 表格列表数据
     * @param pagination 分页控件参数
     *        showSizeChanger/是否可以改变 pageSize
     *        showQuickJumper	是否可以快速跳转至某页
     * @param loading 表格页面是否加载中
     * @param config 默认请求参数
     * @param searchContent 搜索内容
     */
    enterpriseId: "",
    list: [],
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
    },
    loading: false,
    config: {
      pageNum: 0,
      pageSize: 10,
    },
    searchContent: {},

    /** 弹窗 */
    detail: {}, // 详情
    insertVisible: false,
    detailVisible: false,
    modalLoading: false,
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({ enterpriseId: id }, () => {
      this.getList();
    });
  }

  /**
   * 获取表格列表数据
   */
  getList = async (params) => {
    params = {
      enterpriseId: this.state.enterpriseId,
      ...this.state.config,
      ...this.state.searchContent,
      ...params,
    };
    this.setState({ loading: true });
    const res = await followRecordsApi.list({ ...params });
    this.setState({ loading: false });
    if (res.code === ResultCodeEnum.success.code) {
      const pagination = { ...this.state.pagination };
      pagination.current = params.pageNum + 1;
      pagination.total = res.data.pageInfo.totalCount;
      pagination.showTotal = (total) => {
        return `总共 ${total} 条`;
      };
      res.data.rows = res.data.rows.map((item) => {
        return {
          ...item,
          followTime: moment(item.followTime).format("YYYY-MM-DD"),
          gmtCreate: moment(item.gmtCreate).format("YYYY-MM-DD"),
          gmtModified: moment(item.gmtModified).format("YYYY-MM-DD"),
        };
      });
      this.setState({
        pagination,
        list: res.data.rows,
      });
    }
  };

  /**
   * 数据表格的搜索
   * @param values 搜索表格对应的值
   */
  onFinishSearch = (values) => {
    this.setState({
      searchContent: values,
    });
    this.getList({ ...values });
  };

  /**
   * 表格onChange事件
   * 分页、排序、筛选变化时触发
   * @param pagination 分页参数
   */
  onChangeTable = (pagination) => {
    const pager = { ...this.state.pagination };
    const config = { ...this.state.config };
    pager.current = pagination.current;
    config.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      config,
    });
    this.getList({
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      ...this.state.searchContent,
    });
  };

  handleInsertModalOk = async (values) => {
    this.setState({ modalLoading: true });
    const params = {
      ...values,
      // followTime: values.followTime.toDate(),
    };
    const res = await followRecordsApi.create(params);
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("提交成功", () => {
        this.getList({ pageNum: 0 });
        this.setState({ insertVisible: false, modalLoading: false });
      });
    } else {
      this.setState({ modalLoading: false });
    }
  };

  handleInsertModalOpen = () => {
    this.setState({ insertVisible: true });
  };
  handleDetailModalOpen = (row) => {
    this.setState({ detail: row, detailVisible: true });
  };

  handleInsertModalClose = () => {
    if (!this.state.modalLoading) {
      this.formRef.current.resetFields();
      this.setState({ insertVisible: false });
    }
  };
  handleDetailModalClose = () => {
    this.setState({ detailVisible: false });
  };

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  render() {
    const { enterpriseId, list, loading, pagination, insertVisible, detailVisible, modalLoading, detail } = this.state;

    /**
     * 表格列的配置描述
     */
    const tablecColumns = [
      {
        title: "跟进内容",
        dataIndex: "content",
        key: "content",
        ellipsis: true,
      },
      {
        title: "跟进时间",
        dataIndex: "followTime",
        key: "followTime",
      },
      {
        title: "跟进人",
        dataIndex: "createdName",
        key: "createdName",
      },
      {
        title: "操作",
        dataIndex: "id",
        key: "id ",
        render: (_, record) => {
          return (
            <div className="operation">
              <Button onClick={this.handleDetailModalOpen.bind(this, record)} type="link">
                查看详情
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <Link to={`/enterprise/management/index`}>
                  <span>企业管理</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>跟进记录</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          {/** 表格列表 */}
          <Card bordered={false}>
            <div className="tableListOperator flex">
              <Button onClick={this.handleInsertModalOpen.bind(this)} type="primary">
                新增
              </Button>
            </div>
            <div className="tableList">
              <Table
                columns={tablecColumns}
                dataSource={list}
                loading={loading}
                onChange={this.onChangeTable}
                pagination={pagination}
                rowKey="id"
                size={TableSize}
              />
            </div>
          </Card>
          {/* 新增跟进记录 */}
          <Modal
            footer={null}
            loading={modalLoading}
            onCancel={this.handleInsertModalClose.bind(this)}
            title={`新增跟进记录`}
            visible={insertVisible}
            width={600}
          >
            <Form onFinish={(values) => this.handleInsertModalOk(values)} ref={this.formRef}>
              <Form.Item
                hidden
                initialValue={enterpriseId}
                label="企业ID"
                labelCol={modalLabelCol}
                name="enterpriseId"
                rules={[{ required: false, message: "请输入企业ID" }]}
                wrapperCol={modalWrapperCol}
              >
                <Input placeholder="请输入企业ID" />
              </Form.Item>

              {/* <Form.Item
                label="跟进时间"
                labelCol={modalLabelCol}
                name="followTime"
                rules={[{ required: true, message: "请选择跟进时间" }]}
                wrapperCol={modalWrapperCol}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item> */}

              <Form.Item
                label="跟进内容"
                labelCol={modalLabelCol}
                name="content"
                rules={[{ required: true, message: "请输入跟进内容" }]}
                wrapperCol={modalWrapperCol}
              >
                <TextArea maxLength={200} placeholder="请输入跟进内容" rows={3} showCount></TextArea>
              </Form.Item>

              <Form.Item wrapperCol={modalSubmitFormLayout}>
                <Button htmlType="submit" loading={modalLoading} type="primary">
                  {modalLoading ? "提交中" : "确定"}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {/* 记录详情 */}
          <Modal
            footer={null}
            onCancel={this.handleDetailModalClose.bind(this)}
            title={`记录详情`}
            visible={detailVisible}
            width={900}
          >
            <Descriptions bordered>
              <Descriptions.Item label="跟进人" span={1}>
                {detail.createdName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="跟进时间" span={2}>
                {detail.followTime || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="跟进内容" span={3}>
                {detail.content || "-"}
              </Descriptions.Item>
            </Descriptions>
          </Modal>
        </div>
      </div>
    );
  }
}

const EnterpriseProgressList = List;
export default EnterpriseProgressList;
