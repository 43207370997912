import React from "react";
import { Button, Form, Breadcrumb, Card, Input } from "antd";

import dialog from "@/util/dialog";
import { formItemLayout, submitFormLayout } from "@/util/formLayout";

import { ResultCodeEnum, mdConfiguresApi } from "@/api/index";

const FormItem = Form.Item;

class Detail extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: "",
    bankId: "",
    employeeId: "",
    isEdit: false, // 是否为编辑模式
    detail: {}, // 详情
    submitting: false, // 提交的loading
    imgUploadLoading: false,
    code: "enterprise_template_url",
  };

  /**
   * 在组件render之后调用
   */
  UNSAFE_componentWillMount() {
    this.getDetail();
  }

  /**
   * 我们推荐使用 Form.useForm 创建表单数据域进行控制。如果是在 class component 下，你也可以通过 ref 获取数据域。
   * 你不能用控件的 value 或 defaultValue 等属性来设置表单域的值，
   * 默认值可以用 Form 里的 initialValues 来设置。
   * 注意 initialValues 不能被 setState 动态更新，你需要用 setFieldsValue 来更新。
   */
  formRef = React.createRef();

  // 获取详情
  getDetail = async (id) => {
    const res = await mdConfiguresApi.detail({
      code: this.state.code,
    });
    if (res.code === ResultCodeEnum.success.code) {
      const detail = res.data;
      this.setState({
        detail,
      });
      // 回填表单的值
      this.formRef.current.setFieldsValue({
        value: detail.value,
      });
    }
  };

  /**
   * 提交信息
   */
  onSubmit = async (values) => {
    values.code = this.state.code;
    this.setState({ submitting: true });
    const res = await mdConfiguresApi.updateByCode(values);
    this.setState({ submitting: false });
    if (res.code === ResultCodeEnum.success.code) {
      dialog.success("编辑成功");
    }
  };

  render() {
    const { submitting } = this.state;

    return (
      <div id="wrap">
        <div className="pageHeader">
          <div className="breadcrumb">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <span>配置管理</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>企业导入模板</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="pageHeaderWrapper">
          <Card bordered={false}>
            <Form onFinish={this.onSubmit.bind(this)} ref={this.formRef}>
              <FormItem
                {...formItemLayout}
                label="企业导入模板URL"
                name="value"
                rules={[{ required: true, message: "请输入企业导入模板URL" }]}
              >
                <Input placeholder="请输入企业导入模板URL"></Input>
              </FormItem>

              <FormItem {...submitFormLayout}>
                <Button htmlType="submit" loading={submitting} type="primary">
                  {submitting ? "正在提交..." : "提交"}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const EnterpriseTemplateUrlDetail = Detail;
export default EnterpriseTemplateUrlDetail;
